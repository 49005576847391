import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const AddPriceRule: React.FC = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg width="50" height="50" viewBox="0 0 50 50" fill="black" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.33 3L47.33 22.9284L19.9284 50.33L-3.03609e-06 30.4016L27.4016 3L47.33 3ZM20.9933 18.5102L25.0971 22.614L29.2008 18.5102C29.3103 18.4008 29.4979 18.4008 29.6073 18.5102L31.6839 20.5869C31.7934 20.6963 31.7934 20.8839 31.6839 20.9933L27.5802 25.0971L31.6839 29.2008C31.7542 29.2711 31.7765 29.3517 31.7665 29.4271C31.7645 29.4887 31.7397 29.5515 31.6839 29.6073L29.6073 31.6839C29.4979 31.7934 29.3103 31.7934 29.2008 31.6839L25.0971 27.5802L20.9933 31.6839C20.8839 31.7934 20.6963 31.7934 20.5869 31.6839L18.5102 29.6073C18.4008 29.4979 18.4008 29.3103 18.5102 29.2008L22.614 25.0971L18.5102 20.9933C18.4008 20.8839 18.4008 20.6963 18.5102 20.5869L20.5869 18.5102C20.6963 18.4008 20.8839 18.4008 20.9933 18.5102Z"
      />
    </svg>
  </BaseSvgWrapper>
);

export default AddPriceRule;
