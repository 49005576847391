import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { media } from 'config/theme/utils';
import styled from '../../config/theme';
import { AnimationProps } from '../../config/theme/types';

const ItemSidebarWrapper = styled.div<AnimationProps>`
  background-color: ${(props) => props.theme.itemSidebar.background.main};
  border-right: ${(props) => props.theme.itemSidebar.border.right};
  display: flex;
  flex-direction: column;
  font-size: 10px;
  max-height: 100vh;
  min-width: ${(props) => props.theme.internalSidebar.width.large};
  position: relative;
  width: ${(props) => props.theme.internalSidebar.width.large};

  ${(props) => media.desktop`
    width: ${props.theme.internalSidebar.width.huge};
    min-width: ${props.theme.internalSidebar.width.huge};

    .search > .clearSearch > svg {
      margin-left: 35px;
    }
  `};

  > span.count {
    position: fixed;
    top: calc(${(props) => props.theme.header.height} - ${(props) => props.theme.itemSidebar.fontSize.count} + 1px);
    padding: 0px 8px;
    border-radius: ${(props) => props.theme.itemSidebar.border.radiusCount};
    margin-left: 7px;
    font-size: ${(props) => props.theme.itemSidebar.fontSize.count};
    color: ${(props) => props.theme.itemSidebar.color.count};
    background: ${(props) => props.theme.itemSidebar.background.count};
    z-index: 2;
  }

  .infinite-scroll-component {
    > * {
      flex-shrink: 0;
    }

    > *:not(:last-child) {
      border-bottom: ${(props) => props.theme.itemSidebar.border.itemBottom};
    }
  }

  .loading-text {
    color: ${(props) => props.theme.itemSidebar.color.loading};
    font-weight: 200;
    text-align: center;
  }

  #scrollable-list {
    max-height: 100%;
    overflow: auto;
  }

  ${(props) => props.state === 'entering' && props.theme.itemSidebar.animation.main.entering};
  ${(props) => props.state === 'entered' && props.theme.itemSidebar.animation.main.entered};
`;

const TransitionedWrapper: React.FC<React.RefAttributes<HTMLDivElement>> = React.forwardRef(
  ({ children, ...rest }, ref) => (
    <CSSTransition in timeout={200} mountOnEnter appear>
      {(state) => (
        <ItemSidebarWrapper state={state} {...rest} ref={ref}>
          {children}
        </ItemSidebarWrapper>
      )}
    </CSSTransition>
  )
);

export default TransitionedWrapper;
