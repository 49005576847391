import { useGetExternalLinks } from '../adapters/queries';

const useConfigStore = () => {
  const { data, isLoading, isFetching } = useGetExternalLinks();

  return {
    externalLinks: data,
    isExternalLinksLoading: isLoading || isFetching,
  };
};

export default useConfigStore;
