import { createAction, createAsyncAction } from 'typesafe-actions';
import { HttpErrorResponse } from '../../../../../types';

export const initiateLogin = createAction(
  'LOGIN_INITIATED',
  action => (email: string, password: string, returnUrl: string) =>
    action({
      email,
      password,
      returnUrl,
    })
);

export const loginRequest = createAsyncAction('LOGIN_REQUEST', 'LOGIN_SUCCESS', 'LOGIN_REJECTED')<
  undefined,
  undefined,
  HttpErrorResponse
>();

export const initiateRedirect = createAction('LOGIN_REDIRECT_INITIATED', action => (returnUrl: string) =>
  action({
    returnUrl,
  })
);

type RedirectResponse = {
  isSameOrigin: boolean;
};

export const loginRedirectRequest = createAsyncAction(
  'LOGIN_REDIRECT_REQUEST',
  'LOGIN_REDIRECT_SUCCESS',
  'LOGIN_REDIRECT_REJECTED'
)<undefined, RedirectResponse, HttpErrorResponse>();

export const initiateOauthSignIn = createAction('OAUTH_SIGN_IN_INITIATED', action => (searchQuery: string) =>
  action({
    searchQuery,
  })
);

export const oauthSignInRequest = createAsyncAction(
  'OAUTH_SIGN_IN_REQUEST',
  'OAUTH_SIGN_IN_SUCCESS',
  'OAUTH_SIGN_IN_REJECTED'
)<undefined, undefined, HttpErrorResponse>();
