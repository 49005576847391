import * as React from 'react';

const CreditCard: React.FC = () => (
  <svg width="42" height="32" viewBox="0 0 42 32" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35 4H7C5.34315 4 4 5.34315 4 7V25C4 26.6569 5.34315 28 7 28H35C36.6569 28 38 26.6569 38 25V7C38 5.34315 36.6569 4 35 4ZM7 0C3.13401 0 0 3.13401 0 7V25C0 28.866 3.13401 32 7 32H35C38.866 32 42 28.866 42 25V7C42 3.13401 38.866 0 35 0H7Z"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M38 12L4 12L4 8L38 8L38 12Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M16 20H6V18H16V20Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M20 24H6V22H20V24Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.5 22C27.3284 22 28 21.3284 28 20.5C28 19.6716 27.3284 19 26.5 19C25.6716 19 25 19.6716 25 20.5C25 21.3284 25.6716 22 26.5 22ZM26.5 24C28.433 24 30 22.433 30 20.5C30 18.567 28.433 17 26.5 17C24.567 17 23 18.567 23 20.5C23 22.433 24.567 24 26.5 24Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.1667 22C32.9951 22 33.6667 21.3284 33.6667 20.5C33.6667 19.6716 32.9951 19 32.1667 19C31.3382 19 30.6667 19.6716 30.6667 20.5C30.6667 21.3284 31.3382 22 32.1667 22ZM32.1667 24C34.0997 24 35.6667 22.433 35.6667 20.5C35.6667 18.567 34.0997 17 32.1667 17C30.2337 17 28.6667 18.567 28.6667 20.5C28.6667 22.433 30.2337 24 32.1667 24Z"
    />
  </svg>
);

export default CreditCard;
