import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const Activate: React.FC = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
      <path d="M25 4C13.38 4 4 13.38 4 25C4 36.62 13.38 46 25 46C36.62 46 46 36.62 46 25C46 13.38 36.62 4 25 4ZM25 40.7C16.6 40.7 9.3 33.925 9.3 25C9.3 16.075 17.125 9.3 25 9.3C35.08 9.3 40.7 16.075 40.7 25C40.7 33.4 33.925 40.7 25 40.7Z" />
      <path d="M20.8 33.4691V16.5309C20.8 15.7012 21.7521 15.2324 22.4097 15.7382L33.4196 24.2074C33.94 24.6077 33.94 25.3923 33.4196 25.7926L22.4097 34.2618C21.7521 34.7676 20.8 34.2988 20.8 33.4691Z" />
    </svg>
  </BaseSvgWrapper>
);

export default Activate;
