const formatPercentage = (numberToFormat: string | number | null, precision?: number): string => {
  const parsedNumber =
    typeof numberToFormat === 'string' ? parseFloat(numberToFormat.replace(/,/, '.')) : numberToFormat;

  if (parsedNumber === null) {
    return '';
  }

  if (isNaN(parsedNumber)) {
    return `${numberToFormat}`;
  }

  if (precision && parsedNumber !== undefined) {
    return `${parsedNumber.toFixed(precision)}%`;
  }

  if (numberToFormat !== undefined) {
    return `${numberToFormat}%`;
  }

  return '';
};

export default formatPercentage;
