import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { LinkButton } from '../';
import FourOFour from './images/404.svg';
import { NoListItemSelectedImage, NoListItemSelectedWrapper } from './';

const NotFound: React.FC = () => {
  const { t } = useTranslation();

  return (
    <NoListItemSelectedWrapper>
      <NoListItemSelectedImage src={FourOFour} />
      <h1>{t('notFound.title')}</h1>
      <p>{t('notFound.subText')}</p>
      <LinkButton color="primary" to={'/'}>
        {t('notFound.primaryButton')}
      </LinkButton>
    </NoListItemSelectedWrapper>
  );
};

export default NotFound;
