import * as React from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import AddButton from './AddButton';
import ItemSidebarWrapper from './ItemSidebarWrapper';
import LoadingState from './LoadingState';

type Props = {
  addButtonUrl?: string;
  children: JSX.Element | JSX.Element[] | undefined;
  getDataCb: () => void;
  hasMore: boolean;
  isPending: boolean;
  listSize: number;
  search?: JSX.Element;
  searchText?: string;
  totalItems?: number;
  filterComponent?: React.ReactNode;
};

const ItemSidebar: React.FC<Props> = ({
  addButtonUrl,
  children,
  getDataCb,
  hasMore,
  isPending,
  listSize,
  search,
  searchText,
  totalItems,
  filterComponent,
}) => {
  const { t } = useTranslation();
  const wrapper = React.createRef<HTMLDivElement>();
  React.useEffect(() => {
    if (wrapper && wrapper.current) {
      wrapper.current.scrollTop = 0;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  return (
    <ItemSidebarWrapper>
      {search}
      {Boolean(totalItems) && <span className="count">{`${listSize} ${t('list.countOf')} ${totalItems}`}</span>}
      {addButtonUrl && <AddButton addUrl={addButtonUrl} data-cy="add-button" />}
      {filterComponent}
      <div id="scrollable-list" ref={wrapper} style={{ maxHeight: '100%', overflow: 'auto' }}>
        <InfiniteScroll
          dataLength={listSize}
          next={getDataCb}
          hasMore={hasMore}
          loader={<h4 className="loading-text">{t('general.loading')}</h4>}
          scrollableTarget="scrollable-list"
          scrollThreshold="75%"
        >
          {children || (isPending && <LoadingState />)}
        </InfiniteScroll>
      </div>
    </ItemSidebarWrapper>
  );
};

export default ItemSidebar;
