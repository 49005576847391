import * as React from 'react';
import { MainInfoTitleWrapper, MainInfoWrapper } from '../../../components/Paper';
import styled from '../../../config/theme';
import ProductInfoLoadingState from './ProductInfoLoadingState';
import { ProductStatusBadge } from './';

type Props = {
  code?: string;
  productIsPending?: boolean;
  priceMethodDescription?: string;
  productDescription?: string;
  productStatusDescription?: string;
  productStatusId?: number;
  productTypeDescription?: string;
};

const ProductInfo: React.FC<Props> = ({
  code,
  productIsPending,
  priceMethodDescription,
  productDescription,
  productStatusDescription,
  productStatusId,
  productTypeDescription,
}: Props) => (
  <ProductInfoWrapper data-cy="product-general-info">
    {!code || productIsPending ? (
      <ProductInfoLoadingState />
    ) : (
      <ProductInfoTitleWrapper>
        <h1>{productDescription}</h1>
        <h2>{code}</h2>
        <p>{productTypeDescription}</p>
        <BadgeWrapper>
          <ProductStatusBadge text={priceMethodDescription || ''} />
          <ProductStatusBadge id={productStatusId} text={productStatusDescription || ''} />
        </BadgeWrapper>
      </ProductInfoTitleWrapper>
    )}
  </ProductInfoWrapper>
);

const BadgeWrapper = styled.div`
  display: flex;
`;

const ProductInfoTitleWrapper = styled(MainInfoTitleWrapper)`
  flex-direction: column;
`;

const ProductInfoWrapper = styled(MainInfoWrapper)`
  padding: 0;
  flex-direction: column;
  justify-content: space-between;
`;

export default ProductInfo;
