import { Formik } from 'formik';
import * as React from 'react';
import productPriceSchema from '../../../schema/productPriceSchema';
import { PricingFormValues, Pricing } from '../../../types/pricing';
import PricingForm from '../PricingForm';
import useGlobalInfoStore from 'modules/products/services/useGlobalInfoStore';
import { GetParamByIndex, SuccessCallback } from 'types';
import { api } from '../../../adapters';

type Props = {
  formRef: React.RefObject<any>;
  productGuid: string;
  toggleModal: () => void;
  price?: Pricing;
  isEdit?: boolean;
  createPricing: (params: GetParamByIndex<typeof api.createPrice, 0>, successCallback?: SuccessCallback) => void;
  updatePricing: (params: GetParamByIndex<typeof api.updatePrice, 0>, successCallback?: SuccessCallback) => void;
};

const PricingFormik: React.FC<Props> = ({
  formRef,
  productGuid,
  toggleModal,
  price,
  isEdit,
  createPricing,
  updatePricing,
}) => {
  const { priceTranslations } = useGlobalInfoStore();

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize
      initialValues={{
        amount: price?.amount || 0,
        dateFrom: price?.dateFrom ? new Date(price?.dateFrom) : undefined,
        dateUntil: price?.dateUntil ? new Date(price?.dateUntil) : undefined,
        entityId: price?.entityId,
        priceTypeId: price?.priceTypeId,
        quantityFrom: price?.quantityFrom,
        quantityUntil: price?.quantityUntil,
        priceTranslation:
          priceTranslations?.find(
            (item) => item.billingPlanId === price?.billingPlanId && item.termDurationId === price.termDurationId
          ) || null,
      }}
      onSubmit={(values: PricingFormValues, { resetForm }) => {
        const { priceTranslation } = values;
        const payload: Pricing = {
          ...values,
          productGuid,
          priceTypeDescription: '',
          priceTypeId: values.priceTypeId ?? 0,
          priceId: price?.priceId ?? 0,
          termDurationId: priceTranslation?.termDurationId,
          billingPlanId: priceTranslation?.billingPlanId,
          // If the hours are zero (which is the case when we don't edit the date), the toIsoString() will subtract 1 day
          dateFrom: values?.dateFrom ? new Date(values.dateFrom.setHours(12) || '')?.toISOString() : undefined,
          dateUntil: values?.dateUntil ? new Date(values.dateUntil.setHours(12) || '')?.toISOString() : undefined,
        };
        if (isEdit) {
          updatePricing(payload, () => {
            toggleModal();
            resetForm();
          });
        } else {
          createPricing(payload, () => {
            toggleModal();
            resetForm();
          });
        }
      }}
      validationSchema={productPriceSchema}
      render={({ errors, touched, setFieldValue }) => (
        <PricingForm errors={errors} touched={touched} setFieldValue={setFieldValue} isEdit={isEdit} />
      )}
    />
  );
};

export default PricingFormik;
