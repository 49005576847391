import { Button } from '@unbrace/components';
import { ButtonProps } from '@unbrace/components/dist/components/Button/Button';
import * as React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  to: string;
} & ButtonProps;

const LinkButton: React.FC<Props> = ({ to, ...props }: Props) => (
  <Link to={to}>
    <Button {...props} />
  </Link>
);

export default LinkButton;
