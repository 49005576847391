import { SortOrder } from '@unbrace/components';

export type ModuleFilters = {
  searchText?: string;
  sorting?: SortingFilter;
  pageIndex?: number;
  pageSize: number;
  additionalFilters?: {
    [key: string]: any;
  };
};

export type FormattedModuleFilters = Omit<ModuleFilters, 'sorting'> & {
  orderBys?: string[];
};

export type SortingFilter = {
  columnNames: string[];
  type: SortOrder;
};

export type getCustomFiltersParameters = {
  screen: string;
};

export type ScreenConfiguration = {
  screenConfigurationId: number;
  applicationId: number;
  userGuid: string;
  screen: string;
  name: string;
  type: string;
  values: CustomFilter;
};

export enum screenConfigTypes {
  FILTERS = 0,
}

export type CustomFilter = {
  additionalFilters: {
    [key: string]: any;
  };
};

export type SaveCustomFilterData = {
  screen: string;
  name: string;
  values: CustomFilter;
};

export enum ApplicationCode {
  CBO = 'CBO',
}
