import { DatePickerInput } from '@unbrace/components';
import { FieldProps } from 'formik';
import * as React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DayPickerProps } from 'react-day-picker';
import { dateParser, getNestedError } from 'helpers';
import { ErrorText } from './ErrorText';
import { RelativeContainer } from 'components/RelativeContainer';

type Props = {
  disabled?: boolean;
  inputProps?: object;
  dayPickerProps?: DayPickerProps;
  errorAsInline?: boolean;
} & FieldProps;

const FormikDatePicker = ({ disabled, form, field, inputProps, errorAsInline, dayPickerProps, ...props }: Props) => {
  const error = getNestedError(field.name, form.errors, form.touched);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue = dateParser(event.target.value);
    if (parsedValue || !event.target.value.trim()) {
      form.setFieldValue(field.name, parsedValue);
    }
  };

  return (
    <React.Fragment>
      <RelativeContainer>
        <DatePickerInput
          value={field.value}
          dayPickerProps={{
            onDayClick: (date: Date | undefined) => {
              form.setFieldValue(field.name, date);
              form.setFieldTouched(field.name);
            },
            ...dayPickerProps,
          }}
          inputProps={{
            ...field,
            disabled,
            autoComplete: 'off',
            onChange: handleChange,
            isClearable: disabled !== undefined ? !disabled : true,
            onClear: () => form.setFieldValue(field.name, undefined),
            ...inputProps,
          }}
          {...props}
        />
        {error && <ErrorText block={!errorAsInline}>{error}</ErrorText>}
      </RelativeContainer>
    </React.Fragment>
  );
};

export { FormikDatePicker };
