import BaseSvgWrapper from './BaseSvgWrapper';

type Props = {
  auto?: boolean;
};

const Refresh = ({ auto }: Props): JSX.Element => (
  <BaseSvgWrapper>
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M35.8277 35.0629C33.183 37.6469 29.5341 39.25 25.5 39.25C17.4343 39.25 10.875 32.8589 10.875 25H6C6 35.4928 14.7311 44 25.5 44C30.8844 44 35.7351 41.8506 39.2646 38.4116L45 44V29.75H30.375L35.8277 35.0629Z" />
      <path d="M15.1723 14.4075C17.817 11.6875 21.4659 10 25.5 10C33.5657 10 40.125 16.7275 40.125 25H45C45 13.955 36.2689 5 25.5 5C20.1156 5 15.2649 7.2625 11.7354 10.8825L6 5V20H20.625L15.1723 14.4075Z" />
      {auto && (
        <text x="19" y="31" style={{ fontSize: 20 }}>
          {'A'}
        </text>
      )}
    </svg>
  </BaseSvgWrapper>
);

export default Refresh;
