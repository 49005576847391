import * as React from 'react';
import { useSelector } from 'react-redux';
import { createActionRequestIsPendingSelector } from '../modules/app/redux/selectors/loading';

const useActionIsPending = (action: string, manualInit = true): [boolean, () => void] => {
  const isActionPending = useSelector(createActionRequestIsPendingSelector(action));
  const [running, setRunning] = React.useState(false);
  const [started, setStart] = React.useState(false);

  React.useEffect(() => {
    if (started) {
      setRunning(isActionPending);
    }
    if (started && !isActionPending && !running) {
      setStart(false);
    }
    // It's important that we do not want to run an effect on the started change
    // Because we are working async, the isActionPending is too late resulting in a reset of the started value
    // This way it doesn't really matter if the start action is called after or before the dispatch
    // eslint-disable-next-line
  }, [isActionPending, setRunning, running]);

  const start = () => {
    setStart(true);
  };

  if (!manualInit) {
    return [isActionPending, () => undefined];
  }

  return [running, start];
};

export default useActionIsPending;
