import * as Yup from 'yup';
import { ProductBaseFormValues, ProductAndPriceFormValues } from '../types/product';
import i18n from '../../../config/i18n';
import productPriceSchema from './productPriceSchema';

const partnerCenterSchema = Yup.object().shape({
  offerId: Yup.string(),
  offerUri: Yup.string(),
  availabilityProductId: Yup.string(),
  availabilitySkuId: Yup.string(),
  availabilityId: Yup.string(),
  segment: Yup.string().when(['availabilityProductId', 'availabilitySkuId', 'availabilityId'], {
    is: (availabilityProductId, availabilitySkuId, availabilityId) =>
      Boolean(availabilityProductId) && Boolean(availabilitySkuId) && Boolean(availabilityId),
    then: Yup.string().required(() => i18n.t('validation:required')),
    otherwise: Yup.string(),
  }),
});

const productBaseEditScheme = Yup.object().shape({
  code: Yup.string().required(),
  metadatas: Yup.array().of(
    Yup.object().shape({
      metadataId: Yup.number().required(),
      value: Yup.string().required(),
    })
  ),
  partnerPricingProfile: Yup.object().shape({
    rebateGroupId: Yup.number().required().nullable(),
  }),
  priceMethodId: Yup.number().required(),
  productStatusId: Yup.number().required(),
  productTypeId: Yup.number().required(),
  translations: Yup.array().of(
    Yup.object().shape({
      description: Yup.string().required(),
    })
  ),
});

const extendedProductSchema = Yup.object().shape({
  partnerCenterProfile: partnerCenterSchema,
});

export const fullProductSchema: Yup.ObjectSchema<ProductBaseFormValues> = productBaseEditScheme.concat(
  extendedProductSchema
) as Yup.ObjectSchema<ProductBaseFormValues>;

export const productAndPriceSchema: Yup.ObjectSchema<ProductAndPriceFormValues> =
  fullProductSchema.concat(productPriceSchema);

export default productBaseEditScheme;
