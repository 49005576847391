import { Field, FieldProps, FormikErrors, FormikTouched } from 'formik';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../../components/Form';
import { EditableItem, FullViewBlock } from '../../../../components/FullView';
import { getNestedError } from '../../../../helpers';
import { PartnerCenterProfile, ProductBaseFormValues } from '../../types/product';
import SegmentField from './SegmentField';

type Props = {
  touched: FormikTouched<ProductBaseFormValues>;
  errors: FormikErrors<ProductBaseFormValues>;
  partnerCenterProfile?: PartnerCenterProfile;
};

const PartnerCenterInfo: React.FC<Props> = ({ touched, errors, partnerCenterProfile }: Props) => {
  const { t } = useTranslation('products');

  return partnerCenterProfile ? (
    <FullViewBlock amountOfButtons={1}>
      <h2 className="block-title">{t('fullView.blockTitles.partnerCenterProfile')}</h2>
      <div className="block-data">
        <EditableItem
          label={t('fullView.labels.offerId')}
          value={
            <Field
              name={t(`partnerCenterProfile.offerId`)}
              render={({ field }: FieldProps) => (
                <Input
                  {...field}
                  value={field.value || ''}
                  type="text"
                  noLabel
                  error={getNestedError('partnerCenterProfile.offerId', errors, touched)}
                />
              )}
            />
          }
        />
        <EditableItem
          label={t('fullView.labels.offerUri')}
          value={
            <Field
              name={t(`partnerCenterProfile.offerUri`)}
              render={({ field }: FieldProps) => (
                <Input
                  {...field}
                  value={field.value || ''}
                  type="text"
                  noLabel
                  error={getNestedError('partnerCenterProfile.offerUri', errors, touched)}
                />
              )}
            />
          }
        />
        <EditableItem
          label={t('fullView.labels.availabilityProductId')}
          value={
            <Field
              name={t(`partnerCenterProfile.availabilityProductId`)}
              render={({ field }: FieldProps) => (
                <Input
                  {...field}
                  value={field.value || ''}
                  type="text"
                  noLabel
                  error={getNestedError('partnerCenterProfile.availabilityProductId', errors, touched)}
                />
              )}
            />
          }
        />
        <EditableItem
          label={t('fullView.labels.availabilitySkuId')}
          value={
            <Field
              name={t(`partnerCenterProfile.availabilitySkuId`)}
              render={({ field }: FieldProps) => (
                <Input
                  {...field}
                  value={field.value || ''}
                  type="text"
                  noLabel
                  error={getNestedError('partnerCenterProfile.availabilitySkuId', errors, touched)}
                />
              )}
            />
          }
        />
        <EditableItem
          label={t('fullView.labels.availabilityId')}
          value={
            <Field
              name={t(`partnerCenterProfile.availabilityId`)}
              render={({ field }: FieldProps) => (
                <Input
                  {...field}
                  value={field.value || ''}
                  type="text"
                  noLabel
                  error={getNestedError('partnerCenterProfile.availabilityId', errors, touched)}
                />
              )}
            />
          }
        />
        <SegmentField errors={errors} touched={touched} />
      </div>
    </FullViewBlock>
  ) : null;
};

export default PartnerCenterInfo;
