import { ModalContent } from '@unbrace/components';
import styled from 'styled-components';

export const ShortcutTitle = styled.h2`
  font-size: ${(props) => props.theme.typography.fontSize.medium_4};
  text-align: center;
  margin: 15px 0 20px;
`;

export const KeyCapShortcut = styled.div`
  width: 100%;
  padding: 0 12px;
  font-weight: 900;
  margin-bottom: 12px;
`;

export const CategoryWrapper = styled.div`
  width: 100%;
`;

export const ModalBody = styled(ModalContent.Body)`
  display: flex;
`;
