import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const CalculatePeriod: React.FC = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
      <path d="M29.579 26.6353V29.4123C26.9119 29.9809 25 32.0973 25 34.5216C25 37.1188 26.9138 38.8769 30.688 39.7472C34.1575 40.5469 34.1575 41.7359 34.1575 42.1265C34.1575 43.3957 32.7112 44.468 30.9993 44.468C29.2872 44.468 27.8406 43.3957 27.8406 42.1265V41.8416H25V42.1265C25 44.5509 26.9119 46.667 29.579 47.2355V50H32.4196V47.2355C35.0865 46.6669 36.9981 44.5507 36.9981 42.1265C36.9981 39.5294 35.0846 37.7713 31.3104 36.901C27.8406 36.1013 27.8406 34.9123 27.8406 34.5216C27.8406 33.2522 29.2872 32.1798 30.9993 32.1798C32.7112 32.1798 34.1575 33.2522 34.1575 34.5216V34.8066H36.9981V34.5216C36.9981 32.0973 35.0865 29.9809 32.4196 29.4123V26.6353H29.579Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6632 45H5V9.44444H9.44444V5H16.1111V9.44444H33.8889V5H40.5556V9.44444H45V45H40.5548V40.5556H40.5556V22.7778H9.44444V40.5556H20.6632V45ZM40.5556 18.3333V13.8889H9.44444V18.3333H40.5556Z"
      />
    </svg>
  </BaseSvgWrapper>
);

export default CalculatePeriod;
