import * as React from 'react';
import { Route, RouteComponentProps, RouteProps, StaticContext } from 'react-router';
import { NotFound } from '../../../components/EmptyState';

export const AccessRightsRoute = ({ hasAccess, render, ...props }: RouteProps & { hasAccess: boolean }) => (
  <Route
    {...props}
    render={hasAccess ? render : (NotFound as (props: RouteComponentProps<any, StaticContext, any>) => React.ReactNode)}
  />
);
