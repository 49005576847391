import * as React from 'react';
import { ConfirmationModal } from 'components';
import useIdentityStore from '../services/useIdentityStore';

type Props = {
  closeModal: () => void;
};

const ResetPasswordModal: React.FC<Props> = ({ closeModal }) => {
  const { resetPassword, isResetPasswordLoading } = useIdentityStore();

  const handleResetPassword = () => {
    resetPassword(() => closeModal?.());
  };

  return (
    <ConfirmationModal onSuccess={handleResetPassword} closeModal={closeModal} isPending={isResetPasswordLoading} />
  );
};

export default ResetPasswordModal;
