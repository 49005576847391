import { addMinutes, addYears, compareAsc, format, isAfter, isBefore, subMinutes, subYears } from 'date-fns';
import groupBy, { Group } from '../../../helpers/groupBy';
import { Pricing } from '../types/pricing';

type ExtendedPricing = {
  range: string;
} & Pricing;

export type Series = {
  data: Array<[string, number]>;
  name: string;
};

const getMaxDate = (pricing: Pricing[]) => {
  const list = pricing.filter((item) => item.dateUntil).map((item) => new Date(item.dateUntil as string).getTime());
  if (list.length > 0) {
    return addMinutes(new Date(Math.max.apply(Math, list)), 1);
  }

  return addYears(new Date(), 1);
};

const getMinDate = (pricing: Pricing[]) => {
  const list = pricing.filter((item) => item.dateFrom).map((item) => new Date(item.dateFrom as string).getTime());
  if (list.length > 0) {
    return subMinutes(new Date(Math.min.apply(Math, list)), 1);
  }

  return subYears(new Date(), 2);
};

const undefToString = (date: string | undefined) => date || '';

export const mapPricingForGraph = (pricing: Pricing[]): Series[] => {
  const maxDate = getMaxDate(pricing);
  const minDate = getMinDate(pricing);

  const rangeMap: ExtendedPricing[] = pricing.map((item: Pricing) => ({
    ...item,
    range: `${item.quantityFrom || 0} -> ${item.quantityUntil || '...'} (${item.priceTypeDescription})`,
  }));

  const group: Group<ExtendedPricing> = groupBy(rangeMap, 'range') as Group<ExtendedPricing>;

  return Object.keys(group).map((rangeKey) => {
    const sortedByDateFrom = group[rangeKey].sort((a, b) =>
      isBefore(undefToString(a.dateFrom), undefToString(b.dateFrom)) || (a.dateFrom && !b.dateFrom)
        ? -1
        : isAfter(undefToString(a.dateFrom), undefToString(b.dateFrom)) || (!a.dateFrom && b.dateFrom)
        ? 1
        : 0
    );

    const dataSet = sortedByDateFrom
      .reduce((result: Array<[any, number]>, item) => {
        const dataItem = { ...item };
        sortedByDateFrom
          .filter(
            (x) =>
              x !== item &&
              (isAfter(undefToString(x.dateFrom), undefToString(item.dateFrom)) ||
                (x.dateFrom && x.dateUntil === undefined) ||
                isAfter(undefToString(x.dateUntil), undefToString(item.dateUntil))) &&
              !(
                isAfter(undefToString(x.dateFrom), undefToString(item.dateFrom)) &&
                isBefore(undefToString(x.dateUntil), undefToString(item.dateUntil))
              ) &&
              !isBefore(undefToString(item.dateUntil), undefToString(x.dateFrom)) &&
              !isBefore(undefToString(x.dateFrom), undefToString(item.dateFrom))
          )
          .forEach((other) => {
            dataItem.amount = other.amount;
          });

        result.push([format(item.dateFrom || minDate), item.amount]);
        if (!item.dateUntil) {
          result.push([format(maxDate), item.amount]);
        } else {
          result.push([format(item.dateUntil), dataItem.amount]);
        }

        return result;
      }, [])
      .sort((a, b) => compareAsc(a[0], b[0]));

    return {
      data: dataSet,
      name: rangeKey,
    };
  });
};
