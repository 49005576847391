export const CMS_MODULE_KEY: 'cms' = 'cms';
export const CMS_ADD_LINK = '/cms/add';

export enum CMSItemStatus {
  InActive = 0, // CONCEALED
  Active = 1, // PUBLISHED
  Hidden = 2, // DELETED
}

export enum CMSSearchTags {
  isFeatured = 'isFeatured',
}
