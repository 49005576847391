import { GetPriceResponse, GetPriceTranslations } from 'adapters/products/productsAPI';
import { api } from '..';
import { productsKeys } from '../keyFactory';
import { useMutationWithSuccessAlert, useQueryWithAlert } from 'adapters/queries';
import { groupBy } from 'helpers';
import { Group } from 'helpers/groupBy';
import { useQueryClient } from '@tanstack/react-query';
import { GetParamByIndex } from 'types';

export const useGetPricing = (productguid?: string) =>
  useQueryWithAlert(productsKeys.pricing(productguid), () => api.getPrices({ productguid: productguid || '' }), {
    enabled: productguid !== undefined,
    keepPreviousData: true,
    select: (data) => ({
      entity: groupBy(
        data.filter((pricing) => Boolean(pricing.entityId)).map((pricing) => formatPricingPayload(pricing)),
        'entityId'
      ) as Group<GetPriceResponse>,
      general: data.filter((pricing) => !pricing.entityId).map((pricing) => formatPricingPayload(pricing)),
    }),
  });

export const useMutateUpdatePricing = () => {
  const queryClient = useQueryClient();

  return useMutationWithSuccessAlert(
    (payload: GetParamByIndex<typeof api.updatePrice, 0>) => api.updatePrice(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(productsKeys.pricingList());
      },
    }
  );
};

export const useMutateCreatePricing = () => {
  const queryClient = useQueryClient();

  return useMutationWithSuccessAlert(
    (payload: GetParamByIndex<typeof api.createPrice, 0>) => api.createPrice(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(productsKeys.pricingList());
      },
    }
  );
};

export const useMutateDeletePricing = () => {
  const queryClient = useQueryClient();

  return useMutationWithSuccessAlert(
    (payload: GetParamByIndex<typeof api.deletePrice, 0>) => api.deletePrice(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(productsKeys.pricingList());
      },
    }
  );
};

const formatPricingPayload = (
  payload: GetPriceResponse,
  priceTranslations?: GetPriceTranslations[]
): GetPriceResponse => ({
  ...payload,
  ...{
    description: priceTranslations
      ? priceTranslations.find(
          (price) => price.billingPlanId === payload.billingPlanId && price.termDurationId === payload.termDurationId
        )?.description
      : payload.description,
  },
});
