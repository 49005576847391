import styled from 'config/theme';
import { media } from 'config/theme/utils';
import ResetFilters from 'modules/app/components/Sidebar/ResetFilters';
import * as React from 'react';
import { CSSTransition } from 'react-transition-group';
import { css } from 'styled-components';
import { AnimationProps } from '../../config/theme/types';
import useKeyShortcut from '../KeyboardShortcuts/hooks/useKeyShortcut';
import FilterCollapseButton from './FilterCollapseButton';

const Sidebar = styled.div<AnimationProps & { sidebarCollapsed?: boolean }>`
  background-color: ${(props) => props.theme.internalSidebar.background.main};
  width: ${(props) => props.theme.internalSidebar.width.large};
  min-width: ${(props) => props.theme.internalSidebar.width.large};
  border-right: ${(props) => props.theme.internalSidebar.border};
  max-height: 100vh;
  overflow-y: scroll;
  padding-bottom: 80px;
  margin-left: 0px;

  ${media.laptopSMandBelow`
    position: absolute;
    z-index: 98;
    height: 100vh;
  `}

  & {
    ${(props) =>
      props.state !== 'entering' &&
      css`
        transition: all 0.2s cubic-bezier(0.67, 0.49, 0.15, 0.82);
      `}
  }

  ${(props) =>
    props.sidebarCollapsed &&
    css`
      margin-left: -${props.theme.internalSidebar.width.large};
    `};

  ${(props) =>
    media.desktop`
      width: ${props.theme.internalSidebar.width.huge};
      min-width: ${props.theme.internalSidebar.width.huge};

      .search > .clearSearch > svg {
        margin-left: 35px;
      }

      ${
        props.sidebarCollapsed &&
        css`
          margin-left: -${props.theme.internalSidebar.width.huge};
        `
      };
    `};

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.scrollbar.track};
  }
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.scrollbar.thumb};
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.scrollbar.thumbHover};
  }
  ::-webkit-scrollbar-button {
    display: ${(props) => props.theme.scrollbar.buttonDisplay};
  }

  .search {
    position: sticky;
    top: 0;
    z-index: 98;
  }

  > div {
    padding: ${(props) => props.theme.internalSidebar.padding.filter};

    h5 {
      margin: 30px 0px 10px;
      font-size: 16px;
      font-weight: 600;
      color: ${(props) => props.theme.internalSidebar.color.filterTitle};

      &:first-of-type {
        margin-top: 0px;
      }
    }
  }
  ${(props) => props.state === 'entering' && props.theme.itemSidebar.animation.main.entering};
  ${(props) => props.state === 'entered' && props.theme.itemSidebar.animation.main.entered};
`;

type Props = {
  hasReset?: boolean;
  moduleKey?: string;
  children?: React.ReactNode | React.ReactNode[];
};

const TransitionedSidebar: React.FC<Props> = ({ hasReset, moduleKey, children, ...props }: Props) => {
  const checkLocalStorage = () => {
    if (localStorage.getItem('sideBarCollapsed') === null) {
      localStorage.setItem('sideBarCollapsed', 'false');

      return false;
    }

    return localStorage.getItem('sideBarCollapsed') === 'true';
  };
  const [sidebarCollapsed, setSidebarCollapsed] = React.useState(checkLocalStorage());

  const handleSidebarCollapse = () => {
    localStorage.setItem('sideBarCollapsed', (!sidebarCollapsed).toString());
    setSidebarCollapsed(!sidebarCollapsed);
  };

  const { useToggleFilters } = useKeyShortcut();
  useToggleFilters(handleSidebarCollapse);

  return (
    <CSSTransition in timeout={200} mountOnEnter appear>
      {(state) => (
        <React.Fragment>
          <FilterCollapseButton
            state={state}
            setSidebarCollapsed={handleSidebarCollapse}
            sidebarCollapsed={sidebarCollapsed}
          />
          <Sidebar sidebarCollapsed={sidebarCollapsed} state={state} {...props}>
            {children}
            {hasReset && moduleKey && <ResetFilters moduleKey={moduleKey} />}
          </Sidebar>
        </React.Fragment>
      )}
    </CSSTransition>
  );
};

export default TransitionedSidebar;
