/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface GetContractOverviewResponse {
  items: ContractOverviewResponse[];
  pageIndex: number;
  pageSize: number;
  totalPages: number;
  totalItems: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface ContractOverviewResponse {
  mainContractid: number;
  mainContractGuid: string;
  contractid: number;
  guid: string;
  contractNumber: string;
  version: number;
  reference?: string;
  quoteReference?: string;
  internalReference?: string;
  contractType: string;
  status: string;
  startedOn: string;
  renewal?: string;
  invoiceProfile?: GetContractOverviewInvoiceProfile;
  parentParties?: GetContractOverviewParty[];
  links?: GetLinkResponse[];
  purchaseOrderNr?: string;
}

/** GetContractOverviewInvoiceProfile */
export interface GetContractOverviewInvoiceProfile {
  partyTypeId?: number;
  partyType?: string;
}

export interface GetContractOverviewParty {
  partyId: number;
  guid: string;
  partyName: string;
  partyCode: string;
  entityType: string;
  partyTypeId: number;
}

/** GetLinkResponse */
export interface GetLinkResponse {
  name?: string;
  url?: string;
}

export interface GetMainContractResponse {
  mainContractId: number;
  guid: string;
  contractVersionId?: number;
  contractVersionGuid?: string;
  contractVersionVersion?: number;
  contractParties: GetMainContractContractParty[];
  contracts: GetMainContractContract[];
  invoiceProfile: MainContractInvoiceProfileResponse;
  links: GetLinkResponse[];
}

export interface GetMainContractContractParty {
  contractPartyId: number;
  partyTypeId: number;
  partyId: number;
  partyGuid: string;
  partyCode: string;
  partyName: string;
}

export interface GetMainContractContract {
  guid: string;
  contractTypeId: number;
  versions: GetMainContractContractVersion[];
}

/** GetMainContractContractVersion */
export interface GetMainContractContractVersion {
  contractId: number;
  version: number;
  contractNumber: string;
  reference?: string;
  quoteReference?: string;
  by?: string;
  dateFrom: string;
  dateUntil?: string;
  purchaseOrderNr?: string;
}

/** MainContractInvoiceProfileResponse */
export interface MainContractInvoiceProfileResponse {
  partyTypeId?: number;
}

export interface GetDynamicSearchOptionsResponse {
  id: number;
  code: string;
  description: string;
}

export interface GetContractVersionResponse {
  mainContractId: number;
  contract?: GetContractVersionContract;
  contractParties: GetContractVersionContractParty[];
}

export interface GetContractVersionContract {
  metadatas: EntityWithMetadataResponse[];
  contractId: number;
  version: number;
  versionFrom: string;
  versionUntil?: string;
  guid: string;
  contractNumber: string;
  dateStart: string;
  dateEnd?: string;
  contractStatusId: number;
  contractStatus: string;
  contractStatusDescription: string;
  reference?: string;
  quoteReference?: string;
  internalReference?: string;
  remarks?: string;
  contractTypeId: number;
  contractType: string;
  contractTypeCode?: string;
  firstInvoiced?: string;
  lastInvoiced?: string;
  invoicedUntil?: string;
  contractItems?: GetContractVersionContractItem[];
  totals?: GetContractVersionContractTotal[];
  links?: GetLinkResponse[];
  purchaseOrderNr?: string;
}

export interface EntityWithMetadataResponse {
  metadataId: number;
  value: string;
  entityType: string;
  entityGuid: string;
  entityId: number;
  code: string;
  dataType: string;
  category?: string;
  description?: string;
  showOnCreate: boolean;
  accessibility: 'hidden' | 'readOnly' | 'editable';
  availableValues?: object;
}

export interface GetContractVersionContractItem {
  metadatas: EntityWithMetadataResponse[];
  guid: string;
  originalGuid: string;
  contractItemId: number;
  productId: number;
  productGuid: string;
  productCode: string;
  productDescription: string;
  productType: string;
  productTypeCode?: string;
  dateStart: string;
  dateEnd?: string;
  firstInvoiced?: string;
  lastInvoiced?: string;
  invoicedUntil?: string;
  contractStatusId: number;
  contractStatus: string;
  contractStatusDescription: string;
  quantity: number;
  description?: string;
  priceFrequency?: string;
  upfront?: boolean;
  prorata?: boolean;
  factor?: number;
  partnerCenterProfile?: PartnerCenterProfileResponse;
  dnsProfile?: DnsProfileResponse;
  contractItemPrices: GetContractVersionContractItemPrice[];
  links: GetLinkResponse[];
}

export interface PartnerCenterProfileResponse {
  subscriptionGuid?: string;
  parentSubscriptionGuid?: string;
  catalogItemId?: string;
  autoRenew?: boolean;
  termDuration?: string;
  renewalDate?: string;
  cancellationAllowedUntilDate?: string;
  creationDate?: string;
  billingCycle?: string;
  scheduledChangesResponse?: ScheduledChangesResponse[];
  instantChangesResponse?: InstantChangesResponse[];
}

export interface ScheduledChangesResponse {
  dateTime: string;
  productId: string;
  skuId: string;
  availabilityId: string;
  billingCycle: string;
  termDuration: string;
  quantity: number;
  applyDate: string;
  changeId: string;
  active: boolean;
}

export interface InstantChangesResponse {
  dateTime: string;
  cancellationAllowedUntil: string;
  oldValue: string;
  newValue: string;
  type: string;
  changeId: string;
}

export interface DnsProfileResponse {
  tenant?: {
    tenantId?: number;
    name?: string;
  };
}

export interface GetContractVersionContractItemPrice {
  contractItemPriceId: number;
  priceType: string;
  price: number;
}

/** GetContractVersionContractTotal */
export interface GetContractVersionContractTotal {
  priceFrequency: 'onetime' | 'payPerUse' | 'month' | 'year' | 'trimester' | 'licenseBased';
  total: number;
}

export interface GetContractVersionContractParty {
  contractPartyId: number;
  partyTypeId: number;
  partyId: number;
}

export interface GetContractVersionByMainContractResponse {
  mainContractId: number;
  mainContractGuid: string;
  contract: GetContractVersionContract;
  contractParties: GetContractVersionContractParty[];
}

export interface GetPartyTypeResponse {
  partyTypeId: number;
  parentPartyTypeId: number;
  code: string;
  entityType: string;
  description: string;
  required: boolean;
  sortOrder: number;
  childPartyTypes: GetPartyTypeResponse[];
}

export interface GetProductResponse {
  productid: number;
  guid: string;
  productCode: string;
  productType: string;
  productTypeCode?: string;
  status: string;
  productTranslations: GetProductTranslation[];
}

/** GetProductTranslation */
export interface GetProductTranslation {
  productTranslationId: number;
  language: string;
  description: string;
}

export interface GetPartiesResponse {
  partyId: number;
  guid: string;
  entityType: string;
  name: string;
  extraInfo: string;
  parentPartyGuid?: string;
}

export interface GetContractTypesResponse {
  contractTypeId: number;
  code: string;
  description: string;
}

export interface GetContractStatusesResponse {
  contractStatusId: number;
  code: string;
  description: string;
}

export interface GetPartySummaryResponse {
  activeContracts: number;
  lastContractStartDate?: string;
  total: number;
  monthTotals: GetPartySummaryPriceResponse[];
}

/** GetPartySummaryPriceResponse */
export interface GetPartySummaryPriceResponse {
  date: string;
  total: number;
}

/** CalculatePriceResponse */
export interface CalculatePriceResponse {
  priceType: string;
  price: number;
}

export interface GetOtherMainContractResponse {
  mainContractId: number;
  contracts: GetOtherMainContractContract[];
}

export interface GetOtherMainContractContract {
  guid: string;
  contractTypeId: number;
  contractType: string;
  reference?: string;
  dateFrom: string;
  dateUntil?: string;
}

export interface GetContractVersionDifferenceResponse {
  changeType: string;
  oldValue: string;
  newValue: string;
}

export interface GetContractVersionItemDifferenceResponse {
  contractItemGuid: string;
  productCode: string;
  product: string;
  changeType: string;
  oldValue?: string;
  newValue: string;
}

export interface MetadataResponse {
  metadataId: number;
  entityType: string;
  code: string;
  dataType: string;
  category?: string;
  description?: string;
  showOnCreate: boolean;
  accessibility: 'hidden' | 'readOnly' | 'editable';
  availableValues: object;
}

/** Availability */
export type IpAvailability = 'Available' | 'Used';

export interface GetIpsResponse {
  pageIndex: number;
  pageSize: number;
  totalPages: number;
  totalItems: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  items: IpResponse[];
}

/** IpResponse */
export interface IpResponse {
  publicIpAddress: string;
  ipAddressId: number;
  internalIpAddress: string;
  comment: string;
  contract: IpContract;
}

/** IpContract */
export interface IpContract {
  contractNumber: string;
  contractId: number;
  parties: IpParty[];
}

/** IpParty */
export interface IpParty {
  partyId: number;
  guid: string;
  partyName: string;
  entityType: string;
  partyTypeId: number;
  partyCode: 'RESELLER' | 'ENDUSER' | 'CONTACT' | 'TECHCONTACT' | 'TECHCONTACT2';
}

/** Response when retrieving an internal subscription */
export type GetSubscriptionWithRelationsInternalResponse = {
  subscriptionId?: string;
  parentSubscription?: string;
  contractItemId?: string;
  orderId?: string;
  offerId?: string;
  quantity?: number;
  friendlyName?: string;
  status?: string;
  billingCycle?: string;
  catalogItemId?: string;
  subscriptionGroupId?: string;
  autoRenew?: boolean;
  termDuration?: string;
  renewalDate?: string;
  cancellationAllowedUntilDate?: string;
  creationDate?: string;
  customerId?: string;
  subscriptionGroupInternalResponse?: {
    subscriptionGroupId?: string;
    customerId?: string;
    name?: string;
    code?: string;
  };
  scheduledChangesResponse?: {
    scheduledChangeId?: string;
    subscriptionId?: string;
    dateTime?: string;
    productId?: string;
    skuId?: string;
    availabilityId?: string;
    billingCycle?: string;
    termDuration?: string;
    quantity?: number;
    applyDate?: string;
    changeId?: string;
    active?: boolean;
  }[];
  instantChangesResponse?: {
    instantChangeId?: string;
    subscriptionId?: string;
    dateTime?: string;
    cancellationAllowedUntil?: string;
    oldValue?: string;
    newValue?: string;
    type?: number;
    changeId?: string;
  }[];
}[];

/** Request used to update a subscription (limited by only a couple of values) */
export interface UpdateSubscriptionLimitedRequest {
  /** Guid */
  subscriptionId: string;
  /** Guid */
  subscriptionGroupId?: string;
  fullUpgradeDate?: string;
  provisioningStatus?: 'None' | 'Found' | 'NotFound';
  renewalDateUtc?: string;
}

/** The response when trying to update a subscription. Can contain one or more error responses for failed validation or a success response code. */
export interface UpdateSubscriptionLimitedResponse {
  responseCodes?: ('SUCCESS' | 'ERROR' | 'SUBSCRIPTION_NOTFOUND' | 'SUBSCRIPTIONGROUPID_NOTFOUND' | 'STATUS_UNKNOWN')[];
}

export interface SyncSubscriptionByContractItemResponse {
  /** The internal subscription */
  subscription?: {
    id?: string;
    creationDate?: string;
    unitType?: string;
    quantity?: number;
    friendlyName?: string;
    offerName?: string;
    offerId?: string;
    suspensionReasons?: string[];
    partnerId?: string;
    billingCycle?: number;
    billingType?: number;
    autoRenewEnabled?: boolean;
    status?: number;
    commitmentEndDate?: string;
    contractType?: number;
    effectiveStartDate?: string;
    parentSubscriptionId?: string;
    links?: {
      offer?: {
        uri?: string;
        method?: string;
        headers?: {
          key?: string;
          value?: string;
        }[];
      };
      entitlement?: {
        uri?: string;
        method?: string;
        headers?: {
          key?: string;
          value?: string;
        }[];
      };
      parentSubscription?: {
        uri?: string;
        method?: string;
        headers?: {
          key?: string;
          value?: string;
        }[];
      };
      product?: {
        uri?: string;
        method?: string;
        headers?: {
          key?: string;
          value?: string;
        }[];
      };
      sku?: {
        uri?: string;
        method?: string;
        headers?: {
          key?: string;
          value?: string;
        }[];
      };
      availability?: {
        uri?: string;
        method?: string;
        headers?: {
          key?: string;
          value?: string;
        }[];
      };
      activationLinks?: {
        uri?: string;
        method?: string;
        headers?: {
          key?: string;
          value?: string;
        }[];
      };
      self?: {
        uri?: string;
        method?: string;
        headers?: {
          key?: string;
          value?: string;
        }[];
      };
    };
    entitlementId?: string;
    productType?: {
      id?: string;
      displayName?: string;
      subType?: string;
    };
    consumptionType?: string;
    cancellationAllowedUntilDate?: string;
    hasPurchasableAddons?: boolean;
    isTrial?: boolean;
    actions?: string[];
    termDuration?: string;
    renewalTermDuration?: string;
    refundOptions?: {
      type?: string;
      expiresAt?: string;
    }[];
    isMicrosoftProduct?: boolean;
    publisherName?: string;
    promotionId?: string;
    migratedFromSubscriptionId?: string;
    refundableQuantity?: {
      totalQuantity?: number;
      details?: {
        quantity?: number;
        allowedUntilDateTime?: string;
      }[];
    };
    scheduledNextTermInstructions?: {
      product?: {
        productId?: string;
        skuId?: string;
        availabilityId?: string;
        billingCycle?: number;
        termDuration?: string;
        promotionId?: string;
      };
      quantity?: number;
      customTermEndDate?: string;
    };
    nextChargeInstructions?: {
      product?: {
        productId?: string;
        skuId?: string;
        availabilityId?: string;
        billingCycle?: number;
        termDuration?: string;
        promotionId?: string;
      };
      quantity?: number;
    };
    orderId?: string;
    attributes?: {
      etag?: string;
      objectType?: string;
    };
  };
  /** The MS subscription data */
  subscriptionInternal?: GetSubscriptionWithRelationsInternalResponse;
  /** Response codes for success, errors, ... */
  responseCodes?: ('SYNC_ERROR' | 'SYNC_SUCCESS' | 'CMSUBSCRIPTION_NOTFOUND' | 'MSSUBSCRIPTION_NOTFOUND')[];
}

/** TransferContractRequest */
export interface TransferContractRequest {
  dateStart?: string;
  contractReference?: string;
  quoteReference?: string;
  internalReference?: string;
  contractParties?: ContractParty[];
}

/** ContractParty */
export interface ContractParty {
  partyTypeId?: number;
  partyId?: number;
}

/** TransferContractResponse */
export interface TransferContractResponse {
  oldContract?: ContractResponse;
  newContract?: ContractResponse;
}

/** ContractResponse */
export interface ContractResponse {
  contractId?: number;
  contractGuid?: string;
  contractItems?: TransferContractItemResponse[];
}

/** TransferContractItemResponse */
export interface TransferContractItemResponse {
  contractItemId?: number;
  contractItemGuid?: string;
  contractItemReference?: string;
}

export interface UpdateIpPlanValues {
  ipAddressId: number;
  internalIpAddress: string;
  contractId?: number;
  comment: string;
}

/** AllowedProductTypeForContractTypeResponse */
export interface AllowedProductTypeForContractTypeResponse {
  id: string;
  description: string;
  code?: string;
}

export interface GetContractsParams {
  pageIndex?: number;
  pageSize?: number;
  search?: string;
  orderbys?: string;
  contractTypes?: string[];
  statuses?: string[];
  includeContractItems?: boolean;
}

export interface GetContractsForPeriodParams {
  /** @format date */
  periodFrom: string;
  /** @format date */
  periodUntil: string;
}

export interface GetMainContractParams {
  mainContractId: number;
}

export interface CreateMainContractPayload {
  contract?: {
    metadatas?: {
      metadataId?: number;
      value?: string;
    }[];
    contractTypeId?: number;
    dateStart?: string;
    dateEnd?: string;
    contractStatusId?: number;
    contractReference?: string;
    quoteReference?: string;
    internalReference?: string;
    remarks?: string;
    purchaseOrderNr?: string;
  };
  contractParties?: {
    partyTypeId?: number;
    partyId?: number;
  }[];
  invoiceProfile?: {
    partyTypeId?: number;
  };
}

export interface UpdateMainContractPayload {
  mainContractId?: number;
  contract?: {
    metadatas?: {
      metadataId?: number;
      value?: string;
    }[];
    contractId?: number;
    contractTypeId?: number;
    dateStart?: string;
    dateEnd?: string;
    contractStatusId?: number;
    contractReference?: string;
    quoteReference?: string;
    internalReference?: string;
    remarks?: string;
    purchaseOrderNr?: string;
  };
  contractParties?: {
    contractPartyId?: number;
    partyTypeId?: number;
    partyId?: number;
  }[];
  invoiceProfile?: {
    partyTypeId?: number;
  };
}

export interface UpdateMainContractParams {
  forAllLinkedContracts?: boolean;
}

export interface CreateContractItemPayload {
  metadatas?: {
    metadataId?: number;
    value?: string;
  }[];
  guid?: string;
  contractId?: number;
  productId?: number;
  productType?: string;
  quantity?: number;
  description?: string;
  dateStart?: string;
  dateEnd?: string;
  contractStatusId?: number;
  factor?: number;
  contractItemPrices?: {
    priceType?: string;
    price?: number;
  }[];
  customFields?: Record<string, string>;
}

export interface UpdateContractItemPayload {
  metadatas?: {
    metadataId?: number;
    value?: string;
  }[];
  guid?: string;
  originalGuid?: string;
  contractId?: number;
  description?: string;
  dateStart?: string;
  dateEnd?: string;
  factor?: number;
  partnerCenterProfile?: {
    subscriptionGuid?: string;
    parentSubscriptionGuid?: string;
    catalogItemId?: string;
    autoRenew?: boolean;
    termDuration?: string;
    renewalDate?: string;
    cancellationAllowedUntilDate?: string;
    billingCycle?: string;
    orderId?: string;
    offerId?: string;
    quantity?: number;
  };
}

export type UpdateContractItemsPayload = object;

export interface UpdateContractItemsForDatePayload {
  dateFrom?: string;
  contractId?: number;
  contractItems?: {
    guid?: string;
    originalGuid?: string;
    quantity?: number;
    contractItemPrices?: {
      priceType?: string;
      price?: number;
    }[];
    partnerCenterProfile?: {
      subscriptionGuid?: string;
      parentSubscriptionGuid?: string;
      catalogItemId?: string;
      autoRenew?: boolean;
      termDuration?: string;
      renewalDate?: string;
      cancellationAllowedUntilDate?: string;
      billingCycle?: string;
      orderId?: string;
      offerId?: string;
      quantity?: number;
    };
  }[];
}

export interface GetContractVersionParams {
  contractGuid?: string;
  contractVersionId?: number;
  version?: number;
}

export interface GetProductsParams {
  take?: number;
  search?: string;
  contractType?: string;
}

export interface GetPartiesParams {
  entityType?: string;
  parentPartyGuid?: string;
  search?: string;
  take?: number;
}

export interface GetPartySummaryParams {
  guid?: string;
  entityType?: string;
}

export interface CalculatePricesPayload {
  companyGuid?: string;
  productGuid?: string;
  quantity?: number;
  billingPlanId?: number;
  termDurationId?: number;
  baseDateForCalculation?: string;
}

export interface ActivateContractParams {
  date?: string;
  id: number;
}

export type LinkContractToOtherMainContractPayload = object;

export interface ActiveContractToQuoteParams {
  date?: string;
  id: string;
}

export interface ActiveContractToActiveNoInvoiceParams {
  date?: string;
  id: string;
}

export interface ActivateContractItemsPayload {
  contractItemIds?: number[];
}

export interface ActivateContractItemsParams {
  date?: string;
}

export interface ActiveContractItemsToQuotePayload {
  contractItemIds?: number[];
}

export interface ActiveContractItemsToQuoteParams {
  date?: string;
}

export interface ActiveContractItemsToActiveNoInvoicePayload {
  contractItemIds?: number[];
}

export interface SuspendContractParams {
  date?: string;
  id: number;
}

export interface SuspendContractItemsPayload {
  contractItemIds?: number[];
}

export interface SuspendContractItemsParams {
  date?: string;
}

export interface GetIpsParams {
  pageIndex?: number;
  pageSize?: number;
  search?: string;
  availability?: IpAvailability[];
}

export interface GetActiveContractsParams {
  search?: string;
  take?: number;
}

export interface GetSubscriptionsByContractItemsParams {
  /** Array of contract item original GUIDs */
  contractItemIds: any[];
}

export interface TransferContractParams {
  guid: string;
}

export interface GetAllowedProductTypesForContractTypesParams {
  contractTypeCode: string;
}

import axios, { AxiosInstance, AxiosRequestConfig, ResponseType } from 'axios';

type HeaderValue = string | string[] | number | boolean | null;

interface RawHeaders {
  [key: string]: HeaderValue;
}

interface HeadersDefaults {
  common: RawHeaders;
  delete: RawHeaders;
  get: RawHeaders;
  head: RawHeaders;
  post: RawHeaders;
  put: RawHeaders;
  patch: RawHeaders;
  options?: RawHeaders;
  purge?: RawHeaders;
  link?: RawHeaders;
  unlink?: RawHeaders;
}

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || 'http://localhost:3000' });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<T> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance
      .request({
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
        },
        params: query,
        responseType: responseFormat,
        data: body,
        url: path,
      })
      .then((response) => response.data?.response);
  };
}

/**
 * @title ContractModule
 * @version 1.0
 * @baseUrl http://localhost:3000
 *
 * contracts module
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * @description get contracts overview
     *
     * @name GetContracts
     * @summary Your GET endpoint
     * @request GET:/api/contract/v2/contracts
     */
    getContracts: (query: GetContractsParams, params: RequestParams = {}) =>
      this.request<GetContractOverviewResponse, any>({
        path: `/api/contract/v2/contracts`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description get main contracts for period
     *
     * @name GetContractsForPeriod
     * @summary Your GET endpoint
     * @request GET:/api/contract/v2/contracts/period
     */
    getContractsForPeriod: (query: GetContractsForPeriodParams, params: RequestParams = {}) =>
      this.request<GetMainContractResponse[], any>({
        path: `/api/contract/v2/contracts/period`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description get dynamic search options
     *
     * @name GetDynamicSearchOptions
     * @summary Your GET endpoint
     * @request GET:/api/contract/v2/contracts/dynamicsearchoptions
     */
    getDynamicSearchOptions: (params: RequestParams = {}) =>
      this.request<GetDynamicSearchOptionsResponse[], any>({
        path: `/api/contract/v2/contracts/dynamicsearchoptions`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description get main contract
     *
     * @name GetMainContract
     * @summary Your GET endpoint
     * @request GET:/api/contract/v2/contracts/maincontract
     */
    getMainContract: (query: GetMainContractParams, params: RequestParams = {}) =>
      this.request<GetMainContractResponse, any>({
        path: `/api/contract/v2/contracts/maincontract`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description add new main contract
     *
     * @name CreateMainContract
     * @request POST:/api/contract/v2/contracts/maincontract
     */
    createMainContract: (data: CreateMainContractPayload, params: RequestParams = {}) =>
      this.request<GetMainContractResponse, any>({
        path: `/api/contract/v2/contracts/maincontract`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description update main contract
     *
     * @name UpdateMainContract
     * @request PUT:/api/contract/v2/contracts/maincontract
     */
    updateMainContract: (
      query: UpdateMainContractParams,
      data: UpdateMainContractPayload,
      params: RequestParams = {}
    ) =>
      this.request<GetMainContractResponse, any>({
        path: `/api/contract/v2/contracts/maincontract`,
        method: 'PUT',
        query: query,
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description add new contract item
     *
     * @name CreateContractItem
     * @request POST:/api/contract/v2/contracts/contractitem
     */
    createContractItem: (data: CreateContractItemPayload, params: RequestParams = {}) =>
      this.request<
        GetMainContractResponse,
        {
          errorCode?: string;
          errorMessage?: string;
          stackTrace?: string;
          fieldErrors?: {
            ''?: string;
          };
          contentType?: string;
          statusCode?: number;
          statusDescription?: string;
          isSuccesful?: boolean;
          request?: {
            basePath?: string;
            resource?: string;
            queryString?: string;
            method?: string;
          };
        }
      >({
        path: `/api/contract/v2/contracts/contractitem`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description update contract item
     *
     * @name UpdateContractItem
     * @request PUT:/api/contract/v2/contracts/contractitem
     */
    updateContractItem: (data: UpdateContractItemPayload, params: RequestParams = {}) =>
      this.request<GetMainContractResponse, any>({
        path: `/api/contract/v2/contracts/contractitem`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description update multiple contract items
     *
     * @name UpdateContractItems
     * @request PUT:/api/contract/v2/contracts/contractitems
     */
    updateContractItems: (data: UpdateContractItemsPayload, params: RequestParams = {}) =>
      this.request<GetMainContractResponse, any>({
        path: `/api/contract/v2/contracts/contractitems`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateContractItemsForDate
     * @request PUT:/api/contract/v2/contracts/contractitems/bydate
     */
    updateContractItemsForDate: (data: UpdateContractItemsForDatePayload, params: RequestParams = {}) =>
      this.request<GetMainContractResponse, any>({
        path: `/api/contract/v2/contracts/contractitems/bydate`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description get contract version
     *
     * @name GetContractVersion
     * @summary Your GET endpoint
     * @request GET:/api/contract/v2/contracts/contractversion
     */
    getContractVersion: (query: GetContractVersionParams, params: RequestParams = {}) =>
      this.request<GetContractVersionResponse, any>({
        path: `/api/contract/v2/contracts/contractversion`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description get contract version by main contract
     *
     * @name GetContractVersionByMainContractId
     * @summary Your GET endpoint
     * @request GET:/api/contract/v2/contracts/{maincontractid}/contractversion
     */
    getContractVersionByMainContractId: (maincontractid: number, params: RequestParams = {}) =>
      this.request<GetContractVersionByMainContractResponse, any>({
        path: `/api/contract/v2/contracts/${maincontractid}/contractversion`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description get party types
     *
     * @name GetPartyTypes
     * @summary Your GET endpoint
     * @request GET:/api/contract/v2/contracts/partytypes
     */
    getPartyTypes: (params: RequestParams = {}) =>
      this.request<GetPartyTypeResponse[], any>({
        path: `/api/contract/v2/contracts/partytypes`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description get products
     *
     * @name GetProducts
     * @summary Your GET endpoint
     * @request GET:/api/contract/v2/contracts/products
     */
    getProducts: (query: GetProductsParams, params: RequestParams = {}) =>
      this.request<GetProductResponse[], any>({
        path: `/api/contract/v2/contracts/products`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetParties
     * @summary Your GET endpoint
     * @request GET:/api/contract/v2/contracts/parties
     */
    getParties: (query: GetPartiesParams, params: RequestParams = {}) =>
      this.request<GetPartiesResponse[], any>({
        path: `/api/contract/v2/contracts/parties`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetContractTypes
     * @summary Your GET endpoint
     * @request GET:/api/contract/v2/contracts/contracttypes
     */
    getContractTypes: (params: RequestParams = {}) =>
      this.request<GetContractTypesResponse[], any>({
        path: `/api/contract/v2/contracts/contracttypes`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetContractStatuses
     * @summary Your GET endpoint
     * @request GET:/api/contract/v2/contracts/contractstatuses
     */
    getContractStatuses: (params: RequestParams = {}) =>
      this.request<GetContractStatusesResponse[], any>({
        path: `/api/contract/v2/contracts/contractstatuses`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description get party summary
     *
     * @name GetPartySummary
     * @summary Your GET endpoint
     * @request GET:/api/contract/v2/contracts/partysummary
     */
    getPartySummary: (query: GetPartySummaryParams, params: RequestParams = {}) =>
      this.request<GetPartySummaryResponse, any>({
        path: `/api/contract/v2/contracts/partysummary`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description calucalate prices
     *
     * @name CalculatePrices
     * @request POST:/api/contract/v2/contracts/calculateprices
     */
    calculatePrices: (data: CalculatePricesPayload, params: RequestParams = {}) =>
      this.request<CalculatePriceResponse[], any>({
        path: `/api/contract/v2/contracts/calculateprices`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description get linked main contracts
     *
     * @name GetOtherMainContracts
     * @summary Your GET endpoint
     * @request GET:/api/contract/v2/contracts/{maincontractid}/other-maincontracts
     */
    getOtherMainContracts: (maincontractid: number, params: RequestParams = {}) =>
      this.request<GetOtherMainContractResponse[], any>({
        path: `/api/contract/v2/contracts/${maincontractid}/other-maincontracts`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description activate contract
     *
     * @name ActivateContract
     * @request POST:/api/contract/v2/contracts/{id}/activate
     */
    activateContract: ({ id, ...query }: ActivateContractParams, params: RequestParams = {}) =>
      this.request<GetContractVersionContract, any>({
        path: `/api/contract/v2/contracts/${id}/activate`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description link contract to other main contract
     *
     * @name LinkContractToOtherMainContract
     * @request POST:/api/contract/v2/contracts/linktomaincontract
     */
    linkContractToOtherMainContract: (data: LinkContractToOtherMainContractPayload, params: RequestParams = {}) =>
      this.request<GetMainContractResponse, any>({
        path: `/api/contract/v2/contracts/linktomaincontract`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description activate contract to status 'quote'
     *
     * @name ActiveContractToQuote
     * @request POST:/api/contract/v2/contracts/{id}/active-to-quote
     */
    activeContractToQuote: ({ id, ...query }: ActiveContractToQuoteParams, params: RequestParams = {}) =>
      this.request<GetContractVersionContract, any>({
        path: `/api/contract/v2/contracts/${id}/active-to-quote`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description activate contract to status 'active no invoice'
     *
     * @name ActiveContractToActiveNoInvoice
     * @request POST:/api/contract/v2/contracts/{id}/active-to-active-no-invoice
     */
    activeContractToActiveNoInvoice: (
      { id, ...query }: ActiveContractToActiveNoInvoiceParams,
      params: RequestParams = {}
    ) =>
      this.request<GetContractVersionContract, any>({
        path: `/api/contract/v2/contracts/${id}/active-to-active-no-invoice`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description active contract items
     *
     * @name ActivateContractItems
     * @request POST:/api/contract/v2/contracts/contractitems/activate
     */
    activateContractItems: (
      query: ActivateContractItemsParams,
      data: ActivateContractItemsPayload,
      params: RequestParams = {}
    ) =>
      this.request<GetContractVersionContract, any>({
        path: `/api/contract/v2/contracts/contractitems/activate`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description active contract items to status 'quote'
     *
     * @name ActiveContractItemsToQuote
     * @request POST:/api/contract/v2/contracts/contractitems/active-to-quote
     */
    activeContractItemsToQuote: (
      query: ActiveContractItemsToQuoteParams,
      data: ActiveContractItemsToQuotePayload,
      params: RequestParams = {}
    ) =>
      this.request<GetContractVersionContract, any>({
        path: `/api/contract/v2/contracts/contractitems/active-to-quote`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description active contract items to status 'active no invoice'
     *
     * @name ActiveContractItemsToActiveNoInvoice
     * @request POST:/api/contract/v2/contracts/contractitems/active-to-active-no-invoice
     */
    activeContractItemsToActiveNoInvoice: (
      data: ActiveContractItemsToActiveNoInvoicePayload,
      params: RequestParams = {}
    ) =>
      this.request<GetContractVersionContract, any>({
        path: `/api/contract/v2/contracts/contractitems/active-to-active-no-invoice`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description suspend contract
     *
     * @name SuspendContract
     * @request POST:/api/contract/v2/contracts/{id}/suspend
     */
    suspendContract: ({ id, ...query }: SuspendContractParams, params: RequestParams = {}) =>
      this.request<GetContractVersionContract, any>({
        path: `/api/contract/v2/contracts/${id}/suspend`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description suspend contract items
     *
     * @name SuspendContractItems
     * @request POST:/api/contract/v2/contracts/contractitems/suspend
     */
    suspendContractItems: (
      query: SuspendContractItemsParams,
      data: SuspendContractItemsPayload,
      params: RequestParams = {}
    ) =>
      this.request<GetContractVersionContract, any>({
        path: `/api/contract/v2/contracts/contractitems/suspend`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description get contract differences
     *
     * @name GetContractVersionDifference
     * @summary Your GET endpoint
     * @request GET:/api/contract/v2/contracts/{id}/difference
     */
    getContractVersionDifference: (id: number, params: RequestParams = {}) =>
      this.request<GetContractVersionDifferenceResponse[], any>({
        path: `/api/contract/v2/contracts/${id}/difference`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description get contract items differences
     *
     * @name GetContractVersionItemDifference
     * @summary Your GET endpoint
     * @request GET:/api/contract/v2/contracts/{id}/items/difference
     */
    getContractVersionItemDifference: (id: number, params: RequestParams = {}) =>
      this.request<GetContractVersionItemDifferenceResponse[], any>({
        path: `/api/contract/v2/contracts/${id}/items/difference`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description get contract metadatas
     *
     * @name GetContractMetadatas
     * @summary Your GET endpoint
     * @request GET:/api/contract/v2/metadatas/contract
     */
    getContractMetadatas: (params: RequestParams = {}) =>
      this.request<MetadataResponse[], any>({
        path: `/api/contract/v2/metadatas/contract`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description get contract item metadatas
     *
     * @name GetContractItemMetadatas
     * @summary Your GET endpoint
     * @request GET:/api/contract/v2/metadatas/contractitem
     */
    getContractItemMetadatas: (params: RequestParams = {}) =>
      this.request<MetadataResponse[], any>({
        path: `/api/contract/v2/metadatas/contractitem`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description get IP's overview
     *
     * @name GetIps
     * @summary Your GET endpoint
     * @request GET:/api/ipplan/v2/ipplan
     */
    getIps: (query: GetIpsParams, params: RequestParams = {}) =>
      this.request<GetIpsResponse, any>({
        path: `/api/ipplan/v2/ipplan`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description update ip
     *
     * @name UpdateIp
     * @request PUT:/api/ipplan/v2/ipplan
     */
    updateIp: (data: UpdateIpPlanValues, params: RequestParams = {}) =>
      this.request<IpResponse, any>({
        path: `/api/ipplan/v2/ipplan`,
        method: 'PUT',
        body: data,
        format: 'json',
        ...params,
      }),

    /**
     * @description get active contracts
     *
     * @name GetActiveContracts
     * @summary Your GET endpoint
     * @request GET:/api/ipplan/v2/ipplan/activecontracts
     */
    getActiveContracts: (query: GetActiveContractsParams, params: RequestParams = {}) =>
      this.request<IpContract[], any>({
        path: `/api/ipplan/v2/ipplan/activecontracts`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetSubscriptionsByContractItems
     * @summary Get Subscriptions By Contract Items Async
     * @request GET:/api/contract/v2/subscriptions/contractitems
     */
    getSubscriptionsByContractItems: (query: GetSubscriptionsByContractItemsParams, params: RequestParams = {}) =>
      this.request<GetSubscriptionWithRelationsInternalResponse, any>({
        path: `/api/contract/v2/subscriptions/contractitems`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates a single internal Subscription with a limited selection of values
     *
     * @name UpdateSubscriptionLimited
     * @summary Update Subscription Limited Async
     * @request PUT:/api/contract/v2/subscriptions/limited
     */
    updateSubscriptionLimited: (data: UpdateSubscriptionLimitedRequest, params: RequestParams = {}) =>
      this.request<UpdateSubscriptionLimitedResponse, UpdateSubscriptionLimitedResponse>({
        path: `/api/contract/v2/subscriptions/limited`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Used to sync the internal subscription with Microsoft Partner Center. This gets the most up to date information from MS and imports it internally.
     *
     * @name SyncSubscription
     * @summary Sync Subscription Async
     * @request GET:/api/contract/v2/subscriptions/contractitem/{contractitemoriginalguid}/sync
     */
    syncSubscription: (contractitemoriginalguid: string, params: RequestParams = {}) =>
      this.request<SyncSubscriptionByContractItemResponse, any>({
        path: `/api/contract/v2/subscriptions/contractitem/${contractitemoriginalguid}/sync`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name TransferContract
     * @summary TransferContractAsync
     * @request POST:/api/contract/v2/contracts/{guid}/transfer
     */
    transferContract: (
      { guid, ...query }: TransferContractParams,
      data: TransferContractRequest,
      params: RequestParams = {}
    ) =>
      this.request<TransferContractResponse, any>({
        path: `/api/contract/v2/contracts/${guid}/transfer`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve all allowed product types to be configured on a contract item for a certain contract type
     *
     * @name GetAllowedProductTypesForContractTypes
     * @summary GetAllowedProductTypesForContractTypes
     * @request GET:/api/contract/v2/contracts/contracttypes/{contractTypeCode}/producttypes
     */
    getAllowedProductTypesForContractTypes: (
      { contractTypeCode, ...query }: GetAllowedProductTypesForContractTypesParams,
      params: RequestParams = {}
    ) =>
      this.request<AllowedProductTypeForContractTypeResponse, any>({
        path: `/api/contract/v2/contracts/contracttypes/${contractTypeCode}/producttypes`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  };
}
