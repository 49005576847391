import { ContractItemField } from '../types/contractItem';

// THIS CHECKS FOR A DATE TIME. FE 0001-01-01 00:00:001234
export const DATETIME_REGEX = /^[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}:[0-9]{2}(\.[0-9]{1,3})?[0-9]{4}$/;

export const CONTRACT_PREFIX = 'CONTRACT';
export const CONTRACTS_MODULE_KEY = 'contract';
export const CONTRACTS_STATUS_PREFIX = 'CONTRACTS_STATUSES';
export const CONTRACTS_TYPES_PREFIX = 'CONTRACTS_TYPES';
export const CONTRACT_DIFFERENCE_PREFIX = 'CONTRACT_DIFFERENCE';

export const MAIN_CONTRACT_PREFIX = 'MAIN_CONTRACT';

export const CONTRACTS_PAGE_SIZE = 20;

export const CONTRACTS_CALC_TYPE_INVOICED_AFTER = 'invoicedAfter';
export const CONTRACTS_CALC_TYPE_INVOICED_UP_FRONT = 'invoicedUpFront';
export const CONTRACTS_CALC_TYPE_ONE_TIME = 'oneTime';
export const CONTRACTS_CALC_TYPE_PAY_PER_USE = 'payPerUse';
export const CONTRACTS_MIGRATED_METADATA = 'MIGRATED';
export const CONTRACTS_MIGRATED_METADATA_CODE = 'MIGRATIONDATE';

export const DOMAIN_PRODUCT_CODE = 'DomainsAndCertificates';
export const DOMAIN_CONTRACT_CODE = 'domain';

export enum CONTRACTS_STATUS {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  QUOTE = 'QUOTE',
  SUSPENDED = 'SUSPENDED',
  TRIAL = 'TRIAL',
  ACTIVE_NOINVOICE = 'ACTIVE_NOINVOICE',
}

export enum CONTRACT_ITEM_PRICING {
  RRP = 'RRP',
  REBATE = 'Rebate',
  PARTNERPRICE = 'PartnerPrice',
  MARGIN = 'Margin',
  ENDUSERPRICE = 'EnduserPrice',
}
export const ContractItemPricing = [
  CONTRACT_ITEM_PRICING.RRP,
  CONTRACT_ITEM_PRICING.REBATE,
  CONTRACT_ITEM_PRICING.PARTNERPRICE,
  // CONTRACT_ITEM_PRICING.MARGIN,
  CONTRACT_ITEM_PRICING.ENDUSERPRICE,
];

export enum PartyTypes {
  RESELLER = 'RESELLER',
  ENDUSER = 'ENDUSER',
  NEWENDUSER = 'NEWENDUSER',
  NEWPARTNER = 'NEWPARTNER',
}

export const contractItemFields: ContractItemField[] = [
  {
    name: 'domain',
    typesToShowInAdd: [DOMAIN_PRODUCT_CODE],
    typesToShowInEdit: [DOMAIN_PRODUCT_CODE],
    typesToShowInDetail: [DOMAIN_PRODUCT_CODE],
  },
  {
    name: 'description',
    typesToShowInAdd: ['others'],
    typesToShowInEdit: ['others'],
    typesToShowInDetail: ['others'],
  },
  {
    name: 'contractStatusId',
    typesToShowInAdd: ['others', DOMAIN_PRODUCT_CODE],
    typesToShowInEdit: ['others', DOMAIN_PRODUCT_CODE],
    typesToShowInDetail: [],
  },
  {
    name: 'factor',
    typesToShowInAdd: ['others'],
    typesToShowInEdit: ['others'],
    typesToShowInDetail: ['others'],
  },
  {
    name: 'dateStart',
    typesToShowInAdd: ['others', DOMAIN_PRODUCT_CODE],
    typesToShowInEdit: ['others', DOMAIN_PRODUCT_CODE],
    typesToShowInDetail: ['others', DOMAIN_PRODUCT_CODE],
  },
  {
    name: 'dateEnd',
    typesToShowInAdd: ['others'],
    typesToShowInEdit: ['others'],
    typesToShowInDetail: ['others'],
  },
  {
    name: 'dateFrom',
    typesToShowInAdd: [],
    typesToShowInEdit: ['others'],
    typesToShowInDetail: [],
  },
  {
    name: 'quantity',
    typesToShowInAdd: ['others'],
    typesToShowInEdit: ['others'],
    typesToShowInDetail: ['others'],
  },
  {
    name: 'advancedOptions',
    typesToShowInAdd: ['others'],
    typesToShowInEdit: ['others'],
    typesToShowInDetail: [],
  },
  {
    name: 'invoicedUntil',
    typesToShowInAdd: [],
    typesToShowInEdit: [],
    typesToShowInDetail: ['others'],
  },
  {
    name: 'partnerCenterProfile',
    typesToShowInAdd: [],
    typesToShowInEdit: [],
    typesToShowInDetail: ['others'],
  },
  {
    name: 'dnsProfile',
    typesToShowInAdd: [],
    typesToShowInEdit: [],
    typesToShowInDetail: ['others', DOMAIN_PRODUCT_CODE],
  },
  {
    name: 'metadata',
    typesToShowInAdd: ['others'],
    typesToShowInEdit: ['others'],
    typesToShowInDetail: [],
  },
  {
    name: 'createAnother',
    typesToShowInAdd: ['others'],
    typesToShowInEdit: [],
    typesToShowInDetail: [],
  },
];
