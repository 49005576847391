import { NotionIcon } from 'components/icons';
import styled from 'styled-components';

export enum NotionVariant {
  SUCCESS = 'success',
  DANGER = 'danger',
  WARNING = 'warning',
  INFO = 'info',
}

type Props = {
  variant: NotionVariant;
  title?: string;
  description: string;
};

export default function Notion({ variant, title, description }: Props) {
  return (
    <Container variant={variant}>
      <NotionIcon variant={variant} />
      <TextWrapper>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </TextWrapper>
    </Container>
  );
}

const Container = styled.div<{ variant: NotionVariant }>`
  --primary-color: ${(props) =>
    props.variant === NotionVariant.SUCCESS
      ? props.theme.palette.success.shade4
      : props.variant === NotionVariant.DANGER
      ? props.theme.palette.danger.shade4
      : props.variant === NotionVariant.WARNING
      ? props.theme.palette.warning.shade8
      : props.variant === NotionVariant.INFO
      ? props.theme.palette.primary.shade4
      : 'none'};
  --secondary-color: ${(props) =>
    props.variant === NotionVariant.SUCCESS
      ? props.theme.palette.success.shade1
      : props.variant === NotionVariant.DANGER
      ? props.theme.palette.danger.shade1
      : props.variant === NotionVariant.WARNING
      ? props.theme.palette.warning.shade1
      : props.variant === NotionVariant.INFO
      ? props.theme.palette.primary.shade1
      : 'none'};

  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 16px;
  background-color: var(--secondary-color);
  border-radius: 7px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-left: 5px solid var(--primary-color);

  svg {
    fill: var(--primary-color);
    margin-right: 16px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  color: ${({ theme }) => theme.palette.neutral.shade9};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  margin-bottom: 4px;
`;

const Description = styled.span`
  color: ${({ theme }) => theme.palette.neutral.shade8};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
`;
