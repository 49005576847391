import * as React from 'react';
import { FullViewBlockItem } from './FullViewElement';
import { Label, Value } from './ItemComponents';

type Props = {
  label: string;
  value?: string;
  values?: string[];
  capitalize?: boolean;
};

const FullViewItem: React.FC<Props> = ({ label, value, values, capitalize }: Props) =>
  value ? (
    <FullViewBlockItem key={label}>
      <Label>{label}</Label>
      <Value capitalize={capitalize}>{value}</Value>
    </FullViewBlockItem>
  ) : values ? (
    <React.Fragment>
      <FullViewBlockItem key={label}>
        <Label>{label}</Label>
        <Value capitalize={capitalize}>{values[0]}</Value>
      </FullViewBlockItem>
      {values.slice(1).map((element) => (
        <FullViewBlockItem key={element}>
          <Value capitalize={capitalize}>{element}</Value>
        </FullViewBlockItem>
      ))}
    </React.Fragment>
  ) : null;

export default FullViewItem;
