import * as React from 'react';
import { media } from 'config/theme/utils';
import styled from '../../../../config/theme';

type Props = {
  children: JSX.Element | JSX.Element[] | null | React.ReactNode;
  className?: string;
  isPending?: boolean;
};

const PageHeader: React.FC<Props> = ({ children, className, isPending }: Props) => (
  <Header className={className}>{isPending ? null : children}</Header>
);

const Header = styled.header`
  align-items: center;
  background-color: ${(props) => props.theme.header.background};
  border-bottom: ${(props) => props.theme.header.borderBottom};
  display: flex;
  flex-shrink: 0;
  height: ${(props) => props.theme.header.height};
  max-height: ${(props) => props.theme.header.height};
  padding: ${(props) => props.theme.header.padding};
  position: sticky;
  top: 0;
  width: 100%;
  justify-content: space-between;
  z-index: 94;
  overflow: visible;

  ${media.desktop`
    padding-right: calc(100% - 1450px);
    .paginator { 
      margin-right: 32px;
      }
  `};
`;

export const HeaderActionWrapper = styled.div`
  display: flex;
`;

export default PageHeader;
