import { TenantTheme } from '../tenants/unbrace';

const headerTheme = (theme: TenantTheme) => ({
  background: theme.palette.neutral.shade1,
  borderBottom: `solid 2px ${theme.palette.neutral.shade2}`,
  color: {
    disabled: theme.palette.neutral.shade4,
    hover: theme.palette.neutral.shade9,
    normal: theme.palette.neutral.shade7,
  },
  height: '60px',
  padding: '5px 20px',
});

export default headerTheme;
