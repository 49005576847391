import { ModuleEditAccessContext } from 'modules/security';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import styled from '../../config/theme';
import { Add } from '../icons';
import useKeyShortcut from '../KeyboardShortcuts/hooks/useKeyShortcut';

type Props = {
  addUrl?: string;
} & RouteComponentProps;

const AddButton: React.FC<Props> = ({ addUrl, history, ...props }: Props) => {
  const pushUrl = React.useCallback(() => addUrl && history.push(addUrl), [addUrl, history]);

  const { useOpenAdd } = useKeyShortcut();
  useOpenAdd(pushUrl);

  return (
    <ModuleEditAccessContext.Consumer>
      {({ canEdit }) =>
        canEdit && (
          <AddFabIconWrapper onClick={pushUrl} {...props}>
            <Add />
          </AddFabIconWrapper>
        )
      }
    </ModuleEditAccessContext.Consumer>
  );
};

const AddFabIconWrapper = styled.span`
  z-index: 2;
  top: ${(props) => props.theme.header.height};
  position: sticky;

  &:after {
    content: '';
    position: absolute;
    width: 35px;
    height: 35px;
    background: ${(props) => props.theme.palette.success.shade4};
    box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.08), 1px 1px 25px rgba(0, 0, 0, 0.08);
    border-radius: 50%;
    cursor: pointer;
    transition: all ease-in-out 0.25s;
    top: -1px;
    right: 0;
    transform: translate(-50%, -50%);
  }

  svg {
    position: absolute;
    fill: ${(props) => props.theme.palette.success.shade1};
    width: 25px;
    height: 25px;
    top: -1px;
    right: 10px;
    transform: translate(-50%, -52%);
    z-index: 2;
    cursor: pointer;
  }

  &:focus,
  &:hover {
    &:after {
      background: ${(props) => props.theme.palette.success.shade5};
      box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.18), 1px 1px 25px rgba(0, 0, 0, 0.08);
    }
  }
`;

export default withRouter(AddButton);
