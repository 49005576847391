import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

type Props = {};

const GoTo: React.FC<Props> = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M26.8469 25C27.2003 24.9997 27.539 24.8584 27.7887 24.6071L39.4815 12.8571V18.0893C39.4815 18.4445 39.6219 18.7851 39.8719 19.0363C40.1218 19.2875 40.8873 19.4286 41.2408 19.4286C41.5942 19.4286 42.3597 19.2875 42.6096 19.0363C42.8596 18.7851 43 18.4445 43 18.0893V8.33929C43 7.98409 42.8596 7.64343 42.6096 7.39227C42.3597 7.1411 42.0207 7 41.6672 7H31.9647C31.6113 7 31.2723 7.1411 31.0223 7.39227C30.7724 7.64343 30.632 8.41266 30.632 8.76786C30.632 9.12306 30.7724 9.89228 31.0223 10.1434C31.2723 10.3946 31.6113 10.5357 31.9647 10.5357H37.1714L25.3898 22.2857C25.2036 22.473 25.0769 22.7115 25.0255 22.9712C24.9742 23.2308 25.0006 23.9285 25.1014 24.1731C25.2022 24.4177 25.3728 24.6267 25.5918 24.7739C25.8107 24.9211 26.4946 24.9998 26.7581 25H26.8469Z" />
      <path d="M41.1557 26.4854C40.7889 26.4854 39.9944 26.6312 39.735 26.8907C39.4756 27.1503 39.3299 27.5023 39.3299 27.8693V38.4239C39.3299 38.6637 39.2347 38.8937 39.0652 39.0632C38.8958 39.2328 38.6659 39.328 38.4262 39.328H11.5738C11.3341 39.328 11.1042 39.2328 10.9348 39.0632C10.7653 38.8937 10.6701 38.6637 10.6701 38.4239V11.5577C10.6701 11.3179 10.7653 11.0879 10.9348 10.9183C11.1042 10.7488 11.3341 10.6535 11.5738 10.6535H21.5143C21.8812 10.6535 22.233 10.5077 22.4924 10.2482C22.7518 9.98864 22.8975 9.19379 22.8975 8.82676C22.8975 8.45972 22.7518 7.66487 22.4924 7.40534C22.233 7.1458 21.8812 7 21.5143 7H10.6885C9.71027 7 8.77208 7.38881 8.08034 8.0809C7.38861 8.77298 7 9.71166 7 10.6904V39.3096C7 40.2883 7.38861 41.227 8.08034 41.9191C8.77208 42.6112 9.71027 43 10.6885 43H39.3115C40.2897 43 41.2279 42.6112 41.9197 41.9191C42.6114 41.227 43 40.2883 43 39.3096V27.8693C43 27.686 42.9636 27.5045 42.893 27.3354C42.8223 27.1663 42.7188 27.0129 42.5884 26.8841C42.458 26.7554 42.3033 26.6538 42.1334 26.5854C41.9634 26.5169 41.3389 26.4829 41.1557 26.4854Z" />
    </svg>
  </BaseSvgWrapper>
);

export default GoTo;
