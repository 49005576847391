import { notificationsReducer, NotificationsState } from '@unbrace/notifications-micro-package';
import { connectRouter, RouterState } from 'connected-react-router';
import { combineReducers, Reducer } from 'redux';
import appReducer, { AppState } from '../../modules/app/redux/reducers';
import alertsReducer, { AlertsState } from '../../modules/app/redux/reducers/alerts';
import loadingReducer, { LoadingState } from '../../modules/app/redux/reducers/loading';
import securityReducer, { SecurityState } from '../../modules/security/redux/reducers';

export type RootState = {
  readonly alerts: AlertsState;
  readonly app: AppState;
  readonly loading: LoadingState;
  readonly notifications: NotificationsState;
  readonly router: RouterState;
  readonly security: SecurityState;
};

export const rootReducer = (history: any): Reducer<RootState> =>
  combineReducers<RootState, any>({
    alerts: alertsReducer,
    app: appReducer,
    loading: loadingReducer,
    notifications: notificationsReducer,
    router: connectRouter(history),
    security: securityReducer,
  });
