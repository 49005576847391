import { ContextMenuBlock, ContextMenuContainer, ContextMenuItem } from 'components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import useConfigStore from '../services/useConfigStore';

const ExternalLinkscontent: React.FC = () => {
  const { t } = useTranslation('security');
  const { externalLinks } = useConfigStore();

  const handleExternalLinkRedirect = (redirectUrl: string) => {
    window.open(redirectUrl, '_blank', 'noreferrer noopener');
  };

  return (
    <ContextMenuContainer>
      <ContextMenuBlock title={t('externalLinks')}>
        {externalLinks?.map((link) => (
          <ContextMenuItem
            key={link.externalLinkId}
            text={link.name}
            onClick={() => handleExternalLinkRedirect(link.link)}
          />
        ))}
      </ContextMenuBlock>
    </ContextMenuContainer>
  );
};

export default ExternalLinkscontent;
