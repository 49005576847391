import styled from '../../config/theme';

const MainInfoTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h1 {
    color: ${(props) => props.theme.internalPaper.mainInfo.color.header};
    font-size: ${(props) => props.theme.internalPaper.mainInfo.fontSize.header};
    font-weight: ${(props) => props.theme.internalPaper.mainInfo.fontWeight.header};
    min-height: ${(props) => props.theme.internalPaper.mainInfo.fontSize.header};
  }

  h2 {
    color: ${(props) => props.theme.internalPaper.mainInfo.color.main};
    font-size: ${(props) => props.theme.internalPaper.mainInfo.fontSize.subHeader};
    font-weight: ${(props) => props.theme.internalPaper.mainInfo.fontWeight.subHeader};
  }
`;

export default MainInfoTitleWrapper;
