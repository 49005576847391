export const DRAFTS_MODULE_KEY = 'drafts';
export const INVOICES_MODULE_KEY = 'invoices';
export const MANUAL_INVOICES_MODULE_KEY = 'manualInvoices';
export const MANUAL_PRODUCTS_FILTER_KEY = 'manualProducts';
export const REQUESTLOGS_MODULE_KEY = 'requestlogs';

export const DRAFTS_PAGE_SIZE = 20;
export const INVOICES_PAGE_SIZE = 20;
export const MANUAL_PAGE_SIZE = 20;
export const REQUESTLOGS_PAGE_SIZE = 20;

export const REBATE_METADATA_CODE = 'rebate';
export const BILLING_PLAN_METADATA_CODE = 'billingplan';
export const TERM_DURATION_METADATA_CODE = 'termduration';
export const RENEWAL_DATE_METADATA_CODE = 'renewaldate';

export enum INVOICE_STATE {
  DRAFT = 'draft',
  CREATED = 'created',
  SEND = 'sent',
  PAID = 'paid',
  OVERDUE = 'overdue',
  PARTIALLYPAID = 'partiallyPaid',
  REMINDER1 = 'reminder1',
  REMINDER2 = 'reminder2',
  NOTICE1 = 'notice1',
  NOTICE2 = 'notice2',
}

export enum INVOICE_LINE_TYPE {
  IS_CREDITED = 'isCredited',
  IS_CREDITER = 'isCrediter',
  ORIGINAL = 'original',
}

export enum REQUESTLOG_STATUS {
  FINSIHED = 'finished',
  PENDING = 'pending',
}

export enum REQUESTLOG_DETAIL_TYPE {
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
}

export enum ManualInvoiceDate {
  FROM = 'from',
  TO = 'to',
}

export enum ManualInvoiceActions {
  ADD = 'add',
  EDIT = 'edit',
  NEW = 'NEW',
}

export enum INVOICE_RECEIVER {
  CLIENT = 'client',
  ACCOUNTING = 'accounting',
}

export enum InvoiceType {
  CREDITNOTE = 'creditNote',
  INVOICE = 'invoice',
}
