import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

type Props = {
  className?: string;
};

const Link: React.FC<Props> = (props: Props): JSX.Element => (
  <BaseSvgWrapper {...props}>
    <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
      <path d="M28.6359 34.2851L21.5881 41.3456C18.0425 44.8975 12.2785 44.9189 8.70565 41.4067L8.70514 41.4072C8.69692 41.399 8.68871 41.3903 8.68049 41.3816C8.67228 41.3734 8.66355 41.3652 8.65483 41.357L8.65534 41.3564C5.13679 37.7898 5.14765 32.0259 8.69382 28.474L16.4606 20.6938C20.0062 17.142 25.7702 17.1205 29.343 20.6328L29.3436 20.6323C29.3518 20.6405 29.36 20.6492 29.3682 20.6574C29.3764 20.6656 29.3851 20.6738 29.3939 20.6825L29.3933 20.683C29.8205 21.116 30.1958 21.5809 30.5188 22.0704L28.4086 24.1844C27.8069 24.7871 26.89 24.8848 26.1839 24.4812C26.0909 24.3644 25.9913 24.2516 25.884 24.143C24.2362 22.5278 21.5819 22.5383 19.9482 24.1749L12.181 31.9551C10.5463 33.5926 10.5409 36.2484 12.1616 37.8934C13.8094 39.5112 16.4657 39.5011 18.0999 37.8636L22.5147 33.4411C24.4527 34.2796 26.5748 34.5603 28.6359 34.2851ZM41.3283 8.62623L41.3278 8.62674C37.7549 5.11449 31.9909 5.13543 28.4453 8.68776L21.397 15.7483C23.4586 15.4731 25.5807 15.7538 27.5182 16.5918L31.933 12.1693C33.5677 10.5318 36.2235 10.5217 37.8713 12.1395C39.492 13.7845 39.4866 16.4408 37.8519 18.0778L30.0852 25.8585C28.4515 27.4951 25.7972 27.5056 24.1494 25.8904C24.0426 25.7818 23.9425 25.669 23.8496 25.5522C23.1434 25.1486 22.2265 25.2464 21.6248 25.849L19.5146 27.963C19.8376 28.4525 20.2129 28.9179 20.6401 29.3504L20.6395 29.3509C20.6478 29.3591 20.6565 29.3673 20.6652 29.376C20.6734 29.3842 20.6816 29.3929 20.6899 29.4011L20.6904 29.4006C24.2632 32.9129 30.0272 32.8919 33.5729 29.3396L41.3396 21.5594C44.8852 18.0075 44.8966 12.2436 41.3781 8.67696L41.3786 8.67645C41.3704 8.66825 41.3616 8.66005 41.3529 8.65185C41.3447 8.64314 41.3365 8.63494 41.3283 8.62623Z" />
    </svg>
  </BaseSvgWrapper>
);

export default Link;
