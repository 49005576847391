import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { useToggle } from '@unbrace/hooks';
import { ChevronDown, ChevronRight } from '../../../../components/icons';
import { Module } from '../../../security/types/modules';
import { NavigationLink, StyledNavigationLink } from './';

type Props = {
  module: Module;
};

const SubNavigation: React.FC<Props> = ({ ...props }) => {
  const { t } = useTranslation();
  const { module, children: icon } = props;
  const match = Boolean(useRouteMatch({ path: `/${module.key.toLowerCase()}` }));
  const [isOpen, toggleOpen] = useToggle(match);

  const toggleNavigationItems = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    toggleOpen();
  };

  const openNavigationItems = () => {
    toggleOpen(true);
  };

  return (
    <StyledNavigationLink isOpen={isOpen}>
      <a href="/" onClick={toggleNavigationItems}>
        {icon}
        <span>{t(`sidebar.${module.key}`)}</span>
        <span className="dropper">{isOpen ? <ChevronDown /> : <ChevronRight />}</span>
      </a>
      <ul>
        {(module.subModules as Module[]).map((subModule: Module) => (
          <NavigationLink
            key={`${module.key}/${subModule.key}`}
            to={`${module.key}/${subModule.key}`}
            onFocus={openNavigationItems}
          />
        ))}
      </ul>
    </StyledNavigationLink>
  );
};

export default SubNavigation;
