import { TenantTheme } from '../tenants/unbrace';

const dashboardTheme = (theme: TenantTheme) => ({
  cards: {
    background: theme.palette.neutral.shade6,
    borderRadius: theme.globals.borderRadius,
    boxShadow: theme.globals.boxShadowNarrow,
    fontSize: theme.typography.fontSize.regular,
    fontWeight: 200,
    hover: {
      boxShadow: theme.globals.boxShadow,
    },
    image: {
      height: '60px',
      width: '60px',
    },
    textColor: theme.palette.neutral.shade2,
  },
});

export default dashboardTheme;
