import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { Logo } from '../../../components';
import { LoginBox } from '../components';
import { LoginManager } from '../services';
import { LoginManagerRenderProps } from '../services/LoginManager';
import LoginForm from './LoginForm';

type Props = {} & RouteComponentProps;

const Login: React.FC<Props> = ({ location: { state: locationState } }) => {
  const { t } = useTranslation('security');

  return (
    <LoginManager>
      {({ handleLogin, isPending }: LoginManagerRenderProps) => (
        <LoginBox>
          <section>
            <Logo />
            <h4>{t('login_title')}</h4>
            <LoginForm handleSubmit={handleLogin} locationState={locationState} isPending={isPending} />
            {process.env.REACT_APP_VERSION && <span>{`${t('version')}: ${process.env.REACT_APP_VERSION}`}</span>}
          </section>
        </LoginBox>
      )}
    </LoginManager>
  );
};

export default withRouter(Login);
