import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { MultiSelectActions } from 'hooks/useMultiSelect';
import { Paper } from '../../../components';
import { EmptyStateImage, EmptyStateWrapper } from '../../../components/EmptyState';
import NoPricing from '../../../components/EmptyState/images/NoPricing.svg';
import RouteLeavingGuard from '../../app/components/RouteLeavingGuard';
import PricingLoadingState from '../components/pricing/PricingLoadingState';
import ProductPricingGroup from '../components/pricing/ProductPricingGroup';
import { Pricing, PricingGroups } from '../types/pricing';
import { ChangedPrices } from './ProductDefaultView';

type Props = {
  changedPrices: ChangedPrices;
  handleDelete: (price: Pricing) => void;
  handleFormChange: (values: Pricing) => void;
  priceChanged: boolean;
  pricing?: PricingGroups;
  pricingIsPending: boolean;
  selectedPricing: Pricing[];
  selectedPricingActions: MultiSelectActions;
  productIsPending: boolean;
};

const ProductPricing: React.FC<Props> = ({
  changedPrices,
  handleDelete,
  handleFormChange,
  priceChanged,
  pricing,
  pricingIsPending,
  productIsPending,
  selectedPricing,
  selectedPricingActions,
}) => {
  const { t } = useTranslation('products');

  return (
    <React.Fragment>
      {pricingIsPending || productIsPending || !pricing ? (
        <Paper noTopMargin>
          <PricingLoadingState />
        </Paper>
      ) : pricing &&
        ((pricing.general && pricing.general.length > 0) ||
          (pricing.entity && Object.values(pricing.entity).length > 0)) ? (
        <Paper multiple onlyBottomPadding noTopMargin>
          <React.Fragment>
            <RouteLeavingGuard when={priceChanged} />
            {pricing.general && pricing.general.length > 0 && (
              <ProductPricingGroup
                changedPrices={changedPrices}
                handleDelete={handleDelete}
                handleFormChange={handleFormChange}
                pricing={pricing.general}
                selectedPricing={selectedPricing}
                selectedPricingActions={selectedPricingActions}
              />
            )}
            {pricing.entity &&
              Object.values(pricing.entity).map((pricingGroup, index) =>
                pricingGroup.length > 0 ? (
                  <ProductPricingGroup
                    changedPrices={changedPrices}
                    handleDelete={handleDelete}
                    handleFormChange={handleFormChange}
                    isEntity
                    key={index}
                    pricing={pricingGroup}
                    selectedPricing={selectedPricing}
                    selectedPricingActions={selectedPricingActions}
                  />
                ) : null
              )}
          </React.Fragment>
        </Paper>
      ) : (
        <EmptyStateWrapper paper>
          <EmptyStateImage src={NoPricing} />
          <p>{t('emptyState.pricing.title')}</p>
        </EmptyStateWrapper>
      )}
    </React.Fragment>
  );
};

export default ProductPricing;
