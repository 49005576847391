import styled from 'styled-components';

type Props = {
  noPadding?: boolean;
  noSidePadding?: boolean;
  noTopPadding?: boolean;
  onlyBottomPadding?: boolean;
};

const PaperPaddingWrapper = styled.div<Props>`
  padding: ${(props) => props.theme.internalPaper.padding.vertical}
    ${(props) => props.theme.internalPaper.padding.vertical};
  ${(props) => props.noSidePadding && `padding: ${props.theme.internalPaper.padding.vertical} 0`}
  ${(props) => props.noPadding && `padding: 0`};
  ${(props) =>
    props.noTopPadding &&
    `padding: 0 ${props.theme.internalPaper.padding.vertical} ${props.theme.internalPaper.padding.vertical}`};
  ${(props) => props.onlyBottomPadding && `padding: 0 0 ${props.theme.internalPaper.padding.vertical} 0`};
`;

export default PaperPaddingWrapper;
