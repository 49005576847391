export const TASKS_MODULE_KEY = 'tasks';

export enum TaskActionType {
  AssignToMe = 'assignTaskToMe',
  Cancel = 'cancel',
  Execute = 'execute',
  OnHold = 'onHold',
  Resolve = 'resolve',
  Restart = 'restart',
  View = 'view',
  AssignTask = 'assignTask',
  ReOpen = 'reOpen',
}
