import styled from 'config/theme';
import { animations } from 'config/theme/keyframes';

export const FilterContainer = styled.div`
  background: ${(props) => props.theme.itemSidebar.background.filter};
  max-height: 50%;
  font-size: 1rem;
  transition: all 0.15s ease-out;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`;

export const OpenFilterButton = styled.div<{ hasActiveFilters: boolean }>`
  background: ${(props) =>
    props.hasActiveFilters
      ? props.theme.itemSidebar.background.filterButton.active
      : props.theme.itemSidebar.background.filterButton.main};
  padding: 12px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .pill {
    text-align: center;
    display: inline-block;
    margin-left: 8px;
    background: ${(props) => props.theme.itemSidebar.background.filterButton.pill};
    color: ${(props) => props.theme.itemSidebar.color.filterButton.pill};
    border-radius: 50%;
    padding: 3px 5px;
    font-size: 80%;
    font-weight: 500;
    min-width: 24px;
    animation: 0.2s ease-in ${animations.fadeIn};
  }

  &:hover {
    cursor: pointer;
  }
`;
