import * as React from 'react';
import styled from '../../config/theme';
import { MainInfoTitleWrapper } from '../Paper';

type Props = {
  title: string;
  description: string;
};

const BasicFormTitle: React.FC<Props> = ({ title, description }: Props) => {
  return (
    <HeaderWrapper>
      <h1>{title}</h1>
      <h2>{description}</h2>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled(MainInfoTitleWrapper)`
  margin-bottom: 30px;

  h2 {
    margin-top: 5px;
    font-size: 14px;
  }
`;

export default BasicFormTitle;
