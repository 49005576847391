import * as React from 'react';
import { preventClickEventBubbling } from '../../../helpers';
import { ContextMenuItemWrapper } from './styles';

type Props = {
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  closeMenu?: () => void;
  isDisabled?: boolean;
  icon?: JSX.Element;
  iconSize?: 'small' | 'regular';
  text: string;
  isLoading?: boolean;
  keepMenuOpenOnClick?: boolean;
};

const ContextMenuItem: React.FC<Props> = ({
  onClick,
  closeMenu,
  isDisabled,
  icon,
  iconSize,
  text,
  isLoading,
  keepMenuOpenOnClick,
}: Props) => {
  const handleClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (isDisabled) {
        preventClickEventBubbling(e);
      }
      if (onClick && !isDisabled) {
        onClick(e);
      }
      if (closeMenu && !keepMenuOpenOnClick) {
        closeMenu();
      }
    },
    [onClick, closeMenu, isDisabled, keepMenuOpenOnClick]
  );

  return (
    <ContextMenuItemWrapper
      iconSize={iconSize}
      isDisabled={isDisabled || isLoading}
      onClick={handleClick}
      isLoading={isLoading}
    >
      {icon}
      <p>{text}</p>
    </ContextMenuItemWrapper>
  );
};

export default ContextMenuItem;
