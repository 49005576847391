import { IconButton } from '@unbrace/components';
import { ContextMenu } from 'components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ORIENTATION_TOP_RIGHT } from '../../../components/ContextMenu/constants';
import { GoTo } from '../../../components/icons';
import ExternalLinkscontent from './ExternalLinksContent';

const ExternalLinks: React.FC = () => {
  const { t } = useTranslation('security');

  return (
    <ContextMenu enhanceWithOnContext={false} orientation={ORIENTATION_TOP_RIGHT} content={<ExternalLinkscontent />}>
      {({ toggleMenu }) => (
        <IconButton tooltip={t('externalLinks')} tooltipPosition="top" onClick={toggleMenu}>
          <GoTo />
        </IconButton>
      )}
    </ContextMenu>
  );
};

export default ExternalLinks;
