import { Action } from 'redux';

export type LoadingState = {
  [key: string]: boolean | undefined;
};

const loadingReducer = (state: LoadingState = {}, action: Action) => {
  const { type } = action;
  const matches = /(.*)_(REQUEST|SUCCESS|MERGED|REJECTED|CLEARED)/.exec(type);

  if (!matches) {
    return state;
  }

  const [, requestName, requestState] = matches;

  return {
    ...state,
    [requestName]: requestState === 'CLEARED' ? undefined : requestState === 'REQUEST',
  };
};

export default loadingReducer;
