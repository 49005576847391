import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const MoneyMail: React.FC = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36 12.5949V17.5812L40.2808 20.7673L25 34.1476L9.71921 20.7673L14 17.5812L14 12.5949L5 19.2934V46H45V19.2934L36 12.5949ZM26.9763 37.7338L41 25.4543V42H9V25.4543L23.0237 37.7338C24.155 38.7244 25.845 38.7244 26.9763 37.7338Z"
      />
      <path d="M8 24L24.9853 37.5L41 24V43H8V24Z" />
      <path d="M23.343 2V5.24038C20.2309 5.90386 18 8.37341 18 11.2022C18 14.2327 20.2331 16.2842 24.637 17.2997C28.6855 18.2328 28.6855 19.6202 28.6855 20.076C28.6855 21.5569 26.9978 22.8081 25.0003 22.8081C23.0025 22.8081 21.3145 21.5569 21.3145 20.076V19.7436H18V20.076C18 22.9048 20.2309 25.3741 23.343 26.0374V29.2632H26.6576V26.0374C29.7694 25.3739 32 22.9047 32 20.076C32 17.0456 29.7672 14.9941 25.3633 13.9786C21.3145 13.0455 21.3145 11.6581 21.3145 11.2022C21.3145 9.72101 23.0025 8.46967 25.0003 8.46967C26.9978 8.46967 28.6855 9.72101 28.6855 11.2022V11.5347H32V11.2022C32 8.3734 29.7694 5.90385 26.6576 5.24038V2H23.343Z" />
    </svg>
  </BaseSvgWrapper>
);

export default MoneyMail;
