import { TenantTheme } from '../tenants/unbrace';

const drawerTheme = (theme: TenantTheme) => ({
  background: {
    // Override @unbrace/components pill
    danger: theme.palette.danger.shade4,
  },
});

export default drawerTheme;
