import * as React from 'react';
import { Route } from 'react-router-dom';
import { loadScene } from '../../../components';
import ModuleRoutes from '../../../components/routing/ModuleRoutes';
import { Module } from '../../security/types/modules';
import { SERVICEFEE_MODULE_KEY } from '../constants';

type Props = {
  allowedModules: Module[];
};

const servicefeeOverviewScene = loadScene('servicefee', 'ServicefeeScene');

export const SERVICEFEE_BASE_ROUTES = '/servicefee';

const ServicefeeRoutes: React.FC<Props> = ({ allowedModules }: Props) => (
  <ModuleRoutes allowedModules={allowedModules} moduleKey={SERVICEFEE_MODULE_KEY}>
    <Route path={'/servicefee'} exact component={servicefeeOverviewScene} />
  </ModuleRoutes>
);

export default ServicefeeRoutes;
