import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import deepmerge from 'deepmerge';
import Qs from 'qs';

export type RequestConfig<T = {}> = {
  method?: RequestMethodType;
  url: string;
  params?: T;
  data?: any;
  pageSize?: number;
  headers?: any;
  preventCancelling?: boolean;
};

export type RequestMethodType = 'GET' | 'POST' | 'PUT' | 'DELETE';

export const defaultRequestConfig = {
  baseURL: process.env.REACT_APP_API_ROOT?.replace('/api', ''),
  paramsSerializer: (params: any) => Qs.stringify(params, { arrayFormat: 'repeat' }),
  withCredentials: true,
  headers: [{ 'content-type': 'application/json' }],
};

class AxiosConfig {
  private defaultRequestConfig: AxiosRequestConfig = {
    baseURL: process.env.REACT_APP_API_ROOT,
    paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: 'repeat' }),
    withCredentials: true,
  };

  private getRequestConfig: AxiosRequestConfig = {
    ...this.defaultRequestConfig,
    method: 'GET',
  };

  private getPagedRequestConfig: AxiosRequestConfig = {
    ...this.defaultRequestConfig,
    method: 'GET',
    params: {
      pageSize: Number(localStorage.getItem('pageSize')) || 20,
    },
  };

  private postRequestConfig: AxiosRequestConfig = {
    ...this.defaultRequestConfig,
    method: 'POST',
  };

  private putRequestConfig: AxiosRequestConfig = {
    ...this.defaultRequestConfig,
    method: 'PUT',
  };

  private deleteRequestConfig: AxiosRequestConfig = {
    ...this.defaultRequestConfig,
    method: 'DELETE',
  };

  public async fetchAxiosDownloadRequest<T = {}>(
    config: RequestConfig,
    fileName: string,
    type?: number
  ): Promise<void> {
    config.params = {
      invoiceDocumentType: type,
    };
    const result = await axios.request({ ...this.defaultRequestConfig, ...config });

    const binaryString = window.atob(result.data.response.byteArray || '');
    const bytes = new Uint8Array(binaryString.length);
    const arrayBuffer = bytes.map((_, index) => binaryString.charCodeAt(index));

    const blob = new Blob([arrayBuffer]);
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName || '');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  public fetchAxiosGetRequest<T = {}>(config?: RequestConfig): Promise<T> {
    return this.fetchRequest(this.getRequestConfig, config);
  }

  public fetchAxiosPagedGetRequest<T = {}>(config?: RequestConfig): Promise<T> {
    return this.fetchRequest(this.getPagedRequestConfig, config);
  }

  public fetchAxiosPostRequest<T = {}>(config?: RequestConfig): Promise<T> {
    return this.fetchRequest(this.postRequestConfig, config);
  }

  public fetchAxiosPutRequest<T = {}>(config?: RequestConfig): Promise<T> {
    return this.fetchRequest(this.putRequestConfig, config);
  }

  public fetchAxiosDeleteRequest<T = {}>(config?: RequestConfig): Promise<T> {
    return this.fetchRequest(this.deleteRequestConfig, config);
  }

  // TODO: Remove this when backend returns everything in correct data format
  public async fetchAxiosRequestTemp<T = {}>(config?: RequestConfig): Promise<T> {
    const result = await axios.request({ ...this.defaultRequestConfig, ...config });

    return result.data;
  }

  private calls: { [key: string]: CancelTokenSource } = {};

  private async fetchRequest<T = {}>(baseConfig: AxiosRequestConfig, config?: RequestConfig): Promise<T> {
    const requestConfig = this.addCancelTokenToConfig(deepmerge(baseConfig, config || {}));
    const result = await axios.request(requestConfig);

    if (result.data && result.data.response) {
      return result.data.response;
    }

    return result.data;
  }

  private addCancelTokenToConfig(config: AxiosRequestConfig & RequestConfig) {
    if (config.preventCancelling) {
      return config;
    }

    const requestKey = `${config.method}-${config.url}`;
    if (this.calls[requestKey]) {
      this.calls[requestKey].cancel('Cancelling previous request');
    }
    this.calls[requestKey] = axios.CancelToken.source();
    config.cancelToken = this.calls[requestKey].token;

    return config;
  }
}

export default new AxiosConfig();
