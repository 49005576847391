import { createSelector } from 'reselect';
import { RootState } from '../../../../config/store/rootReducer';

export const getIsSameOrigin = (state: RootState): boolean => state.security.login.isSameOrigin;

export const makeGetIsSameOrigin = () =>
  createSelector(
    getIsSameOrigin,
    (isSameOrigin: boolean) => isSameOrigin
  );
