import { css } from 'styled-components';
import styled from 'config/theme';

const ToggleBadge = styled('div')<{ active: boolean }>`
  align-self: center;
  border-bottom: 5px solid transparent;
  color: ${(props) => props.theme.palette.neutral.shade5};
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  margin-right: 0;
  padding: 16px 30px 12px;
  transition: all ease-in-out 0.2s;

  &:first-child{
    border-radius: 7px 0 0 0;
  }

  ${(props) =>
    props.active &&
    css`
      background: ${props.theme.palette.primary.shade1};
      border-bottom: 5px solid ${props.theme.palette.primary.shade4};
      color: ${props.theme.palette.primary.shade7};
      transition: all ease-in-out 0.2s;
    `};

  &:hover {
    ${(props) =>
      !props.active &&
      css`
        background: ${props.theme.palette.neutral.shade1};
        border-bottom: 5px solid transparent;
        color: ${props.theme.palette.neutral.shade5};
        cursor: pointer;
        transition: all ease-in-out 0.2s;
      `}
`;

export default ToggleBadge;
