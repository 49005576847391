import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

type Props = {
  className?: string;
};

const Lock: React.FC<Props> = (props: Props): JSX.Element => (
  <BaseSvgWrapper {...props}>
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.35 45H11.7037C9.65802 45 8 43.3414 8 41.2963V22.0401C8 19.9944 9.65802 18.3364 11.7037 18.3364H12.4167V17.6099H12.4401C12.4426 10.6457 18.0895 5 25.0543 5C32.021 5 37.608 10.6475 37.608 17.6142C37.608 17.8691 37.6179 18.1056 37.6185 18.3364H38.3494C40.3951 18.3364 42.0531 19.9944 42.0531 22.0401V41.2963C42.0537 43.342 40.3963 45 38.35 45ZM25.0796 9.4463C20.579 9.4463 16.9309 13.0944 16.929 17.5938H16.9136V18.3364H33.1938V18.2778C33.2148 18.063 33.2309 17.8407 33.2309 17.5969C33.2302 13.0957 29.5809 9.4463 25.0796 9.4463ZM37.6309 24.2796C37.6309 23.4611 36.9673 22.7981 36.1494 22.7981H13.908C13.0901 22.7981 12.4265 23.4611 12.4265 24.2796V39.0815C12.4265 39.9 13.0901 40.563 13.908 40.563H36.1494C36.9673 40.563 37.6309 39.8994 37.6309 39.0815V24.2796ZM25.3877 36.0975H24.6469C23.6278 36.0975 22.8019 35.2716 22.8019 34.2525V29.134C22.8019 28.1148 23.6278 27.2889 24.6469 27.2889H25.3877C26.4068 27.2889 27.2327 28.1148 27.2327 29.134V34.2525C27.2333 35.2716 26.4068 36.0975 25.3877 36.0975Z"
      />
    </svg>
  </BaseSvgWrapper>
);

export default Lock;
