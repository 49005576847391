import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../../config/store/rootReducer';
import { filterActions, FilterActionsType } from '../redux/actions/creators';
import { makeGetPageIndex, makeGetSearchText } from '../redux/selectors/filters';

export type OwnProps = {
  children: RenderCallBack;
  moduleKey: string;
  callbackFunction?: (pageIndex?: number, searchValue?: string) => void;
};

type Props = OwnProps & MapStateToProps & MapDispatchToProps;

type MapDispatchToProps = {
  setSearchText: (text: string) => void;
  setPageIndex: (pageIndex: number) => void;
};

type MapStateToProps = {
  pageIndex: number | undefined;
  searchText: string | undefined;
};

type PropsToPass = MapStateToProps & MapDispatchToProps & { moduleKey: string };

type RenderCallBack = (args: PropsToPass) => JSX.Element;

class FilterTableService extends React.Component<Props, any> {
  componentDidMount() {
    const { callbackFunction, pageIndex, searchText } = this.props;
    if (callbackFunction) {
      callbackFunction(pageIndex, searchText);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { callbackFunction, pageIndex, searchText } = this.props;
    if (prevProps.pageIndex !== this.props.pageIndex || prevProps.searchText !== this.props.searchText) {
      if (callbackFunction) {
        callbackFunction(pageIndex, searchText);
      }
    }
  }

  render(): JSX.Element {
    const { children, setSearchText, searchText, setPageIndex, pageIndex, moduleKey } = this.props;
    const renderProp = {
      moduleKey,
      pageIndex: pageIndex ? pageIndex - 1 : undefined,
      searchText,
      setPageIndex,
      setSearchText,
    };

    return children(renderProp);
  }
}

const mapDispatchToProps = (dispatch: Dispatch<FilterActionsType>, props: OwnProps) => ({
  setPageIndex: (pageIndex: number) => dispatch(filterActions.setPageIndex(pageIndex, props.moduleKey)),
  setSearchText: (text: string) => dispatch(filterActions.setSearchText(text, props.moduleKey)),
});

const makeMapStateToProps = () => {
  const getSearchTextSelector = makeGetSearchText();
  const getPageIndexSelector = makeGetPageIndex();

  return (state: RootState, props: OwnProps) => ({
    pageIndex: getPageIndexSelector(state, props.moduleKey),
    searchText: getSearchTextSelector(state, props.moduleKey),
  });
};

export default connect<MapStateToProps, MapDispatchToProps, OwnProps, RootState>(
  makeMapStateToProps,
  mapDispatchToProps
)(FilterTableService);
