import * as React from 'react';
import { Route } from 'react-router-dom';
import { loadScene } from '../../../components';
import ModuleRoutes from '../../../components/routing/ModuleRoutes';
import { Module } from '../../security/types/modules';
import { REBATEGROUP_MODULE_KEY } from '../constants';
import { REBATEGROUP_BASE_ROUTES } from '.';

type Props = {
  allowedModules: Module[];
};

const rebateGroupOverviewScene = loadScene('rebategroups', 'RebateGroupsScene');

const RebateGroupsRoutes: React.FC<Props> = ({ allowedModules }: Props) => (
  <ModuleRoutes allowedModules={allowedModules} moduleKey={REBATEGROUP_MODULE_KEY}>
    <Route path={REBATEGROUP_BASE_ROUTES} exact component={rebateGroupOverviewScene} />
  </ModuleRoutes>
);

export default RebateGroupsRoutes;
