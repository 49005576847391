import React from 'react';
import { useGetModules } from '../adapters/queries';
import { makeGetAccessRights, makeGetFunctionalityAccessRights } from '../redux/selectors/user';
import { canEditModule, canReadModule } from '../util';
import { useSelector } from 'react-redux';

const useModulesStore = () => {
  const { data: modules, isLoading, isFetching } = useGetModules();
  const accessRights = useSelector(makeGetAccessRights());
  const functionalityAccessRights = useSelector(makeGetFunctionalityAccessRights());

  const accessibleModules = React.useMemo(
    () => modules?.filter((module) => canReadModule(module, accessRights)),
    [modules, accessRights]
  );

  const editableModules = React.useMemo(
    () => modules?.filter((module) => canEditModule(module, accessRights)).map((module) => module.key.toLowerCase()),
    [modules, accessRights]
  );

  const hasEditFunctionality = (module: string) => editableModules?.includes(module) || false;

  return {
    isModulesLoading: isLoading || isFetching,
    hasEditFunctionality,
    accessibleModules,
    functionalityAccessRights,
    modules,
  };
};

export default useModulesStore;
