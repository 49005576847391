import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ModuleEditAccessContext } from 'modules/security';
import { LinkButton } from '../../../components';
import { NoListItemSelectedImage, NoListItemSelectedWrapper } from '../../../components/EmptyState';
import NoProductSelectedImg from '../../../components/EmptyState/images/NoProductSelected.svg';
import { PRODUCT_NEW_URL } from '../constants';

type Props = {};

const NoProductSelected: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation('products');
  const { canEdit } = React.useContext(ModuleEditAccessContext);

  return (
    <NoListItemSelectedWrapper>
      <NoListItemSelectedImage src={NoProductSelectedImg} />
      <React.Fragment>
        <h1>{t('emptyState.productDetail.title')}</h1>
        <p>{t('emptyState.productDetail.subText')}</p>
        <LinkButton color="primary" to={PRODUCT_NEW_URL} isDisabled={!canEdit}>
          {t('emptyState.productDetail.primaryButton')}
        </LinkButton>
      </React.Fragment>
    </NoListItemSelectedWrapper>
  );
};

export default NoProductSelected;
