import styled from '../../config/theme';

type Props = {
  bottomPadding?: boolean;
  noPadding?: boolean;
  flexDirection?: 'row' | 'column';
};

export const MainInfoWrapper = styled.section<Props>`
  background: ${(props) => props.theme.internalPaper.mainInfo.background};
  padding: 0 ${(props) => !props.noPadding && props.theme.internalPaper.padding.horizontal}
    ${(props) => props.bottomPadding && props.theme.internalPaper.padding.vertical};
  color: ${(props) => props.theme.internalPaper.mainInfo.color.main};
  width: 100%;
  display: flex;
  flex-direction: ${(props) => props.flexDirection || 'row'};
  & > div {
    flex-grow: 1;
  }
`;

export const LeftInfo = styled.div`
  text-align: left;
  width: 60%;
`;

export const RightInfo = styled.div`
  text-align: right;
  width: 40%;
  font-size: ${(props) => props.theme.internalPaper.mainInfo.fontSize.main};

  > p {
    margin: 8px 0;

    &:last-child {
      margin-bottom: 24px;
    }
  }
`;
