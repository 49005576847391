const locale = process.env.REACT_APP_CURRENCY_LOCALE || 'nl-BE';
const currencyCode = process.env.REACT_APP_CURRENCY_CODE || 'EUR';
const minFrac = process.env.REACT_APP_CURRENCY_MIN_FRACTION_DIGITS || '2';

type CurrencyPart = {
  type: string;
  value: string;
};

type FallbackFormatter = {
  format: (numberToFormat: number) => string;
  formatToParts?: (numberToFormat: number) => CurrencyPart[];
};

let intl: {
  formatter: Intl.NumberFormat | FallbackFormatter;
  precisionFormatter: (precision: number) => Intl.NumberFormat | FallbackFormatter;
};

if (global.Intl) {
  intl = {
    formatter: new Intl.NumberFormat(locale, {
      currency: currencyCode,
      minimumFractionDigits: parseInt(minFrac, 10),
      style: 'currency',
    }),
    precisionFormatter: (precision: number) =>
      new Intl.NumberFormat(locale, {
        currency: currencyCode,
        minimumFractionDigits: precision,
        style: 'currency',
      }),
  };
} else {
  import('currency.js')
    .then((currencyLib) => {
      return (intl = {
        formatter: {
          format: (numberToFormat: number) =>
            currencyLib
              .default(numberToFormat, {
                decimal: ',',
                pattern: '! #',
                precision: parseInt(minFrac, 10),
                separator: '.',
                symbol: currencyCode,
              })
              .format(),
          formatToParts: () => [{ type: 'currency', value: currencyCode }],
        },
        precisionFormatter: (precision: number) => ({
          format: (numberToFormat: number) =>
            currencyLib
              .default(numberToFormat, {
                decimal: ',',
                pattern: '! #',
                precision,
                separator: '.',
                symbol: currencyCode,
              })
              .format(),
        }),
      });
    })
    .catch(() => console.error('currency error'));
}

export const formatCurrency = (numberToFormat?: number | null, precision?: number): string => {
  if (numberToFormat === null || numberToFormat === undefined) {
    return '';
  }

  if (precision) {
    return intl.precisionFormatter(precision).format(numberToFormat);
  }

  return intl.formatter.format(numberToFormat);
};

export const getCurrency = (): string => {
  if (!intl.formatter.formatToParts) {
    return currencyCode;
  }

  const parts = intl.formatter.formatToParts(0);
  const currency = parts.find((part: CurrencyPart) => part.type === 'currency');

  return (currency && currency.value) || currencyCode;
};
