import * as React from 'react';
import { media } from 'config/theme/utils';
import styled, { css } from '../../config/theme';

export const FullViewWrapper = styled.div`
  position: relative;

  .close {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;

    svg {
      fill: ${(props) => props.theme.fullView.color.primary};
    }

    &:hover {
      transform: scale(1.1);
      transition: transform 0.2s;
    }
  }
`;

const mobileFullViewBlockStyling = css`
  flex-direction: column;

  h2.block-title {
    margin-bottom: 32px;
    font-weight: 500;
    color: inherit;
    text-align: center;
  }

  h3.sub-title {
    transform: none;
    margin-right: 0;
    margin-bottom: 0;
    text-align: left;
  }
`;

export const FullViewBlockLayout = styled.section<{ amountOfButtons?: number; noBorder?: boolean }>`
  border-top: ${(props) => !props.noBorder && props.theme.fullView.border};
  display: flex;
  flex-direction: row;
  font-weight: 200;
  justify-content: space-between;
  padding: 20px 0;

  h2.block-title {
    color: ${(props) => props.theme.fullView.color.secondary};
    font-size: ${(props) => props.theme.fullView.fontSize.blockTitle};
    font-weight: ${(props) => props.theme.fullView.fontWeight.blockTitle};
    text-transform: capitalize;
  }

  h3.sub-title {
    color: ${(props) => props.theme.fullView.color.secondary};
    font-size: ${(props) => props.theme.fullView.fontSize.subTitle};
    font-weight: ${(props) => props.theme.fullView.fontWeight.blockTitle};
    margin-bottom: 10px;
    margin-right: 320px;
    text-align: center;
    text-transform: uppercase;
    transform: translateX(50%);

    ${() => media.desktop`
      margin-right: 350px;
    `}
  }

  div.block-data {
    display: flex;
    flex-direction: column;
    min-height: 42px;
    justify-content: center;
    ${(props) => props.amountOfButtons && `margin-right: ${props.amountOfButtons * 50}px;`};
  }

  ${() => media.mobile`
    ${mobileFullViewBlockStyling}
  `}

  ${() => media.tablet`
    ${mobileFullViewBlockStyling}
  `}

  ${() => media.laptopSM`
    ${mobileFullViewBlockStyling}
  `}
`;

type FullViewBlockProps = {
  children?: React.ReactNode | React.ReactNode[];
  title?: string;
  amountOfButtons?: number;
  data?: React.ReactNode;
  noBorder?: boolean;
};

export const FullViewBlock: React.FC<FullViewBlockProps> = ({ children, title, data, ...rest }) => (
  <FullViewBlockLayout {...rest}>
    {title && <h2 className="block-title">{title}</h2>}
    {data && <div className="block-data">{data}</div>}
    {children}
  </FullViewBlockLayout>
);

export const FullViewSubBlock = styled.div`
  :not(:last-child) {
    margin-bottom: 40px;
  }
`;

const mobileFullViewBlockItemStyling = css`
  flex-direction: column;

  &:not(:last-child) {
    padding-bottom: 20px;
  }
`;

export const FullViewBlockItem = styled.div<{ capitalize?: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  &:not(:last-child) {
    padding-bottom: 8px;
  }

  ${() => media.mobile`
    ${mobileFullViewBlockItemStyling}
  `}

  ${() => media.tablet`
    ${mobileFullViewBlockItemStyling}
  `}

  ${() => media.laptopSM`
    ${mobileFullViewBlockItemStyling}
  `}
`;
