import * as React from 'react';
import Chart from 'react-apexcharts';
import { withTheme } from '../../../../config/theme';
import { RootTheme } from '../../../../config/theme/types';
import { formatCurrency } from '../../../../helpers';
import { Pricing } from '../../types/pricing';
import { mapPricingForGraph, Series } from '../../util/pricingGraphMapper';

const fixedOptions = {
  dataLabels: {
    enabled: false,
  },
  fill: {
    gradient: {
      opacityFrom: 0.3,
      opacityTo: 0.6,
      shadeIntensity: 1,
    },
    type: 'gradient',
  },
  markers: {
    hover: {
      size: 8,
    },
    size: 6,
  },
  stroke: {
    curve: 'smooth',
  },
  tooltip: {
    x: {
      format: 'dd MMMM yy',
    },
  },
  xaxis: {
    tooltip: {
      enabled: false,
    },
    type: 'datetime',
  },
  yaxis: {
    labels: {
      formatter: (value: number) => formatCurrency(value),
    },
  },
};

type Props = {
  pricing: Pricing[];
  theme: RootTheme;
};

const ProductPriceGraph: React.FC<Props> = ({
  pricing,
  theme: {
    palette,
    typography: { fontFamily },
  },
}) => {
  const series = React.useMemo<Series[]>(() => {
    return mapPricingForGraph(pricing);
  }, [pricing]);

  const options = React.useMemo(() => {
    const { danger, primary, neutral, success, warning } = palette;

    return {
      ...fixedOptions,
      chart: { fontFamily },
      colors: [primary.shade4, success.shade4, danger.shade4, warning.shade6, neutral.shade5],
    };
  }, [palette, fontFamily]);

  return <Chart options={options as any} series={series} type="area" height="350" />;
};

export default withTheme(ProductPriceGraph);
