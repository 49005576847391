/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface MetadataResponse {
  metadataId: number;
  entityType: string;
  code: string;
  dataType: string;
  category?: string;
  description?: string;
  showOnCreate: boolean;
  accessibility: 'hidden' | 'readOnly' | 'editable';
  availableValues: object;
}

export interface GetPriceResponse {
  priceId: number;
  productGuid: string;
  priceTypeId: number;
  amount: number;
  quantityFrom?: number;
  quantityUntil?: number;
  dateFrom?: string;
  dateUntil?: string;
  priceTypeDescription: string;
  entityId?: number;
  entityName?: string;
  termDurationId?: number;
  billingPlanId?: number;
  description?: string;
}

export interface GetLookupResponse {
  id: number;
  description: string;
}

export interface GetCompaniesResponse {
  companyid: number;
  companyGuid: string;
  companyNumber: string;
  companyName: string;
}

export interface GetPriceTranslations {
  priceTranslationId: number;
  description: string;
  termDurationId: number;
  billingPlanId: number;
}

export interface GetProductOverviewResponse {
  items: GetProductOverview[];
  pageIndex: number;
  pageSize: number;
  totalPages: number;
  totalItems: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface GetProductOverview {
  productId: number;
  currentUserLocaleTranslationName: string;
  code: string;
  productTypeDescription: string;
  productStatusDescription: string;
}

export interface GetProductDetailResponse {
  metadatas?: EntityMetadataResponse[];
  productId: number;
  productTypeId: number;
  productTypeDescription: string;
  productStatusId: number;
  productStatusDescription: string;
  priceMethodId: number;
  priceMethodDescription: string;
  currentUserLocaleTranslationName: string;
  code: string;
  externalId?: string;
  productGuid: string;
  partnerPricingProfile: GetProductPartnerPricingProfile;
  partnerCenterProfile: GetProductPartnerCenterProfile;
  translations: GetProductTranslationResponse[];
}

export interface GetProductPartnerCenterProfile {
  offerId: string;
  offerUri?: string;
  offerSku?: string;
  availabilityProductId?: string;
  availabilitySkuId?: string;
  availabilityId?: string;
  segment?: Segment;
}

export interface GetProductPartnerPricingProfile {
  rebateGroupId?: number;
  rebateGroup?: string;
}

export interface GetProductTranslationResponse {
  productTranslationId: number;
  language: string;
  description: string;
}

export interface GetPriceMethodResponse {
  priceMethodId: number;
  description: string;
  priceFrequency: PriceFrequency;
  upfront?: boolean;
  prorata?: boolean;
}

/** PriceFrequency */
export type PriceFrequency = 'onetime' | 'payPerUse' | 'month' | 'year' | 'trimester' | 'licenseBased';

/** productType */
export type ProductType = string;

/** EntityMetadataResponse */
export interface EntityMetadataResponse {
  metadataId: number;
  value?: string;
  entityType: string;
  entityGuid: string;
  entityId: number;
  code: string;
  dataType: 'STRING' | 'MULTISELECT' | 'GUID' | 'DECIMAL' | 'BOOL' | 'INTEGER' | 'PERCENTAGE' | 'DATE';
  category?: string;
  description?: string;
  showOnCreate: boolean;
  accessibility: 'hidden' | 'editable' | 'readOnly';
  availableValues: object;
}

export interface GetRebateGroupResponse {
  rebateGroupId: number;
  code: string;
  description: string;
  rebatePercentage?: number;
  marginPercentage?: number;
}

/** Segment */
export type Segment = 'Commercial' | 'Education' | 'Charity';

export interface GetPricesParams {
  language?: string;
  productguid: string;
}

export interface GetCompaniesParams {
  search?: string;
  take?: number;
}

export interface GetPriceTranslationsParams {
  language?: string;
}

export interface GetProductsParams {
  pageIndex?: number;
  pageSize?: number;
  search?: string;
  productTypes?: number[];
  productStatuses?: number[];
}

export interface GetProductRebateGroupsParams {
  search?: string;
  take?: number;
}

import axios, { AxiosInstance, AxiosRequestConfig, ResponseType } from 'axios';

type HeaderValue = string | string[] | number | boolean | null;

interface RawHeaders {
  [key: string]: HeaderValue;
}

interface HeadersDefaults {
  common: RawHeaders;
  delete: RawHeaders;
  get: RawHeaders;
  head: RawHeaders;
  post: RawHeaders;
  put: RawHeaders;
  patch: RawHeaders;
  options?: RawHeaders;
  purge?: RawHeaders;
  link?: RawHeaders;
  unlink?: RawHeaders;
}

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || 'http://localhost:3000' });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<T> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance
      .request({
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
        },
        params: query,
        responseType: responseFormat,
        data: body,
        url: path,
      })
      .then((response) => response.data?.response);
  };
}

/**
 * @title ProductsModule
 * @version 1.0
 * @baseUrl http://localhost:3000
 *
 * Products module
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * @description get product metadatas
     *
     * @name GetProductMetadatas
     * @summary Your GET endpoint
     * @request GET:/api/product/v2/metadatas/product
     */
    getProductMetadatas: (params: RequestParams = {}) =>
      this.request<MetadataResponse[], any>({
        path: `/api/product/v2/metadatas/product`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description get product prices
     *
     * @name GetPrices
     * @summary Your GET endpoint
     * @request GET:/api/product/v2/prices/{productguid}
     */
    getPrices: ({ productguid, ...query }: GetPricesParams, params: RequestParams = {}) =>
      this.request<GetPriceResponse[], any>({
        path: `/api/product/v2/prices/${productguid}`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description get product price types
     *
     * @name GetPriceTypes
     * @summary Your GET endpoint
     * @request GET:/api/product/v2/prices/pricetypes
     */
    getPriceTypes: (params: RequestParams = {}) =>
      this.request<GetLookupResponse[], any>({
        path: `/api/product/v2/prices/pricetypes`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description get companies
     *
     * @name GetCompanies
     * @summary Your GET endpoint
     * @request GET:/api/product/v2/prices/companies
     */
    getCompanies: (query: GetCompaniesParams, params: RequestParams = {}) =>
      this.request<GetCompaniesResponse[], any>({
        path: `/api/product/v2/prices/companies`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description update price
     *
     * @name UpdatePrice
     * @request PUT:/api/product/v2/prices
     */
    updatePrice: (
      data: {
        priceId: number;
        productGuid: string;
        priceTypeId: number;
        amount: number;
        quantityFrom?: number;
        quantityUntil?: number;
        dateFrom?: string;
        dateUntil?: string;
        entityId?: number;
        termDurationId?: number;
        billingPlanId?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetPriceResponse, any>({
        path: `/api/product/v2/prices`,
        method: 'PUT',
        body: data,
        format: 'json',
        ...params,
      }),

    /**
     * @description add new price
     *
     * @name CreatePrice
     * @request POST:/api/product/v2/prices
     */
    createPrice: (
      data: {
        productGuid: string;
        priceTypeId: number;
        amount: number;
        quantityFrom?: number;
        quantityUntil?: number;
        dateFrom?: string;
        dateUntil?: string;
        entityId?: number;
        termDurationId?: number;
        billingPlanId?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetPriceResponse, any>({
        path: `/api/product/v2/prices`,
        method: 'POST',
        body: data,
        format: 'json',
        ...params,
      }),

    /**
     * @description remove price
     *
     * @name DeletePrice
     * @request DELETE:/api/product/v2/prices/{priceid}
     */
    deletePrice: (priceid: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/product/v2/prices/${priceid}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * @description get price translations
     *
     * @name GetPriceTranslations
     * @summary Your GET endpoint
     * @request GET:/api/product/v2/prices/pricetranslations
     */
    getPriceTranslations: (query: GetPriceTranslationsParams, params: RequestParams = {}) =>
      this.request<GetPriceTranslations[], any>({
        path: `/api/product/v2/prices/pricetranslations`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description get product overview
     *
     * @name GetProducts
     * @summary Your GET endpoint
     * @request GET:/api/product/v2/products
     */
    getProducts: (query: GetProductsParams, params: RequestParams = {}) =>
      this.request<GetProductOverviewResponse, any>({
        path: `/api/product/v2/products`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description update product
     *
     * @name UpdateProduct
     * @request PUT:/api/product/v2/products
     */
    updateProduct: (
      data: {
        metadatas: {
          metadataId: number;
          value?: string;
        }[];
        productId: number;
        productTypeId: number;
        productStatusId: number;
        priceMethodId: number;
        code: string;
        externalId?: string;
        partnerPricingProfile: {
          rebateGroupId?: number;
        };
        translations: {
          productTranslationId: number;
          language: string;
          description: string;
        }[];
        partnerCenterProfile?: {
          offerId: string;
          offerUri?: string;
          offerSku?: string;
          availabilityProductId?: string;
          availabilitySkuId?: string;
          availabilityId?: string;
          segment?: Segment;
        };
      },
      params: RequestParams = {}
    ) =>
      this.request<GetProductDetailResponse, any>({
        path: `/api/product/v2/products`,
        method: 'PUT',
        body: data,
        format: 'json',
        ...params,
      }),

    /**
     * @description add new product
     *
     * @name CreateProduct
     * @request POST:/api/product/v2/products
     */
    createProduct: (
      data: {
        metadatas: {
          metadataId: number;
          value?: string;
        }[];
        productTypeId: number;
        productStatusId: number;
        priceMethodId: number;
        code: string;
        externalId?: string;
        partnerPricingProfile: {
          rebateGroupId?: number;
        };
        translations: {
          productTranslationId: number;
          language: string;
          description: string;
        }[];
        productPartnerCenterProfile?: {
          offerId: string;
          offerUri?: string;
          offerSku?: string;
          availabilityProductId?: string;
          availabilitySkuId?: string;
          availabilityId?: string;
          segment?: Segment;
        };
      },
      params: RequestParams = {}
    ) =>
      this.request<GetProductDetailResponse, any>({
        path: `/api/product/v2/products`,
        method: 'POST',
        body: data,
        format: 'json',
        ...params,
      }),

    /**
     * @description get product detail
     *
     * @name GetProductDetailById
     * @summary Your GET endpoint
     * @request GET:/api/product/v2/products/{id}
     */
    getProductDetailById: (id: number, params: RequestParams = {}) =>
      this.request<GetProductDetailResponse, any>({
        path: `/api/product/v2/products/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description get product detail
     *
     * @name GetProductDetailByGuid
     * @summary Your GET endpoint
     * @request GET:/api/product/v2/products/{guid}
     */
    getProductDetailByGuid: (guid: string, params: RequestParams = {}) =>
      this.request<GetProductDetailResponse, any>({
        path: `/api/product/v2/products/${guid}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description get product statuses
     *
     * @name GetProductStatuses
     * @summary Your GET endpoint
     * @request GET:/api/product/v2/products/statuses
     */
    getProductStatuses: (params: RequestParams = {}) =>
      this.request<GetLookupResponse[], any>({
        path: `/api/product/v2/products/statuses`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description get product types
     *
     * @name GetProductTypes
     * @summary Your GET endpoint
     * @request GET:/api/product/v2/products/producttypes
     */
    getProductTypes: (params: RequestParams = {}) =>
      this.request<GetLookupResponse[], any>({
        path: `/api/product/v2/products/producttypes`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description get product price methods
     *
     * @name GetPriceMethods
     * @summary Your GET endpoint
     * @request GET:/api/product/v2/products/pricemethods
     */
    getPriceMethods: (params: RequestParams = {}) =>
      this.request<GetPriceMethodResponse[], any>({
        path: `/api/product/v2/products/pricemethods`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description get product rebate groups
     *
     * @name GetProductRebateGroups
     * @summary Your GET endpoint
     * @request GET:/api/partner/v2/products/rebategroups
     */
    getProductRebateGroups: (query: GetProductRebateGroupsParams, params: RequestParams = {}) =>
      this.request<GetRebateGroupResponse[], any>({
        path: `/api/partner/v2/products/rebategroups`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  };
}
