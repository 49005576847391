import * as React from 'react';
import { areArraysEqual } from 'helpers/arrayUtils';

export type MultiSelectActions = {
  deselectAll: () => void;
  selectAll: () => void;
  toggleId: (id: string | number) => void;
};

type State<T> = {
  selected: T[];
  list: T[];
};

type Action<T> =
  | { type: 'TOGGLE'; payload: { id: number | string; identifyingProp: string } }
  | { type: 'DESELECT' }
  | { type: 'SELECT_ALL' }
  | { type: 'SET_LIST'; payload: { list: T[]; identifyingProp: string } };

const createReducer =
  <T>() =>
  (state: State<T>, action: Action<T>) => {
    switch (action.type) {
      case 'TOGGLE':
        const item = state.list?.find((x) => x[action.payload.identifyingProp] === action.payload.id);
        if (item) {
          const selectedItem = state.selected.find((x) => x[action.payload.identifyingProp] === action.payload.id);

          return {
            ...state,
            selected: selectedItem
              ? state.selected.filter((x) => x[action.payload.identifyingProp] !== action.payload.id)
              : [...state.selected, item],
          };
        }

        return state;
      case 'DESELECT':
        return {
          ...state,
          selected: [],
        };
      case 'SELECT_ALL':
        return state.selected.length === state.list.length
          ? { ...state, selected: [] }
          : { ...state, selected: state.list };
      case 'SET_LIST':
        return {
          ...state,
          list: action.payload.list,
          selected: areArraysEqual(
            action.payload.list?.map((i) => i[action.payload.identifyingProp]),
            state.list?.map((i) => i[action.payload.identifyingProp])
          )
            ? state.selected
            : [],
        };

      default:
        return state;
    }
  };

const useMultiSelect = <T>(list: T[], identifyingProp: string, initialValue: T[] = []): [T[], MultiSelectActions] => {
  const reducer = createReducer<T>();
  const [state, dispatch] = React.useReducer(reducer, { selected: initialValue, list });

  const toggleId = (id: string | number) => {
    dispatch({ type: 'TOGGLE', payload: { identifyingProp, id } });
  };

  const deselectAll = () => dispatch({ type: 'DESELECT' });

  const selectAll = () => dispatch({ type: 'SELECT_ALL' });

  React.useEffect(() => {
    if (list?.length > 0) {
      dispatch({ type: 'SET_LIST', payload: { list, identifyingProp } });
    }
  }, [list, identifyingProp]);

  return [state.selected, { toggleId, deselectAll, selectAll }];
};

export default useMultiSelect;
