import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const Bell: React.FC = (): JSX.Element => (
  <BaseSvgWrapper style={{ transform: 'rotate(-45deg)' }}>
    <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <path d="M46.5738 12.087V4.99996H50V8.51469H53.3919L50 8.46859V11.9257C50.1142 11.9257 50.1028 11.9257 50.1256 11.9257L46.5738 12.087Z" />
      <path d="M80.9499 80.0884H19.0501C13.5111 80.0884 9 75.5365 9 69.9476C9 68.1153 9.49109 66.3061 10.4276 64.7273L16.195 55.0244V37.0128C16.195 32.6799 17.1657 28.4507 19.0844 24.429C20.8774 20.6607 23.4242 17.2612 26.6449 14.3111C33.0404 8.45711 41.5031 5.05762 49.8744 5C49.9201 5 49.9543 5 50.0114 5C50.0457 5 50.0914 5 50.1371 5C58.5198 5.04609 67.0167 8.43406 73.4351 14.3111C76.6557 17.2612 79.214 20.6607 81.0184 24.429C82.9485 28.4507 83.9193 32.6799 83.9193 37.0128V55.0359L89.6067 64.7619C90.5204 66.3176 91 68.1038 91 69.913V69.936C91 75.5366 86.4889 80.0884 80.9499 80.0884ZM50.0343 11.9257H49.9772C35.6329 11.9949 23.0474 23.726 23.0474 37.0128V56.9373L16.3092 68.2766C16.0123 68.7837 15.8524 69.3483 15.8524 69.936C15.8524 71.7222 17.2914 73.1627 19.0501 73.1627H80.9499C82.7201 73.1627 84.1477 71.7107 84.1477 69.936V69.913C84.1477 69.3368 83.9992 68.7721 83.7023 68.2766L77.0669 56.9258V37.0128C77.0669 23.726 64.4585 11.9949 50.0914 11.9142L50.0343 11.9257Z" />
      <path d="M53.4263 12.087L49.8745 11.9142C49.9087 11.9142 49.8859 11.9142 50.0001 11.9142V8.5147H46.6082L50.0001 8.45708V5.05759H53.4263V12.087Z" />
      <path d="M49.8059 95.0001C44.2441 95.0001 38.9335 91.7734 35.9527 86.5647L41.88 83.1076C43.6159 86.1383 46.7223 88.0858 49.7945 88.0858C53.4605 88.0858 56.5326 86.2997 57.9945 83.3035L64.1388 86.3688C62.8368 89.0308 60.804 91.2318 58.2572 92.7414C55.7675 94.2165 52.8438 95.0001 49.8059 95.0001Z" />
      <path d="M38.8078 31.7349L32.7663 28.4622C34.2738 25.6274 36.5922 23.2074 39.4588 21.4673C42.3368 19.7272 45.5688 18.7823 48.8123 18.7592C48.9151 18.7592 49.0178 18.7592 49.1092 18.7592L48.7666 25.6619C48.8579 25.6619 48.9493 25.6619 49.0407 25.6619H48.8808C44.7694 25.708 40.715 28.1395 38.8078 31.7349Z" />
    </svg>
  </BaseSvgWrapper>
);

export default Bell;
