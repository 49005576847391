import styled, { css, keyframes } from 'config/theme';

const slide = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
`;

export const ContextMenuItemWrapper = styled('div')<{
  isDisabled?: boolean;
  iconSize?: 'small' | 'regular';
  isLoading?: boolean;
}>`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  position: relative;
  overflow: hidden;

  ${(props) =>
    !props.isLoading &&
    css`
      &:hover {
        cursor: ${props.isDisabled ? 'not-allowed' : 'pointer'};
        background-color: ${props.theme.contextMenu.color.blockItemHover};
      }
    `}

  > p {
    font-size: ${(props) => props.theme.contextMenu.fontSize.blockItem};
    color: ${(props) => (props.isDisabled ? props.theme.contextMenu.color.disabled : 'inherit')};
  }

  > span svg {
    height: ${(props) =>
      props.iconSize === 'small' ? props.theme.contextMenu.iconSize.small : props.theme.contextMenu.iconSize.main};
    margin-right: 15px;
    fill: ${(props) => (props.isDisabled ? props.theme.contextMenu.color.disabled : 'inherit')};
  }

  ${(props) =>
    props.isLoading &&
    css`
      &::before {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: ${props.theme.palette.neutral.shade2};
        opacity: 0.3;
        animation: ${slide} 1.5s infinite ease-in-out;
      }
    `}
`;
