import styled, { css } from 'styled-components';

type Props = {
  inlineLabel?: boolean;
  hasParentLabel?: boolean;
} & React.ComponentPropsWithoutRef<'label'>;

export const Label = styled.label<Props>`
  align-self: flex-start;
  font-weight: 600;
  text-transform: capitalize;

  ${(props) =>
    props.inlineLabel &&
    !props.hasParentLabel &&
    css`
      z-index: 1;
      position: absolute;
      top: 17px;
      left: 15px;
      font-weight: normal;
      color: ${props.theme.palette.neutral.shade5};
    `}

  ${(props) =>
    props.inlineLabel &&
    props.hasParentLabel &&
    css`
      z-index: 1;
      position: absolute;
      top: 40px;
      left: 15px;
      font-weight: normal;
      color: ${props.theme.palette.neutral.shade5};
    `}
`;
