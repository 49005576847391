import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const Search: React.FC = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
      <path d="M37.0091 19.0046C37.0091 28.3959 29.3959 36.0091 20.0046 36.0091C10.6132 36.0091 3 28.3959 3 19.0046C3 9.6132 10.6132 2 20.0046 2C29.3959 2 37.0091 9.6132 37.0091 19.0046ZM9.57872 19.0046C9.57872 24.7626 14.2465 29.4304 20.0046 29.4304C25.7626 29.4304 30.4304 24.7626 30.4304 19.0046C30.4304 13.2465 25.7626 8.57872 20.0046 8.57872C14.2465 8.57872 9.57872 13.2465 9.57872 19.0046Z" />
      <path d="M33.782 37.9881C32.3444 36.5505 32.3444 34.2196 33.782 32.782C35.2196 31.3444 37.5505 31.3444 38.9881 32.782L46.9218 40.7157C48.3594 42.1534 48.3594 44.4842 46.9218 45.9218C45.4842 47.3594 43.1534 47.3594 41.7157 45.9218L33.782 37.9881Z" />
      <path d="M30.2073 32.0398L33.0398 29.2073L37.1624 33.3298L34.3298 36.1624L30.2073 32.0398Z" />
    </svg>
  </BaseSvgWrapper>
);

export default Search;
