import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ContextMenuBlock, ContextMenuContainer, ContextMenuItem } from '../';
import { groupBy } from '../../helpers';
import { MetaData, MetaDataAccess } from '../../types/metadata';

type Props = {
  handleAdd?: (newMetaData: MetaData) => () => void;
  metaData?: MetaData[];
};

const AddMetaDataContextMenu: React.FC<Props> = ({ handleAdd, metaData, ...props }: Props) => {
  const { t } = useTranslation(['metaData', 'global']);
  const editableMetaData = metaData?.filter((meta) => meta.accessibility === MetaDataAccess.Editable);

  return editableMetaData ? (
    <ContextMenuContainer {...props}>
      {editableMetaData.length > 0 ? (
        Object.keys(groupBy(editableMetaData, 'category')).map((category: string) => (
          <ContextMenuBlock title={category !== 'undefined' ? category : t('global:general.other')} key={category}>
            {editableMetaData
              .filter((metaDataItem: MetaData) => {
                return category !== 'undefined' ? metaDataItem.category === category : !metaDataItem.category;
              })
              .map((metaDataItem: MetaData) => (
                <ContextMenuItem
                  key={metaDataItem.metadataId}
                  isDisabled={metaDataItem.isAdded}
                  text={t(`metaData:${metaDataItem.code?.toUpperCase()}`) || ''}
                  onClick={handleAdd?.({ ...metaDataItem, isNew: true })}
                />
              ))}
          </ContextMenuBlock>
        ))
      ) : (
        <ContextMenuBlock title={t('global:general.other')}>
          <p>{t('global:general.noneAvailable')}</p>
        </ContextMenuBlock>
      )}
    </ContextMenuContainer>
  ) : null;
};

export default AddMetaDataContextMenu;
