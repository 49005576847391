import styled from '../../config/theme';

export const ElementWithButton = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;

  > :first-child {
    width: 100%;
  }

  > button {
    position: absolute;
    left: calc(100% + 8px);
    top: 3px;

    &.buttonForCheckbox {
      top: -9px;
    }
  }
`;
