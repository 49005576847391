import styled from 'styled-components';
import { TextWrap } from '@unbrace/components/';
import ListItem from '../../../components/ItemSidebar/ListItem';
import { IconWrapper, Row, SubText, Text } from '../../../components/ItemSidebar/ListItemElements';
import { Products } from '../types/products';
import { ProductsOrb } from './';

type Props = {
  product: Products;
};

const ProductListItem = ({ product }: Props) => {
  return (
    <ListItem height={120} to={`/products/${product.productId}`} data-cy="product-item">
      <EllipsisHeader asTag="p" data-cy="product-name">
        {product.currentUserLocaleTranslationName}
      </EllipsisHeader>
      <Text data-cy="product-code">{product.code}</Text>
      <Row>
        <IconWrapper>
          <ProductsOrb status={product.productStatusDescription} data-cy="product-status" />
        </IconWrapper>
        <SubText data-cy="product-type">{product.productTypeDescription}</SubText>
      </Row>
    </ListItem>
  );
};

export default ProductListItem;

const EllipsisHeader = styled(TextWrap)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  font-size: ${(props) => props.theme.itemSidebar.fontSize.headerStrong};
  color: ${(props) => props.theme.itemSidebar.color.item.header};
  font-weight: 600;
`;
