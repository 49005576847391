import styled from 'config/theme';

export const Footer = styled.footer`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  vertical-align: middle;
  flex-flow: column;
  flex-wrap: wrap;
  padding: 9px 28px;
  background: ${(props) => props.theme.internalSidebar.background.brand};
`;
