import { TenantTheme } from '../tenants/unbrace';

const invoiceTheme = (theme: TenantTheme) => ({
  invoiceDetail: {
    extraInfo: {
      dark: theme.palette.neutral.shade8,
      dimmed: theme.palette.neutral.shade5,
    },
    subTitle: {
      color: theme.palette.neutral.shade5,
      fontSize: theme.typography.fontSize.medium_2,
    },
    title: {
      color: theme.palette.neutral.shade8,
      fontSize: theme.typography.fontSize.medium_6,
    },
  },
  manualProducts: {
    color: {
      modalSubtitle: theme.palette.neutral.shade4,
      modalTitle: theme.palette.neutral.shade9,
    },
    fontSize: {
      modalSubtitle: theme.typography.fontSize.small_5,
      modalTitle: theme.typography.fontSize.medium_6,
    },
    fontWeight: {
      modalTitle: 600,
    },
  },
  overviewItem: {
    amount: {
      fontSize: theme.typography.fontSize.medium_8,
    },
    subTitle: {
      fontSize: theme.typography.fontSize.medium_2,
    },
    title: {
      color: theme.palette.neutral.shade5,
      fontSize: theme.typography.fontSize.small_4,
    },
  },
  statusHistory: {
    textColorActive: theme.palette.neutral.shade8,
    textColor: theme.palette.neutral.shade4,
    borderColor: theme.palette.neutral.shade2,
    background: {
      danger: theme.palette.danger.shade1,
      primary: theme.palette.primary.shade1,
      secondary: theme.palette.neutral.shade0,
      success: theme.palette.success.shade1,
      warning: theme.palette.warning.shade1,
    },
    color: {
      danger: theme.palette.danger.shade4,
      primary: theme.palette.primary.shade4,
      secondary: theme.palette.neutral.shade3,
      success: theme.palette.success.shade4,
      warning: theme.palette.warning.shade5,
    },
    lineColor: theme.palette.neutral.shade2,
  },
  overview: {
    overdueBadge: {
      border: `1px solid ${theme.palette.danger.shade4}`,
      background: 'transparent',
    },
  },
});

export default invoiceTheme;
