import { Table, TableBody } from '@unbrace/components';
import { Formik } from 'formik';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { BasicFormTitle, Paper } from '../../../components';
import PageWrapper from '../../app/components/PageWrapper';
import RouteLeavingGuard from '../../app/components/RouteLeavingGuard';
import PricingNewHeader from '../components/new/PricingNewHeader';
import PricingForm from '../components/pricing/PricingForm';
import PricingRow from '../components/pricing/PricingRow';
import productPriceSchema from '../schema/productPriceSchema';
import usePricingStore from '../services/usePricingStore';
import useProductStore from '../services/useProductStore';
import { Pricing } from '../types/pricing';

type Props = {
  activeProductsId: number;
};

const ProductNewWithPricing: React.FC<Props> = ({ activeProductsId }: Props) => {
  const { t } = useTranslation('products');
  const [pricingChanged, setPricingChanged] = React.useState(false);
  const formRef: React.RefObject<any> = React.useRef(null);
  const { newProduct } = useProductStore(activeProductsId);
  const { createPricing, pricing, isCreatePricingLoading } = usePricingStore(newProduct?.productGuid);
  const { productGuid, currentUserLocaleTranslationName, productId } = { ...newProduct };

  const isTherePricing = (pricing && pricing.general.length > 0) || Object.values(pricing?.entity || {}).length > 0;

  const handleFormChange = React.useCallback(() => setPricingChanged(true), []);

  const handleSave = () => {
    formRef.current.handleSubmit();
  };

  const submit = (formValues: Pricing) => {
    const formPricing = {
      ...formValues,
      productGuid: productGuid || '',
    };

    createPricing(formPricing, () => {
      setPricingChanged(false);
      formRef.current.resetForm();
    });
  };

  return (
    <PageWrapper>
      <RouteLeavingGuard when={pricingChanged} />
      <PricingNewHeader
        handleSave={handleSave}
        hasChanges={pricingChanged}
        isSaving={isCreatePricingLoading}
        productId={productId || 0}
      />
      <Paper>
        <BasicFormTitle
          title={`${t('newView.pricing.title')}${currentUserLocaleTranslationName}`}
          description={t('newView.pricing.titleDescription')}
        />
        <Formik
          enableReinitialize
          innerRef={formRef}
          initialValues={{
            priceId: 0 as unknown as number,
            productGuid: '' as unknown as string,
            priceTypeDescription: '' as unknown as string,
            amount: '' as unknown as number,
            dateFrom: '' as unknown as string,
            dateUntil: '' as unknown as string,
            entityId: '' as unknown as number,
            priceTypeId: '' as unknown as number,
            quantityFrom: '' as unknown as number,
            quantityUntil: '' as unknown as number,
          }}
          onSubmit={(values: Pricing) => {
            submit(values);
          }}
          validate={handleFormChange}
          validationSchema={productPriceSchema}
          render={({ errors, touched, setFieldValue }) => (
            <PricingForm errors={errors} touched={touched} setFieldValue={setFieldValue} />
          )}
        />
      </Paper>
      {isTherePricing && (
        <Paper>
          <BasicFormTitle
            title={t('newView.pricing.newPricingsTitle')}
            description={t('newView.pricing.newPricings')}
          />
          <Table>
            <TableBody>
              {pricing && pricing.general?.map((price: Pricing) => <PricingRow key={price.priceId} price={price} />)}
              {pricing &&
                pricing.entity &&
                Object.values(pricing.entity).map((item: Pricing[]) =>
                  item.map((price: Pricing) => <PricingRow key={price.priceId} price={price} />)
                )}
            </TableBody>
          </Table>
        </Paper>
      )}
    </PageWrapper>
  );
};

export default ProductNewWithPricing;
