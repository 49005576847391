import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore, Reducer, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../../modules/app/redux/sagas';
import { RootState } from './rootReducer';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const history = createBrowserHistory();

const configureStoreProd = (rootReducer: Reducer<RootState>): Store<RootState> => {
  const sagaMiddleware = createSagaMiddleware();
  const middleWares = [sagaMiddleware, routerMiddleware(history)];
  const store = createStore(rootReducer, compose(applyMiddleware(...middleWares)));

  sagaMiddleware.run(rootSaga);

  return store;
};
const configureStoreDev = (rootReducer: Reducer<RootState>): Store<RootState> => {
  const sagaMiddleware = createSagaMiddleware();
  const middleWares = [sagaMiddleware, routerMiddleware(history)];

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middleWares)));

  sagaMiddleware.run(rootSaga);

  return store;
};

const configureStore = process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev;

export default configureStore;
