import * as React from 'react';
import { Route } from 'react-router-dom';
import { loadScene } from '../../../components';
import ModuleRoutes from '../../../components/routing/ModuleRoutes';
import { Module } from '../../security/types/modules';
import { ACCESS_MODULE_KEY } from '../constants/index';
import { ACCESS_BASE_ROUTES } from './index';

type Props = {
  allowedModules: Module[];
};

const accessOverviewScene = loadScene('access', 'AccessScene');

const AccessRoutes: React.FC<Props> = ({ allowedModules }: Props) => (
  <ModuleRoutes allowedModules={allowedModules} moduleKey={ACCESS_MODULE_KEY}>
    <Route path={ACCESS_BASE_ROUTES} component={accessOverviewScene} />
  </ModuleRoutes>
);

export default AccessRoutes;
