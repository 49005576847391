import styled from '../../config/theme';

type Props = {
  clearable?: boolean;
  reducedBottomMargin?: boolean;
  smallLabel?: boolean;
  noAddedPadding?: boolean;
};

const InlineLabelWrapper = styled('div')<Props>`
  position: relative;

  > span svg {
    fill: ${(props) => props.theme.form.inlineLabel.color.close};
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: absolute;
    right: 10px;
    top: ${(props) => (props.smallLabel ? '14px' : '18px')};
    transition: transform 0.2s linear;

    &:hover {
      fill: ${(props) => props.theme.form.inlineLabel.color.closeActive};
      transform: scale(1.1);
    }
  }

  > div:not(.clickoutside-wrapper) {
    position: relative;
    padding-bottom: ${(props) => (props.noAddedPadding ? '0px' : '15px')};

    label {
      left: 15px;
      position: absolute;
      top: ${(props) => (props.smallLabel ? '14px' : '18px')};
      font-weight: ${(props) => props.theme.form.inlineLabel.fontWeight.labelText};
      color: ${(props) => props.theme.form.inlineLabel.color.labelText};
    }

    input {
      text-align: right;
      padding-right: ${(props) => (props.clearable ? '35px' : '5px')};
    }

    input,
    .formik-select {
      ${(props) => props.reducedBottomMargin && `margin-bottom: 0px; margin-top: 4px`};
      + span {
        margin-top: 4px;
        bottom: 15px;
      }
    }
  }
`;

export default InlineLabelWrapper;
