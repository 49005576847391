import * as React from 'react';
import { Input } from '..';
import styled from '../../config/theme';
import { TYPE_CHECKBOX } from '../Form/Input';

type Props = {
  label: string;
  name: string;
  onChange: (newValue: boolean) => void;
  value?: boolean;
};

const FilterListItem: React.FC<Props> = ({ label, name, onChange, value }: Props) => {
  const changeHandler = () => onChange(!value);

  return (
    <FilterItemWrapper>
      <Input type={TYPE_CHECKBOX} label={label} name={name} checked={value || false} onChange={changeHandler} />
    </FilterItemWrapper>
  );
};

const FilterItemWrapper = styled.div`
  position: relative;
  margin: 12px 0;

  label {
    text-overflow: ellipsis;
    overflow: hidden;
    margin-left: 0px;
    padding-left: 40px;
    font-weight: 200;
    color: ${(props) => props.theme.internalSidebar.color.filterLabel};

    &:before {
      left: 0;
    }

    &:after {
      left: 4px;
    }
  }
`;

export default FilterListItem;
