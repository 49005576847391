import * as React from 'react';
import styled from '../../config/theme';

type Props = {
  text: string;
  icon: JSX.Element;
  disabled?: boolean;
};
const TextIcon: React.FC<Props> = ({ text, icon, disabled }: Props) => (
  <TextIconWrapper disabled={disabled}>
    {icon}
    <p>{text}</p>
  </TextIconWrapper>
);

const TextIconWrapper = styled('div')<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};

  svg {
    margin-right: 5px;
  }
`;

export default TextIcon;
