import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../../config/store/rootReducer';
import { createIsPendingSelector } from '../../app/redux/selectors/loading';
import { LOGIN_ACTION_PREFIX } from '../redux/actions/constants/login';
import { loginActions, LoginActionsType } from '../redux/actions/creators';
import { LoginData } from '../types/login';

export type LoginManagerRenderProps = {
  handleLogin: (formData: LoginData) => void;
  isPending: boolean;
};

type RenderCallback = (renderProp: LoginManagerRenderProps) => JSX.Element | null;

type Props = {
  initiateLogin: (email: string, password: string, returnUrl: string) => void;
  isPending: boolean;
  children: RenderCallback;
};

class LoginManager extends React.PureComponent<Props> {
  handleLogin = ({ username, password, returnUrl }: LoginData) => {
    this.props.initiateLogin(username, password, returnUrl);
  };

  render(): JSX.Element | null {
    const { children, isPending } = this.props;
    const renderProp = {
      handleLogin: this.handleLogin,
      isPending,
    };

    return children ? children(renderProp) : null;
  }
}

const mapDispatchToProps = (dispatch: Dispatch<LoginActionsType>) =>
  bindActionCreators(
    {
      initiateLogin: loginActions.initiateLogin,
    },
    dispatch
  );

const mapStateToProps = (state: RootState) => ({
  isPending: createIsPendingSelector(LOGIN_ACTION_PREFIX)(state),
});

const enhance = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default enhance(LoginManager);
