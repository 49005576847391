import { Field, FieldProps } from 'formik';
import * as React from 'react';
import { Input } from 'components';
import { getNestedError } from 'helpers';

type Props = {
  autoFocus?: boolean;
  disabled?: boolean;
  label: string;
  name: string;
};

const FormikTextField: React.FC<Props> = ({ label, name, autoFocus, disabled }: Props) => {
  return (
    <Field
      name={name}
      render={({ field, form }: FieldProps) => (
        <Input
          {...field}
          autoFocus={autoFocus}
          disabled={disabled}
          error={getNestedError(name, form.errors, form.touched)}
          label={label}
          type="text"
        />
      )}
    />
  );
};

export default FormikTextField;
