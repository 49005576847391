import { Field, FieldProps, FormikErrors, FormikTouched } from 'formik';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FormikSelect, Input } from '../../../../components/Form';
import { EditableItem, FullViewBlock } from '../../../../components/FullView';
import { ProductBaseFormValues } from '../../types/product';
import useGlobalInfoStore from 'modules/products/services/useGlobalInfoStore';

type Props = {
  errors: FormikErrors<ProductBaseFormValues>;
  touched: FormikTouched<ProductBaseFormValues>;
};

const GeneralInfo: React.FC<Props> = ({ touched, errors }) => {
  const { t } = useTranslation('products');
  const { priceMethods, productStatuses, productTypes } = useGlobalInfoStore();

  return (
    <FullViewBlock amountOfButtons={1}>
      <h2 className="block-title">{t('fullView.blockTitles.general')}</h2>
      <div className="block-data">
        <EditableItem
          label={t('fullView.labels.code')}
          value={
            <Field
              name="code"
              render={({ field }: FieldProps) => (
                <Input {...field} type="text" noLabel error={(touched.code && errors.code) || ''} />
              )}
            />
          }
        />
        <EditableItem
          label={t('fullView.labels.type')}
          value={
            <Field
              name="productTypeId"
              render={(fieldProps: FieldProps) => (
                <FormikSelect
                  {...fieldProps}
                  error={(touched.productTypeId && errors.productTypeId) || ''}
                  options={productTypes}
                  getOptionLabel={(option) => option.description}
                  // id is of type number. unable to pass type number to the getOptionValue method.
                  // id.toString() not working (number !== string)
                  getOptionValue={(option) => option.id as unknown as string}
                />
              )}
            />
          }
        />
        <EditableItem
          label={t('fullView.labels.priceMethod')}
          value={
            <Field
              name="priceMethodId"
              render={(fieldProps: FieldProps) => (
                <FormikSelect
                  {...fieldProps}
                  error={(touched.priceMethodId && errors.priceMethodId) || ''}
                  options={priceMethods}
                  getOptionLabel={(option) => option.description}
                  //  priceMethodId is of type number. unable to pass type number to the getOptionValue method.
                  // priceMethodId.toString() not working (number !== string)
                  getOptionValue={(option) => option.priceMethodId as unknown as string}
                />
              )}
            />
          }
        />
        <EditableItem
          label={t('fullView.labels.status')}
          value={
            <Field
              name="productStatusId"
              render={(fieldProps: FieldProps) => (
                <FormikSelect
                  {...fieldProps}
                  error={(touched.productStatusId && errors.productStatusId) || ''}
                  options={productStatuses}
                  // id is of type number. unable to pass type number to the getOptionValue method.
                  // id.toString() not working (number !== string)
                  getOptionValue={(option) => option.id as unknown as string}
                  getOptionLabel={(option) => option.description}
                />
              )}
            />
          }
        />
      </div>
    </FullViewBlock>
  );
};

export default GeneralInfo;
