import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, TableActions, TableCell, TableRow, Text, TextWrap } from '@unbrace/components';
import { ContextMenu, CurrencyNumberFormat, Input } from 'components';
import { formatAsDate } from 'helpers/dateFormatter';
import { MultiSelectActions } from 'hooks/useMultiSelect';
import { TYPE_CHECKBOX } from '../../../../components/Form/Input';
import { Pricing } from '../../types/pricing';
import PricingContextMenu from './PricingContextMenu';
import PricingActions from './ProductPricingActions';
import PricingModal from './NewPrice/PricingModal';

type Props = {
  handleDelete: (price: Pricing) => void;
  handleFormChange: (values: Pricing) => void;
  price: Pricing;
  selectedPricing: Pricing[];
  selectedPricingActions: MultiSelectActions;
};

const ProductPricingRow: React.FC<Props> = ({ handleDelete, price, selectedPricing, selectedPricingActions }) => {
  const { t } = useTranslation('products');
  const isSelected = Boolean(selectedPricing.find((selected) => selected.priceId === price.priceId));

  return (
    <React.Fragment>
      <Modal content={PricingModal} contentProps={{ price, productGuid: price.productGuid, isEdit: true }}>
        {(toggleModal) => (
          <ContextMenu
            content={<PricingContextMenu handleDelete={handleDelete} price={price} toggleModal={toggleModal} />}
          >
            {() => (
              <TableRow>
                <TableCell size={60}>
                  <Input
                    checked={isSelected}
                    onChange={() => selectedPricingActions.toggleId(price.priceId || '')}
                    name={`${price.priceId}`}
                    type={TYPE_CHECKBOX}
                  />
                </TableCell>
                <TableCell size={120} align="left">
                  <Text size="xs" colorLevel="light" asTag="span">
                    {t('pricing.from')}
                  </Text>{' '}
                  <Text size="xs" color="primary" colorLevel="medium" asTag="span">
                    {price.quantityFrom ? price.quantityFrom : '...'}
                  </Text>{' '}
                  <Text size="xs" colorLevel="light" asTag="span">
                    {t('pricing.to')}
                  </Text>{' '}
                  <Text size="xs" color="primary" colorLevel="medium" asTag="span">
                    {price.quantityUntil ? price.quantityUntil : ' ...'}
                  </Text>
                  <Text size="xs" colorLevel="light" asTag="p">
                    {t('pricing.quantity')}
                  </Text>
                </TableCell>
                <TableCell>
                  {price.description && (
                    <React.Fragment>
                      <Text size="xs" asTag="p">
                        {price.description}
                      </Text>
                      <Text size="xs" colorLevel="light" asTag="span">
                        {t('pricing.nce')}
                      </Text>
                    </React.Fragment>
                  )}
                </TableCell>
                <TableCell align="right" size={300}>
                  <p>
                    {price.dateFrom && (
                      <TextWrap>
                        <Text size="xs" colorLevel="light" asTag="span">
                          {t('pricing.from')}
                        </Text>{' '}
                        <Text size="xs" color="warning" asTag="span">
                          {' '}
                          {formatAsDate(price.dateFrom)}
                        </Text>
                      </TextWrap>
                    )}
                  </p>
                  <p>
                    {price.dateUntil && (
                      <TextWrap>
                        <Text size="xs" colorLevel="light" asTag="span">
                          {t('pricing.to')}
                        </Text>{' '}
                        <Text size="xs" color="warning" asTag="span">
                          {' '}
                          {formatAsDate(price.dateUntil)}
                        </Text>
                      </TextWrap>
                    )}
                  </p>
                </TableCell>
                <TableCell align="right" size={200}>
                  <Text size="xs" asTag="p">
                    <CurrencyNumberFormat price={price.amount} />
                  </Text>
                  <Text size="xs" colorLevel="light" asTag="span">
                    {price.priceTypeDescription}
                  </Text>
                  <TableActions>
                    <PricingActions handleDelete={handleDelete} price={price} />
                  </TableActions>
                </TableCell>
              </TableRow>
            )}
          </ContextMenu>
        )}
      </Modal>
    </React.Fragment>
  );
};

export default ProductPricingRow;
