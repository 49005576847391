import {
  GetPriceTranslationsParams,
  GetProductRebateGroupsParams,
  GetProductsParams,
} from 'adapters/products/productsAPI';

export const productsKeys = {
  root: ['product'] as const,
  list: () => [...productsKeys.root, 'list'] as const,
  filtered: (params: GetProductsParams) => [...productsKeys.list(), params, 'list'] as const,
  detailRoot: () => [...productsKeys.root, 'detailRoot'] as const,
  detail: (id?: string | number) => [...productsKeys.detailRoot(), id, 'detail'] as const,
  pricingList: () => [...productsKeys.root, 'pricingList'] as const,
  pricing: (productguid?: string) => [...productsKeys.pricingList(), productguid, 'pricing'] as const,
  priceTypes: () => [...productsKeys.root, 'priceTypes'] as const,
  statuses: () => [...productsKeys.root, 'statuses'] as const,
  metaData: () => [...productsKeys.root, 'metaData'] as const,
  priceMethods: () => [...productsKeys.root, 'priceMethods'] as const,
  productTypes: () => [...productsKeys.root, 'productTypes'] as const,
  rebateGroups: (params: GetProductRebateGroupsParams) => [...productsKeys.root, params, 'rebateGroups'] as const,
  registrars: () => [...productsKeys.root, 'registrars'] as const,
  priceTranslations: (params: GetPriceTranslationsParams) =>
    [...productsKeys.root, params, 'priceTranslations'] as const,
};
