import { TenantTheme } from '../tenants/unbrace';

const productTheme = (theme: TenantTheme) => ({
  graph: {
    color: {
      background: theme.palette.neutral.shade1,
    },
  },
  pricing: {
    color: {
      date: theme.palette.warning.shade7,
      highlightRow: theme.palette.primary.shade1,
      quantity: theme.palette.primary.shade4,
      subText: theme.palette.neutral.shade4,
    },
    fontSize: {
      subText: theme.typography.fontSize.small_5,
      title: theme.typography.fontSize.medium_6,
    },
    fontWeight: {
      title: 600,
    },
  },
});

export default productTheme;
