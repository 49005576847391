import * as React from 'react';
import { LinkProps } from 'react-router-dom';

type Props = {
  children: React.ReactElement<LinkProps> | Array<React.ReactElement<LinkProps>>;
};

const Navigation: React.FC<Props> = ({ children }: Props) => (
  <nav>
    <ul>{children}</ul>
  </nav>
);

export default Navigation;
