export const COMPANY_MODULE_KEY = 'company';
export const DYNAMICS_MODULE_KEY = 'dynamics';
export const NEW_COMPANY_URL = '/company/add';
export const LINKED_COMPANIES_MODULE_KEY = 'COMPANY_LINKED';

export enum Gender {
  NOT_DEFINED = 'nd',
  MALE = 'm',
  FEMALE = 'f',
  OTHER = 'o',
}

export enum Language {
  NL = 'NL',
  FR = 'FR',
  EN = 'EN',
  DE = 'DE',
}

export enum Currency {
  EUR = 'EUR',
}

export const STRIP_WHITESPACES_REGEX = /\s/g;
