import styled from '../../config/theme';

type HeaderProps = {
  strong?: boolean;
  flexAuto?: boolean;
};

const Header = styled('h5')<HeaderProps>`
  font-size: ${props =>
    props.strong ? props.theme.itemSidebar.fontSize.headerStrong : props.theme.itemSidebar.fontSize.header};
  color: ${props => props.theme.itemSidebar.color.item.header};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: ${props => (props.strong ? 600 : 500)};
  ${props => props.flexAuto && 'flex: auto'};
`;

const Text = styled.p`
  max-width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: ${props => props.theme.itemSidebar.fontSize.text};
  color: ${props => props.theme.itemSidebar.color.item.text};
`;

const SubText = styled.span`
  display: block;
  font-size: ${props => props.theme.itemSidebar.fontSize.subText};
  color: ${props => props.theme.itemSidebar.color.item.subText};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  i {
    font-size: ${props => props.theme.itemSidebar.fontSize.icon};
  }

  svg {
    width: ${props => props.theme.itemSidebar.fontSize.icon};
    height: ${props => props.theme.itemSidebar.fontSize.icon};
  }

  .secondary svg {
    fill: ${props => props.theme.itemSidebar.color.secondary};
  }
`;

type RowProps = {
  width?: number;
};

const Row = styled('div')<RowProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: ${props => (props.width ? `${props.width}%` : '100%')};
`;

type ItemWrapperProps = {
  height: number;
};

const ItemWrapper = styled('div')<ItemWrapperProps>`
  a {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px;
    height: ${props => props.height}px;
    cursor: pointer;
    transition: background 0.25s ease-out;

    &:hover,
    &:focus {
      background-color: ${props => props.theme.itemSidebar.background.item.hover};
    }

    &.active,
    &.active:hover,
    &.active:focus {
      background-color: ${props => props.theme.itemSidebar.background.item.active};
    }
  }
`;

export { Header, IconWrapper, ItemWrapper, Row, SubText, Text };
