import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { media } from 'config/theme/utils';
import styled from '../../../../config/theme';
import { AnimationProps } from '../../../../config/theme/types';

const AsideWrapper = styled.aside<AnimationProps>`
  background: ${(props) => props.theme.internalSidebar.background.main};
  padding: 100px 0 0 0;
  position: relative;
  border-right: ${(props) => props.theme.internalSidebar.border};
  display: flex;
  flex: 0 0 ${(props) => props.theme.internalSidebar.width.regular};
  top: 0;
  width: ${(props) => props.theme.internalSidebar.width.regular};
  z-index: 101;

  ${(props) => media.desktop`
    flex: 0 0 ${props.theme.internalSidebar.width.large};
    width: ${props.theme.internalSidebar.width.large};
  `}
  > nav {
    overflow-y: auto;
    width: 100%;
  }

  ul {
    margin: 0;
    padding: 0;
    padding-bottom: 60px;
  }

  ${(props) => props.state === 'entering' && props.theme.internalSidebar.animation.entering};
  ${(props) => props.state === 'entered' && props.theme.internalSidebar.animation.entered};
`;

type Props = {
  children?: React.ReactNode;
};

const TransitionedWrapper: React.FC<Props> = ({ children }: Props) => (
  <CSSTransition in timeout={150} mountOnEnter appear>
    {(state) => <AsideWrapper state={state}>{children}</AsideWrapper>}
  </CSSTransition>
);

export { TransitionedWrapper as AsideWrapper };
