import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const FileImport: React.FC = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.47011 9.15122V0.381317H27.0798L32.4279 5.79979L35.9933 9.4121L37.8795 11.5747L38.5393 12.3065V14.3489H40.7109C42.5299 14.3489 43.1241 14.3489 43.1241 16.0347V21.0317H45.3947C46.4891 21.0317 47.878 22.1356 47.878 23.4143V23.5448L40.4217 43.8944C40.214 44.6622 38.6462 46.1373 37.776 46.1373H32.4032V43.0227H37.2054C37.3973 43.0227 37.5658 42.8943 37.6155 42.7124L44.73 24.3579C44.73 24.3579 44.8785 24.0505 44.5643 24.0505L42.5299 24.0476H37.8795H36.3741H12.224C12.0328 24.0476 11.8635 24.176 11.8138 24.3579L11.7947 24.4174V24.4145L4.72998 42.5543C4.70213 42.6671 4.77518 42.7965 4.8226 42.8574C4.87002 42.919 4.97635 43.0227 5.15926 43.0227H22.3398V46.1373H4.17869C3.57277 46.1373 3.01419 45.8656 2.64537 45.3904C2.38042 45.0481 2.12266 44.0375 2.12642 43.6195H2.12201V11.1801C2.12201 10.0619 3.04513 9.15122 4.17869 9.15122H7.47011ZM24.7029 2.94889H10.4413V9.15122V21.4899C10.7043 21.2701 11.0311 21.1162 11.3866 21.0578C11.4905 21.0407 11.5965 21.0317 11.7042 21.0317H36.1195V14.3489V13.6064H24.7029V2.94889ZM34.665 11.4662L27.3595 4.25924V11.4662H34.665ZM7.47011 12.0869H5.61042C5.30709 12.0869 5.05913 12.344 5.05913 12.644V33.8743L7.47011 27.4551V12.0869ZM39.8937 17.1105H38.5393V21.0317H40.7109V17.9894C40.7109 17.2776 40.5829 17.1105 39.8937 17.1105ZM12.7955 17.1105C12.7955 16.0288 13.2213 15.8548 13.6368 15.8548H33.2954C33.7109 15.8548 34.0481 16.3651 34.0481 17.1105C34.0481 17.8559 33.7109 18.3824 33.2954 18.3824H13.5482C13.1327 18.3824 12.7955 18.1922 12.7955 17.1105ZM13.6368 10.5646C13.2213 10.5646 12.7955 10.7386 12.7955 11.8203C12.7955 12.902 13.1327 13.0922 13.5482 13.0922H21.7317C22.1472 13.0922 22.4844 12.5657 22.4844 11.8203C22.4844 11.0749 22.1472 10.5646 21.7317 10.5646H13.6368ZM12.7955 6.45342C12.7955 5.37172 13.2213 5.19774 13.6368 5.19774H21.7317C22.1472 5.19774 22.4844 5.70803 22.4844 6.45342C22.4844 7.19881 22.1472 7.72535 21.7317 7.72535H13.5482C13.1327 7.72535 12.7955 7.53512 12.7955 6.45342Z"
      />
      <path d="M31.8787 32.4296L35.3969 35.7473L32.8561 38.1757L27.2843 32.8944L23.9722 36.0615C22.1505 37.8034 21.7131 38.1645 21.6997 38.1645C21.6863 38.1645 21.1066 37.6206 20.4114 36.9558L19.1475 35.747L22.6656 32.4294C25.1858 30.0195 27.2588 28.0477 27.2722 28.0477C27.2856 28.0477 29.3584 30.0196 31.8787 32.4296Z" />
      <path d="M29.1839 32.4979L29.1839 49.6187H25.5453L25.5453 32.4979H29.1839Z" />
    </svg>
  </BaseSvgWrapper>
);

export default FileImport;
