import { Button, ChevronLeft, Tooltip } from '@unbrace/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TransitionStatus } from 'react-transition-group/Transition';
import { Filter } from '../icons';
import { AnimationProps } from '../../config/theme/types';

type Props = {
  sidebarCollapsed: boolean;
  setSidebarCollapsed: () => void;
  state: TransitionStatus;
};

const FilterCollapseButton: React.FC<Props> = ({ state, sidebarCollapsed, setSidebarCollapsed }: Props) => {
  const { t } = useTranslation();

  return (
    <Tooltip content={sidebarCollapsed ? t('global:actions.showFilter') : t('global:actions.hideFilter')}>
      <CollapseButton state={state} onClick={() => setSidebarCollapsed()}>
        {sidebarCollapsed ? <Filter /> : <ChevronLeft />}
      </CollapseButton>
    </Tooltip>
  );
};

const CollapseButton = styled(Button)<AnimationProps>`
  border-radius: 50%;
  box-shadow: none;
  fill: white;
  left: -16px;
  padding: 6px;
  position: absolute;
  top: 45px;
  transition: all 0s;
  z-index: 110;

  svg {
    height: 18px;
    width: 18px;
  }

  ${(props) => props.state === 'entering' && props.theme.itemSidebar.animation.main.entering};
  ${(props) => props.state === 'entered' && props.theme.itemSidebar.animation.main.entered};
`;

export default FilterCollapseButton;
