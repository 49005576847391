import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const Cms: React.FC = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <path d="M94.1,14.7H45.3c-1.9,0-3.4,1.5-3.4,3.4v38.3c0,1.9,1.5,3.4,3.4,3.4h48.9c1.9,0,3.4-1.5,3.4-3.4V18.1     C97.5,16.2,96,14.7,94.1,14.7z M82.8,21.9c2.3,0,4.2,1.9,4.2,4.2c0,2.3-1.9,4.2-4.2,4.2c-2.3,0-4.2-1.9-4.2-4.2     C78.6,23.8,80.4,21.9,82.8,21.9z M87,51H52.2c-0.8,0-1.3-0.9-0.9-1.6l12.3-19.5c0.4-0.6,1.3-0.7,1.7-0.1l7.6,10.1     c0.4,0.5,1.1,0.6,1.5,0.1l3.2-3.1c0.5-0.4,1.2-0.4,1.6,0.1l8.7,12.2C88.4,50,87.9,51,87,51z" />
          <path d="M5.3,20.6h26.1c1.5,0,2.8-1.3,2.8-2.8S33,15,31.4,15H5.3c-1.5,0-2.8,1.3-2.8,2.8S3.8,20.6,5.3,20.6z" />
          <path d="M5.3,33.6h26.1c1.5,0,2.8-1.3,2.8-2.8S33,28,31.4,28H5.3c-1.5,0-2.8,1.3-2.8,2.8S3.8,33.6,5.3,33.6z" />
          <path d="M5.3,46.5h26.1c1.5,0,2.8-1.3,2.8-2.8s-1.3-2.8-2.8-2.8H5.3c-1.5,0-2.8,1.3-2.8,2.8S3.8,46.5,5.3,46.5z" />
          <path d="M5.3,59.4h26.1c1.5,0,2.8-1.3,2.8-2.8s-1.3-2.8-2.8-2.8H5.3c-1.5,0-2.8,1.3-2.8,2.8S3.8,59.4,5.3,59.4z" />
          <path d="M94.6,66.8H5.3c-1.5,0-2.8,1.3-2.8,2.8c0,1.5,1.3,2.8,2.8,2.8h89.3c1.5,0,2.8-1.3,2.8-2.8C97.4,68,96.2,66.8,94.6,66.8z" />
          <path d="M94.6,79.7H5.3c-1.5,0-2.8,1.3-2.8,2.8c0,1.5,1.3,2.8,2.8,2.8h89.3c1.5,0,2.8-1.3,2.8-2.8C97.4,80.9,96.2,79.7,94.6,79.7     z" />
        </g>
      </g>
    </svg>
  </BaseSvgWrapper>
);

export default Cms;
