import { takeLatest, put, call } from 'redux-saga/effects';
import { filterActions } from '../actions/creators/index';
import FiltersApi from '../../api/FiltersApi';

function* filtersSaga() {
  yield takeLatest(filterActions.getCustomFilters, handleGetCustomFilters);
  yield takeLatest(filterActions.saveCustomFilter, handleSaveCustomFilter);
  yield takeLatest(filterActions.deleteCustomFilter, handleDeleteCustomFilter);
}

function* handleGetCustomFilters(action: ReturnType<typeof filterActions.getCustomFilters>): any {
  const { request, success, failure } = filterActions.getCustomFiltersRequest;

  yield put(request());
  try {
    const payload = yield call(FiltersApi.getCustomFilters, action.payload);
    yield put(success({ screenConfigs: payload, moduleKey: action.payload }));
  } catch (e) {
    yield put(failure(e.response));
  }
}

function* handleSaveCustomFilter(action: ReturnType<typeof filterActions.saveCustomFilter>): any {
  const { request, success, failure } = filterActions.saveCustomFilterRequest;

  yield put(request());
  try {
    const payload = yield call(FiltersApi.saveCustomFilter, action.payload.params);
    yield put(success(payload));
    action.payload.successCallback?.();
  } catch (e) {
    yield put(failure(e.response));
  }
}

function* handleDeleteCustomFilter(action: ReturnType<typeof filterActions.deleteCustomFilter>): any {
  const { request, success, failure } = filterActions.deleteCustomFilterRequest;

  yield put(request());
  try {
    yield call(FiltersApi.deleteCustomFilter, action.payload.id);
    yield put(success(action.payload));
  } catch (e) {
    yield put(failure(e.response));
  }
}

export default filtersSaga;
