import * as React from 'react';
import { FilterListTitle, FilterListWrapper } from './styles';

type Props = {
  title: string;
  children: JSX.Element | JSX.Element[];
};

const FilterList: React.FC<Props> = ({ title, children }) => {
  return (
    <FilterListWrapper>
      <FilterListTitle>{title}</FilterListTitle>
      {children}
    </FilterListWrapper>
  );
};

export default FilterList;
