import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

type Props = {
  className?: string;
};

const UnLock: React.FC<Props> = (props: Props): JSX.Element => (
  <BaseSvgWrapper {...props}>
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.3021 47.3744H11.6979C9.65541 47.3744 8 45.7183 8 43.6765V24.4507C8 22.4082 9.65541 20.7528 11.6979 20.7528H33.143C33.1467 18.7017 33.1436 15.7379 33.1436 15.5764C33.1436 11.0823 29.5462 7.43867 25.0521 7.43867C20.5592 7.43867 16.8699 11.0804 16.8687 15.5734V15.5764C16.8607 15.5764 16.8539 15.5764 16.8465 15.5764C16.8465 15.5838 16.849 15.5912 16.849 15.5986V16.3382C16.849 17.1326 16.2049 17.7773 15.4099 17.7773H13.8636C13.0685 17.7773 12.4245 17.1332 12.4245 16.3382V15.5986C12.4245 15.5955 12.4251 15.5925 12.4257 15.5894H12.4097V15.5764C12.4097 15.5764 12.4189 15.5764 12.427 15.5764C12.4276 15.5284 12.4356 15.4828 12.4411 15.4359C12.5262 8.55481 18.1247 3 25.0274 3C31.9831 3 37.5613 8.63863 37.5613 15.5943C37.5613 15.8556 37.5675 18.8416 37.5607 20.7528H38.3021C40.3446 20.7528 42 22.4082 42 24.4507V43.6765C41.9994 45.7189 40.344 47.3744 38.3021 47.3744ZM37.5835 26.6867C37.5835 25.8694 36.921 25.2075 36.1044 25.2075H13.8981C13.0815 25.2075 12.4189 25.8694 12.4189 26.6867V41.4652C12.4189 42.2824 13.0815 42.9443 13.8981 42.9443H36.1044C36.921 42.9443 37.5835 42.2818 37.5835 41.4652V26.6867ZM25.3596 38.4859H24.62C23.6025 38.4859 22.7779 37.6613 22.7779 36.6438V31.5333C22.7779 30.5158 23.6025 29.6912 24.62 29.6912H25.3596C26.3771 29.6912 27.2018 30.5158 27.2018 31.5333V36.6438C27.2024 37.6613 26.3771 38.4859 25.3596 38.4859Z"
      />
    </svg>
  </BaseSvgWrapper>
);

export default UnLock;
