import React from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from '../../../config/theme';
import { AnimationProps } from '../../../config/theme/types';

const LoginBox = styled.div<AnimationProps>`
  align-self: center;
  display: flex;
  flex: 1;
  justify-content: center;

  > section {
    align-items: center;
    background: ${(props) => props.theme.login.background};
    border-radius: ${(props) => props.theme.login.borderRadius};
    box-shadow: ${(props) => props.theme.login.boxShadow};
    color: ${(props) => props.theme.login.textColor};
    display: flex;
    flex-flow: column;
    padding: ${(props) => props.theme.login.padding};
    position: relative;
    width: 400px;

    > h4 {
      font-family: ${(props) => props.theme.typography.fontFamily};
      font-size: 1.2rem;
      font-weight: 600;
      margin: 1.4rem 0 1.5rem;
      text-align: center;
    }

    > form {
      display: flex;
      flex-flow: wrap;

      > div {
        width: 100%;
      }

      > button {
        margin-top: 25px;
      }
    }

    > span {
      bottom: -40px;
      color: ${(props) => props.theme.login.versionColor};
      font-size: ${(props) => props.theme.login.versionSize};
      left: 0;
      position: absolute;
      text-align: center;
      width: 100%;
    }
  }

  ${(props) => props.state === 'entering' && props.theme.login.animation.entering};
  ${(props) => props.state === 'entered' && props.theme.login.animation.entered};
`;

const TransitionedWrapper: React.FC = ({ children }) => (
  <CSSTransition in timeout={150} mountOnEnter appear>
    {(state) => <LoginBox state={state}>{children}</LoginBox>}
  </CSSTransition>
);

export default TransitionedWrapper;
