import { Option } from '../Select';
import { FormikErrors, getIn } from 'formik';

export const createOptions = <T extends {}>(options: T[], valueKey: keyof T, labelKey: keyof T): Option[] =>
  options
    ? options.map((option) => ({ ...option, value: option[valueKey.toString()], label: option[labelKey.toString()] }))
    : [];

export const isRequiredField = <T extends {}>(errors: FormikErrors<T>, name: string) => {
  return Boolean(getIn(errors, name)?.includes('required'));
};
