import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const Money: React.FC = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.2312 4V8.99193C17.4368 10.014 14 13.8185 14 18.1764C14 22.8449 17.4402 26.0054 24.2245 27.5697C30.4614 29.0073 30.4614 31.1446 30.4614 31.8468C30.4614 34.1282 27.8615 36.0557 24.7843 36.0557C21.7065 36.0557 19.1062 34.1282 19.1062 31.8468V31.3347H14V31.8468C14 36.2048 17.4367 40.0087 22.2312 41.0306V46H27.3373V41.0306C32.1313 40.0085 35.5676 36.2045 35.5676 31.8468C35.5676 27.1783 32.1279 24.0179 25.3435 22.4535C19.1062 21.016 19.1062 18.8787 19.1062 18.1764C19.1062 15.8945 21.7065 13.9668 24.7843 13.9668C27.8615 13.9668 30.4614 15.8945 30.4614 18.1764V18.6886H35.5676V18.1764C35.5676 13.8185 32.1313 10.014 27.3374 8.99193V4H22.2312Z" />
    </svg>
  </BaseSvgWrapper>
);

export default Money;
