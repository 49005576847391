import * as React from 'react';
import { Route } from 'react-router-dom';
import { loadScene } from 'components';
import ModuleRoutes from 'components/routing/ModuleRoutes';
import { Module } from '../../security/types/modules';
import { COMPANY_MODULE_KEY } from '../constants';

type Props = {
  allowedModules: Module[];
};

const companyOverviewScene = loadScene('company', 'CompanyScene');

export const COMPANY_BASE_ROUTES = '/company';

const CompanyRoutes: React.FC<Props> = ({ allowedModules }: Props) => {
  return (
    <ModuleRoutes allowedModules={allowedModules} moduleKey={COMPANY_MODULE_KEY}>
      <Route path={[COMPANY_BASE_ROUTES, '/company/:subroute']} exact component={companyOverviewScene} />
    </ModuleRoutes>
  );
};

export default CompanyRoutes;
