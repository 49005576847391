import { Divider, Heading, Text } from '@unbrace/components';
import { useToggle } from '@unbrace/hooks';
import { FormikSelect } from 'components';
import { ShowMoreToggle } from 'components/ShowMore/ShowMoreToggle';
import { Field, FieldProps, useFormikContext } from 'formik';
import { PricingFormValues } from 'modules/products/types/pricing';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useGlobalInfoStore from 'modules/products/services/useGlobalInfoStore';

const PricingAdvancedOptions = () => {
  const { t } = useTranslation('products');
  const { values } = useFormikContext<PricingFormValues>();
  const [areOptionsVisible, setAreOptionsVisible] = useToggle(values.priceTranslation !== null);
  const { priceTranslations } = useGlobalInfoStore();

  return (
    <React.Fragment>
      {areOptionsVisible && (
        <React.Fragment>
          {/* This is temporarily commented out because it is not used in the current implementation
          {/* 
          {!isEdit && (
            <React.Fragment>
              <Divider margin={'0 0 20px 0'} />
              <EntitySelect />
            </React.Fragment>
          )} */}
          <Divider margin={'0 0 20px 0'} />
          <Heading colorLevel="medium" asTag="h5" size="xs" weight="thin">
            {t('pricing.nceTitle')}
          </Heading>
          <Text colorLevel="medium">{t('pricing.nceSubTitle')}</Text>
          <Field name="priceTranslation">
            {(fieldProps: FieldProps) => (
              <FormikSelect
                {...fieldProps}
                isClearable
                label={t('pricing.nce')}
                options={priceTranslations}
                getOptionLabel={(option) => option.description}
                getOptionValue={(option) => option.description}
                menuPosition="fixed"
                objectAsValue
              />
            )}
          </Field>
        </React.Fragment>
      )}
      <ShowMoreToggle
        isShowingMore={areOptionsVisible}
        toggleShow={setAreOptionsVisible}
        showMoreText={t('pricing.showMore')}
        showLessText={t('pricing.showLess')}
      />
    </React.Fragment>
  );
};

export default PricingAdvancedOptions;
