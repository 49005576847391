import * as React from 'react';
import { Route } from 'react-router-dom';
import { loadScene } from '../../../components';
import ModuleRoutes from '../../../components/routing/ModuleRoutes';
import { Module } from '../../security/types/modules';
import { CMS_MODULE_KEY } from '../constants/cms';

type Props = {
  allowedModules: Module[];
};

const CmsScene = loadScene('cms', 'CmsScene');

export const CMS_BASE_ROUTES = '/cms';

const CmsRoutes: React.FC<Props> = ({ allowedModules }: Props) => {
  return (
    <ModuleRoutes allowedModules={allowedModules} moduleKey={CMS_MODULE_KEY}>
      <Route path={CMS_BASE_ROUTES} exact={false} component={CmsScene} />
    </ModuleRoutes>
  );
};

export default CmsRoutes;
