import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { BasicFormTitle, Paper } from '../../../components';
import PageWrapper from '../../app/components/PageWrapper';
import RouteLeavingGuard from '../../app/components/RouteLeavingGuard';
import { ProductBaseForm } from '../components/edit';
import ProductNewHeader from '../components/new/ProductNewHeader';
import useProductStore from '../services/useProductStore';
import { ProductBaseFormValues } from '../types/product';
import useGlobalInfoStore from '../services/useGlobalInfoStore';

const ProductNew: React.FC = () => {
  const { t } = useTranslation('products');
  const { productRebategroups } = useGlobalInfoStore();
  const [productChanged, setProductChanged] = React.useState(false);
  const formRef: React.RefObject<any> = React.useRef(null);
  const handleFormChange = React.useCallback(() => setProductChanged(true), []);
  const [addPricingNext, setAddPricingNext] = React.useState(false);
  const { createProduct, isCreateProductLoading } = useProductStore();

  const generateInitialProduct = (rebateGroupDataAvailable: boolean): ProductBaseFormValues => ({
    code: '',
    metadatas: [],
    productId: 0,
    partnerPricingProfile: rebateGroupDataAvailable ? { rebateGroupId: undefined } : {},
    partnerCenterProfile: { offerId: '' },
    priceMethodId: 0,
    productStatusId: 0,
    productTypeId: 0,
    translations: [
      { description: '', language: 'NL', productTranslationId: 0 },
      { description: '', language: 'EN', productTranslationId: 0 },
      { description: '', language: 'FR', productTranslationId: 0 },
    ],
  });

  const handleSaveWithPricing = () => {
    setAddPricingNext(true);
    formRef.current.handleSubmit();
  };

  const handleCancel = () => {
    setProductChanged(false);
    formRef.current.resetForm();
  };

  const handleSave = () => {
    formRef.current.handleSubmit();
  };

  const submit = (formValues: ProductBaseFormValues) => {
    const newFormValues = {
      pricingNext: addPricingNext,
      ...formValues,
    };
    setProductChanged(false);
    createProduct(newFormValues);
  };

  return (
    <PageWrapper>
      <RouteLeavingGuard when={productChanged} />
      <ProductNewHeader
        handleCancel={handleCancel}
        handleSave={handleSave}
        handleSaveWithPricing={handleSaveWithPricing}
        hasChanges={productChanged}
        isSaving={isCreateProductLoading}
      />
      <Paper>
        <BasicFormTitle title={t('newView.product.title')} description={t('newView.product.titleDescription')} />
        <ProductBaseForm
          formRef={formRef}
          handleFormChange={handleFormChange}
          handleSubmit={submit}
          product={generateInitialProduct((productRebategroups && productRebategroups.length > 0) || false)}
        />
      </Paper>
    </PageWrapper>
  );
};

export default ProductNew;
