import { Badge } from '@unbrace/components';
import * as React from 'react';
import styled from '../../../config/theme';

type Props = {
  id?: number;
  text: string;
};

const ProductStatusBadge: React.FC<Props> = ({ id, text }: Props) => {
  switch (id) {
    case 1:
      return <StatusBadge color="success">{text}</StatusBadge>;
    case 2:
      return <StatusBadge color="danger">{text}</StatusBadge>;
    case 3:
      return <StatusBadge color="warning">{text}</StatusBadge>;
    default:
      return <StatusBadge color="primary">{text}</StatusBadge>;
  }
};

const StatusBadge = styled(Badge)`
  margin: 15px 10px 10px 0;
  max-width: fit-content;
`;

export default ProductStatusBadge;
