import styled from '../../config/theme';

type Props = {
  margin?: string;
};

const Divider = styled.hr<Props>`
  border: none;
  border-top: 1px solid ${(props) => props.theme.palette.neutral.shade3};
  ${(props) => props.margin && `margin: ${props.margin}`}
`;

export default Divider;
