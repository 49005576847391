import * as React from 'react';
import { MetaData, UseMetaDataActions } from '../../types/metadata';

const useMetaData = (data?: MetaData[], initialActive?: MetaData[]): [MetaData[], UseMetaDataActions] => {
  const [activeMetaData, setActiveMetaData] = React.useState<(number | string)[]>([]);

  const resetMetaData = React.useCallback(() => {
    setActiveMetaData(initialActive ? initialActive.map((metadata: MetaData) => metadata.metadataId.toString()) : []);
  }, [initialActive]);

  React.useEffect(() => {
    resetMetaData();
  }, [resetMetaData]);

  const toggleMetaData = (id: number | string) => {
    setActiveMetaData(() => {
      const newData = [...activeMetaData];
      if (activeMetaData.includes(id)) {
        newData.splice(newData.indexOf(id), 1);
      } else {
        newData.push(id);
      }

      return newData;
    });
  };

  if (!data) {
    return [
      [],
      {
        resetMetaData,
        toggleMetaData,
      },
    ];
  }

  return [
    data.map((dat: MetaData) => ({
      ...dat,
      isAdded: activeMetaData.includes(dat.metadataId),
    })),
    {
      resetMetaData,
      toggleMetaData,
    },
  ];
};

export default useMetaData;
