import React from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from '../../../config/theme';
import { AnimationProps } from '../../../config/theme/types';
import { media } from '../../../config/theme/utils';

const Wrapper = styled.section<AnimationProps>`
  text-align: center;
  width: 100%;

  > h1 {
    color: ${(props) => props.theme.palette.neutral.shade7};
    font-size: ${(props) => props.theme.typography.fontSize.medium_6};
    font-weight: 500;
    letter-spacing: initial;

    ${(props) => media.laptopLG`
      font-size: ${props.theme.typography.fontSize.medium_6};
    `};
  }

  > p {
    color: ${(props) => props.theme.palette.neutral.shade5};
    font-size: ${(props) => props.theme.typography.fontSize.regular};
    text-align: center;
    max-width: 450px;

    ${media.mobile` max-width: 200px; `};
    ${media.tablet` max-width: 300px; `};
    ${media.laptopSM` max-width: 380px; `};
    ${media.laptopLG` max-width: 400px; `};
  }

  button {
    margin: 30px auto 0;
  }

  ${(props) => props.state === 'entering' && 'opacity: 0.01;'};
  ${(props) => props.state === 'entered' && 'opacity: 1; transition: opacity 150ms 50ms;'};
`;

const TransitionedWrapper: React.FC = ({ children, ...rest }) => (
  <CSSTransition in timeout={150} mountOnEnter appear>
    {(state) => (
      <Wrapper state={state} {...rest}>
        {children}
      </Wrapper>
    )}
  </CSSTransition>
);

export default TransitionedWrapper;
