import styled from '../../config/theme';
import { media } from '../../config/theme/utils';
import { Image, Wrapper } from './components';

const NoListItemSelectedImage = styled(Image)`
  height: 300px;
  margin: 0 auto 40px;

  ${media.mobile` height: 100px; `};
  ${media.tablet` height: 130px; `};
  ${media.laptopSM` height: 240px; `};
  ${media.laptopLG` height:260px; `};
`;

const NoListItemSelectedWrapper = styled(Wrapper)`
  align-self: center;

  > p {
    margin: 0 auto;
  }
`;

export { NoListItemSelectedImage, NoListItemSelectedWrapper };
