import { FormikErrors, FormikTouched, getIn } from 'formik';

const getNestedError = (name: string, errors: FormikErrors<any>, touched: FormikTouched<any>, submitCount?: number) => {
  const error = getIn(errors, name);
  const touch = getIn(touched, name);
  const submitted = submitCount && submitCount > 0;

  return (touch || submitted) && error ? error : null;
};

export default getNestedError;
