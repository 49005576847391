/* eslint-disable complexity */
import { getType } from 'typesafe-actions';
import { SortOrder } from '@unbrace/components';
import { ModuleFilters, ScreenConfiguration } from '../../types/filters';
import { filterActions, FilterActionsType } from '../actions/creators';
import { getDefaultPageSize } from 'modules/app/constants';

export type AppState = {
  filters: {
    [filterKey: string]: ModuleFilters;
  };
  screenConfigs: {
    [moduleKey: string]: {
      filters: ScreenConfiguration[];
    };
  };
};

const initialState = {
  filters: {
    company: {
      additionalFilters: {
        statuses: ['active'],
      },
      pageSize: getDefaultPageSize(),
    },
    products: {
      additionalFilters: {
        productStatuses: [1],
        productTypes: [],
      },
      pageSize: getDefaultPageSize(),
    },
    contract: {
      additionalFilters: {
        statuses: [
          {
            contractStatusId: 3,
            code: 'ACTIVE',
            description: 'Active',
          },
        ],
      },
      pageSize: getDefaultPageSize(),
    },
    invoices: {
      pageSize: getDefaultPageSize(),
      sorting: {
        type: SortOrder.DESC,
        columnNames: ['datedoc'],
      },
    },
    manualInvoices: {
      additionalFilters: {
        statuses: { open: true },
      },
      pageSize: getDefaultPageSize(),
    },
  },
  screenConfigs: {},
};

const appReducer = (state: AppState = initialState, action: FilterActionsType): AppState => {
  switch (action.type) {
    case getType(filterActions.getCustomFiltersRequest.success):
      return {
        ...state,
        screenConfigs: {
          ...state.screenConfigs,
          [action.payload.moduleKey]: {
            ...state.screenConfigs[action.payload.moduleKey],
            filters: action.payload.screenConfigs,
          },
        },
      };
    case getType(filterActions.saveCustomFilterRequest.success):
      return {
        ...state,
        screenConfigs: {
          ...state.screenConfigs,
          [action.payload.screen]: {
            ...state.screenConfigs[action.payload.screen],
            filters: [...state.screenConfigs[action.payload.screen].filters, action.payload],
          },
        },
      };
    case getType(filterActions.deleteCustomFilterRequest.success):
      return {
        ...state,
        screenConfigs: {
          ...state.screenConfigs,
          [action.payload.screen]: {
            ...state.screenConfigs[action.payload.screen],
            filters: state.screenConfigs[action.payload.screen].filters.filter(
              (filter) => filter.screenConfigurationId !== action.payload.id
            ),
          },
        },
      };
    case getType(filterActions.replaceFilters):
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filterKey]: {
            ...state.filters[action.payload.filterKey],
            additionalFilters: {
              ...action.payload.filters.additionalFilters,
            },
          },
        },
      };
    case getType(filterActions.clearAdditionalFilters):
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filterKey]: {
            ...state.filters[action.payload.filterKey],
            additionalFilters: undefined,
          },
        },
      };
    case getType(filterActions.setSearchText):
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filterKey]: {
            ...state.filters[action.payload.filterKey],
            pageIndex: 1,
            searchText: action.payload.searchText,
          },
        },
      };
    case getType(filterActions.setPageIndex):
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filterKey]: {
            ...state.filters[action.payload.filterKey],
            pageIndex: action.payload.pageIndex,
          },
        },
      };
    case getType(filterActions.setPageSize):
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filterKey]: {
            ...state.filters[action.payload.filterKey],
            pageSize: action.payload.pageSize,
            pageIndex: 1,
          },
        },
      };
    case getType(filterActions.setAdditionalFilters):
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filterKey]: {
            ...state.filters[action.payload.filterKey],
            additionalFilters: action.payload.filters,
            pageIndex: 1,
          },
        },
      };

    case getType(filterActions.toggleColumnSort):
      const alreadyFilteringOnColumn =
        state.filters[action.payload.filterKey]?.sorting?.columnNames[0] === action.payload.columnNames[0];
      const currentSortingType = state.filters[action.payload.filterKey]?.sorting?.type;
      const nextSortingType =
        currentSortingType === SortOrder.ASC
          ? SortOrder.DESC
          : currentSortingType === SortOrder.DESC
          ? undefined
          : SortOrder.ASC;

      return alreadyFilteringOnColumn
        ? {
            ...state,
            filters: {
              ...state.filters,
              [action.payload.filterKey]: {
                ...state.filters[action.payload.filterKey],
                sorting: nextSortingType && {
                  columnNames: action.payload.columnNames,
                  type: action.payload.sortOrder || nextSortingType,
                },
                pageIndex: 1,
              },
            },
          }
        : {
            ...state,
            filters: {
              ...state.filters,
              [action.payload.filterKey]: {
                ...state.filters[action.payload.filterKey],
                sorting: {
                  columnNames: action.payload.columnNames,
                  type: action.payload.sortOrder || SortOrder.ASC,
                },
                pageIndex: 1,
              },
            },
          };

    default:
      return state;
  }
};

export default appReducer;

// TODO: add reset filters
