export const load = function() {
  if (process.env.REACT_APP_USERSNAP_KEY) {
    const scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.async = true;
    scriptTag.src = `//api.usersnap.com/load/${process.env.REACT_APP_USERSNAP_KEY}.js`;
    // eslint-disable-next-line prefer-destructuring
    const scriptElements = document.getElementsByTagName('script')[0];
    if (scriptElements && scriptElements.parentNode) {
      scriptElements.parentNode.insertBefore(scriptTag, scriptElements);
    }
  }
};
