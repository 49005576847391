import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ContextMenuContainer } from 'components';
import { ContextMenuItemWrapper } from 'components/ContextMenu/ContextMenuItem/styles';
import { ModuleEditAccessContext } from 'modules/security';
import { Delete, Edit } from '../../../../components/icons';
import { Pricing } from '../../types/pricing';

type Props = {
  closeMenu?: VoidFunction;
  toggleModal?: VoidFunction;
  handleDelete: (price: Pricing) => void;
  price: Pricing;
};

const PricingContextMenu: React.FC<Props> = ({ closeMenu, handleDelete, price, toggleModal }: Props) => {
  const { t } = useTranslation('products');
  const { canEdit } = React.useContext(ModuleEditAccessContext);
  const deletePrice = () => handleDelete(price);

  return (
    <ContextMenuContainer closeMenu={closeMenu}>
      <ContextMenuItemWrapper
        onClick={() => {
          toggleModal?.();
          closeMenu?.();
        }}
        isDisabled={!canEdit}
      >
        <Edit />
        <p>{t('pricing.editView.tooltip.edit')}</p>
      </ContextMenuItemWrapper>

      <ContextMenuItemWrapper onClick={deletePrice} isDisabled={!canEdit}>
        <Delete />
        <p>{t('pricing.editView.tooltip.delete')}</p>
      </ContextMenuItemWrapper>
    </ContextMenuContainer>
  );
};

export default PricingContextMenu;
