/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface GetAccessDefinitionResponse {
  accessDefinitionId: number;
  name: string;
  description: string;
  code: string;
  definitionType: string;
  applicationId: number;
  enabled: boolean;
  accessValueDefinitions: GetAccessValueDefinitionResponse[];
}

export interface GetAccessValueDefinitionResponse {
  accessValueDefinitionId: number;
  name: string;
  code: string;
  definitionType: string;
  enabled: boolean;
}

export interface EntityResponse {
  entityId: number;
  entityType: string;
  entityGuid: string;
  parentGuid?: string;
  parentType?: string;
  parent?: string;
  name: string;
  extraInfo: string;
  roles: string[];
  enabled: boolean;
}

export interface GetEntityOverviewResponse {
  items: EntityResponse[];
  pageIndex: number;
  pageSize: number;
  totalPages: number;
  totalItems: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export type GetEntityRoleResponse = RoleResponse[];

export interface RoleResponse {
  roleId: number;
  name: string;
  description: string;
  code: string;
  roleType: string;
  isDefault: boolean;
}

export interface GetRoleOverviewResponse {
  items: RoleResponse[];
  pageIndex: number;
  pageSize: number;
  totalPages: number;
  totalItems: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface GetRoleDetailResponse {
  roleId: number;
  name: string;
  description: string;
  code: string;
  roleType: string;
  isDefault: boolean;
  applications: ApplicationResponse[];
}

export interface ApplicationResponse {
  applicationId: number;
  name: string;
  description: string;
  code: string;
  logo?: string;
  accessDefinitions: GetAccessDefinitionResponse[];
}

export interface GetAccessDefinitionsForApplicationParams {
  search?: string;
  take: number;
  application?: string;
}

export interface GetEntitiesOverviewParams {
  pageIndex?: number;
  pageSize?: number;
  search?: string;
  orderBys?: any[];
  roles?: any[];
  entityTypes?: any[];
}

export interface GetRolesOverviewParams {
  pageIndex?: number;
  pageSize?: number;
  search?: string;
  roleTypes?: any[];
  orderBys?: any[];
}

import axios, { AxiosInstance, AxiosRequestConfig, ResponseType } from 'axios';

type HeaderValue = string | string[] | number | boolean | null;

interface RawHeaders {
  [key: string]: HeaderValue;
}

interface HeadersDefaults {
  common: RawHeaders;
  delete: RawHeaders;
  get: RawHeaders;
  head: RawHeaders;
  post: RawHeaders;
  put: RawHeaders;
  patch: RawHeaders;
  options?: RawHeaders;
  purge?: RawHeaders;
  link?: RawHeaders;
  unlink?: RawHeaders;
}

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || 'http://localhost:3000' });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<T> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance
      .request({
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
        },
        params: query,
        responseType: responseFormat,
        data: body,
        url: path,
      })
      .then((response) => response.data?.response);
  };
}

/**
 * @title AccessModule
 * @version 1.0
 * @baseUrl http://localhost:3000
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * @description get access definitions for application
     *
     * @name GetAccessDefinitionsForApplication
     * @summary Access definitions
     * @request GET:/api/access/v2/accessdefinitions
     */
    getAccessDefinitionsForApplication: (query: GetAccessDefinitionsForApplicationParams, params: RequestParams = {}) =>
      this.request<GetAccessDefinitionResponse[], any>({
        path: `/api/access/v2/accessdefinitions`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description get entities overview
     *
     * @name GetEntitiesOverview
     * @summary Your GET endpoint
     * @request GET:/api/access/v2/entities/overview
     */
    getEntitiesOverview: (query: GetEntitiesOverviewParams, params: RequestParams = {}) =>
      this.request<GetEntityOverviewResponse, any>({
        path: `/api/access/v2/entities/overview`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description assign role to entity
     *
     * @name AssignRolesToEntity
     * @request POST:/api/access/v2/entities/{id}/assign-roles
     */
    assignRolesToEntity: (id: number, data: number[], params: RequestParams = {}) =>
      this.request<EntityResponse, any>({
        path: `/api/access/v2/entities/${id}/assign-roles`,
        method: 'POST',
        body: data,
        format: 'json',
        ...params,
      }),

    /**
     * @description get entity types
     *
     * @name GetEntityTypes
     * @summary Your GET endpoint
     * @request GET:/api/access/v2/entities/entitytypes
     */
    getEntityTypes: (params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/api/access/v2/entities/entitytypes`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description get entity roles
     *
     * @name GetEntityRoles
     * @summary Your GET endpoint
     * @request GET:/api/access/v2/entities/roles
     */
    getEntityRoles: (params: RequestParams = {}) =>
      this.request<GetEntityRoleResponse, any>({
        path: `/api/access/v2/entities/roles`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description get roles overview
     *
     * @name GetRolesOverview
     * @summary Your GET endpoint
     * @request GET:/api/access/v2/roles/overview
     */
    getRolesOverview: (query: GetRolesOverviewParams, params: RequestParams = {}) =>
      this.request<GetRoleOverviewResponse, any>({
        path: `/api/access/v2/roles/overview`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description get role detail by roleID
     *
     * @name GetRoleById
     * @summary Your GET endpoint
     * @request GET:/api/access/v2/roles/{id}
     */
    getRoleById: (id: number, params: RequestParams = {}) =>
      this.request<GetRoleDetailResponse, any>({
        path: `/api/access/v2/roles/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description get role types
     *
     * @name GetRoleTypes
     * @summary Your GET endpoint
     * @request GET:/api/access/v2/roles/types
     */
    getRoleTypes: (params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/api/access/v2/roles/types`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description add new role
     *
     * @name CreateRole
     * @request POST:/api/access/v2/roles
     */
    createRole: (
      data: {
        code: string;
        name: string;
        description: string;
        roleType: string;
        isDefault: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<RoleResponse, any>({
        path: `/api/access/v2/roles`,
        method: 'POST',
        body: data,
        format: 'json',
        ...params,
      }),

    /**
     * @description update role
     *
     * @name UpdateRole
     * @request PUT:/api/access/v2/roles
     */
    updateRole: (
      data: {
        roleId: number;
        code: string;
        name: string;
        description: string;
        roleType: string;
        isDefault: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<RoleResponse, any>({
        path: `/api/access/v2/roles`,
        method: 'PUT',
        body: data,
        format: 'json',
        ...params,
      }),

    /**
     * @description set role access definition
     *
     * @name SetRoleAccessDefinition
     * @request POST:/api/access/v2/roles/{id}/set-accessdefinition
     */
    setRoleAccessDefinition: (
      id: number,
      data: {
        accessDefinitionId: number;
        codes: string[];
      },
      params: RequestParams = {}
    ) =>
      this.request<GetRoleDetailResponse, any>({
        path: `/api/access/v2/roles/${id}/set-accessdefinition`,
        method: 'POST',
        body: data,
        format: 'json',
        ...params,
      }),

    /**
     * @description delete role acesss definition
     *
     * @name DeleteRoleAccessDefinition
     * @request POST:/api/access/v2/roles/{id}/delete-accessdefinition
     */
    deleteRoleAccessDefinition: (
      id: number,
      data: {
        accessDefinitionId: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetRoleDetailResponse, any>({
        path: `/api/access/v2/roles/${id}/delete-accessdefinition`,
        method: 'POST',
        body: data,
        format: 'json',
        ...params,
      }),

    /**
     * @description update role access definition value
     *
     * @name UpdateRoleAccessValue
     * @request POST:/api/access/v2/roles/{id}/update-accessdefinitionvalue
     */
    updateRoleAccessValue: (
      id: number,
      data: {
        accessDefinitionId: number;
        accessValueDefinitionId: number;
        enabled: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetRoleDetailResponse, any>({
        path: `/api/access/v2/roles/${id}/update-accessdefinitionvalue`,
        method: 'POST',
        body: data,
        format: 'json',
        ...params,
      }),
  };
}
