import { Button, SubmitButton } from '@unbrace/components';
import * as React from 'react';
import { Spacer } from '../Spacing';
import { ConfirmationButtonWrapper, ConfirmationDescription, Wrapper } from './styles';

type Props = {
  callback: (...args: any) => void;
  cancelText: string;
  closeMenu?: () => void;
  confirmText: string;
  description?: string;
  color?: 'danger' | 'primary';
  disableSubmit?: boolean;
  isLoading?: boolean;
};

const BasicConfirmation: React.FC<Props> = ({
  callback,
  cancelText,
  closeMenu,
  color,
  confirmText,
  description,
  disableSubmit,
  isLoading,
}: Props) => {
  const onClick = React.useCallback(
    (...args) => {
      callback(...args);
      if (closeMenu && !isLoading) {
        closeMenu();
      }
    },
    [callback, closeMenu, isLoading]
  );

  return (
    <Wrapper align="flex-end" direction="column" justify="center">
      {description && <ConfirmationDescription>{description}</ConfirmationDescription>}
      <Spacer top>
        <ConfirmationButtonWrapper>
          <Button color="tertiary" onClick={closeMenu}>
            {cancelText}
          </Button>
          <SubmitButton color={color} onClick={onClick} isDisabled={disableSubmit} isLoading={isLoading}>
            {confirmText}
          </SubmitButton>
        </ConfirmationButtonWrapper>
      </Spacer>
    </Wrapper>
  );
};

export default BasicConfirmation;
