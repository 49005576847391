import AxiosConfig, { RequestConfig } from '../../../config/api/AxiosConfig';
import { Modules } from '../types/modules';
import { MODULES_URL } from './endpoints';

class ModulesApi {
  public async getModules() {
    const requestConfig: RequestConfig = {
      url: MODULES_URL,
    };

    return AxiosConfig.fetchAxiosGetRequest<Modules>(requestConfig);
  }
}

export default new ModulesApi();
