import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const AddField: React.FC = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="15" width="19" height="5" />
      <rect y="32" width="19" height="5" />
      <rect y="37" width="22" height="5" transform="rotate(-90 0 37)" />
      <rect x="10" y="30" width="7" height="2" transform="rotate(-90 10 30)" />
      <rect x="7" y="22" width="8" height="2" />
      <rect x="22" y="49" width="48" height="3" transform="rotate(-90 22 49)" />
      <rect x="16" y="1" width="15" height="3" />
      <rect x="16" y="46" width="15" height="3" />
      <rect x="28" y="15" width="17" height="5" />
      <rect x="28" y="32" width="17" height="5" />
      <rect x="45" y="37" width="22" height="5" transform="rotate(-90 45 37)" />
    </svg>
  </BaseSvgWrapper>
);

export default AddField;
