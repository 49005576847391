import { useMutateLogout } from '../adapters/queries';

const useLogoutStore = () => {
  const { mutate: initiateLogout, isLoading: isLogoutLoading } = useMutateLogout();

  return {
    initiateLogout,
    isLogoutLoading,
  };
};

export default useLogoutStore;
