import { useMutateResetAuthenticator, useMutateResetOtherPassword, useMutateResetPassword } from '../adapters/queries';
import { SuccessCallback } from '../../../types/index';

const useIdentityStore = () => {
  const { mutate: resetOtherPassword, isLoading: isResetOtherPasswordLoading } = useMutateResetOtherPassword();
  const { mutate: resetItem, isLoading: isResetPasswordLoading } = useMutateResetPassword();
  const { mutate: resetAuthenticator, isLoading: isResetAuthenticatorLoading } = useMutateResetAuthenticator();

  const resetPassword = (successCallback?: SuccessCallback) => {
    resetItem(undefined, {
      onSuccess: successCallback,
    });
  };

  return {
    resetOtherPassword,
    isResetOtherPasswordLoading,
    resetPassword,
    isResetPasswordLoading,
    resetAuthenticator,
    isResetAuthenticatorLoading,
  };
};

export default useIdentityStore;
