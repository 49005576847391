import * as React from 'react';
import { Route, Switch } from 'react-router';
import { AccessRightsRoute, ModuleEditAccessContext } from '../../security';
import NoProductSelected from '../components/NoProductSelected';
import ProductNew from '../containers/ProductNew';
import ProductNewWithPricing from '../containers/ProductNewWithPricing';
import ProductsDetail from '../containers/ProductsDetail';
import { PRODUCTS_BASE_ROUTES } from './ProductRoutes';

type Props = {};

const ProductSubRoutes: React.FC<Props> = () => {
  const { canEdit } = React.useContext(ModuleEditAccessContext);

  return (
    <Switch>
      <AccessRightsRoute hasAccess={canEdit} path={`${PRODUCTS_BASE_ROUTES}/add`} exact component={ProductNew} />

      <AccessRightsRoute
        hasAccess={canEdit}
        exact
        path={`${PRODUCTS_BASE_ROUTES}/add/:id/pricing`}
        render={({
          match: {
            params: { id },
          },
        }) => <ProductNewWithPricing activeProductsId={id as any} />}
      />

      <Route
        path={`${PRODUCTS_BASE_ROUTES}/:id`}
        exact
        render={({
          match: {
            params: { id },
          },
        }) => <ProductsDetail activeProductsId={id} />}
      />
      <Route render={() => <NoProductSelected />} />
    </Switch>
  );
};

export default ProductSubRoutes;
