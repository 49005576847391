import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const AddPerson: React.FC = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
      <path d="M38.189 15.2889H32.8638C32.7218 15.2889 32.6 15.4106 32.6 15.5526V18.2474C32.6 18.3894 32.7218 18.5111 32.8638 18.5111H38.189V23.8363C38.189 23.9783 38.3107 24.1 38.4527 24.1H41.1474C41.2894 24.1 41.4111 23.9783 41.4111 23.8363V18.5111H46.7363C46.8783 18.5111 47 18.3894 47 18.2474V15.5526C47 15.4803 46.9754 15.4234 46.9368 15.3822C46.8943 15.3267 46.8274 15.2889 46.7363 15.2889H41.4111V9.96372C41.4111 9.82171 41.2894 9.7 41.1474 9.7H38.4527C38.3107 9.7 38.189 9.82171 38.189 9.96372V15.2889Z" />
      <path d="M37.0097 39.8041L34.8674 32.5306C33.8677 29.1694 30.0116 26.8 25.5841 26.8C25.0129 26.8 24.513 27.0204 24.1559 27.351L20.514 31.4286C20.0142 31.9245 19.0858 31.9245 18.586 31.4286L14.9441 27.351C14.6584 27.0204 14.0871 26.8 13.5159 26.8C9.08845 26.8 5.1609 29.1694 4.23257 32.5306L2.09028 39.8041C1.59041 41.4571 3.23284 43 5.37513 43H33.7249C35.8672 43 37.5096 41.4571 37.0097 39.8041Z" />
      <path d="M19.55 22.3C23.2704 22.3 26.3 19.4593 26.3 15.9707V13.3293C26.3 9.84072 23.2704 7 19.55 7C15.8295 7 12.8 9.84072 12.8 13.3293V16.0205C12.8531 19.4593 15.8827 22.3 19.55 22.3Z" />
    </svg>
  </BaseSvgWrapper>
);

export default AddPerson;
