import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const Copy: React.FC = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34 34.3794H6V5.99999H34V34.3794ZM29.7204 30.0417H10.2796V10.3376H29.7204V30.0417Z"
      />
      <path d="M15 43H43V15H15V43Z" />
    </svg>
  </BaseSvgWrapper>
);

export default Copy;
