import { ActionType } from 'typesafe-actions';
import {
  initiateLogin,
  initiateOauthSignIn,
  initiateRedirect,
  loginRedirectRequest,
  loginRequest,
  oauthSignInRequest,
} from './login';
import * as loginAction from './login';
import * as logoutAction from './logout';
import { initiateLogout, logoutRequest } from './logout';
import { clearUser, fetchUser, fetchUserAccessRights, getUser } from './user';
import * as userAction from './user';

export const loginActions = {
  initiateLogin,
  initiateOauthSignIn,
  initiateRedirect,
  loginRedirectRequest,
  loginRequest,
  oauthSignInRequest,
};
export const logoutActions = { initiateLogout, logoutRequest };
export const userActions = { clearUser, fetchUser, getUser };
export const userAccessRightsActions = { fetchUserAccessRights };

export type UserActionsType = ActionType<typeof userAction>;
export type LoginActionsType = ActionType<typeof loginAction>;
export type LogoutActionsType = ActionType<typeof logoutAction>;

export const GET_EXTERNAL_LINKS_PREFIX = 'GET_EXTERNAL_LINKS';
