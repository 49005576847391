import * as React from 'react';
import { CSSTransition } from 'react-transition-group';
import { media } from 'config/theme/utils';
import styled from '../../config/theme';
import { AnimationProps } from '../../config/theme/types';

type Props = {
  children: React.ReactNode[] | React.ReactNode | null;
  noPadding?: boolean;
  noSidePadding?: boolean;
  noTopMargin?: boolean;
  noTopPadding?: boolean;
  onlyBottomPadding?: boolean;
  noSideBar?: boolean;
  multiple?: boolean;
  hasTable?: boolean;
};

const Section = styled('section')<Props & AnimationProps>`
  background-color: ${(props) => props.theme.internalPaper.backgroundColor};
  border-radius: ${(props) => props.theme.internalPaper.borderRadius};
  box-shadow: ${(props) => props.theme.internalPaper.boxShadow.regular};
  margin: ${(props) => props.theme.internalPaper.margin};
  padding: ${(props) => props.theme.internalPaper.padding.vertical};
  ${(props) => props.theme.internalPaper.padding.vertical};
  ${(props) => props.noSidePadding && `padding: ${props.theme.internalPaper.padding.vertical} 0`}
  ${(props) => props.noPadding && `padding: 0`};
  ${(props) => props.noTopPadding && `padding: 0 ${props.theme.internalPaper.padding.vertical}`};
  ${(props) => props.noTopMargin && `margin-top: 0`};
  ${(props) => props.onlyBottomPadding && `padding: 0 0 ${props.theme.internalPaper.padding.vertical} 0`};
  ${(props) => props.state === 'entering' && props.theme.internalPaper.animation.entering};
  ${(props) => props.state === 'entered' && props.theme.internalPaper.animation.entered};

  ${(props) => media.tablet`
    margin: 12px;
    padding: 24px;
    ${props.multiple ? `overflow: none` : 'overflow: auto'};
    ${props.noSidePadding && `padding: ${props.theme.internalPaper.padding.vertical} 0`};
    ${props.noPadding && `padding: 0`};
    ${props.noTopPadding && `padding: 0 ${props.theme.internalPaper.padding.vertical}`};
    ${props.noTopMargin && `margin-top: 0`};
    ${props.onlyBottomPadding && `padding: 0 0 ${props.theme.internalPaper.padding.vertical} 0`};
  `};

  ${(props) => media.mobile`
    overflow: auto;
    margin: 0;
    padding: 24px;
    border-radius: 0;
    ${props.noSidePadding && `padding: ${props.theme.internalPaper.padding.vertical} 0`};
    ${props.noPadding && `padding: 0`};
    ${props.noTopPadding && `padding: 0 ${props.theme.internalPaper.padding.vertical}`};
    ${props.noTopMargin && `margin-top: 0`};
    ${props.onlyBottomPadding && `padding: 0 0 ${props.theme.internalPaper.padding.vertical} 0`};
  `};

  ${(props) => media.laptopSM`
    margin: 16px;
    padding: 24px;
    ${props.hasTable && `overflow:auto;`}
    ${props.noSidePadding && `padding: ${props.theme.internalPaper.padding.vertical} 0`};
    ${props.noPadding && `padding: 0`};
    ${props.noTopPadding && `padding: 0 ${props.theme.internalPaper.padding.vertical}`};
    ${props.noTopMargin && `margin-top: 0`};
    ${props.onlyBottomPadding && `padding: 0 0 ${props.theme.internalPaper.padding.vertical} 0`};
  `}

  ${(props) => media.laptopLG`
  ${props.hasTable && `overflow:auto;`}
  `};

  ${(props) => media.desktop`
    max-width: ${props.noSideBar ? '1720px' : '1400px'};
  `};
`;

const Paper: React.FC<Props> = (props: Props) => {
  return (
    <CSSTransition in timeout={200} mountOnEnter appear>
      {(state) => <Section state={state} {...props} />}
    </CSSTransition>
  );
};

export default Paper;
