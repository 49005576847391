import * as React from 'react';
import { CSSTransition } from 'react-transition-group';
import styled, { css } from '../../config/theme';
import { AnimationProps } from '../../config/theme/types';

type Props = {
  block?: boolean;
  left?: boolean;
};

const ErrorText = styled.span<Props & AnimationProps>`
  margin-top: 4px;
  display: flex;
  flex-basis: 100%;
  ${(props) =>
    props.block
      ? css`
          text-align: right;
          justify-content: flex-end;
        `
      : props.left
      ? css`
          text-align: left;
          justify-content: flex-start;
        `
      : css`
          justify-content: flex-end;
          position: absolute;
          right: 0;
          bottom: 0;
        `}

  font-size: 78%;
  color: ${(props) => props.theme.form.input.errorColor};

  ${(props) => props.state === 'entering' && props.theme.form.input.animation.entering};
  ${(props) => props.state === 'entered' && props.theme.form.input.animation.entered};
`;

const TransitionedError: React.FC<Props> = (props: Props) => (
  <CSSTransition in timeout={100} mountOnEnter appear>
    {(state) => <ErrorText state={state} data-cy="error-text" {...props} />}
  </CSSTransition>
);

export { TransitionedError as ErrorText };
