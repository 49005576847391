import { TenantTheme } from '../tenants/unbrace';

const cmsTheme = (theme: TenantTheme) => ({
  background: `${theme.palette.neutral.shade0}`,
  color: `${theme.palette.neutral.shade9}`,

  detail: {
    background: {
      image: theme.palette.neutral.shade1,
      language: theme.palette.neutral.shade2,
      languageActive: theme.palette.primary.shade2,
      languageActiveHover: theme.palette.primary.shade3,
      languageError: theme.palette.danger.shade1,
      languageErrorHover: theme.palette.danger.shade2,
      languageHover: theme.palette.neutral.shade3,
    },
    border: `1px solid ${theme.palette.neutral.shade3}`,
    borderLeft: `4px solid ${theme.palette.neutral.shade2}`,
    boxShadow: `inset 0px 1px 1px ${theme.palette.neutral.shade3}`,
    color: {
      error: theme.palette.danger.shade4,
      language: theme.palette.neutral.shade7,
      languageActive: theme.palette.primary.shade8,
      primary: theme.palette.neutral.shade9,
      quaternary: theme.palette.neutral.shade3,
      secondary: theme.palette.neutral.shade5,
      tertiary: theme.palette.neutral.shade4,
    },
    fontSize: {
      content: {
        arrayTitle: theme.typography.fontSize.regular,
        objectTitle: theme.typography.fontSize.medium_2,
      },
      header: {
        title: theme.typography.fontSize.medium_6,
      },
    },
  },

  sidebar: {
    background: {
      active: theme.palette.primary.shade1,
      hover: theme.palette.neutral.shade1,
    },
    border: {
      item: `1px solid ${theme.palette.neutral.shade2}`,
      right: `solid 2px ${theme.palette.neutral.shade2}`,
    },
    color: {
      title: theme.palette.neutral.shade4,
    },
    fontSize: {
      item: theme.typography.fontSize.regular,
      title: theme.typography.fontSize.small_4,
    },
    fontWeight: {
      title: '500',
    },
    letterSpacing: {
      title: '0.1em',
    },
    padding: '15px',
  },
});

export default cmsTheme;
