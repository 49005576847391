import * as React from 'react';
import logoSrc from '../../assets/images/logo/brand.svg';
import logoSmallSrc from '../../assets/images/logo/brand_small.svg';
import styled from '../../config/theme';

type Props = {
  small?: boolean;
};

const LogoContainer = styled('img')<Props>`
  width: ${(props) => (props.small ? '100%' : '70%')};
`;

const Logo: React.FC<Props> = (props: Props): JSX.Element => (
  <LogoContainer src={props.small ? logoSmallSrc : logoSrc} {...props} />
);

export default Logo;
