import { TextWrap } from '@unbrace/components';
import * as React from 'react';
import { ChangeEvent } from 'react';
import styled from '../../config/theme';
import { ErrorText } from './ErrorText';
import { StyledInput } from './Input';

type Props = {
  error?: string;
  label?: string;
  name: string;
  disabled?: boolean;
  checked?: boolean;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  id?: string;
  textWrap?: boolean;
};

const RadioButton: React.FC<Props> = ({ error, name, label, id, textWrap, ...props }: Props) => {
  return (
    <RadioContainer disabled={props.disabled}>
      <StyledInput name={name} id={id} {...props} />
      {textWrap ? (
        <TextWrap>
          <label htmlFor={id}>{label}</label>
        </TextWrap>
      ) : (
        <label htmlFor={id}>{label}</label>
      )}
      {error && <ErrorText>{error}</ErrorText>}
    </RadioContainer>
  );
};

const RadioContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: left;
  align-self: flex-start;
  position: relative;

  label {
    margin-left: 40px;
    position: relative;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    user-select: none;

    &:before {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      background: ${(props) => props.theme.form.input.background};
      border-radius: 50%;
      border: ${(props) => props.theme.form.checkbox.border};
      left: -40px;
      top: 50%;
      transform: translateY(-50%);
    }

    &:after {
      content: '';
      position: absolute;
      left: -34px;
      top: 50%;
      width: 8px;
      height: 8px;
      border: 4px solid transparent;
      border-radius: 50%;
      transform: translateY(-50%);
      transition: border ${(props) => props.theme.form.input.transition};
    }
  }

  input {
    display: none;

    &:checked {
      + span > label {
        color: ${(props) => props.theme.form.checkbox.checkedColor};

        &::before,
        &::after {
          border-color: ${(props) => props.theme.form.checkbox.checkedColor};
        }
      }
      + label {
        color: ${(props) => props.theme.form.checkbox.checkedColor};

        &::before,
        &::after {
          border-color: ${(props) => props.theme.form.checkbox.checkedColor};
        }
      }
    }
  }
`;

export default RadioButton;
