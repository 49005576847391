import * as React from 'react';
import { ArrayHelpers } from 'formik';
import { MetaData } from 'types/metadata';
import useMetaData from './useMetadata';

const useArrayMetaData = (entityMetaData: MetaData[], initialActive?: MetaData[]) => {
  const [metaData, metaDataActions] = useMetaData(entityMetaData, initialActive);
  const [metaDataArrayHelpers, setMetaDataArrayHelpers] = React.useState<ArrayHelpers | null>(null);

  const handleAddMetaData = React.useCallback(
    (newMetaData: MetaData) => () => {
      if (metaDataArrayHelpers) {
        metaDataArrayHelpers.push(newMetaData);
        metaDataActions.toggleMetaData(newMetaData.metadataId.toString());
      }
    },
    [metaDataArrayHelpers, metaDataActions]
  );

  return { metaData, metaDataActions, setMetaDataArrayHelpers, handleAddMetaData };
};

export default useArrayMetaData;
