import SelectComp, { Props as ReactSelectProps } from 'react-select';
import Async from 'react-select/async';
import { withTheme } from '../../config/theme';
import { RootTheme } from '../../config/theme/types';

type Props<T> = ReactSelectProps<T> & {
  async?: boolean;
  error?: string;
  loadOptions: (inputValue: string) => Promise<any>;
  name?: string;
  onBlur?: any;
  options: T[];
  theme: RootTheme;
  value?: T;
  zIndex?: number;
};

export type Option = {
  label: string;
  value: string;
};
export type OptionNumber = {
  label: string;
  value: number;
};

const Select = <T extends { label: string; value: string }>({
  theme,
  options,
  async,
  loadOptions,
  defaultOptions,
  zIndex,
  ...props
}: Props<T>) => {
  const { control, option } = theme.form.select;
  const customStyles = {
    control: (provided: any, state: any) => {
      const { ...defaultStyles } = provided;

      return {
        ...defaultStyles,
        ...control.regularStyles,
        background: state.isDisabled ? control.stateStyles.backgroundDisabled : control.regularStyles.background,
        border: props.error
          ? control.stateStyles.borderError
          : state.isFocused
          ? control.stateStyles.borderFocus
          : control.stateStyles.border,
        '&:hover': {
          border: control.stateStyles.border,
        },
        boxShadow: state.isFocused
          ? props.error
            ? control.stateStyles.boxShadowFocusError
            : control.stateStyles.boxShadowFocus
          : control.stateStyles.boxShadow,
        cursor: state.isDisabled ? 'not-allowed' : 'default',
        padding: '1px 5px',
        height: props.isMulti && 'unset',
      };
    },
    container: (provided: any) => ({
      ...provided,
      pointerEvents: 'all',
      zIndex,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    clearIndicator: (provided: any) => ({
      ...provided,
      paddingRight: 0,
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: 5,
    }),
    option: (provided: any, state: any) => {
      const { stateStyles } = option;

      return {
        ...provided,
        ...option.regularStyles,
        background: state.isSelected
          ? stateStyles.backgroundActive
          : state.isFocused
          ? stateStyles.backgroundHover
          : stateStyles.background,
        color: state.isSelected
          ? stateStyles.colorActive
          : state.isDisabled
          ? stateStyles.colorDisabled
          : stateStyles.color,
        cursor: state.isDisabled ? 'not-allowed' : 'default',
      };
    },
  };

  return async ? (
    <Async
      cacheOptions
      defaultOptions={defaultOptions || true}
      loadOptions={loadOptions}
      styles={customStyles}
      {...props}
    />
  ) : (
    <SelectComp styles={customStyles} options={options} {...props} />
  );
};

export default withTheme(Select);
