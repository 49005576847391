import * as React from 'react';
import styled from 'styled-components';
import BaseSvgWrapper from './BaseSvgWrapper';

type Props = {
  reverse?: boolean;
} & React.HTMLAttributes<HTMLSpanElement>;

const CreditNote: React.FC<Props> = ({ reverse, ...rest }: Props): JSX.Element => (
  <BaseSvgWrapper {...rest}>
    {reverse ? (
      <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
        <path d="M44.9405 6.66882C44.9405 3.02073 41.9249 0 38.2768 0H7.66378C4.01565 0 1 3.02073 1 6.66882V42.4462C1 46.0943 4.01545 49.11 7.66378 49.11H36.6009V43.9456H7.66378C6.79015 43.9456 6.16948 43.3199 6.16948 42.4462V6.66882C6.16948 5.79518 6.78996 5.16947 7.66378 5.16947H38.2768C39.1506 5.16947 39.7711 5.79518 39.7711 6.66882V27.1038H44.9405V6.66882Z" />
        <path d="M28.0489 30.9361V28.3513H11.2582V30.9361H28.0489Z" />
        <path d="M25.1339 38.6903V36.1055H11.2582V38.6903H25.1339Z" />
        <path d="M21.5316 10.243V7.67344H24.1162V10.2531L26.7161 10.2633L26.706 12.848L20.8954 12.8227H20.8903C20.0984 12.8129 19.6154 13.0267 19.3404 13.2619C19.0655 13.4971 18.9467 13.7568 18.9467 14.1302C18.9467 14.8772 19.5274 15.458 20.2744 15.458C21.6812 15.458 25.4186 15.3923 25.4186 15.3923H25.4439C27.5877 15.3923 29.3513 17.1559 29.3513 19.2997C29.3513 20.3716 28.8814 21.4236 28.0489 22.1318C27.2163 22.84 26.0766 23.192 24.7876 23.192H24.1162V25.7666H21.5315V23.192H16.3973V20.6072H24.808C25.6106 20.6134 26.1018 20.3931 26.378 20.1579C26.6541 19.923 26.7667 19.6682 26.7667 19.2947C26.7667 18.5477 26.191 17.972 25.444 17.972C25.4405 17.972 25.3828 17.973 25.2813 17.9747C24.5527 17.9871 21.572 18.0376 20.2746 18.0376C18.1308 18.0376 16.3621 16.269 16.3621 14.1252C16.3621 13.0533 16.8289 12.0034 17.6595 11.2931C18.4879 10.5846 19.6277 10.2071 20.9107 10.2329L20.9207 10.238L21.5316 10.243Z" />
        <path d="M32.2208 31.0532L35.6258 27.6481L37.513 29.5362L32.5819 34.4672L35.0431 36.9284C36.3968 38.2821 37.5043 39.3978 37.5043 39.4078C37.5043 39.4177 37.0816 39.8485 36.5649 40.3651L35.6256 41.3043L32.2206 37.8993C30.3478 36.0266 28.8156 34.4862 28.8156 34.4762C28.8156 34.4663 30.3479 32.926 32.2208 31.0532Z" />
        <path d="M32.2738 33.0556H48.1104V35.7594H32.2738V33.0556Z" />
      </svg>
    ) : (
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.89516 3.73454C2.89516 1.69161 4.58392 0 6.62687 0H23.7701C25.8131 0 27.5019 1.69161 27.5019 3.73454V23.7699C27.5019 25.8128 25.8132 27.5016 23.7701 27.5016H7.56535V24.6095H23.7701C24.2594 24.6095 24.6069 24.2591 24.6069 23.7699V3.73454C24.6069 3.2453 24.2595 2.89491 23.7701 2.89491H6.62687C6.13753 2.89491 5.79006 3.2453 5.79006 3.73454V15.1781H2.89516V3.73454Z" />
        <path d="M9.19334 17.3242V15.8767H21.7573V17.3242H9.19334Z" />
        <path d="M13.9869 21.6666V20.2191H21.7573V21.6666H13.9869Z" />
        <path d="M14.52 5.7361V4.29713H15.9674V5.74176L17.4233 5.74742L17.4177 7.19487L14.1638 7.18073H14.1609C13.7174 7.17523 13.4469 7.29497 13.293 7.42668C13.139 7.5584 13.0724 7.70378 13.0724 7.91294C13.0724 8.33125 13.3977 8.65645 13.816 8.65645C14.6038 8.65645 16.6967 8.6197 16.6967 8.6197H16.7109C17.9114 8.6197 18.8991 9.60733 18.8991 10.8078C18.8991 11.4081 18.6359 11.9972 18.1697 12.3938C17.7034 12.7904 17.0652 12.9875 16.3434 12.9875H15.9674V14.4293H14.52V12.9875H11.6448V11.5401H16.3548C16.8042 11.5435 17.0793 11.4202 17.234 11.2884C17.3886 11.1569 17.4517 11.0142 17.4517 10.805C17.4517 10.3867 17.1293 10.0643 16.711 10.0643C16.709 10.0643 16.6767 10.0649 16.6199 10.0658C16.2119 10.0728 14.5427 10.1011 13.8161 10.1011C12.6156 10.1011 11.6251 9.11063 11.6251 7.91011C11.6251 7.30985 11.8865 6.72189 12.3517 6.32413C12.8155 5.92739 13.4539 5.71598 14.1723 5.73045L14.1779 5.73327L14.52 5.7361Z" />
        <path d="M2.46697 19.0698L4.37381 17.163L5.4306 18.2203L2.66921 20.9816L4.04748 22.3599C4.80554 23.118 5.42574 23.7428 5.42574 23.7483C5.42574 23.7539 5.18903 23.9952 4.89971 24.2844L4.37368 24.8104L2.46687 22.9036C1.41812 21.8549 0.560058 20.9923 0.560059 20.9867C0.560058 20.9811 1.41819 20.1185 2.46697 19.0698Z" />
        <path d="M2.49669 20.1912H11.3651V21.7053H2.49669V20.1912Z" />
      </svg>
    )}
  </BaseSvgWrapper>
);

export default styled(CreditNote)`
  svg {
    fill: ${(props) => (props.reverse ? props.theme.palette.success.shade5 : props.theme.palette.danger.shade5)};
  }
`;
