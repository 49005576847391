import * as React from 'react';
import { Route } from 'react-router';
import { loadScene } from 'components';
import ModuleRoutes from 'components/routing/ModuleRoutes';
import { Module } from '../../security/types/modules';
import { FILEIMPORT_MODULE_KEY } from '../constants/index';
import { FILEIMPORT_BASE_ROUTES } from './index';

type Props = {
  allowedModules: Module[];
};

const fileImportOverviewScene = loadScene('fileimports', 'FileImportScene');

const FileImportRoutes: React.FC<Props> = ({ allowedModules }: Props) => (
  <ModuleRoutes allowedModules={allowedModules} moduleKey={FILEIMPORT_MODULE_KEY}>
    <Route path={FILEIMPORT_BASE_ROUTES} exact component={fileImportOverviewScene} />
  </ModuleRoutes>
);

export default FileImportRoutes;
