import { ArrayHelpers, FormikErrors } from 'formik';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { EditableMetaData, FullViewBlock } from '../../components';
import { groupBy } from '../../helpers';
import { Group } from '../../helpers/groupBy';
import { MetaDataValue, UseMetaDataActions, MetaDataAccess } from '../../types/metadata';

type Props = {
  amountOfButtons?: number;
  arrayHelpers: ArrayHelpers;
  metaDatas: MetaDataValue[];
  setMetaDataArrayHelpers: (arrayHelpers: ArrayHelpers) => void;
  metaDataActions?: UseMetaDataActions;
  errors: FormikErrors<{
    [key: string]: any;
    metadatas: MetaDataValue[];
  }>;
};

const MetaDataInfo: React.FC<Props> = React.memo(
  ({ amountOfButtons, metaDatas, setMetaDataArrayHelpers, arrayHelpers, metaDataActions, errors }) => {
    React.useEffect(() => {
      setMetaDataArrayHelpers(arrayHelpers);
      // eslint-disable-next-line
    }, []);

    const { t } = useTranslation();
    const groupedMetaData = metaDatas.length > 0 ? (groupBy(metaDatas, 'category') as Group<MetaDataValue>) : [];

    return metaDatas.length > 0 ? (
      <React.Fragment>
        {Object.keys(groupedMetaData).map((key: string) =>
          groupedMetaData[key].filter((meta: MetaDataValue) => meta.accessibility !== MetaDataAccess.Hidden).length >
          0 ? (
            <FullViewBlock amountOfButtons={amountOfButtons || 2} key={key}>
              <h2 className="block-title">{key !== 'undefined' ? key : t('general.other')}</h2>
              <div className="block-data">
                {groupedMetaData[key].map((metaDataItem: MetaDataValue) =>
                  metaDataItem.accessibility === MetaDataAccess.Editable ? (
                    <EditableMetaData
                      arrayHelpers={arrayHelpers}
                      key={metaDataItem.metadataId}
                      metaData={metaDataItem}
                      index={metaDatas.findIndex(
                        // eslint-disable-next-line max-nested-callbacks
                        (metaData: MetaDataValue) => metaData.metadataId === metaDataItem.metadataId
                      )}
                      metaDataActions={metaDataActions}
                      errors={errors}
                    />
                  ) : null
                )}
              </div>
            </FullViewBlock>
          ) : null
        )}
      </React.Fragment>
    ) : null;
  }
);

export default MetaDataInfo;
