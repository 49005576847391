import { AlertType, AlertWithIdentifier } from '@unbrace/components';
import { Action } from 'redux';
import { getType } from 'typesafe-actions';
import { v4 as uuidv4 } from 'uuid';
import i18n from '../../../../config/i18n';
import { GenericAction, HttpErrorData } from '../../../../types';
import { alertsActions } from '../actions/creators';
import { userActions } from 'modules/security/redux/actions/creators';

export type AlertsState = AlertWithIdentifier[];

// eslint-disable-next-line
const alertsReducer = (state: AlertsState = [], action: Action): AlertsState => {
  switch (action.type) {
    case getType(alertsActions.createAlert):
      return [...state, { ...(action as ReturnType<typeof alertsActions.createAlert>).payload, id: uuidv4() }];
    case getType(alertsActions.createSuccessAlert):
      return [...state, { id: uuidv4(), message: i18n.t('alerts.success'), type: AlertType.Success }];
    case getType(alertsActions.removeAlert):
      return state.filter((alert) => alert.id !== (action as ReturnType<typeof alertsActions.removeAlert>).payload);

      return [...state, { id: uuidv4(), message: i18n.t('alerts.success'), type: AlertType.Success }];

    // Probably unused?
    case getType(userActions.fetchUser.failure):
      return state;

    case getType(alertsActions.setAlert):
      const { payload } = action as GenericAction;

      if (payload && payload.data && payload.data.errorMessage === 'could not get user') {
        return state;
      }

      if (payload && payload.data && payload.data.statusCode && payload.data.statusCode === 400) {
        const { errorMessage, fieldErrors } = payload.data as HttpErrorData;
        if (fieldErrors) {
          const errorAlerts: AlertWithIdentifier[] = [];
          Object.keys(fieldErrors).forEach((key) =>
            errorAlerts.push({
              id: uuidv4(),
              message: `${key}${key ? ': ' : ''}${i18n.t(`errors:errorPrefix`)} ${i18n.t(
                `errors:${fieldErrors[key]}`
              )}`,
              type: AlertType.Danger,
            })
          );

          return [...state, ...errorAlerts];
        }

        return [
          ...state,
          {
            id: uuidv4(),
            message: i18n.t(`errors:errorPrefix`) + i18n.t(`errors:${errorMessage}`),
            type: AlertType.Danger,
          },
        ];
      }

      return [...state, { id: uuidv4(), message: i18n.t('alerts.error'), type: AlertType.Danger }];
    default:
      return state;
  }
};

export default alertsReducer;
