import * as React from 'react';
import { useToggle } from '@unbrace/hooks';
import { useTranslation } from 'react-i18next';
import { FilterContent } from 'components/Filter';
import { FilterContainer, OpenFilterButton } from './styles';
import ResetFilters from 'modules/app/components/Sidebar/ResetFilters';

type Props = {
  filterCount?: number;
  children: React.ReactNode | React.ReactNode[];
  hasReset?: boolean;
  moduleKey: string;
};

const FilterMenu: React.FC<Props> = ({ filterCount = 0, children, hasReset, moduleKey }) => {
  const { t } = useTranslation();
  const [isOpen, toggleOpen] = useToggle(false);

  return (
    <FilterContainer>
      {isOpen && (
        <React.Fragment>
          <FilterContent>
            {children}
            {hasReset && <ResetFilters moduleKey={moduleKey} fixedUnderFilters />}
          </FilterContent>
        </React.Fragment>
      )}
      <OpenFilterButton hasActiveFilters={filterCount > 0} onClick={toggleOpen}>
        <span>{isOpen ? t('filters.hideFilters') : t('filters.showFilters')}</span>
        {filterCount > 0 && <span className="pill">{filterCount}</span>}
      </OpenFilterButton>
    </FilterContainer>
  );
};

export default FilterMenu;
