import * as React from 'react';
import { ContentLoader, SkeletonGenerator } from '../../../components';

const ProductInfoLoadingState: React.FC = () => (
  <section style={{ maxWidth: 600 }}>
    <SkeletonGenerator count={1}>
      <ContentLoader height={94}>
        <rect x="0" y="4" rx="5" ry="5" width="200" height="13" />
        <rect x="0" y="28" rx="5" ry="5" width="90" height="10" />
        <rect x="0" y="48" rx="5" ry="5" width="110" height="8" />
        <rect x="0" y="68" rx="10" ry="10" width="75" height="20" />
        <rect x="80" y="68" rx="10" ry="10" width="75" height="20" />
      </ContentLoader>
    </SkeletonGenerator>
  </section>
);

export default ProductInfoLoadingState;
