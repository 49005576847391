import * as React from 'react';
import { ModuleEditAccessProvider as ExternalModuleEditAccessProvider } from '@unbrace/helpers-micro-package';

type EditableModulesContextType = {
  hasEditFunctionality: (module: string) => boolean;
};

export const EditableModulesContext = React.createContext<EditableModulesContextType>({
  hasEditFunctionality: (module: string) => false,
});

type ModuleEditAccessContextType = {
  canEdit: boolean;
  canEditOnModule: (module: string) => boolean;
};

export const ModuleEditAccessContext = React.createContext<ModuleEditAccessContextType>({
  canEdit: false,
  canEditOnModule: (module: string) => false,
});

type Props = {
  module: string;
  children: JSX.Element;
};

export const ModuleEditAccessProvider: React.FC<Props> = ({ module, children }) => (
  <EditableModulesContext.Consumer>
    {({ hasEditFunctionality }) => (
      <ModuleEditAccessContext.Provider
        value={{
          canEdit: hasEditFunctionality(module),
          canEditOnModule: hasEditFunctionality,
        }}
      >
        <ExternalModuleEditAccessProvider module={module} hasEditFunctionality={hasEditFunctionality}>
          {children}
        </ExternalModuleEditAccessProvider>
      </ModuleEditAccessContext.Provider>
    )}
  </EditableModulesContext.Consumer>
);
