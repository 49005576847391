import styled from '../../../../config/theme';

const IconButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export default IconButtonWrapper;
