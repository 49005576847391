import * as React from 'react';
import { MultiSelectActions } from 'hooks/useMultiSelect';
import { Paper } from '../../../components';
import PageWrapper from '../../app/components/PageWrapper';
import { ProductInfo } from '../components';
import { Pricing, PricingGroups } from '../types/pricing';
import { Product } from '../types/product';
import usePricingStore from '../services/usePricingStore';
import ProductPricing from './ProductPricing';

type Props = {
  baseHeader: JSX.Element;
  pricing?: PricingGroups;
  pricingIsPending: boolean;
  product?: Product;
  productIsPending: boolean;
  selectedPricing: Pricing[];
  selectedPricingActions: MultiSelectActions;
};

type InitialPriceState = {
  changedPrices: ChangedPrices;
  priceChanged: boolean;
};

export type ChangedPrices = {
  [key: number]: Pricing;
};

const ProductDefaultView: React.FC<Props> = ({
  baseHeader,
  pricing,
  pricingIsPending,
  product,
  productIsPending,
  selectedPricing,
  selectedPricingActions,
}) => {
  const initialPriceState: InitialPriceState = {
    changedPrices: {},
    priceChanged: false,
  };

  React.useEffect(() => () => handleCancel(), []);

  const changedPricingReducer = (pricingState: InitialPriceState, action: { type: string; payload?: Pricing }) => {
    switch (action.type) {
      case 'RESET':
        return {
          changedPrices: {},
          priceChanged: false,
        };

      case 'REMOVE':
        if (action.payload && action.payload.priceId) {
          const { [action.payload.priceId]: deleted, ...withoutDeletedKey } = pricingState.changedPrices;

          return {
            changedPrices: {
              ...withoutDeletedKey,
            },
            priceChanged: Object.entries(withoutDeletedKey).length > 0,
          };
        }

        return pricingState;

      case 'CHANGE':
        if (action.payload && action.payload.priceId) {
          return {
            changedPrices: {
              ...pricingState.changedPrices,
              [action.payload.priceId]: {
                ...action.payload,
              },
            },
            priceChanged: true,
          };
        }

        return pricingState;

      default:
        return pricingState;
    }
  };

  const [state, dispatch] = React.useReducer(changedPricingReducer, initialPriceState);
  const { updatePricing, deletePricing, isUpdatePricingLoading } = usePricingStore();

  const handleFormChange = (values: Pricing) => {
    dispatch({ type: 'CHANGE', payload: values });
  };

  const handleCancel = () => {
    dispatch({ type: 'RESET' });
  };

  const handleSave = () => {
    const changedPricesValues = Object.values(state.changedPrices);
    changedPricesValues.forEach((value: Pricing, index: number) => {
      updatePricing(value, () => {
        if (index === changedPricesValues.length - 1) {
          dispatch({ type: 'RESET' });
        }
      });
    });
  };

  const handleDelete = (price: Pricing) => {
    if (price.priceId) {
      dispatch({ type: 'REMOVE', payload: price });
      deletePricing(price.priceId);
    }
  };

  return (
    <PageWrapper>
      {React.cloneElement(baseHeader, {
        handleCancel,
        handleSave,
        hasChanges: state.priceChanged,
        productGuid: product?.productGuid,
        isSaving: isUpdatePricingLoading,
      })}
      <Paper multiple>
        <ProductInfo
          code={product?.code}
          productIsPending={productIsPending}
          priceMethodDescription={product?.priceMethodDescription}
          productDescription={
            product?.translations && product?.translations.length > 0 ? product?.translations[0].description : ''
          }
          productStatusDescription={product?.productStatusDescription}
          productStatusId={product?.productStatusId}
          productTypeDescription={product?.productTypeDescription}
        />
      </Paper>
      <ProductPricing
        changedPrices={state.changedPrices}
        handleDelete={handleDelete}
        handleFormChange={handleFormChange}
        priceChanged={state.priceChanged}
        pricing={pricing}
        pricingIsPending={pricingIsPending}
        selectedPricing={selectedPricing}
        selectedPricingActions={selectedPricingActions}
        productIsPending={productIsPending}
      />
    </PageWrapper>
  );
};

export default ProductDefaultView;
