import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const AddToList: React.FC = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
      <path d="M30.9285 35.1715H20.611C20.3358 35.1715 20.1 34.9356 20.1 34.6605V29.4395C20.1 29.1644 20.3358 28.9285 20.611 28.9285H30.9285V18.611C30.9285 18.3358 31.1644 18.1 31.4395 18.1H36.6605C36.9356 18.1 37.1715 18.3358 37.1715 18.611V28.9285H47.489C47.7642 28.9285 48 29.1644 48 29.4395V34.6605C48 34.8007 47.9522 34.9109 47.8774 34.9908C47.7951 35.0982 47.6656 35.1715 47.489 35.1715H37.1715V45.489C37.1715 45.7642 36.9356 46 36.6605 46H31.4395C31.1644 46 30.9285 45.7642 30.9285 45.489V35.1715Z" />
      <path d="M3 34.75C3 34.9985 3.20147 35.2 3.45 35.2H15.15C15.3985 35.2 15.6 34.9985 15.6 34.75V29.35C15.6 29.1015 15.3985 28.9 15.15 28.9H3.45C3.20147 28.9 3 29.1015 3 29.35V34.75Z" />
      <path d="M3 23.95C3 24.1985 3.20147 24.4 3.45 24.4H25.95C26.1985 24.4 26.4 24.1985 26.4 23.95V18.55C26.4 18.3015 26.1985 18.1 25.95 18.1H3.45C3.20147 18.1 3 18.3015 3 18.55V23.95Z" />
      <path d="M41.7 23.95C41.7 24.1985 41.9015 24.4 42.15 24.4H47.55C47.7985 24.4 48 24.1985 48 23.95V18.55C48 18.3015 47.7985 18.1 47.55 18.1H42.15C41.9015 18.1 41.7 18.3015 41.7 18.55V23.95Z" />
      <path d="M3 13.15C3 13.3985 3.20147 13.6 3.45 13.6H47.55C47.7985 13.6 48 13.3985 48 13.15V7.75C48 7.50147 47.7985 7.3 47.55 7.3H3.45C3.20147 7.3 3 7.50147 3 7.75V13.15Z" />
    </svg>
  </BaseSvgWrapper>
);

export default AddToList;
