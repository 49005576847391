import * as Yup from 'yup';
import { PricingFormValues } from '../types/pricing';
import i18n from '../../../config/i18n';

const productPriceSchema: Yup.ObjectSchema<PricingFormValues> = Yup.object().shape({
  amount: Yup.number()
    .min(0, () => i18n.t('validation:number.positive'))
    .required(),
  dateFrom: Yup.date().typeError(() => i18n.t('validation:date')),
  dateUntil: Yup.date().typeError(() => i18n.t('validation:date')),
  priceTypeId: Yup.number().required(() => i18n.t('products:pricing.pricingTypeRequired')),
  quantityFrom: Yup.number().positive(),
  quantityUntil: Yup.number().positive().moreThan(Yup.ref('quantityFrom')),
  priceTranslation: Yup.object()
    .shape({
      description: Yup.string(),
      billingPlanId: Yup.number(),
      termDurationId: Yup.number(),
      priceTranslationId: Yup.number(),
    })
    .nullable(),
});

export default productPriceSchema;
