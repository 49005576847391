import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const Mails: React.FC = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 32 32"
      enableBackground="new 0 0 32 32"
      xmlSpace="preserve"
    >
      <g display="none">
        <rect x="-130.995" y="-132.25" display="inline" width="411.25" height="211.25" />
      </g>
      <g display="none">
        <path
          display="inline"
          strokeMiterlimit="10"
          d="M2.469,5h27.063C30.614,5,31.5,5.886,31.5,6.969   v18.063c0,1.083-0.886,1.969-1.969,1.969H2.469C1.386,27,0.5,26.114,0.5,25.031V6.969C0.5,5.886,1.386,5,2.469,5z"
        />
        <polyline display="inline" strokeMiterlimit="10" points="0.995,6 15.995,18 30.995,6  " />
        <line display="inline" strokeMiterlimit="10" x1="30.906" y1="26.375" x2="20.406" y2="14.25" />
        <line display="inline" strokeMiterlimit="10" x1="1.094" y1="26.375" x2="11.594" y2="14.25" />
        <g display="inline">
          <g>
            <line strokeMiterlimit="10" x1="10.365" y1="20.886" x2="21.635" y2="20.886" />
            <line strokeMiterlimit="10" x1="13.328" y1="24.448" x2="18.672" y2="24.448" />
          </g>
        </g>
      </g>
      <g display="none">
        <g display="inline">
          <path d="M29.531,5.5C30.341,5.5,31,6.159,31,6.968v18.063c0,0.81-0.659,1.469-1.469,1.469H2.469C1.659,26.5,1,25.841,1,25.031    V6.968C1,6.159,1.659,5.5,2.469,5.5H29.531 M29.531,5H2.469C1.386,5,0.5,5.886,0.5,6.968v18.063C0.5,26.114,1.386,27,2.469,27    h27.063c1.083,0,1.969-0.886,1.969-1.969V6.968C31.5,5.886,30.614,5,29.531,5L29.531,5z" />
        </g>
        <polyline display="inline" strokeWidth="0.5" strokeMiterlimit="10" points="0.995,6 15.995,18    30.995,6  " />
        <line display="inline" strokeWidth="0.5" strokeMiterlimit="10" x1="30.906" y1="26.375" x2="20.406" y2="14.25" />
        <line display="inline" strokeWidth="0.5" strokeMiterlimit="10" x1="1.094" y1="26.375" x2="11.594" y2="14.25" />
        <g display="inline">
          <line strokeWidth="0.5" strokeMiterlimit="10" x1="10.365" y1="20.886" x2="21.635" y2="20.886" />
          <line strokeWidth="0.5" strokeMiterlimit="10" x1="13.328" y1="24.448" x2="18.672" y2="24.448" />
        </g>
      </g>
      <g>
        <g>
          <path d="M29.76,4.814H2.24c-1.101,0-2.002,0.901-2.002,2.002v18.369c0,1.101,0.901,2.002,2.002,2.002H29.76    c1.101,0,2.002-0.901,2.002-2.002V6.816C31.762,5.715,30.861,4.814,29.76,4.814z M15.994,16.72L3.613,6.814h24.763L15.994,16.72z     M10.04,14.517l-7.802,9.01l0.002-15.25L10.04,14.517z M11.603,15.767l4.392,3.513l4.399-3.519l8.161,9.424L3.448,25.184    L11.603,15.767z M21.956,14.512l7.807-6.245l-0.002,15.258L21.956,14.512z" />
          <rect x="10.365" y="20.849" width="11.271" height="2" />
        </g>
      </g>
      <g display="none">
        <path
          display="inline"
          d="M29.532,4.5H2.468C1.108,4.5,0,5.607,0,6.969v18.062C0,26.393,1.108,27.5,2.468,27.5h27.063   c1.361,0,2.469-1.107,2.469-2.469V6.969C32,5.607,30.893,4.5,29.532,4.5z M29.532,26.5H2.468c-0.244,0-0.47-0.065-0.673-0.171   l9.744-11.252l4.455,3.564l4.462-3.57l9.749,11.258C30.002,26.434,29.776,26.5,29.532,26.5z M15.994,17.359L1.567,5.818   C1.817,5.622,2.127,5.5,2.468,5.5h27.063c0.339,0,0.647,0.12,0.896,0.313L15.994,17.359z"
        />
        <rect x="10.365" y="20.386" display="inline" width="11.271" height="1" />
        <rect x="13.328" y="23.947" display="inline" width="5.343" height="1" />
      </g>
    </svg>
  </BaseSvgWrapper>
);

export default Mails;
