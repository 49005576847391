import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const Contract: React.FC = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 128 128"
      xmlSpace="preserve"
    >
      <g>
        <path d="M105.5,96.4c-3.1,0.9-6.1,1-8.8,0.3c-1.1-0.3-2.1-1-3.3-1.7c-1.6-1-3.5-2.2-5.7-2.3c-1.8-0.1-3.6,0.7-4.8,2   c-1.2,1.3-1.8,3-1.6,4.7c0.1,0.8,0.7,1.4,1.5,1.4c0,0,0.1,0,0.2,0c0.8-0.1,1.4-0.8,1.3-1.6c-0.1-0.9,0.2-1.7,0.8-2.4   c0.7-0.7,1.6-1.1,2.6-1.1c1.4,0,2.8,0.9,4.3,1.8c1.3,0.8,2.6,1.6,4.1,2c3.2,0.9,6.8,0.8,10.4-0.3c0.8-0.2,1.3-1.1,1-1.9   C107.1,96.7,106.3,96.2,105.5,96.4z" />
        <path d="M84.6,81.7c0-1.5-1.2-2.7-2.7-2.7H59c-1.5,0-2.7,1.2-2.7,2.7c0,1.5,1.2,2.7,2.7,2.7h22.8C83.4,84.4,84.6,83.1,84.6,81.7z" />
        <path d="M104.7,65.7H59c-1.5,0-2.7,1.2-2.7,2.7c0,1.5,1.2,2.7,2.7,2.7h45.7c1.5,0,2.7-1.2,2.7-2.7   C107.4,66.9,106.2,65.7,104.7,65.7z" />
        <path d="M56.3,55.1c0,1.5,1.2,2.7,2.7,2.7h45.7c1.5,0,2.7-1.2,2.7-2.7s-1.2-2.7-2.7-2.7H59C57.5,52.4,56.3,53.6,56.3,55.1z" />
        <path d="M10.4,14.4L10.4,14.4c-4.9,0-8.9,4-8.9,8.9V99c0,1.9,0.6,3.7,1.6,5.3l5.8,8.7v2.6c0,0.8,0.7,1.5,1.5,1.5   c0.4,0,0.8-0.2,1-0.4c0.3-0.3,0.4-0.6,0.4-1l0-2.6l5.8-8.7c1.1-1.6,1.6-3.4,1.6-5.3V23.3C19.3,18.4,15.3,14.4,10.4,14.4z" />
        <path d="M26,22.5c-1.2,0-2.2,1-2.2,2.2v37.1c0,1.2,1,2.2,2.2,2.2c1.2,0,2.2-1,2.2-2.2V24.7C28.2,23.5,27.2,22.5,26,22.5z" />
        <path d="M125.4,34.1l-22-22c-0.7-0.7-1.7-1.2-2.8-1.2H44.3c-3.9,0-7.1,3.2-7.1,7.1v59.1v6.4V110c0,3.9,3.2,7.1,7.1,7.1h75.1   c3.9,0,7.1-3.2,7.1-7.1V36.9C126.5,35.8,126.1,34.8,125.4,34.1z M118.6,109.2H45.1v-18v-6.3V18.8h51.5v14.9c0,3.9,3.2,7.1,7.1,7.1   h14.9V109.2z" />
      </g>
    </svg>
  </BaseSvgWrapper>
);

export default Contract;
