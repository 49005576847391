import { SubmitButton } from '@unbrace/components';
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../components';
import { loginSchema } from '../schemas/loginSchema';
import { LoginData, LoginFormValues } from '../types/login';

type Props = {
  isPending: boolean;
  handleSubmit: (formData: LoginData) => void;
  locationState: any;
};

const LoginForm: React.FC<Props> = ({ handleSubmit, locationState, isPending }: Props) => {
  const { t } = useTranslation('security');

  return (
    <Formik
      initialValues={{ password: '', username: '' }}
      validationSchema={loginSchema}
      validateOnChange={false}
      onSubmit={(values: LoginFormValues) =>
        handleSubmit({
          password: values.password,
          returnUrl: (locationState && locationState.referrer) || '/',
          username: values.username,
        })
      }
    >
      {({ touched, errors, isSubmitting }: FormikProps<LoginFormValues>) => (
        <Form>
          <Field name="username">
            {({ field }: FieldProps) => (
              <Input
                {...field}
                error={(touched.username && errors.username) || ''}
                type="text"
                label={t('login_email')}
              />
            )}
          </Field>
          <Field name="password">
            {({ field }: FieldProps) => (
              <Input
                {...field}
                error={(touched.password && errors.password) || ''}
                type="password"
                label={t('login_password')}
              />
            )}
          </Field>
          <SubmitButton color="primary" block type="submit" isLoading={isPending && isSubmitting}>
            {t('login_submit')}
          </SubmitButton>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
