import BaseSvgWrapper from './BaseSvgWrapper';

const MultiEdit = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
      <path d="M41.9861 10.9363L35.0497 4L29.2695 9.78028L36.2058 16.7166L41.9861 10.9363Z" />
      <path d="M33.4561 19.5342L26.6488 12.7268L8.2568 31.1188L7 38.9165L15.0641 37.9262L33.4561 19.5342Z" />
      <path d="M44 22.9478L38.0522 17L33.0957 21.9565L39.0435 27.9043L44 22.9478Z" />
      <path d="M36.6857 30.3203L30.8486 24.4831L15.0777 40.254L14 46.9404L20.9149 46.0912L36.6857 30.3203Z" />
    </svg>
  </BaseSvgWrapper>
);

export default MultiEdit;
