import { Modal } from '@unbrace/components';
import * as React from 'react';
import useKeyShortcut from '../hooks/useKeyShortcut';
import ShortcutsModal from './ShortcutsModal';

type Props = {
  shortcutModalVisible: boolean;
  toggleShortcutModalVisibility: VoidFunction;
};

const OverviewModal: React.FC<Props> = ({ toggleShortcutModalVisibility, shortcutModalVisible }: Props) => {
  const { useShowAllShortcuts } = useKeyShortcut();
  useShowAllShortcuts(toggleShortcutModalVisibility);

  return (
    <Modal
      size={'large'}
      isVisible={shortcutModalVisible}
      content={ShortcutsModal}
      contentProps={{ toggleModal: toggleShortcutModalVisibility }}
    />
  );
};

export default OverviewModal;
