import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const Error: React.FC = (props: React.HTMLAttributes<HTMLSpanElement>): JSX.Element => (
  <BaseSvgWrapper {...props}>
    <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.3635 45.9712C19.1871 45.7545 15.0501 44.2652 11.704 41.7736C10.8047 41.104 10.1532 40.5382 9.30809 39.6931C7.53741 37.9224 6.21449 36.0654 5.14718 33.8522C2.28421 27.9157 2.28421 21.0855 5.14718 15.149C6.21449 12.9359 7.53741 11.0788 9.30809 9.30814C11.0788 7.53746 12.9358 6.21453 15.1489 5.14722C21.0855 2.28426 27.9157 2.28426 33.8522 5.14722C36.0653 6.21453 37.9224 7.53746 39.693 9.30814C41.4637 11.0788 42.7866 12.9359 43.8539 15.149C46.7169 21.0855 46.7169 27.9157 43.8539 33.8522C42.7866 36.0654 41.4637 37.9224 39.693 39.6931C38.2675 41.1186 36.8851 42.1794 35.1719 43.1623C31.6138 45.2038 27.4882 46.1851 23.3635 45.9712V45.9712ZM26.5386 40.5149C27.7174 40.3548 28.6972 40.1236 29.7753 39.7513C35.242 37.8629 39.2724 33.1993 40.3587 27.5052C40.5699 26.398 40.6145 25.8741 40.6145 24.5006C40.6145 23.1272 40.5699 22.6032 40.3587 21.4961C39.1139 14.9712 34.03 9.88729 27.5051 8.64247C26.398 8.43125 25.874 8.38664 24.5006 8.38664C23.1271 8.38664 22.6032 8.43125 21.496 8.64247C14.9712 9.88729 9.88724 14.9712 8.64242 21.4961C8.4312 22.6032 8.38659 23.1272 8.38659 24.5006C8.38659 25.8741 8.4312 26.398 8.64242 27.5052C9.9639 34.4318 15.6404 39.7247 22.6096 40.5284C23.4785 40.6286 23.4785 40.6286 24.7317 40.6141C25.6186 40.6039 26.0635 40.5794 26.5386 40.5149V40.5149ZM24.0887 35.7833C22.9954 35.5851 22.1313 34.6867 21.9465 33.556C21.8876 33.1955 21.8876 33.1186 21.9465 32.7581C22.1389 31.5811 23.0086 30.7113 24.1856 30.519C24.5462 30.4601 24.623 30.4601 24.9836 30.519C26.1606 30.7113 27.0303 31.5811 27.2227 32.7581C27.2816 33.1186 27.2816 33.1955 27.2227 33.556C27.0362 34.6968 26.1734 35.5865 25.0612 35.7848C24.6619 35.8559 24.4884 35.8557 24.0887 35.7833V35.7833ZM21.8952 21.0549V14.3314H24.5846H27.274V21.0549V27.7783H24.5846H21.8952V21.0549Z" />
    </svg>
  </BaseSvgWrapper>
);

export default Error;
