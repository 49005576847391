const areObjectsEqual = (object1: any, object2: any): boolean => {
  if (!object1 || !object2) {
    return false;
  }

  const filteredObject1 = Object.values(object1).filter((obj) => obj !== undefined && obj !== '');
  const filteredObject2 = Object.values(object2).filter((obj) => obj !== undefined && obj !== '');

  const keys1 = Object.keys(filteredObject1);
  const keys2 = Object.keys(filteredObject2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  if (JSON.stringify(filteredObject1) !== JSON.stringify(filteredObject2)) {
    return false;
  }

  return true;
};

export default areObjectsEqual;
