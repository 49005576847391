import * as React from 'react';
import { AccessibleModulesContext } from 'modules/security/context/AccessibleModules';

type Props = {
  moduleKey: string;
  // module key is the key received from the backend, the module name is the name of the directory in /src/modules/
  moduleName: string;
  // name of the component. Should be in the 'tiles' directory in one of the modules
  componentName: string;
  tileProps?: {
    [key: string]: any;
  };
};

const Tile: React.FC<Props> = React.memo(({ moduleKey, componentName, moduleName, tileProps }: Props) => {
  const modules = React.useContext(AccessibleModulesContext);

  const moduleAvailable = React.useMemo(
    () => modules.find((module) => module.toLowerCase() === moduleKey.toLowerCase()),
    [moduleKey, modules]
  );

  if (moduleAvailable) {
    const DynamicComponent = React.lazy(() => {
      if (process.env.NODE_ENV === 'development') {
        return import(/* webpackMode: "eager" */ `modules/${moduleName}/tiles/${componentName}`);
      }

      return import(/* webpackMode: "lazy" */ `modules/${moduleName}/tiles/${componentName}`);
    });

    return (
      <React.Suspense fallback={<div />}>
        <DynamicComponent {...tileProps} />
      </React.Suspense>
    );
  }

  return null;
});

export default Tile;
