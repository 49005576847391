export const load = () => {
  if ((process.env.REACT_APP_API_ROOT || '').includes('.int.')) {
    const element = document.createElement('div');
    element.style.position = 'fixed';
    element.style.top = '0px';
    element.style.left = '56%';
    element.style.padding = '8px 8px';
    element.style.fontSize = '16px';
    element.style.zIndex = '1000';
    element.style.color = '#FFFFFF';
    element.style.borderRadius = '0 0 5px 5px';
    element.style.boxShadow = '3px 3px 15px 0 rgba(0, 0, 0, 0.1)';
    element.innerHTML = '⚠️ TEST ENVIRONMENT ⚠️';
    element.style.background = '#91598C';

    document.getElementById('root')?.after(element);
  }
  if ((process.env.REACT_APP_API_ROOT || '').includes('.dev.')) {
    const element = document.createElement('div');
    element.style.position = 'fixed';
    element.style.top = '0px';
    element.style.left = '56%';
    element.style.padding = '8px 8px';
    element.style.fontSize = '16px';
    element.style.zIndex = '1000';
    element.style.color = '#FFFFFF';
    element.style.borderRadius = '0 0 5px 5px';
    element.style.boxShadow = '3px 3px 15px 0 rgba(0, 0, 0, 0.1)';
    element.innerHTML = '⚠️ TEST ENVIRONMENT ⚠️';
    element.style.background = '#597e91';

    document.getElementById('root')?.after(element);
  }
};
