const unbracePalette = {
  danger: {
    shade1: '#FEE4E3',
    shade2: '#F8C7C5',
    shade3: '#F78B87',
    shade4: '#DF5858',
    shade5: '#B22A2A',
    shade6: '#A01818',
    shade7: '#7F0D0D',
    shade8: '#5F0505',
    shade9: '#440404',
  },
  neutral: {
    shade0: '#ffffff',
    shade1: '#F5F7FD',
    shade2: '#ECF1F8',
    shade3: '#DFE4EB',
    shade4: '#AFB8C4',
    shade5: '#8892A0',
    shade6: '#686B82',
    shade7: '#484C60',
    shade8: '#2C353F',
    shade9: '#070b10',
  },
  primary: {
    shade0: '#E8FBFF',
    shade1: '#ECF9FE',
    shade2: '#DBF2FA',
    shade3: '#98CEE3',
    shade4: '#49A6CA',
    shade5: '#3184A5',
    shade6: '#226E8C',
    shade7: '#11485E',
    shade8: '#0A394B',
    shade9: '#082D3C',
    shade10: '#0093B2',
  },
  secondary: {
    shade1: '#ECF9FE',
    shade2: '#DBF2FA',
    shade3: '#98CEE3',
    shade4: '#49A6CA',
    shade5: '#3184A5',
    shade6: '#226E8C',
    shade7: '#11485E',
    shade8: '#0A394B',
    shade9: '#082D3C',
  },
  success: {
    shade1: '#E3FCEF',
    shade2: '#BCF7D7',
    shade3: '#8ADCB0',
    shade4: '#46C384',
    shade5: '#188A50',
    shade6: '#19663F',
    shade7: '#0A4D2B',
    shade8: '#033B1E',
    shade9: '#002B15',
  },
  warning: {
    shade1: '#FFFFD8',
    shade2: '#FFFAA5',
    shade3: '#FFF280',
    shade4: '#F6DE62',
    shade5: '#F6D636',
    shade6: '#F6C636',
    shade7: '#EEAE18',
    shade8: '#E09C16',
    shade9: '#C57600',
  },
};

if ((process.env.REACT_APP_API_ROOT || '').includes('.int.')) {
  unbracePalette.neutral = {
    shade0: '#F8F9F8',
    shade1: '#EFEBEF',
    shade2: '#E4DDE4',
    shade3: '#DBCDD7',
    shade4: '#D1BDCF',
    shade5: '#B290B0',
    shade6: '#91598C',
    shade7: '#6D506B',
    shade8: '#402C40',
    shade9: '#1B141A',
  };

  unbracePalette.primary = {
    shade0: '#E8FBFF',
    shade1: '#FFE9FF',
    shade2: '#F3CDFF',
    shade3: '#D599FF',
    shade4: '#814CB4',
    shade5: '#663399',
    shade6: '#52297a',
    shade7: '#3d1f5c',
    shade8: '#29143d',
    shade9: '#1f0f2e',
    shade10: '#0093B2',
  };
}

export { unbracePalette };
