import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const MicrosoftAgreement: React.FC = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25 43H43V26.8H25V43ZM7 43H23.2V26.8H7V43ZM25 25H43V7H25V25ZM7 25H23.2V7H7V25Z"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect x="7" y="7" width="36" height="36" />
        </clipPath>
      </defs>
    </svg>
  </BaseSvgWrapper>
);

export default MicrosoftAgreement;
