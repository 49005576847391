import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ModalContent } from '@unbrace/components';

type Props = {
  closeModal: (e: React.MouseEvent) => void;
  handleConfirmNavigationClick: (e: React.MouseEvent) => void;
};

const UnsavedChangesModal = ({ closeModal, handleConfirmNavigationClick }: Props) => {
  const { t } = useTranslation();

  return (
    <ModalContent>
      <ModalContent.Header>
        <h1>{t('unsavedChanges.title')}</h1>
      </ModalContent.Header>
      <ModalContent.Body>{t('unsavedChanges.description')}</ModalContent.Body>
      <ModalContent.Footer>
        <Button color="tertiary" onClick={closeModal}>
          {t('unsavedChanges.cancel')}
        </Button>
        <Button onClick={handleConfirmNavigationClick} color="danger" type="submit">
          {t('unsavedChanges.leavePage')}
        </Button>
      </ModalContent.Footer>
    </ModalContent>
  );
};

export default UnsavedChangesModal;
