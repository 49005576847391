import { TenantTheme } from '../tenants/unbrace';

const contractTheme = (theme: TenantTheme) => ({
  color: {
    primary: theme.palette.neutral.shade9,
    secondary: theme.palette.neutral.shade5,
    sidebar: {
      activeItem: {
        background: theme.palette.primary.shade1,
        icon: theme.palette.primary.shade4,
        text: theme.palette.primary.shade6,
      },
      nonActiveItem: {
        background: theme.palette.neutral.shade0,
        hoverBackground: theme.palette.neutral.shade1,
        icon: theme.palette.neutral.shade3,
        text: theme.palette.neutral.shade4,
      },
    },
  },
  fontSizes: {
    normal: theme.typography.fontSize.small_5,
    small: theme.typography.fontSize.small_4,
    title: theme.typography.fontSize.medium_6,
  },
});

export default contractTheme;
