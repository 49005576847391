import styled from '../../../../config/theme';

export const LogoWrapper = styled.div`
  position: absolute;
  top: 1rem;
  background: ${(props) => props.theme.internalSidebar.background.brand};
  width: 55px;
  height: 55px;
  border-radius: 5px;
  left: 50%;
  transform: translateX(-50%);
  padding: 7px;
`;
