/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface GetInvoiceConfigResponse {
  invoiceConfigId: number;
  code: string;
  contractTypes: GetContractTypesResponse[];
}

export interface GetContractTypesResponse {
  contractTypeId: number;
  code: string;
  description: string;
  sortOrder: number;
  childContractTypes: GetContractTypesResponse[];
}

export interface GetCustomerResponse {
  customerId: number;
  guid: string;
  number: string;
  name: string;
  deliveryMethod?: DeliveryMethod;
  paymentTerms?: number;
  partyTypes: GetPartyTypeResponse[];
}

export interface GetPartyTypeResponse {
  partyTypeId: number;
  code: string;
  isDefault: boolean;
}

export interface GetFollowUpTypeResponse {
  followUpTypeId: number;
  sortOrder: number;
  code: string;
  mailTemplate: string;
}

export interface GetDraftResponse {
  invoiceId: number;
  customerNumber: string;
  customerGuid: string;
  customerName: string;
  invoiceType: InvoiceType;
  totalAmountExclVat: number;
  totalVat: number;
  totalAmountInclVat: number;
  deliveryMethod: DeliveryMethod;
  nbrOfLines: number;
  invoiceConfig: string;
  customMessage?: string;
  links: GetLinkResponse[];
}

export interface GetDraftsOverviewResponse {
  items: GetDraftResponse[];
  pageIndex: number;
  pageSize: number;
  totalPages: number;
  totalItems: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface GetDraftSummaryResponse {
  totalAmountExclVat: number;
  totalVat: number;
  totalAmountInclVat: number;
}

export interface GetInvoicesOverviewResponse {
  items: GetInvoiceResponse[];
  pageIndex: number;
  pageSize: number;
  totalPages: number;
  totalItems: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface GetInvoiceResponse {
  invoiceId: number;
  docNumber: string;
  dateDoc: string;
  invoiceType: InvoiceType;
  customerNumber: string;
  customerGuid: string;
  customerName: string;
  totalAmountExclVat: number;
  totalVat: number;
  totalAmountInclVat: number;
  deliveryMethod: DeliveryMethod;
  invoiceState: InvoiceStatus;
  statusDate: string;
  nbrOfLines: number;
  links: GetLinkResponse[];
}

/** GetLinkResponse */
export interface GetLinkResponse {
  name: string;
  url: string;
}

export interface GetInvoicesSummaryResponse {
  totalInvoicedLastMonth: number;
  totalIncreased: number;
  totalOverdue: number;
  overdueInvoices: number;
  totalUnpaid: number;
  unpaidInvoices: number;
  lastPayedInvoiceDate: string;
}

export interface GetCustomerSummaryResponse {
  amountLastMonth: number;
  amountUnpaid: number;
  amountOverdue: number;
}

export interface GetInvoiceDetailResponse {
  metadatas: MetadataResponse[];
  invoiceId: number;
  guid: string;
  docNumber?: string;
  vatNumber?: string;
  salesDiaryId?: number;
  dateSent?: string;
  dateDoc?: string;
  dateDue?: string;
  totalAmountExclVat: number;
  totalVat: number;
  totalAmountInclVat: number;
  customerGuid: string;
  customerNumber: string;
  customerEmail: string;
  customerName: string;
  address: string;
  address2?: string;
  city: string;
  zipCode: string;
  country: string;
  deliveryMethod: DeliveryMethod;
  structuredMessage?: string;
  reference?: string;
  status: InvoiceStatus;
  datePaid?: string;
  amountPaid: number;
  lines: GetInvoiceLine[];
  links: GetLinkResponse[];
  dynamicsProfile?: DynamicsProfileResponse;
  customMessage?: string;
}

export interface MetadataResponse {
  metadataId: number;
  value: string;
  entityType: string;
  entityGuid: string;
  entityId: number;
  code: string;
  dataType: string;
  category?: string;
  description?: string;
  showOnCreate: boolean;
  accessibility: 'hidden' | 'readOnly' | 'editable';
  availableValues: object;
}

export interface GetInvoiceLine {
  metadatas: MetadataResponse[];
  code: string;
  entityType: string;
  entityGuid: string;
  description?: string;
  description2?: string;
  description3?: string;
  description4?: string;
  quantity: number;
  amount: number;
  totalAmount: number;
  vatAmount: number;
  vatPercentage: number;
  periodFrom: string;
  periodUntil: string;
  dateFrom: string;
  dateUntil: string;
  invoiceLineType: InvoiceLineType;
  invoiceIdReference?: number;
  productType?: string;
  contractNumber?: string;
  links: GetLinkResponse[];
}

export interface DynamicsProfileResponse {
  dynamicsId?: string;
  prospectId?: string;
  info: string;
}

export interface GetInvoiceStatusHistoryResponse {
  timeStamp: string;
  status: InvoiceStatus;
}

export interface GetManualProductResponse {
  manualProductId: number;
  code: string;
  description: string;
  amount?: number;
  extraInfo?: string;
  productTypeId: number;
  productType?: string;
  status: ManualProductStatus;
}

export interface GetManualInvoiceResponse {
  manualInvoiceId: number;
  customerId: number;
  customerNumber: string;
  customerName: string;
  partyTypeId: number;
  partyType: string;
  contractTypeId?: number;
  contractType?: string;
  periodFrom: string;
  periodUntil: string;
  totalAmountExclVat: number;
  totalVat: number;
  totalAmountInclVat: number;
  lines: GetManualInvoiceLine[];
}

export interface GetManualInvoiceLine {
  manualInvoiceLineId: number;
  code: string;
  description: string;
  quantity: number;
  amount: number;
  totalAmount: number;
  vatAmount: number;
  vatPercentage: number;
  extraInfo?: string;
  productTypeId: number;
  productType: string;
  status: ManualInvoiceLineStatus;
  commissionPercentage?: number;
  resellerGuid?: string;
}

export interface GetManualInvoiceOverviewResponse {
  items: GetManualInvoiceResponse[];
  pageIndex: number;
  pageSize: number;
  totalPages: number;
  totalItems: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface GetManualProductsOverviewResponse {
  items?: GetManualProductResponse[];
  pageIndex: number;
  pageSize: number;
  totalPages: number;
  totalItems: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface GetProductTypesResponse {
  productTypeId: number;
  description: string;
}

export interface GetInvoiceRequestsOverviewResponse {
  items: GetInvoiceRequestResponse[];
  pageIndex: number;
  pageSize: number;
  totalPages: number;
  totalItems: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface GetInvoiceRequestResponse {
  invoiceRequestId: number;
  periodFrom: string;
  periodUntil: string;
  customer?: string;
  requestedOn: string;
  requestedBy?: string;
  status: InvoiceRequestStatus;
  invoiceConfig?: string;
  logs: number;
}

export interface GetInvoiceRequestDetailResponse {
  invoiceRequestId: number;
  periodFrom: string;
  periodUntil: string;
  customer?: string;
  requestedOn: string;
  requestedBy?: string;
  status: InvoiceRequestStatus;
  logs?: {
    logLevel: 'information' | 'warning' | 'error';
    description: string;
    links: GetLinkResponse[];
  }[];
}

export interface GetInvoiceRequestLog {
  logLevel: number;
  description: string;
  links: GetLinkResponse[];
}

/** DeliveryMethod */
export type DeliveryMethod = 'email' | 'post';

/** InvoiceRequestStatus */
export type InvoiceRequestStatus = 'pending' | 'finished';

/** InvoiceStatus */
export type InvoiceStatus =
  | 'draft'
  | 'created'
  | 'sent'
  | 'partiallyPaid'
  | 'paid'
  | 'overdue'
  | 'reminder1'
  | 'reminder2'
  | 'notice1'
  | 'notice2'
  | 'manuallyPaid';

/** SimulationStatus */
export type SimulationStatus = 'draft' | 'created';

/** InvoiceType */
export type InvoiceType = 'invoice' | 'creditNote';

/** SimulationType */
export type SimulationType = 'invoice' | 'creditNote';

/** InvoiceLineType */
export type InvoiceLineType = 'original' | 'isCredited' | 'isCrediter';

/** SimulationLineType */
export type SimulationLineType = 'original' | 'isCredited' | 'isCrediter';

/** ManualInvoiceLineStatus */
export type ManualInvoiceLineStatus = 'open' | 'invoiced' | 'deleted';

/** ManualProductStatus */
export type ManualProductStatus = 'inactive' | 'active';

/** DownloadResponse */
export interface DownloadResponse {
  byteArray?: string;
  contentType?: string;
  fileName?: string;
}

/** GetSimulationResponse */
export interface GetSimulationResponse {
  metadatas?: any[];
  simulationId?: number;
  guid?: string;
  vatNumber?: string;
  customerGuid?: string;
  customerNumber?: string;
  customerEmail?: string;
  customerName?: string;
  salesDiaryId?: number;
  name?: string;
  address?: string;
  address2?: string;
  city?: string;
  zipCode?: string;
  country?: string;
  totalAmountExclVat?: number;
  totalVat?: number;
  totalAmountInclVat?: number;
  reference?: string;
  nbrOfLines?: number;
  currency?: string;
  vatPercentage?: number;
  status?: SimulationStatus;
  type?: SimulationType;
  lines?: GetSimulationLineResponse[];
  links?: GetLinkResponse[];
}

/** GetSimulationLineResponse */
export interface GetSimulationLineResponse {
  metadatas?: any[];
  simulationLineId?: number;
  guid?: string;
  code?: string;
  entityGuid?: string;
  entityType?: string;
  description?: string;
  description2?: string;
  description3?: string;
  description4?: string;
  quantity?: number;
  amount?: number;
  totalAmount?: number;
  vatAmount?: number;
  vatPercentage?: number;
  periodFrom?: string;
  periodUntil?: string;
  dateFrom?: string;
  dateUntil?: string;
  simulationLineType?: SimulationLineType;
  simulationIdReference?: number;
  productType?: string;
  invoiceGroupLevel1?: string;
  invoiceGroupLevel2?: string;
  invoiceGroupLevel3?: string;
  contractNumber?: string;
  links?: GetLinkResponse[];
}

/** GetSimulationsOverviewResponse */
export interface GetSimulationsOverviewResponse {
  items?: SimulationsOverviewResponse[];
  pageIndex?: number;
  pageSize?: number;
  totalPages?: number;
  totalItems?: number;
  hasPreviousPage?: boolean;
  hasNextPage?: boolean;
}

export interface SimulationsOverviewResponse {
  simulationId?: number;
  simulationType?: SimulationType;
  customerNumber?: string;
  customerGuid?: string;
  customerName?: string;
  totalAmountExclVat?: number;
  totalVat?: number;
  totalAmountInclVat?: number;
  simulationStatus?: SimulationStatus;
  nbrOfLines?: number;
  links?: GetLinkResponse[];
}

export interface GetCustomersByPartyTypeParams {
  search?: string;
  take?: number;
  partyType?: string;
}

export interface GetDraftsParams {
  pageIndex?: number;
  pageSize?: number;
  search?: string;
  minAmount?: number;
  maxAmount?: number;
  invoiceConfigIds?: any[];
  orderBys?: any[];
  deliveryMethods?: DeliveryMethod[];
}

export interface GetDraftTotalsParams {
  search?: string;
  minAmount?: number;
  maxAmount?: number;
  invoiceConfigIds?: any[];
  deliveryMethods?: DeliveryMethod[];
}

export interface GetInvoicesParams {
  pageIndex?: number;
  pageSize?: number;
  search?: string;
  invoiceConfigIds?: any[];
  periodFrom?: string;
  periodUntil?: string;
  showDynamicsError?: boolean;
  customerGuid?: string;
  orderBys?: any[];
  deliveryMethods?: DeliveryMethod[];
  invoiceTypes?: InvoiceType[];
  statuses?: InvoiceStatus[];
}

export interface GetSimulationsParams {
  pageIndex?: number;
  pageSize?: number;
  search?: string;
  invoiceConfigIds?: any[];
  customerGuid?: string;
  orderBys?: any[];
  simulationTypes?: InvoiceType[];
  statuses?: InvoiceStatus[];
}

export interface GetCompanySummaryParams {
  customerGuid: string;
}

export type MarkInvoiceAsSentPayload = number[];

export interface GetInvoicesForCustomerParams {
  dateFrom?: string;
  dateUntil?: string;
  customerguid: string;
}

export interface DownloadInvoiceDocumentParams {
  invoiceDocumentType: 'invoice' | 'invoiceCopy';
  id: number;
}

export interface GetProductsParams {
  search?: string;
  take?: number;
}

export interface GetManualInvoicesParams {
  pageIndex?: number;
  pageSize?: number;
  search?: string;
  statuses?: any[];
  periodFrom?: string;
  periodUntil?: string;
}

export interface GetCustomersParams {
  search?: string;
  take?: number;
}

export interface GetManualProductsParams {
  pageIndex?: number;
  pageSize?: number;
  search?: string;
  statuses?: ('inactive' | 'active')[];
  orderBys?: any[];
}

export interface CreateManualProductPayload {
  code: string;
  description: string;
  amount?: number;
  extraInfo?: string;
  productTypeId?: number;
}

export interface GetInvoiceRequestsParams {
  pageIndex?: number;
  pageSize?: number;
  periodFrom?: string;
  periodUntil?: string;
  statuses?: any[];
  invoiceConfigIds?: any[];
}

import axios, { AxiosInstance, AxiosRequestConfig, ResponseType } from 'axios';

type HeaderValue = string | string[] | number | boolean | null;

interface RawHeaders {
  [key: string]: HeaderValue;
}

interface HeadersDefaults {
  common: RawHeaders;
  delete: RawHeaders;
  get: RawHeaders;
  head: RawHeaders;
  post: RawHeaders;
  put: RawHeaders;
  patch: RawHeaders;
  options?: RawHeaders;
  purge?: RawHeaders;
  link?: RawHeaders;
  unlink?: RawHeaders;
}

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || 'http://localhost:3000' });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<T> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance
      .request({
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
        },
        params: query,
        responseType: responseFormat,
        data: body,
        url: path,
      })
      .then((response) => response.data?.response);
  };
}

/**
 * @title InvoicesModule
 * @version 1.0
 * @baseUrl http://localhost:3000
 *
 * invoices module
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * @description get invoice configurations
     *
     * @name GetInvoiceConfigs
     * @summary Your GET endpoint
     * @request GET:/api/invoice/v2/configs
     */
    getInvoiceConfigs: (params: RequestParams = {}) =>
      this.request<GetInvoiceConfigResponse[], any>({
        path: `/api/invoice/v2/configs`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description get customer
     *
     * @name GetCustomer
     * @summary Your GET endpoint
     * @request GET:/api/v2/customers/{guid}
     */
    getCustomer: (guid: string, params: RequestParams = {}) =>
      this.request<GetCustomerResponse, any>({
        path: `/api/v2/customers/${guid}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description get customers by party type
     *
     * @name GetCustomersByPartyType
     * @summary Your GET endpoint
     * @request GET:/api/v2/customers/partytype
     */
    getCustomersByPartyType: (query: GetCustomersByPartyTypeParams, params: RequestParams = {}) =>
      this.request<GetCustomerResponse[], any>({
        path: `/api/v2/customers/partytype`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description get invoice follow up types
     *
     * @name GetFollowUpTypes
     * @summary Your GET endpoint
     * @request GET:/api/invoice/v2/followups/types
     */
    getFollowUpTypes: (params: RequestParams = {}) =>
      this.request<GetFollowUpTypeResponse[], any>({
        path: `/api/invoice/v2/followups/types`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description request follow ups for invoices
     *
     * @name AddFollowUps
     * @request POST:/api/invoice/v2/followups
     */
    addFollowUps: (
      data: {
        invoiceIds: number[];
        followUpTypeId: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/invoice/v2/followups`,
        method: 'POST',
        body: data,
        ...params,
      }),

    /**
     * @description get draft overview
     *
     * @name GetDrafts
     * @summary Your GET endpoint
     * @request GET:/api/invoice/v2/invoices/drafts
     */
    getDrafts: (query: GetDraftsParams, params: RequestParams = {}) =>
      this.request<GetDraftsOverviewResponse, any>({
        path: `/api/invoice/v2/invoices/drafts`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description get drafts summary
     *
     * @name GetDraftTotals
     * @summary Your GET endpoint
     * @request GET:/api/invoice/v2/invoices/drafts/summary
     */
    getDraftTotals: (query: GetDraftTotalsParams, params: RequestParams = {}) =>
      this.request<GetDraftSummaryResponse, any>({
        path: `/api/invoice/v2/invoices/drafts/summary`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description get invoice overview
     *
     * @name GetInvoices
     * @summary Your GET endpoint
     * @request GET:/api/invoice/v2/invoices
     */
    getInvoices: (query: GetInvoicesParams, params: RequestParams = {}) =>
      this.request<GetInvoicesOverviewResponse, any>({
        path: `/api/invoice/v2/invoices`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description get simulation overview
     *
     * @name GetSimulations
     * @summary Your GET endpoint
     * @request GET:/api/invoice/v2/simulations
     */
    getSimulations: (query: GetSimulationsParams, params: RequestParams = {}) =>
      this.request<GetSimulationsOverviewResponse, any>({
        path: `/api/invoice/v2/simulations`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description get invoices summary
     *
     * @name GetInvoicesTotals
     * @summary Your GET endpoint
     * @request GET:/api/invoice/v2/invoices/summary
     */
    getInvoicesTotals: (params: RequestParams = {}) =>
      this.request<GetInvoicesSummaryResponse, any>({
        path: `/api/invoice/v2/invoices/summary`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description get customer summary
     *
     * @name GetCompanySummary
     * @summary Your GET endpoint
     * @request GET:/api/invoice/v2/invoices/customersummary
     */
    getCompanySummary: (query: GetCompanySummaryParams, params: RequestParams = {}) =>
      this.request<GetCustomerSummaryResponse, any>({
        path: `/api/invoice/v2/invoices/customersummary`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description get invoice detail
     *
     * @name GetInvoiceById
     * @summary Your GET endpoint
     * @request GET:/api/invoice/v2/invoices/{id}
     */
    getInvoiceById: (id: number, params: RequestParams = {}) =>
      this.request<GetInvoiceDetailResponse, any>({
        path: `/api/invoice/v2/invoices/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description get simulation detail
     *
     * @name GetSimulationById
     * @summary Your GET endpoint
     * @request GET:/api/invoice/v2/simulations/{id}
     */
    getSimulationById: (id: number, params: RequestParams = {}) =>
      this.request<GetSimulationResponse, any>({
        path: `/api/invoice/v2/simulations/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description get invoice detail
     *
     * @name GetInvoiceByGuid
     * @summary Your GET endpoint
     * @request GET:/api/invoice/v2/invoices/{guid}
     */
    getInvoiceByGuid: (guid: string, params: RequestParams = {}) =>
      this.request<GetInvoiceDetailResponse, any>({
        path: `/api/invoice/v2/invoices/${guid}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description run invoicing for month
     *
     * @name InvoiceMonth
     * @request POST:/api/invoice/v2/invoices/run-month
     */
    invoiceMonth: (
      data: {
        year: number;
        month: number;
        customerGuid?: string;
        partyTypeId?: number;
        invoiceConfigId?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/invoice/v2/invoices/run-month`,
        method: 'POST',
        body: data,
        ...params,
      }),

    /**
     * @description run simulations for month
     *
     * @name SimulateMonth
     * @request POST:/api/invoice/v2/simulations/run-month
     */
    simulateMonth: (
      data: {
        year: number;
        month: number;
        customerGuid?: string;
        partyTypeId?: number;
        invoiceConfigId?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/invoice/v2/simulations/run-month`,
        method: 'POST',
        body: data,
        ...params,
      }),

    /**
     * @description recalculate selected invoices
     *
     * @name RecalculateInvoices
     * @request POST:/api/invoice/v2/invoices/recalculate
     */
    recalculateInvoices: (
      data: {
        invoiceIds: number[];
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/invoice/v2/invoices/recalculate`,
        method: 'POST',
        body: data,
        ...params,
      }),

    /**
     * @description recalculate selected simulations
     *
     * @name RecalculateSimulations
     * @request POST:/api/invoice/v2/simulations/recalculate
     */
    recalculateSimulations: (
      data: {
        simulationIds: number[];
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/invoice/v2/simulations/recalculate`,
        method: 'POST',
        body: data,
        ...params,
      }),

    /**
     * @description approve invoice
     *
     * @name ApproveInvoice
     * @request POST:/api/invoice/v2/invoices/{id}/approve
     */
    approveInvoice: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/invoice/v2/invoices/${id}/approve`,
        method: 'POST',
        ...params,
      }),

    /**
     * @description approve simulation
     *
     * @name ApproveSimulation
     * @request POST:/api/invoice/v2/simulations/{id}/approve
     */
    approveSimulation: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/invoice/v2/simulations/${id}/approve`,
        method: 'POST',
        ...params,
      }),

    /**
     * @description approve multiple invoices
     *
     * @name ApproveInvoices
     * @request POST:/api/invoice/v2/invoices/approve
     */
    approveInvoices: (
      data: {
        invoiceIds?: number[];
        year?: number;
        month?: number;
        invoiceConfigId?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/invoice/v2/invoices/approve`,
        method: 'POST',
        body: data,
        ...params,
      }),

    /**
     * @description approve multiple simulations
     *
     * @name ApproveSimulations
     * @request POST:/api/invoice/v2/simulations/approve
     */
    approveSimulations: (
      data: {
        invoiceIds?: number[];
        year?: number;
        month?: number;
        invoiceConfigId?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/invoice/v2/simulations/approve`,
        method: 'POST',
        body: data,
        ...params,
      }),

    /**
     * @description update selected invoices to status 'sent'
     *
     * @name MarkInvoiceAsSent
     * @request POST:/api/invoice/v2/invoices/mark-sent
     */
    markInvoiceAsSent: (data: MarkInvoiceAsSentPayload, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/invoice/v2/invoices/mark-sent`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description get invoices for customer
     *
     * @name GetInvoicesForCustomer
     * @summary Your GET endpoint
     * @request GET:/api/invoice/v2/invoices/customers/{customerguid}
     */
    getInvoicesForCustomer: ({ customerguid, ...query }: GetInvoicesForCustomerParams, params: RequestParams = {}) =>
      this.request<GetInvoiceDetailResponse[], any>({
        path: `/api/invoice/v2/invoices/customers/${customerguid}`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description set amount paid
     *
     * @name SetAmountPaid
     * @request POST:/api/invoice/v2/invoices/set-amount-paid
     */
    setAmountPaid: (
      data: {
        invoiceGuid: string;
        amountPaid: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/invoice/v2/invoices/set-amount-paid`,
        method: 'POST',
        body: data,
        ...params,
      }),

    /**
     * @description download invoice pdf
     *
     * @name DownloadInvoiceDocument
     * @summary Your GET endpoint
     * @request GET:/api/invoice/v2/invoices/{id}/download
     */
    downloadInvoiceDocument: ({ id, ...query }: DownloadInvoiceDocumentParams, params: RequestParams = {}) =>
      this.request<DownloadResponse, any>({
        path: `/api/invoice/v2/invoices/${id}/download`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description email selected invoices
     *
     * @name EmailInvoice
     * @request POST:/api/invoice/v2/invoices/email
     */
    emailInvoice: (
      data: {
        invoiceIds: number[];
        sendToAccounting: boolean;
        mergeAttachments: boolean;
        cc?: string[];
        bcc?: string[];
        message?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/invoice/v2/invoices/email`,
        method: 'POST',
        body: data,
        ...params,
      }),

    /**
     * @description email all invoices
     *
     * @name EmailAllInvoice
     * @request POST:/api/invoice/v2/invoices/email-all
     */
    emailAllInvoice: (
      data: {
        sendToAccounting: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/invoice/v2/invoices/email-all`,
        method: 'POST',
        body: data,
        ...params,
      }),

    /**
     * @description get invoice history
     *
     * @name GetInvoiceStatusHistory
     * @summary Your GET endpoint
     * @request GET:/api/invoice/v2/invoices/{id}/status-history
     */
    getInvoiceStatusHistory: (id: number, params: RequestParams = {}) =>
      this.request<GetInvoiceStatusHistoryResponse[], any>({
        path: `/api/invoice/v2/invoices/${id}/status-history`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description send invoice statement for customer
     *
     * @name SSendStatementForCustomer
     * @request POST:/api/invoice/v2/invoices/send-statement
     */
    sSendStatementForCustomer: (
      data: {
        customerGuid: string;
        sendToAccounting: boolean;
        cc?: string[];
        bcc?: string[];
        includeInvoicesAsAttachment: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/invoice/v2/invoices/send-statement`,
        method: 'POST',
        body: data,
        ...params,
      }),

    /**
     * @description update
     *
     * @name UpdateInvoiceReference
     * @request PUT:/api/invoice/v2/invoices/reference
     */
    updateInvoiceReference: (
      data: {
        invoiceId: number;
        reference: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetInvoiceDetailResponse, any>({
        path: `/api/invoice/v2/invoices/reference`,
        method: 'PUT',
        body: data,
        format: 'json',
        ...params,
      }),

    /**
     * @description update
     *
     * @name UpdateInvoiceCustomMessage
     * @request PUT:/api/invoice/v2/invoices/message
     */
    updateInvoiceCustomMessage: (
      data: {
        invoiceIds: number[];
        message: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetInvoiceDetailResponse, any>({
        path: `/api/invoice/v2/invoices/message`,
        method: 'PUT',
        body: data,
        format: 'json',
        ...params,
      }),

    /**
     * @description get manual invoice lines products
     *
     * @name GetProducts
     * @summary Your GET endpoint
     * @request GET:/api/invoice/v2/manualinvoicelines/products
     */
    getProducts: (query: GetProductsParams, params: RequestParams = {}) =>
      this.request<GetManualProductResponse[], any>({
        path: `/api/invoice/v2/manualinvoicelines/products`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description add new manual invoice line
     *
     * @name CreateManualInvoiceLine
     * @request POST:/api/invoice/v2/manualinvoicelines
     */
    createManualInvoiceLine: (
      data: {
        customerId: number;
        partyTypeId: number;
        contractTypeId?: number;
        periodFrom: string;
        periodUntil: string;
        code: string;
        description: string;
        quantity: number;
        amount: number;
        extraInfo?: string;
        productTypeId: number;
        commissionPercentage?: number;
        resellerGuid?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetManualInvoiceResponse, any>({
        path: `/api/invoice/v2/manualinvoicelines`,
        method: 'POST',
        body: data,
        format: 'json',
        ...params,
      }),

    /**
     * @description update manual invoice line
     *
     * @name UpdateManualInvoiceLine
     * @request PUT:/api/invoice/v2/manualinvoicelines
     */
    updateManualInvoiceLine: (
      data: {
        manualInvoiceLineId: number;
        code: string;
        description: string;
        quantity: number;
        amount: number;
        extraInfo?: string;
        productTypeId: number;
        status: ManualInvoiceLineStatus;
        commissionPercentage?: number;
        resellerGuid?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/invoice/v2/manualinvoicelines`,
        method: 'PUT',
        body: data,
        ...params,
      }),

    /**
     * @description get manual invoices overview
     *
     * @name GetManualInvoices
     * @summary Your GET endpoint
     * @request GET:/api/invoice/v2/manualinvoices
     */
    getManualInvoices: (query: GetManualInvoicesParams, params: RequestParams = {}) =>
      this.request<GetManualInvoiceOverviewResponse, any>({
        path: `/api/invoice/v2/manualinvoices`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description get manual invoice customers
     *
     * @name GetCustomers
     * @summary Your GET endpoint
     * @request GET:/api/invoice/v2/manualinvoices/customers
     */
    getCustomers: (query: GetCustomersParams, params: RequestParams = {}) =>
      this.request<GetCustomerResponse[], any>({
        path: `/api/invoice/v2/manualinvoices/customers`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description get manual invoice party types
     *
     * @name GetPartyTypes
     * @summary Your GET endpoint
     * @request GET:/api/invoice/v2/manualinvoices/partytypes
     */
    getPartyTypes: (params: RequestParams = {}) =>
      this.request<GetPartyTypeResponse[], any>({
        path: `/api/invoice/v2/manualinvoices/partytypes`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description get manual invoice contract types
     *
     * @name GetContractTypes
     * @summary Your GET endpoint
     * @request GET:/api/invoice/v2/manualinvoices/contracttypes
     */
    getContractTypes: (params: RequestParams = {}) =>
      this.request<GetContractTypesResponse[], any>({
        path: `/api/invoice/v2/manualinvoices/contracttypes`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description get manual products overview
     *
     * @name GetManualProducts
     * @summary Your GET endpoint
     * @request GET:/api/invoice/v2/manualproducts
     */
    getManualProducts: (query: GetManualProductsParams, params: RequestParams = {}) =>
      this.request<GetManualProductsOverviewResponse, any>({
        path: `/api/invoice/v2/manualproducts`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description update manual product
     *
     * @name UpdateManualProduct
     * @request PUT:/api/invoice/v2/manualproducts
     */
    updateManualProduct: (
      data: {
        manualProductId: number;
        code: string;
        description: string;
        amount?: number;
        extraInfo?: string;
        productTypeId: number;
        status: ManualProductStatus;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetManualProductResponse, any>({
        path: `/api/invoice/v2/manualproducts`,
        method: 'PUT',
        body: data,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateManualProduct
     * @request POST:/api/invoice/v2/manualproducts
     */
    createManualProduct: (data: CreateManualProductPayload, params: RequestParams = {}) =>
      this.request<GetManualProductResponse, any>({
        path: `/api/invoice/v2/manualproducts`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description get manual product product types
     *
     * @name GetProductTypes
     * @summary Your GET endpoint
     * @request GET:/api/invoice/v2/manualproducts/producttypes
     */
    getProductTypes: (params: RequestParams = {}) =>
      this.request<GetProductTypesResponse[], any>({
        path: `/api/invoice/v2/manualproducts/producttypes`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description get manual product detail
     *
     * @name GetManualProduct
     * @summary Your GET endpoint
     * @request GET:/api/invoice/v2/manualproducts/{id}
     */
    getManualProduct: (id: number, params: RequestParams = {}) =>
      this.request<GetManualProductResponse, any>({
        path: `/api/invoice/v2/manualproducts/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description set manual product to status 'inactive'
     *
     * @name SetManualProdcutsToInactive
     * @summary Your POST endpoint
     * @request POST:/api/invoice/v2/manualproducts/set-inactive
     */
    setManualProdcutsToInactive: (
      data: {
        manualProductIds: number[];
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/invoice/v2/manualproducts/set-inactive`,
        method: 'POST',
        body: data,
        ...params,
      }),

    /**
     * @description get invoice requests overview
     *
     * @name GetInvoiceRequests
     * @summary Your GET endpoint
     * @request GET:/api/invoice/v2/requests
     */
    getInvoiceRequests: (query: GetInvoiceRequestsParams, params: RequestParams = {}) =>
      this.request<GetInvoiceRequestsOverviewResponse, any>({
        path: `/api/invoice/v2/requests`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description get invoice request detail
     *
     * @name GetInvoiceRequest
     * @summary Your GET endpoint
     * @request GET:/api/invoice/v2/requests/{id}
     */
    getInvoiceRequest: (id: number, params: RequestParams = {}) =>
      this.request<GetInvoiceRequestDetailResponse, any>({
        path: `/api/invoice/v2/requests/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete a draft
     *
     * @name DeleteDraftAsync
     * @request DELETE:/api/invoice/v2/invoices/drafts/{id}
     */
    deleteDraftAsync: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/invoice/v2/invoices/drafts/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * @description Delete a simulation draft
     *
     * @name DeleteSimulationDraftAsync
     * @request DELETE:/api/invoice/v2/simulations/drafts/{id}
     */
    deleteSimulationDraftAsync: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/invoice/v2/simulations/drafts/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * @description Approve invoices in bulk, used for the BO invoices overview multiselect.
     *
     * @name ApproveInvoicesMultiSelect
     * @summary Your POST endpoint
     * @request POST:/api/invoice/v2/invoices/approve/bulk
     */
    approveInvoicesMultiSelect: (
      data: {
        invoiceIds?: number[];
        year?: number;
        month?: number;
        invoiceConfigId?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/invoice/v2/invoices/approve/bulk`,
        method: 'POST',
        body: data,
        ...params,
      }),
  };
}
