import { Button, Flexer, ModalContent } from '@unbrace/components';
import shortcuts from 'components/KeyboardShortcuts/shortcuts';
import { useTranslation } from 'react-i18next';
import { CategoryWrapper, KeyCapShortcut, ModalBody, ShortcutTitle } from './styles';

type Props = {
  toggleModal: VoidFunction;
};

const ShortcutsModal = ({ toggleModal }: Props) => {
  const { t } = useTranslation('shortcuts');

  return (
    <ModalContent>
      <ModalContent.Header>
        <h1>{t('modal.title')}</h1>
      </ModalContent.Header>
      <ModalBody>
        {Object.keys(shortcuts).map((shortcutKey) => (
          <CategoryWrapper key={shortcutKey}>
            <ShortcutTitle>{t(`${shortcutKey}.title`)}</ShortcutTitle>
            {Object.keys(shortcuts[shortcutKey]).map((shortcutCombination) => (
              <Flexer key={shortcutCombination}>
                <Flexer style={{ width: '100%' }} justify="flex-end">
                  {t(`${shortcutKey}.${shortcutCombination}`)}
                </Flexer>
                <KeyCapShortcut>
                  {shortcuts[shortcutKey][shortcutCombination].map((keycap: string, index: number) => (
                    <span key={keycap}>
                      {keycap} {index < shortcuts[shortcutKey][shortcutCombination].length - 1 && ' + '}
                    </span>
                  ))}
                </KeyCapShortcut>
              </Flexer>
            ))}
          </CategoryWrapper>
        ))}
      </ModalBody>
      <ModalContent.Footer>
        <Button color="tertiary" onClick={toggleModal}>
          {t('modal.close')}
        </Button>
      </ModalContent.Footer>
    </ModalContent>
  );
};

export default ShortcutsModal;
