import { defaultRequestConfig } from 'config/api/AxiosConfig';
import { Api as AccessApi } from './access/accessAPI';
import { Api as TasksApi } from './tasks/tasksAPI';
import { Api as ServicefeeApi } from './servicefee/servicefeeAPI';
import { Api as FileImportsApi } from './fileimports/fileimportsAPI';
import { Api as ProductsApi } from './products/productsAPI';
import { Api as ContractApi } from './contract/contractAPI';
import { Api as CompanyApi } from './company/companyAPI';
import { Api as PartnerApi } from './partner/partnerAPI';
import { Api as InvoicesApi } from './invoices/invoicesAPI';
import { Api as MailsApi } from './mails/mailsAPI';

const { api: accessApi } = new AccessApi({ ...defaultRequestConfig });
const { api: tasksApi } = new TasksApi({ ...defaultRequestConfig });
const { api: servicefeeApi } = new ServicefeeApi({ ...defaultRequestConfig });
const { api: fileImportsApi } = new FileImportsApi({ ...defaultRequestConfig });
const { api: contractApi } = new ContractApi({ ...defaultRequestConfig });
const { api: productsApi } = new ProductsApi({ ...defaultRequestConfig });
const { api: companyApi } = new CompanyApi({ ...defaultRequestConfig });
const { api: partnerApi } = new PartnerApi({ ...defaultRequestConfig });
const { api: invoicesApi } = new InvoicesApi({ ...defaultRequestConfig });
const { api: mailsApi } = new MailsApi({ ...defaultRequestConfig });

export {
  accessApi,
  tasksApi,
  servicefeeApi,
  fileImportsApi,
  productsApi,
  contractApi,
  companyApi,
  partnerApi,
  invoicesApi,
  mailsApi,
};
