import * as React from 'react';
import { DatePickerInput } from '@unbrace/components';
import { useTranslation } from 'react-i18next';
import { dateParser, formatAsDate } from 'helpers/dateFormatter';

type Props = {
  from: string | Date | undefined;
  to: string | Date | undefined;
  onFromDateChange: (day: Date | undefined) => void;
  onToDateChange: (day: Date | undefined) => void;
};

const PeriodFilter = ({ from, to, onFromDateChange, onToDateChange }: Props) => {
  const { t } = useTranslation('global');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, callback: (date: Date | undefined) => void) => {
    const parsedValue = dateParser(event.target.value);
    if (parsedValue || !event.target.value.trim()) {
      return callback(parsedValue);
    }
  };

  return (
    <React.Fragment>
      <DatePickerInput
        value={formatAsDate(from)}
        inputDebounceTimeOut={1000}
        placeholder={''}
        inputProps={{
          name: 'periodFrom',
          label: t('date.period.from'),
          inlineLabel: true,
          style: { marginBottom: 8 },
          autoComplete: 'off',
          isClearable: true,
          onClear: () => onFromDateChange(undefined),
          onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
            handleChange(event, onFromDateChange);
          },
        }}
        dayPickerProps={{
          disabledDays: { after: new Date(to || '') } as { after: Date },
          onDayClick: onFromDateChange,
        }}
      />
      <DatePickerInput
        value={formatAsDate(to)}
        inputDebounceTimeOut={1000}
        placeholder={''}
        inputProps={{
          name: 'periodUntil',
          label: t('date.period.to'),
          inlineLabel: true,
          style: { marginBottom: 8 },
          autoComplete: 'off',
          isClearable: true,
          onClear: () => onToDateChange(undefined),
          onChange: (event: React.ChangeEvent<HTMLInputElement>) => handleChange(event, onToDateChange),
        }}
        dayPickerProps={{
          disabledDays: { before: new Date(from || '') } as { before: Date },
          onDayClick: onToDateChange,
        }}
      />
    </React.Fragment>
  );
};

export default PeriodFilter;
