import * as React from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from 'config/theme';
import { AnimationProps } from 'config/theme/types';
import { animations } from 'config/theme/keyframes';

const FilterContent = styled.div<AnimationProps>`
  overflow: auto;
  padding: ${(props) => props.theme.internalSidebar.padding.filter};

  animation: ${animations.slideOpen} 0.2s;

  ${(props) => props.state === 'entering' && props.theme.itemSidebar.animation.filters.entering};
  ${(props) => props.state === 'entered' && props.theme.itemSidebar.animation.filters.entered};
`;

const TransitionedFilterContent: React.FC = ({ children, ...rest }) => (
  <CSSTransition in timeout={200} mountOnEnter appear>
    {(state) => (
      <FilterContent state={state} {...rest}>
        {children}
      </FilterContent>
    )}
  </CSSTransition>
);

export default TransitionedFilterContent;
