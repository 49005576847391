import styled from 'styled-components';
import * as React from 'react';
import { ChevronUp, ChevronDown } from '@unbrace/components';

type Props = {
  isShowingMore: boolean;
  showLessText?: string;
  showMoreText?: string;
  toggleShow: () => void;
};

export const ShowMoreToggle: React.FC<Props> = ({ isShowingMore, showLessText, showMoreText, toggleShow }: Props) => (
  <StyledShowMore onClick={toggleShow}>
    {isShowingMore ? showLessText : showMoreText}
    {isShowingMore ? <ChevronUp /> : <ChevronDown />}
  </StyledShowMore>
);

export const StyledShowMore = styled.span`
  align-items: end;
  color: ${(props) => props.theme.showMore.color};
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: 400;

  svg {
    fill: ${(props) => props.theme.showMore.color};
    height: 20px;
    margin-right: 20px;
    width: 20px;
  }
`;
