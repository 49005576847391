import { useMutationWithSuccessAlert, useQueryWithAlert } from 'adapters/queries';
import ConfigApi from '../api/ConfigApi';
import { securityKeys } from './keyFactory';
import IdentityApi from 'modules/security/api/IdentityApi';
import LogoutApi from '../api/LogoutApi';
import ModulesApi from '../api/ModulesApi';

export const useGetExternalLinks = () =>
  useQueryWithAlert(securityKeys.externalLinks(), () => ConfigApi.getExternalLinks());

export const useGetModules = () =>
  useQueryWithAlert(securityKeys.modules(), () => ModulesApi.getModules(), {
    staleTime: Infinity,
  });

export const useMutateResetOtherPassword = () =>
  useMutationWithSuccessAlert((guid: string) => IdentityApi.resetOtherUserPassword(guid));

export const useMutateResetPassword = () => useMutationWithSuccessAlert(() => IdentityApi.resetPassword());

export const useMutateResetAuthenticator = () =>
  useMutationWithSuccessAlert((guid: string) => IdentityApi.resetAuthenticator(guid));

export const useMutateLogout = () =>
  useMutationWithSuccessAlert(LogoutApi.logout, {
    onSuccess: (data) => {
      window.location.href = (data && new URL(decodeURIComponent(data)).href) || '/';
    },
  });
