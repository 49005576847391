import { Button, IconButton, Modal, SubmitButton } from '@unbrace/components';
import { AddMetaDataContextMenu, Confirmation, ContextMenu } from 'components';
import { ContextMenuRenderProps } from 'components/ContextMenu/ContextMenu';
import { ORIENTATION_BOTTOM_RIGHT } from 'components/ContextMenu/constants';
import useKeyShortcut from 'components/KeyboardShortcuts/hooks/useKeyShortcut';
import { AddField, AddPriceRule, Delete, Edit, Visibility } from 'components/icons';
import styled from 'config/theme';
import { MultiSelectActions } from 'hooks/useMultiSelect';
import { ModuleEditAccessContext } from 'modules/security';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { MetaData } from 'types/metadata';
import v4 from 'uuid';
import { DraftActionsWrapper, Header, IconButtonWrapper } from '../../app/components/Header';
import usePricingStore from '../services/usePricingStore';
import { Pricing } from '../types/pricing';
import PricingModal from './pricing/NewPrice/PricingModal';

type Props = {
  handleAddMetaData?: (args: any) => () => void;
  handleCancel?: () => void;
  handleSave?: () => void;
  hasChanges?: boolean;
  metaData: MetaData[];
  productGuid?: typeof v4;
  setShowEdit?: (val: boolean) => void;
  setShowFullView?: (val: boolean) => void;
  showEditView?: boolean;
  showFullView?: boolean;
  isSaving?: boolean;
  isPending?: boolean;
  selectedPricing: Pricing[];
  selectedPricingActions: MultiSelectActions;
};

const ProductHeader: React.FC<Props> = ({
  handleAddMetaData,
  handleCancel,
  handleSave,
  hasChanges,
  metaData,
  productGuid,
  setShowEdit,
  setShowFullView,
  showEditView,
  showFullView,
  isSaving,
  isPending,
  selectedPricing,
  selectedPricingActions,
}: Props) => {
  const { t } = useTranslation('products');
  const { canEdit } = React.useContext(ModuleEditAccessContext);
  const anySelected = selectedPricing.length > 0;
  const { deletePricing, isDeletePricingLoading } = usePricingStore();

  const handleDelete = () => {
    selectedPricing.forEach((price) => {
      if (price.priceId) {
        deletePricing(price.priceId);
      }
    });
    selectedPricingActions.deselectAll();
  };

  const toggleFullView = React.useCallback(() => {
    if (setShowFullView) {
      setShowFullView(!showFullView);
    }
    if (setShowEdit) {
      setShowEdit(false);
    }
  }, [showFullView, setShowEdit, setShowFullView]);

  const toggleEdit = React.useCallback(() => {
    if (setShowEdit) {
      setShowEdit(!showEditView);
    }
    if (setShowFullView) {
      setShowFullView(false);
    }
  }, [showEditView, setShowEdit, setShowFullView]);

  const { useToggleDetail, useToggleEdit } = useKeyShortcut();
  useToggleEdit(toggleEdit);
  useToggleDetail(toggleFullView);

  return (
    <Header isPending={isPending}>
      <HeaderWrapper>
        <IconButtonWrapper>
          <IconButton
            isDisabled={toggleEdit === undefined || !canEdit}
            onClick={toggleEdit}
            tooltip={showEditView ? t('header.cancelEdit') : t('header.edit')}
          >
            <Edit active={showEditView} />
          </IconButton>
          <IconButton
            isDisabled={toggleFullView === undefined}
            onClick={toggleFullView}
            tooltip={showFullView ? t('header.baseView') : t('header.fullView')}
          >
            <Visibility active={showFullView ? showFullView : false} />
          </IconButton>
          <ContextMenu
            content={<AddMetaDataContextMenu metaData={metaData} handleAdd={handleAddMetaData} />}
            enhanceWithOnContext={false}
          >
            {({ toggleMenu }: ContextMenuRenderProps) => (
              <IconButton
                onClick={toggleMenu}
                tooltip={t('header.addMetaData')}
                isDisabled={!handleAddMetaData || !canEdit}
              >
                <AddField />
              </IconButton>
            )}
          </ContextMenu>
          <Modal content={PricingModal} keepOpenLabel={t('global:modal.createAnother')} contentProps={{ productGuid }}>
            {(toggleModal) => (
              <IconButton
                onClick={toggleModal}
                tooltip={t('header.addPricingRule')}
                isDisabled={showEditView || showFullView || !canEdit}
              >
                <AddPriceRule />
              </IconButton>
            )}
          </Modal>
          <Confirmation variant="delete" orientation={ORIENTATION_BOTTOM_RIGHT} callback={handleDelete}>
            <IconButton
              isLoading={isDeletePricingLoading}
              isDisabled={!anySelected || !canEdit}
              tooltip={t('header.delete')}
            >
              <Delete />
            </IconButton>
          </Confirmation>
        </IconButtonWrapper>
        {(showEditView || hasChanges) && (
          <DraftActionsWrapper>
            {hasChanges ? (
              <Button isDisabled={isSaving} color="tertiary" onClick={handleCancel}>
                {t('header.cancelEdit')}
              </Button>
            ) : (
              <Button color="tertiary" onClick={toggleEdit}>
                {t('header.closeEdit')}
              </Button>
            )}
            <SubmitButton
              isLoading={isSaving}
              isDisabled={!hasChanges}
              type="submit"
              color="primary"
              onClick={handleSave}
            >
              {t('header.saveEdit')}
            </SubmitButton>
          </DraftActionsWrapper>
        )}
      </HeaderWrapper>
    </Header>
  );
};

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export default ProductHeader;
