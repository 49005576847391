import * as React from 'react';
import styled from '../../config/theme';
import { ErrorText } from './ErrorText';
import { FieldContainer } from './FieldContainer';
import { Label } from './Label';

type Props = {
  value: string;
  onChange: (e: React.FormEvent<HTMLTextAreaElement>) => void;
  error?: string;
  label?: string;
  name?: string;
};

const TextArea: React.FC<Props> = ({ onChange, value, error, label, name, ...props }: Props) => (
  <FieldContainer>
    {label && <Label htmlFor={name}>{label || name}</Label>}
    <StyledTextArea value={value} onChange={onChange} name={name} {...props} hasError={Boolean(error)} />
    {error && <ErrorText>{error}</ErrorText>}
  </FieldContainer>
);

const StyledTextArea = styled.textarea<{ hasError?: boolean }>`
  background: ${(props) => props.theme.form.input.background};
  color: ${(props) => props.theme.form.input.color};
  border: ${(props) => (!props.hasError ? props.theme.form.input.border : props.theme.form.input.borderError)};
  border-radius: ${(props) => props.theme.form.input.borderRadius};
  box-shadow: ${(props) => props.theme.form.input.boxShadow};
  margin: ${(props) => props.theme.form.input.margin};
  padding: ${(props) => props.theme.form.input.padding};
  transition: box-shadow 0.15s ease-in, border 0.15s ease-in;
  max-width: 100%;
  min-height: 80px;

  &:focus {
    box-shadow: ${(props) =>
      !props.hasError ? props.theme.form.input.boxShadowFocus : props.theme.form.input.boxShadowFocusError};
    border: ${(props) => (!props.hasError ? props.theme.form.input.borderFocus : props.theme.form.input.borderError)};
  }

  &[disabled] {
    background: ${(props) => props.theme.form.input.backgroundDisabled};
    cursor: not-allowed;
  }
`;

export default TextArea;
