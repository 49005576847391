import { TenantTheme } from '../tenants/unbrace';

const filterTheme = (theme: TenantTheme) => ({
  customFilters: {
    background: theme.palette.primary.shade1,
    lineColor: theme.palette.primary.shade3,
    iconColor: theme.palette.primary.shade5,
    labelColor: theme.palette.neutral.shade5,
    radioBorder: theme.palette.neutral.shade4,
  },
});

export default filterTheme;
