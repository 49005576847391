import { ChangeEvent } from 'react';
import * as React from 'react';
import ReactToggle from 'react-toggle';
import ToggleWrapper from './ToggleWrapper';

/*
 * more info here https://github.com/aaronshaf/react-toggle#props
 */
type Props = {
  label?: string;
  onChange?: (e?: ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
};

const Toggle: React.FC<Props> = ({ label, onChange, checked }: Props) => {
  return (
    <ToggleWrapper>
      <ReactToggle onChange={onChange} icons={false} checked={checked} />
      {label && <span>{label}</span>}
    </ToggleWrapper>
  );
};

export default Toggle;
