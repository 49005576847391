import { Add, Minus } from 'components/icons';
import styled from 'styled-components';

export const PlusButton = styled(Add)`
  position: absolute;
  cursor: pointer;
  top: 12px;
  left: 34px;

  > svg {
    max-width: 17px;
    max-height: 17px;
    fill: ${(props) => props.theme.palette.neutral.shade4};
  }
  &[disabled] {
    background: ${(props) => props.theme.form.input.backgroundDisabled};
    cursor: not-allowed;
  }
`;

export const MinusButton = styled(Minus)`
  position: absolute;
  cursor: pointer;
  top: 12px;
  left: 10px;

  > svg {
    max-width: 17px;
    max-height: 17px;
    fill: ${(props) => props.theme.palette.neutral.shade4};
  }
  &[disabled] {
    background: ${(props) => props.theme.form.input.backgroundDisabled};
    cursor: not-allowed;
  }
`;

export const VerticalLine = styled.div`
  border-left: ${(props) => props.theme.form.input.border};
  width: 1px;
  height: 100%;
  display: inline-block;
`;

export const Addon = styled.div`
  width: 80px;
  position: absolute;
  display: block;
  height: ${(props) => props.theme.form.input.height};
  margin: ${(props) => props.theme.form.input.margin};
  top: 0;
  right: -19px;
`;
