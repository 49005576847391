/* eslint-disable complexity */
import { ArrayHelpers, Field, FieldProps } from 'formik';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@unbrace/components';
import styled from 'styled-components';
import { FormikDatePicker, Input } from '../';
import { MetaDataValue, UseMetaDataActions, MetaDataAccess } from '../../types/metadata';

import { FormikSelect } from '../Form';
import { InputProps, TYPE_CHECKBOX, TYPE_TEXT, TYPE_PERCENTAGE } from '../Form/Input';
import { Delete } from '../icons';
import { ElementWithButton } from './ElementWithButton';
import NumberInput from 'components/Form/NumberInput';

type Props = {
  disabled?: boolean;
  metaData: MetaDataValue;
  index: number;
  arrayHelpers: ArrayHelpers;
  inputProps?: Partial<InputProps>;
  metaDataActions?: UseMetaDataActions;
  isModal?: boolean;
};

const MetaDataField: React.FC<Props> = ({
  disabled,
  metaData,
  index,
  arrayHelpers,
  inputProps,
  metaDataActions,
  isModal,
}: Props) => {
  const { t } = useTranslation('metaData');
  const options = metaData.availableValues
    ? Object.keys(metaData.availableValues).map((key: string) => ({
        label: metaData.availableValues ? metaData.availableValues[key] : '',
        value: key,
      }))
    : [];

  const handleRemoveClick = () => {
    arrayHelpers.remove(index);
    if (metaDataActions) {
      metaDataActions.toggleMetaData(metaData.metadataId.toString());
    }
  };

  const metaDataType = metaData.dataType?.toUpperCase();

  return (
    <Field name={`metadatas.${index}.value`}>
      {(fieldProps: FieldProps) => {
        return (
          <ElementWithButton>
            {metaDataType === 'MULTISELECT' ? (
              <FormikSelect
                {...fieldProps}
                autoFocus={metaData.isNew}
                isDisabled={metaData.accessibility !== MetaDataAccess.Editable || disabled}
                options={options}
                error={inputProps?.error}
              />
            ) : metaDataType === 'BOOL' ? (
              <Input
                {...inputProps}
                {...fieldProps.field}
                value={fieldProps?.field?.value?.toString()?.toLowerCase() || 'false'}
                checked={fieldProps?.field?.value?.toString()?.toLowerCase() === 'true'}
                label={t(`metaData:${metaData.code?.toUpperCase()}`) || ''}
                disabled={metaData.accessibility !== MetaDataAccess.Editable || disabled}
                type={TYPE_CHECKBOX}
              />
            ) : metaDataType === 'PERCENTAGE' ? (
              <NumberInput
                {...inputProps}
                {...fieldProps.field}
                label={t(`metaData:${metaData.code?.toUpperCase()}`) || ''}
                autoFocus={metaData.isNew}
                disabled={metaData.accessibility !== MetaDataAccess.Editable || disabled}
                type={TYPE_PERCENTAGE}
                value={Number(metaData.value) || 0}
                onChange={({ numberValue }) => fieldProps.form.setFieldValue(fieldProps.field.name, numberValue)}
                isClearable={!disabled}
              />
            ) : metaDataType === 'DATE' ? (
              <FormikDatePicker
                {...{
                  ...fieldProps,
                  field: {
                    ...fieldProps.field,
                    value: fieldProps.field.value ? new Date(fieldProps.field.value) : '',
                  },
                }}
                disabled={metaData.accessibility !== MetaDataAccess.Editable || disabled}
                inputProps={{ ...inputProps }}
              />
            ) : (
              <Input
                {...inputProps}
                {...fieldProps.field}
                label={t(`metaData:${metaData.code?.toUpperCase()}`) || ''}
                autoFocus={metaData.isNew}
                disabled={metaData.accessibility !== MetaDataAccess.Editable || disabled}
                type={TYPE_TEXT}
                value={metaData.value || ''}
              />
            )}

            <MetaDataRemoveButton
              className={metaData.dataType === 'BOOL' ? 'buttonForCheckbox' : ''}
              onClick={handleRemoveClick}
              tooltip={t('tooltips.removeMetaData')}
              isModal={isModal}
              isDisabled={metaData.showOnCreate}
            >
              <Delete />
            </MetaDataRemoveButton>
          </ElementWithButton>
        );
      }}
    </Field>
  );
};

export default MetaDataField;

const MetaDataRemoveButton = styled(IconButton)<{ isModal?: boolean }>`
  margin-top: ${(props) => (props.isModal ? '25px' : '0px')};
`;
