/* eslint-disable import/no-unassigned-import */
import 'core-js/stable';
import 'normalize.css';
import ReactDOM from 'react-dom';
import App from './modules/app/containers/App';
import * as serviceWorker from './serviceWorker';
import * as usersnap from './usersnap';
import * as environmentFlag from './environment-flag';

// We're importing .css because @font-face in styled-components causes font files
// to be constantly re-requested from the server (which causes screen flicker)
// https://github.com/styled-components/styled-components/issues/1593
import './fontStyles.css';

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require
  // const { worker } = require('./mocks/browser');
  // worker.start({
  //   onUnhandledRequest() {},
  // });
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
usersnap.load();
environmentFlag.load();
