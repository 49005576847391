/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface GetCountryResponse {
  countryId: number;
  code: string;
  name: string;
  vatFormat?: string;
  isEu: boolean;
  vatRegimes?: GetVatRegimeResponse[];
}

export interface GetDnsTenantsResponse {
  tenantId: number;
  name: string;
}

export interface GetVatRegimeResponse {
  code: string;
  description: string;
  vatNumberRequired: boolean;
  regionCode: string;
}

export interface GetCityResponse {
  zipCode: string;
  name: string;
  country: GetCountryResponse;
}

export interface GetCompanyOverviewResponse {
  items: CompanyOverviewResponse[];
  pageIndex: number;
  pageSize: number;
  totalPages: number;
  totalItems: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface CompanyOverviewResponse {
  companyId: number;
  companyNumber: string;
  name: string;
  vatNumber?: string;
  email: string;
  phoneNumber?: string;
  street: string;
  number: string;
  zipCode: string;
  city: string;
  country: string;
  status: CompanyStatus;
  profiles: string[];
}

export interface GetCompanyDetailResponse {
  companyId: number;
  guid: string;
  companyNumber: string;
  name: string;
  vatRegimeCode: string;
  vatRegime: string;
  vatNumber?: string;
  currency: string;
  language: string;
  bic?: string;
  iban?: string;
  website?: string;
  remarks?: string;
  email: string;
  phoneNumber?: string;
  registrationType?: string;
  naceCodeId?: number;
  naceCode?: string;
  naceCodeDescription?: string;
  status: CompanyStatus;
  invoiceProfile: GetCompanyInvoiceProfile;
  partnerPricingProfile: GetCompanyPartnerPricingProfile;
  dnsProfile?: GetDnsProfile;
  partnerCenterProfile: GetCompanyPartnerCenterProfile;
  dynamicsProfile: GetCompanyDynamicsProfile;
  addresses: GetAddress[];
  companyProfiles: GetCompanyProfile[];
  metadatas: EntityMetadataResponse[];
  backofficeLinks: GetLinkResponse[];
}

export type GetCompanyForProfileResponse = {
  companyId: number;
  guid: string;
  companyNumber: string;
  name: string;
}[];

export interface GetChildCompanyOverviewResponse {
  items: {
    companyId: number;
    guid: string;
    companyNumber: string;
    name: string;
    status: CompanyStatus;
    profile: string;
    microsoftAgreement?: {
      cloudAgreementId: number;
      guid: string;
      companyGuid: string;
      cspMicrosoftId: string;
      cspMicrosoftIdLInk: string;
      firstName: string;
      lastName: string;
      email: string;
      phoneNumber?: string;
      language?: string;
      dateAgreed: string;
      isSent: boolean;
      dateCreated: string;
      agreementType: string;
    };
    links: {
      name: string;
      url: string;
    }[];
  }[];
  pageIndex: number;
  pageSize: number;
  totalPages: number;
  totalItems: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface GetProfileResponse {
  profileId: number;
  name: string;
  parentProfile?: GetProfileResponse;
}

export interface GetInvoicePartyTypeResponse {
  partyTypeId: number;
  code: string;
  isDefault: boolean;
}

export interface GetNaceCodeResponse {
  naceCodeId: number;
  code: string;
  description: string;
}

export interface GetContactResponse {
  metadatas: EntityMetadataResponse[];
  contactId: number;
  guid: string;
  companyId: number;
  contactTypeId: number;
  contactType: string;
  colorType: ColorType;
  division?: string;
  name: string;
  firstName: string;
  language: string;
  email: string;
  phoneNumber?: string;
  remarks?: string;
  gender: Gender;
  active: boolean;
}

export interface GetContactTypeResponse {
  contactTypeId: number;
  code: string;
  colorType?: ColorType;
}

export interface GetMetadataResponse {
  metadataId: number;
  entityType: string;
  code: string;
  dataType: 'STRING' | 'MULTISELECT' | 'GUID' | 'BOOL' | 'INTEGER' | 'PERCENTAGE' | 'DATE' | 'DECIMAL';
  category?: string;
  description?: string;
  showOnCreate: boolean;
  accessibility: 'hidden' | 'editable' | 'readOnly';
  availableValues: object;
}

export interface GetAddress {
  addressId: number;
  addressType: AddressType;
  street: string;
  number: string;
  zipCode: string;
  city: string;
  countryId: number;
  country: string;
  name?: string;
  extraInfo?: string;
}

/** GetCompanyProfile */
export interface GetCompanyProfile {
  companyProfileId: number;
  profileId: number;
  profile: string;
  parentCompanyId?: number;
  parentCompanyName?: string;
}

export interface EntityMetadataResponse {
  metadataId: number;
  value?: string;
  entityType: string;
  entityGuid: string;
  entityId: number;
  code: string;
  dataType: 'STRING' | 'MULTISELECT' | 'GUID' | 'DECIMAL' | 'BOOL' | 'INTEGER' | 'PERCENTAGE' | 'DATE';
  category?: string;
  description?: string;
  showOnCreate: boolean;
  accessibility: 'hidden' | 'editable' | 'readOnly';
  availableValues: object;
}

/** GetCompanyDynamicsProfile */
export interface GetCompanyDynamicsProfile {
  dynamicsId?: string;
  prospectId?: string;
  info?: string;
}

export type GetCompanyPartnerCenterProfile = {
  microsoftId: string;
  microsoftIdLink: string;
  domain: string;
  partnerId?: string;
  ownPartnerId: string;
};

/** GetDnsProfile */
export interface GetDnsProfile {
  tenantId?: number;
}

/** GetCompanyPartnerPricingProfile */
export interface GetCompanyPartnerPricingProfile {
  rebates?: GetCompanyPartnerPricingProfileRebate[];
}

/** GetCompanyPartnerPricingProfileRebate */
export interface GetCompanyPartnerPricingProfileRebate {
  productTypeId: number;
  productType: string;
  rebatePercentage?: number;
  marginPercentage?: number;
}

/** GetCompanyInvoiceProfile */
export interface GetCompanyInvoiceProfile {
  deliveryMethod: DeliveryMethod;
  paymentTerms: number;
  invoiceTo: number;
  invoiceEmails: string[];
}

/** GetLinkResponse */
export interface GetLinkResponse {
  name: string;
  url: string;
}

/** GetContactImpersonateResponse */
export interface GetContactImpersonateResponse {
  url: string;
}

/** AddressType */
export type AddressType = 'main' | 'invoice' | 'delivery' | 'install';

/** DeliveryMethod */
export type DeliveryMethod = 'email' | 'post';

/** CompanyStatus */
export type CompanyStatus = 'inactive' | 'active' | 'toBeValidated';

/** Gender */
export type Gender = 'nd' | 'm' | 'f' | 'o';

/** ColorType */
export type ColorType =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'quaternary'
  | 'quinary'
  | 'senary'
  | 'septenary'
  | 'octonary'
  | 'nonary'
  | 'denary';

export interface GetMicrosoftAgreementResponse {
  cloudAgreementId?: number;
  guid?: string;
  companyGuid?: string;
  cspMicrosoftId?: string;
  cspMicrosoftIdLInk?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  language?: string;
  dateAgreed?: string;
  isSent?: boolean;
  dateCreated?: string;
  agreementType?: string;
}

export interface GetCitiesParams {
  zipCode?: string;
  name?: string;
  take?: number;
}

export interface GetCompaniesOverviewParams {
  /** @default 1 */
  pageIndex?: number;
  /** @default 20 */
  pageSize?: number;
  search?: string;
  statuses?: CompanyStatus[];
  profiles?: number[];
  showDynamicsError?: boolean;
}

export interface GetCompaniesForProfileParams {
  profileId: number;
  search?: string;
}

export interface GetLinkedCompaniesParams {
  /** @default 1 */
  pageIndex?: number;
  /** @default 20 */
  pageSize?: number;
  search?: string;
  profile?: string;
  id: number;
}

export interface GetNaceCodesParams {
  search?: string;
  take?: number;
}

export interface GetContactsForCompanyParams {
  companyId: number;
}

export interface GetCountriesParams {
  includeVatRegimes: boolean;
}

import axios, { AxiosInstance, AxiosRequestConfig, ResponseType } from 'axios';

type HeaderValue = string | string[] | number | boolean | null;

interface RawHeaders {
  [key: string]: HeaderValue;
}

interface HeadersDefaults {
  common: RawHeaders;
  delete: RawHeaders;
  get: RawHeaders;
  head: RawHeaders;
  post: RawHeaders;
  put: RawHeaders;
  patch: RawHeaders;
  options?: RawHeaders;
  purge?: RawHeaders;
  link?: RawHeaders;
  unlink?: RawHeaders;
}

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || 'http://localhost:3000' });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<T> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance
      .request({
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
        },
        params: query,
        responseType: responseFormat,
        data: body,
        url: path,
      })
      .then((response) => response.data?.response);
  };
}

/**
 * @title CompanyModule
 * @version 1.0
 * @baseUrl http://localhost:3000
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * @description get cities
     *
     * @name GetCities
     * @summary Your GET endpoint
     * @request GET:/api/company/v2/cities
     */
    getCities: (query: GetCitiesParams, params: RequestParams = {}) =>
      this.request<GetCityResponse[], any>({
        path: `/api/company/v2/cities`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetCompaniesOverview
     * @summary Your GET endpoint
     * @request GET:/api/company/v2/companies
     */
    getCompaniesOverview: (query: GetCompaniesOverviewParams, params: RequestParams = {}) =>
      this.request<GetCompanyOverviewResponse, any>({
        path: `/api/company/v2/companies`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateCompany
     * @request PUT:/api/company/v2/companies
     */
    updateCompany: (
      data: {
        metadatas?: {
          metadataId?: number;
          value?: string;
        }[];
        companyId: number;
        companyNumber: string;
        name: string;
        vatRegimeCode: string;
        vatNumber?: string;
        currency: string;
        language: string;
        bic?: string;
        iban?: string;
        website?: string;
        remarks?: string;
        email: string;
        phoneNumber?: string;
        registrationType?: string;
        naceCodeId?: number;
        status?: CompanyStatus;
        invoiceProfile: {
          deliveryMethod: 'post' | 'email';
          paymentTerms: number;
          invoiceTo: number;
          invoiceEmails?: string[];
        };
        partnerPricingProfile?: {
          rebates?: {
            productTypeId?: number;
            rebatePercentage?: number;
            marginPercentage?: number;
          }[];
        };
        dnsProfile?: {
          tenantId?: number;
        };
        partnerCenterProfile?: {
          microsoftId?: string;
          domain?: string;
          partnerId?: string;
          ownPartnerId?: string;
        };
        addresses: {
          addressId: number;
          addressType: 'main' | 'invoice' | 'delivery' | 'install';
          street: string;
          number: string;
          zipCode: string;
          city: string;
          countryId: number;
          name?: string;
          extraInfo?: string;
        }[];
        companyProfiles: {
          companyProfileId: number;
          profileId: number;
          parentCompanyId?: number;
          profile: string;
        }[];
      },
      params: RequestParams = {}
    ) =>
      this.request<GetCompanyDetailResponse, any>({
        path: `/api/company/v2/companies`,
        method: 'PUT',
        body: data,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateCompany
     * @request POST:/api/company/v2/companies
     */
    createCompany: (
      data: {
        metadatas?: {
          metadataId?: number;
          value?: string;
        }[];
        companyNumber: string;
        name: string;
        vatRegimeCode: string;
        vatNumber?: string;
        currency: string;
        language: string;
        bic?: string;
        iban?: string;
        website?: string;
        remarks?: string;
        email: string;
        phoneNumber?: string;
        registrationType?: string;
        status?: CompanyStatus;
        naceCodeId?: number;
        invoiceProfile: {
          deliveryMethod: DeliveryMethod;
          paymentTerms: number;
          invoiceTo: number;
          invoiceEmails?: string[];
        };
        partnerPricingProfile?: {
          rebates?: {
            productTypeId: number;
            rebatePercentage?: number;
            marginPercentage?: number;
          }[];
        };
        partnerCenterProfile?: {
          microsoftId: string;
          domain: string;
          partnerId?: string;
          ownPartnerId?: string;
        };
        addresses: {
          addressId: number;
          addressType: AddressType;
          street: string;
          number: string;
          zipCode: string;
          city: string;
          countryId: number;
          name?: string;
          extraInfo?: string;
        }[];
        companyProfiles: {
          companyProfileId: number;
          profileId: number;
          parentCompanyId?: number;
          profile: string;
        }[];
      },
      params: RequestParams = {}
    ) =>
      this.request<GetCompanyDetailResponse, any>({
        path: `/api/company/v2/companies`,
        method: 'POST',
        body: data,
        format: 'json',
        ...params,
      }),

    /**
     * @description get parent companies
     *
     * @name GetCompaniesForProfile
     * @summary Your GET endpoint
     * @request GET:/api/company/v2/companies/parents
     */
    getCompaniesForProfile: (query: GetCompaniesForProfileParams, params: RequestParams = {}) =>
      this.request<GetCompanyForProfileResponse, any>({
        path: `/api/company/v2/companies/parents`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description get linkes companies (child companies)
     *
     * @name GetLinkedCompanies
     * @summary Your GET endpoint
     * @request GET:/api/company/v2/companies/{id}/linked
     */
    getLinkedCompanies: ({ id, ...query }: GetLinkedCompaniesParams, params: RequestParams = {}) =>
      this.request<GetChildCompanyOverviewResponse, any>({
        path: `/api/company/v2/companies/${id}/linked`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description get company detail
     *
     * @name GetCompanyDetailById
     * @summary Your GET endpoint
     * @request GET:/api/company/v2/companies/{id}
     */
    getCompanyDetailById: (id: number, params: RequestParams = {}) =>
      this.request<GetCompanyDetailResponse, any>({
        path: `/api/company/v2/companies/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description get company detail
     *
     * @name GetCompanyDetailByGuid
     * @summary Your GET endpoint
     * @request GET:/api/company/v2/companies/{guid}
     */
    getCompanyDetailByGuid: (guid: string, params: RequestParams = {}) =>
      this.request<GetCompanyDetailResponse, any>({
        path: `/api/company/v2/companies/${guid}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description get company profiles
     *
     * @name GetProfiles
     * @summary Your GET endpoint
     * @request GET:/api/company/v2/companies/profiles
     */
    getProfiles: (params: RequestParams = {}) =>
      this.request<GetProfileResponse[], any>({
        path: `/api/company/v2/companies/profiles`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description get vat regimes
     *
     * @name GetVatRegimes
     * @summary Your GET endpoint
     * @request GET:/api/company/v2/companies/vatregimes
     */
    getVatRegimes: (params: RequestParams = {}) =>
      this.request<GetVatRegimeResponse[], any>({
        path: `/api/company/v2/companies/vatregimes`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description get invoice party types
     *
     * @name GetPartyTypes
     * @summary Your GET endpoint
     * @request GET:/api/company/v2/companies/partytypes
     */
    getPartyTypes: (params: RequestParams = {}) =>
      this.request<GetInvoicePartyTypeResponse[], any>({
        path: `/api/company/v2/companies/partytypes`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name ValidateCompany
     * @request POST:/api/company/v2/companies/{id}/validate
     */
    validateCompany: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/company/v2/companies/${id}/validate`,
        method: 'POST',
        ...params,
      }),

    /**
     * No description
     *
     * @name InactivateCompany
     * @request POST:/api/company/v2/companies/{id}/inactivate
     */
    inactivateCompany: (id: string, params: RequestParams = {}) =>
      this.request<GetCompanyDetailResponse, any>({
        path: `/api/company/v2/companies/${id}/inactivate`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetNaceCodes
     * @summary Your GET endpoint
     * @request GET:/api/company/v2/companies/nacecodes
     */
    getNaceCodes: (query: GetNaceCodesParams, params: RequestParams = {}) =>
      this.request<GetNaceCodeResponse[], any>({
        path: `/api/company/v2/companies/nacecodes`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description get company contacts
     *
     * @name GetContactsForCompany
     * @summary Your GET endpoint
     * @request GET:/api/company/v2/contacts
     */
    getContactsForCompany: (query: GetContactsForCompanyParams, params: RequestParams = {}) =>
      this.request<GetContactResponse[], any>({
        path: `/api/company/v2/contacts`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description update company contact
     *
     * @name UpdateContact
     * @request PUT:/api/company/v2/contacts
     */
    updateContact: (
      data: {
        metadatas: {
          metadataId: number;
          value?: string;
        }[];
        contactId: number;
        companyId: number;
        contactTypeId: number;
        division?: string;
        name: string;
        firstName: string;
        language: string;
        email: string;
        phoneNumber?: string;
        remarks?: string;
        gender: Gender;
        active: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetContactResponse, any>({
        path: `/api/company/v2/contacts`,
        method: 'PUT',
        body: data,
        format: 'json',
        ...params,
      }),

    /**
     * @description add contact to company
     *
     * @name CreateContact
     * @request POST:/api/company/v2/contacts
     */
    createContact: (
      data: {
        metadatas: {
          metadataId: number;
          value?: string;
        }[];
        contactId: number;
        companyId: number;
        contactTypeId: number;
        division?: string;
        name: string;
        firstName: string;
        language: string;
        email: string;
        phoneNumber?: string;
        remarks?: string;
        gender: Gender;
        active: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetContactResponse, any>({
        path: `/api/company/v2/contacts`,
        method: 'POST',
        body: data,
        format: 'json',
        ...params,
      }),

    /**
     * @description get contact detail
     *
     * @name GetContact
     * @summary Your GET endpoint
     * @request GET:/api/company/v2/contacts/{contactid}
     */
    getContact: (contactid: number, params: RequestParams = {}) =>
      this.request<GetContactResponse, any>({
        path: `/api/company/v2/contacts/${contactid}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description delete contact
     *
     * @name DeleteContact
     * @request DELETE:/api/company/v2/contacts/{contactid}
     */
    deleteContact: (contactid: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/company/v2/contacts/${contactid}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * @description get contact types
     *
     * @name GetContactTypes
     * @summary Your GET endpoint
     * @request GET:/api/company/v2/contacts/types
     */
    getContactTypes: (params: RequestParams = {}) =>
      this.request<GetContactTypeResponse[], any>({
        path: `/api/company/v2/contacts/types`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description invite contact
     *
     * @name InviteContact
     * @request POST:/api/company/v2/contacts/{contactid}/invite
     */
    inviteContact: (contactid: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/company/v2/contacts/${contactid}/invite`,
        method: 'POST',
        ...params,
      }),

    /**
     * @description get impersonate url
     *
     * @name ImpersonateContact
     * @summary Your GET endpoint
     * @request GET:/api/company/v2/contacts/{contactid}/impersonate/redirect
     */
    impersonateContact: (contactid: number, params: RequestParams = {}) =>
      this.request<GetContactImpersonateResponse, any>({
        path: `/api/company/v2/contacts/${contactid}/impersonate/redirect`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description get countries
     *
     * @name GetCountries
     * @summary Your GET endpoint
     * @request GET:/api/company/v2/countries
     */
    getCountries: (query: GetCountriesParams, params: RequestParams = {}) =>
      this.request<GetCountryResponse[], any>({
        path: `/api/company/v2/countries`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description get DNS tenants
     *
     * @name GetDnsTenants
     * @summary Your GET endpoint
     * @request GET:/api/company/v2/dnstenants
     */
    getDnsTenants: (params: RequestParams = {}) =>
      this.request<GetDnsTenantsResponse[], any>({
        path: `/api/company/v2/dnstenants`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description get company metadata's
     *
     * @name GetCompanyMetadatas
     * @summary Your GET endpoint
     * @request GET:/api/company/v2/metadatas/company
     */
    getCompanyMetadatas: (params: RequestParams = {}) =>
      this.request<GetMetadataResponse[], any>({
        path: `/api/company/v2/metadatas/company`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description get contact metadatas
     *
     * @name GetContactMetadatas
     * @summary Your GET endpoint
     * @request GET:/api/company/v2/metadatas/contact
     */
    getContactMetadatas: (params: RequestParams = {}) =>
      this.request<GetMetadataResponse[], any>({
        path: `/api/company/v2/metadatas/contact`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
}
