import BaseSvgWrapper from './BaseSvgWrapper';

const Access = () => (
  <BaseSvgWrapper>
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.6363 26.2071C18.5317 26.2071 17.6363 27.1025 17.6363 28.2071V45.6201C17.6363 46.7247 18.5317 47.6201 19.6363 47.6201H39.376C40.4805 47.6201 41.376 46.7247 41.376 45.6201V28.2071C41.376 27.1025 40.4806 26.2071 39.376 26.2071H19.6363ZM21.488 28.9474C20.9357 28.9474 20.488 29.3951 20.488 29.9474V43.9554C20.488 44.5077 20.9357 44.9554 21.488 44.9554H37.5432C38.0954 44.9554 38.5432 44.5077 38.5432 43.9554V29.9474C38.5432 29.3951 38.0954 28.9474 37.5432 28.9474H21.488Z"
        />
        <path d="M20.9866 26.2071C20.9866 23.9475 21.8842 21.7806 23.4819 20.1828C25.0796 18.5851 27.2466 17.6875 29.5061 17.6875C31.7656 17.6875 33.9326 18.5851 35.5303 20.1828C37.128 21.7806 38.0256 23.9475 38.0256 26.2071L35.3231 26.2071C35.3231 24.6643 34.7102 23.1847 33.6193 22.0938C32.5284 21.003 31.0489 20.3901 29.5061 20.3901C27.9633 20.3901 26.4838 21.003 25.3929 22.0938C24.302 23.1847 23.6891 24.6643 23.6891 26.2071L20.9866 26.2071Z" />
        <ellipse cx="29.5061" cy="35.7005" rx="2.87797" ry="2.87797" />
        <rect x="28.1803" y="37.1252" width="2.65162" height="3.87937" rx="0.974546" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.55 8.05959H45.9969C48.2078 8.05959 50 9.85177 49.9984 12.0627V38.5471C49.9984 40.758 48.2063 42.5502 45.9953 42.5502H44.6532V39.4236H45.9969C46.4813 39.4236 46.875 39.0299 46.875 38.5455H46.8734V12.0627C46.8734 11.5783 46.4797 11.1846 45.9953 11.1846H4.00313C3.51875 11.1846 3.125 11.5783 3.125 12.0627V14.0518V38.5455C3.125 39.0299 3.51875 39.4236 4.00313 39.4236H14.6379V42.5502H4.00313C1.79219 42.5502 0 40.758 0 38.5471V14.0533V12.0643V2.3799H22.7953C24.6281 2.3799 26.3359 3.28771 27.3625 4.80802L29.55 8.05959ZM24.7703 6.55334C24.3266 5.89709 23.5891 5.5049 22.7953 5.5049H3.125V8.16271C3.40781 8.09865 3.70156 8.05959 4.00313 8.05959H25.7828L24.7703 6.55334Z"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="50" height="50" />
        </clipPath>
      </defs>
    </svg>
  </BaseSvgWrapper>
);

export default Access;
