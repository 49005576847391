import { setLocale } from 'yup';
import i18n from '../i18n';

setLocale({
  array: {
    max: ({ max }) => i18n.t('validation:array.max', { max }),
    min: ({ min }) => i18n.t('validation:array.min', { min }),
  },
  date: {
    max: ({ max }) => i18n.t('validation:dateConstraint.max', { max }),
    min: ({ min }) => i18n.t('validation:dateConstraint.min', { min }),
  },
  mixed: {
    oneOf: ({ values }) => i18n.t('validation:oneOf', { values }),
    required: () => i18n.t('validation:required'),
  },
  number: {
    positive: () => i18n.t('validation:number.positive'),
    max: ({ max }) => i18n.t('validation:number.max', { max }),
    min: ({ min }) => i18n.t('validation:number.min', { min }),
    lessThan: ({ less }) => i18n.t('validation:number.lessThan', { less }),
    moreThan: ({ more }) => i18n.t('validation:number.moreThan', { more }),  
  },
  string: {
    email: () => i18n.t('validation:string.email'),
    max: ({ max }) => i18n.t('validation:string.max', { max }),
    min: ({ min }) => i18n.t('validation:string.min', { min }),
  },
});
