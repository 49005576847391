import { TenantTheme } from '../tenants/unbrace';

const companyTheme = (theme: TenantTheme) => ({
  /* tslint:disable:object-literal-sort-keys */
  address: {
    activeColor: theme.palette.primary.shade3,
    borderColor: theme.palette.neutral.shade7,
    boxShadow: theme.globals.boxShadow,
  },
  contact: {
    card: {
      background: theme.palette.neutral.shade0,
      borderRadius: theme.globals.borderRadius,
      boxShadow: '2px 6px 34px rgba(0, 0, 0, 0.08), 1px 3px 6px rgba(0, 0, 0, 0.04)',
      hoverBoxShadow: '2px 6px 34px rgba(0, 0, 0, 0.12), 1px 3px 6px rgba(0, 0, 0, 0.1)',
    },
    division: {
      color: theme.palette.neutral.shade4,
      fontSize: theme.typography.fontSize.small_5,
    },
    emptyState: {
      color: theme.palette.neutral.shade4,
    },
    icon: {
      isActive: {
        yes: theme.palette.success.shade3,
        no: theme.palette.danger.shade4,
      },
      hover: {
        background: theme.palette.neutral.shade3,
      },
    },
    info: {
      border: `1px solid ${theme.palette.neutral.shade3}`,
      color: {
        header: theme.palette.neutral.shade9,
        main: theme.palette.neutral.shade6,
        modalMain: theme.palette.neutral.shade8,
        modalSubTitle: theme.palette.neutral.shade4,
        hover: theme.palette.neutral.shade7,
      },
      fontSize: {
        header: theme.typography.fontSize.medium_6,
        main: theme.typography.fontSize.regular,
        metaDataTitle: theme.typography.fontSize.medium_2,
      },
      fontWeight: {
        header: 600,
        modalMain: 400,
        modalSubTitle: 400,
      },
    },
  },
  primaryInfo: {
    color: theme.palette.neutral.shade9,
    fontSize: {
      header: theme.typography.fontSize.medium_6,
      subHeader: theme.typography.fontSize.medium_4,
      description: theme.typography.fontSize.small_5,
    },
    fontWeight: 600,
  },
  listItem: {
    nameColor: theme.palette.neutral.shade7,
    subTextColor: theme.palette.neutral.shade3,
    vatTextColor: theme.palette.neutral.shade4,
  },
});

export default companyTheme;
