import * as React from 'react';
import { Route } from 'react-router-dom';
import { DATAQUALITY_BASE_ROUTES } from '.';
import { loadScene } from '../../../components';
import ModuleRoutes from '../../../components/routing/ModuleRoutes';
import { Module } from '../../security/types/modules';
import { DATAQUALITY_MODULE_KEY } from '../constants';

type Props = {
  allowedModules: Module[];
};

const dataQualityOverviewScene = loadScene('dataquality', 'DataQualityScene');

const DataQualityRoutes: React.FunctionComponent<Props> = ({ allowedModules }: Props) => (
  <ModuleRoutes allowedModules={allowedModules} moduleKey={DATAQUALITY_MODULE_KEY}>
    <Route path={DATAQUALITY_BASE_ROUTES} exact component={dataQualityOverviewScene} />
  </ModuleRoutes>
);

export default DataQualityRoutes;
