import { FieldProps, useFormikContext } from 'formik';
import * as React from 'react';
import isEqual from 'lodash.isequal';
import { Props as ReactSelectProps } from 'react-select';
import { Select } from '../';
import styled, { css } from '../../config/theme';
import { getNestedError } from '../../helpers';
import { ErrorText } from './ErrorText';
import { isRequiredField } from './helpers/formikFieldHelpers';
import { Label } from './Label';
import { Option } from './Select';

export type Props<T> = FieldProps &
  ReactSelectProps<T> & {
    customTabIndex?: number;
    errorAsBlock?: boolean;
    isCapitalized?: boolean;
    label?: string;
    onChange?: (option: T, action: any) => void;
    options?: T[] | Option[];
    getOptionValue?: (option: T) => string;
    getOptionLabel?: (option: T) => string;
    deprecated?: boolean;
    objectAsValue?: boolean;
    zIndex?: number;
    isRequired?: boolean;
  };

const FormikSelect = <T extends {}>({
  async,
  customTabIndex,
  deprecated,
  errorAsBlock,
  field,
  form,
  getOptionValue,
  isCapitalized,
  label,
  onChange,
  options,
  zIndex,
  objectAsValue,
  theme,
  isRequired,
  ...props
}: Props<T>) => {
  const { setFieldValue } = useFormikContext();

  React.useEffect(() => {
    form.validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (options?.length === 1 && isRequiredField(form.errors, field.name) && !field.value) {
      setFieldValue(field.name, getOptionValue ? getOptionValue(options[0] as T) : (options[0] as any).value);
    }
  }, [form.errors, field.name, setFieldValue, options, field.value, getOptionValue]);

  const getDeprecatedValue = React.useMemo(
    () =>
      options
        ? (options as Option[]).find(
            (option) => option.value?.toString() === (field.value && field.value.toString())
          ) || {
            label: '',
            value: '',
          }
        : { label: '', value: undefined },

    [field.value, options]
  );

  const getValue = React.useCallback(
    (opts: T[], val: any): T[] => {
      if (async) {
        return val || null;
      }
      if (Array.isArray(val)) {
        return opts.filter((option: any) => val.includes(getOptionValue ? getOptionValue(option) : option.value));
      }

      return opts.filter((option: any) =>
        objectAsValue ? isEqual(option, val) : getOptionValue ? getOptionValue(option) === val : val === option.value
      );
    },
    [getOptionValue, async, objectAsValue]
  );

  const enhancedOnchange = React.useCallback(
    (option: T, action: any) => {
      if (onChange) {
        onChange(option, action);
      }
      if (async && !deprecated) {
        return setFieldValue(field.name, option || []);
      }
      if (Array.isArray(option)) {
        return setFieldValue(
          field.name,
          option.map((val: T) => (getOptionValue ? getOptionValue(val) : (val as any).value))
        );
      }
      if (option === null) {
        setFieldValue(field.name, null);
      } else {
        setFieldValue(
          field.name,
          objectAsValue ? option : getOptionValue ? getOptionValue(option) : (option as any).value
        );
      }
    },
    [setFieldValue, field.name, onChange, async, deprecated, getOptionValue, objectAsValue]
  );

  const onBlur = React.useCallback(() => {
    form.setFieldTouched(field.name);
  }, [field, form]);

  const error = props.error || getNestedError(field.name, form.errors, form.touched);

  return (
    <SelectWrapper>
      {label && <Label htmlFor={field.name}>{`${label}${isRequired ? '*' : ''}`}</Label>}
      <SelectWithMargin className="formik-select" isCapitalized={isCapitalized}>
        <Select
          {...props}
          name={field.name}
          onBlur={onBlur}
          onChange={enhancedOnchange}
          options={options}
          tabIndex={customTabIndex}
          value={deprecated ? getDeprecatedValue : getValue((options as T[]) || [], field.value)}
          async={async}
          getOptionValue={getOptionValue}
          zIndex={zIndex}
        />
      </SelectWithMargin>
      {error && <ErrorText block={errorAsBlock}>{error}</ErrorText>}
    </SelectWrapper>
  );
};

const SelectWithMargin = styled.div<{ isCapitalized?: boolean }>`
  ${(props) =>
    props.isCapitalized &&
    css`
      text-transform: capitalize;
    `};
  margin: 8px 0 25px;
`;

const SelectWrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
`;

export default FormikSelect;
