import AxiosConfig, { RequestConfig } from '../../../config/api/AxiosConfig';
import { IDENTITY_URL } from './endpoints';

class IdentityApi {
  public async resetOtherUserPassword(guid: string) {
    const requestConfig: RequestConfig = {
      url: `${IDENTITY_URL}/${guid}/reset-password`,
      data: { onBehalfOfMainApplication: true } 
    };

    return AxiosConfig.fetchAxiosPostRequest(requestConfig);
  }

  public async resetPassword() {
    const requestConfig: RequestConfig = {
      url: `${IDENTITY_URL}/reset-password`,
    };

    return AxiosConfig.fetchAxiosPostRequest(requestConfig);
  }

  public async resetAuthenticator(guid: string) {
    const requestConfig: RequestConfig = {
      url: `${IDENTITY_URL}/${guid}/reset-authenticator`,
    };

    return AxiosConfig.fetchAxiosPostRequest(requestConfig);
  }
}

export default new IdentityApi();
