import { Tooltip } from '@unbrace/components';
import { MetadataResponse } from 'adapters/products/productsAPI';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ProductInfo } from '../';
import { Paper } from '../../../../components';
import { FullViewBlock, FullViewItem, FullViewWrapper } from '../../../../components/FullView';
import { Close } from '../../../../components/icons';
import { groupBy } from '../../../../helpers';
import { Group } from '../../../../helpers/groupBy';
import { getMetaDataValue } from '../../../../helpers/metaData';
import { MetaDataAccess, MetaDataValue } from '../../../../types/metadata';
import PageWrapper from '../../../app/components/PageWrapper';
import { Product, ProductTranslation } from '../../types/product';
import PartnerCenterInfo from './PartnerCenterInfo';

type Props = {
  baseHeader: JSX.Element;
  product?: Product;
  setShowFullView: (val: boolean) => void;
};

const ProductInfoFullView: React.FC<Props> = ({ baseHeader, product, setShowFullView }) => {
  const { t } = useTranslation('products');
  React.useEffect(() => {
    return () => {
      setShowFullView(false);
    };
  });

  const groupedMetaData =
    product?.metadatas && product?.metadatas.length > 0
      ? (groupBy(product?.metadatas, 'category') as Group<MetadataResponse>)
      : [];
  const closeFullView = React.useCallback(() => setShowFullView(false), [setShowFullView]);

  return (
    <PageWrapper>
      {React.cloneElement(baseHeader, {
        productGuid: product?.productGuid,
      })}
      <Paper>
        <FullViewWrapper>
          <Tooltip content={t('global:general.close')}>
            <Close onClick={closeFullView} className="close" />
          </Tooltip>
          <ProductInfo
            priceMethodDescription={product?.priceMethodDescription}
            productStatusId={product?.productStatusId}
            productStatusDescription={product?.productStatusDescription}
            productDescription={product?.translations && product?.translations[0].description}
            code={product?.code}
            productTypeDescription={product?.productTypeDescription}
          />
          <br />
          <FullViewBlock>
            <h2 className="block-title">{t('fullView.blockTitles.general')}</h2>
            <div className="block-data">
              <FullViewItem label={t('fullView.labels.code')} value={product?.code} />
              <FullViewItem label={t('fullView.labels.type')} value={product?.productTypeDescription} />
              <FullViewItem label={t('fullView.labels.priceMethod')} value={product?.priceMethodDescription} />
              <FullViewItem label={t('fullView.labels.status')} value={product?.productStatusDescription} />
            </div>
          </FullViewBlock>
          <FullViewBlock>
            <h2 className="block-title">{t('fullView.blockTitles.description')}</h2>
            <div className="block-data">
              {product?.translations.map((item: ProductTranslation) => (
                <FullViewItem
                  key={item.productTranslationId}
                  label={t(`global:languages.full.${item.language}`)}
                  value={item.description}
                />
              ))}
            </div>
          </FullViewBlock>
          {product?.partnerPricingProfile?.rebateGroupId && (
            <FullViewBlock>
              <h2 className="block-title">{t('fullView.blockTitles.pricingProfile')}</h2>
              <div className="block-data">
                <FullViewItem
                  key={product?.partnerPricingProfile.rebateGroupId}
                  label={t(`fullView.labels.rebateGroup`)}
                  value={product?.partnerPricingProfile.rebateGroup}
                />
              </div>
            </FullViewBlock>
          )}
          <PartnerCenterInfo partnerCenterProfile={product?.partnerCenterProfile} />
          {product?.metadatas &&
            product?.metadatas.length > 0 &&
            Object.keys(groupedMetaData).map((key: string) => (
              <FullViewBlock key={key}>
                <h2 className="block-title">{key !== 'undefined' ? key : t('global:general.other')}</h2>
                <div className="block-data">
                  {groupedMetaData[key].map((metaDataItem: MetaDataValue) =>
                    metaDataItem.accessibility === MetaDataAccess.ReadOnly.toString() ||
                    metaDataItem.accessibility === MetaDataAccess.Editable.toString() ? (
                      <FullViewItem
                        key={metaDataItem.metadataId}
                        label={metaDataItem.code || ''}
                        value={getMetaDataValue(metaDataItem)}
                      />
                    ) : null
                  )}
                </div>
              </FullViewBlock>
            ))}
        </FullViewWrapper>
      </Paper>
    </PageWrapper>
  );
};

export default ProductInfoFullView;
