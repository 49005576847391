import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const Invoice: React.FC = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 48 48"
      enableBackground="new 0 0 48 48"
      xmlSpace="preserve"
    >
      <g>
        <path d="M47.4,18.7c-0.3-1.1-1.4-1.8-2.5-1.4c-1,0.3-1.5,1.3-1.3,2.3c1.5,6.7-0.4,13.7-5.4,18.6C31.4,45,20.8,45.9,13,40.8l1.5-1.5   c0.7-0.7,0.3-1.9-0.7-2l-7.4-0.9c-0.8-0.1-1.4,0.6-1.3,1.3L6.1,45c0.1,1,1.3,1.4,2,0.7l2.1-2.1c9.4,6.6,22.4,5.7,30.7-2.7   C46.9,35.1,49.2,26.7,47.4,18.7z" />
        <path d="M9.8,9.8C16.6,3,27.2,2.1,35,7.2l-1.5,1.5c-0.7,0.7-0.3,1.9,0.7,2l7.4,0.9c0.8,0.1,1.4-0.6,1.3-1.3L41.9,3   c-0.1-1-1.3-1.4-2-0.7l-2.1,2.1C28.4-2.2,15.4-1.3,7,7c-5.9,5.9-8.3,14.3-6.4,22.3c0.3,1.1,1.4,1.8,2.5,1.4c1-0.3,1.5-1.3,1.3-2.3   C2.9,21.8,4.9,14.7,9.8,9.8z" />
        <path d="M25.3,36.4v-2.2c2-0.1,3.7-0.7,4.9-1.8c1.2-1.1,1.9-2.5,1.9-4.3c0-1.8-0.5-3.1-1.6-4c-1.1-0.9-2.8-1.7-5.1-2.2h-0.1v-5.6   c1.5,0.2,3,0.8,4.3,1.7l2-2.8c-2-1.3-4-2.1-6.2-2.2v-1.5h-1.9v1.5c-1.9,0.1-3.5,0.7-4.7,1.8c-1.2,1.1-1.8,2.5-1.8,4.2   c0,1.7,0.5,3,1.6,3.9c1.1,0.9,2.7,1.6,5,2.1V31c-1.8-0.3-3.5-1.2-5.2-2.6L16,31c2.2,1.9,4.6,3,7.4,3.3v2.2H25.3z M25.3,25.7   c1.2,0.4,2,0.7,2.5,1.2c0.4,0.4,0.7,1,0.7,1.7c0,0.7-0.3,1.3-0.8,1.7c-0.6,0.5-1.3,0.7-2.3,0.8V25.7z M21.1,20.3   c-0.4-0.4-0.6-0.9-0.6-1.6c0-0.7,0.3-1.2,0.8-1.7c0.5-0.4,1.2-0.7,2.1-0.8v5.2C22.2,21.1,21.5,20.7,21.1,20.3z" />
      </g>
    </svg>
  </BaseSvgWrapper>
);

export default Invoice;
