import * as React from 'react';
import { Label, Value } from './ItemComponents';
import { FullViewBlockItem } from './';

type Props = {
  label: string;
  value: JSX.Element;
};

const EditableItem: React.FC<Props> = ({ label, value, ...props }: Props) => {
  return (
    <FullViewBlockItem key={label}>
      <Label asInputLabel bold htmlFor={label} fixedLineHeight>
        {label}
      </Label>
      <Value {...props}>{value}</Value>
    </FullViewBlockItem>
  );
};

export default EditableItem;
