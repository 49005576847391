import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const Rebate: React.FC = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.4942 0.550694C33.1772 0.550694 36.5685 4.08372 37.3017 9.69886H44.2396C45.3442 9.69886 46.2396 10.5943 46.2396 11.6989V47.4493C46.2396 48.5539 45.3442 49.4493 44.2396 49.4493H12.7488C11.7035 49.4493 10.8455 48.6474 10.7564 47.6252H36.955V46.1694H43.5798V12.9911H37.3017V17.5645H36.955V11.9755H10.7488V11.6999C10.7488 10.5954 11.6442 9.69992 12.7488 9.69992H19.6461C20.3794 4.08372 23.8112 0.550694 28.4942 0.550694ZM34.6868 9.69886C33.9999 6.20725 31.488 3.60654 28.4942 3.60654C25.5013 3.60654 22.9885 6.20725 22.3016 9.69886H34.6868Z"
      />
      <path d="M31.3246 37.0816C31.3246 39.1401 29.6559 40.8087 27.5975 40.8087C25.539 40.8087 23.8703 39.1401 23.8703 37.0816C23.8703 35.0232 25.539 33.3545 27.5975 33.3545C29.6559 33.3545 31.3246 35.0232 31.3246 37.0816ZM26.1066 37.0816C26.1066 37.905 26.7741 38.5725 27.5975 38.5725C28.4208 38.5725 29.0883 37.905 29.0883 37.0816C29.0883 36.2582 28.4208 35.5908 27.5975 35.5908C26.7741 35.5908 26.1066 36.2582 26.1066 37.0816Z" />
      <path d="M19.1413 26.6162C19.1413 28.6747 17.4726 30.3434 15.4141 30.3434C13.3557 30.3434 11.687 28.6747 11.687 26.6162C11.687 24.5578 13.3557 22.8891 15.4141 22.8891C17.4726 22.8891 19.1413 24.5578 19.1413 26.6162ZM13.9233 26.6162C13.9233 27.4396 14.5907 28.1071 15.4141 28.1071C16.2375 28.1071 16.905 27.4396 16.905 26.6162C16.905 25.7928 16.2375 25.1254 15.4141 25.1254C14.5907 25.1254 13.9233 25.7928 13.9233 26.6162Z" />
      <rect x="27.6362" y="23.5473" width="3.07401" height="20.4203" transform="rotate(45 27.6362 23.5473)" />
      <path d="M30.3133 9.69886C29.58 4.08372 26.1888 0.550693 21.5058 0.550693C16.8227 0.550693 13.391 4.08372 12.6577 9.69992H5.76035C4.65578 9.69992 3.76035 10.5954 3.76035 11.6999V47.4493C3.76035 48.5539 4.65578 49.4493 5.76035 49.4493H37.2512C38.3558 49.4493 39.2512 48.5539 39.2512 47.4493V11.6989C39.2512 10.5943 38.3558 9.69886 37.2512 9.69886H30.3133ZM21.5058 3.60654C24.4995 3.60654 27.0115 6.20725 27.6983 9.69886H15.3132C16.0001 6.20725 18.5129 3.60654 21.5058 3.60654ZM36.5914 46.1694H6.41582V12.9911H12.6577V17.5645H15.1208V12.9911H27.8907V17.5645H30.3133V12.9911H36.5914V46.1694Z" />
    </svg>
  </BaseSvgWrapper>
);

export default Rebate;
