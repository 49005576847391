export const RESPONSIVE_SMALL = 767;
export const RESPONSIVE_MEDIUM = 1024;
export const RESPONSIVE_LARGE = 1300;
export const RESPONSIVE_EXTRA_LARGE = 1600;

const isPhone = { maxWidth: RESPONSIVE_SMALL };
const isTablet = { maxWidth: RESPONSIVE_MEDIUM };
const isLaptopSM = { maxWidth: RESPONSIVE_LARGE };
const isLaptopLG = { maxWidth: RESPONSIVE_EXTRA_LARGE };
const isBigScreen = { minWidth: RESPONSIVE_EXTRA_LARGE + 1 };

const responsive = { isPhone, isTablet, isBigScreen, isLaptopLG, isLaptopSM };
export default responsive;
