import styled from 'styled-components';

const AvatarWrapper = styled.div<{ size: 'normal' | 'small' }>`
  display: inline-block;
  margin: auto;
  position: relative;
  vertical-align: middle;
  width: ${(props) => props.theme.avatar.width[props.size]};
  flex: 0 0 auto;

  & > span {
    background: ${(props) => props.color && props.theme.avatar.color && props.theme.avatar.color[props.color].bColor};
    color: ${(props) => props.color && props.theme.avatar.color && props.theme.avatar.color[props.color].tColor};
    font-size: ${(props) => props.theme.avatar.fontSize[props.size]};
    border-radius: 50%;
    display: block;
    height: ${(props) => props.theme.avatar.spanHeight[props.size]};
    line-height: ${(props) => props.theme.avatar.lineHeight[props.size]};
    padding: ${(props) => props.theme.avatar.padding[props.size]};
    text-align: center;
    width: ${(props) => props.theme.avatar.spanWidth[props.size]};
  }
`;

export default AvatarWrapper;
