import { TenantTheme } from '../tenants/unbrace';

const contextMenuTheme = (theme: TenantTheme) => ({
  background: theme.palette.neutral.shade0,
  borderRadius: theme.globals.borderRadiusSmall,
  boxShadow: '2px 6px 34px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.1)',
  color: {
    blockItem: theme.palette.neutral.shade9,
    blockItemHover: theme.palette.neutral.shade1,
    blockTitle: theme.palette.neutral.shade4,
    disabled: theme.palette.neutral.shade4,
  },
  fontSize: {
    blockItem: theme.typography.fontSize.regular,
    blockItemIcon: theme.typography.fontSize.regular,
    blockTitle: theme.typography.fontSize.small_4,
  },
  iconSize: {
    main: '24px',
    small: '16px',
  },
  fontWeight: {
    blockTitle: 600,
  },
});

export default contextMenuTheme;
