import { IconButton, Modal } from '@unbrace/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ContextMenu } from '../../../components';
import { ORIENTATION_TOP_RIGHT } from '../../../components/ContextMenu/constants';
import { AccountCircle } from '../../../components/icons';
import ProfileMenuContent from './ProfileMenuContent';
import ResetPasswordModal from './ResetPasswordModal';

type Props = {
  userName?: string;
  toggleShortcutModalVisibility: VoidFunction;
};

const ProfileMenu: React.FC<Props> = ({ userName, toggleShortcutModalVisibility }) => {
  const { t } = useTranslation('security');

  return (
    <Modal content={ResetPasswordModal}>
      {(toggleResetPasswordModal) => (
        <ContextMenu
          content={
            <ProfileMenuContent
              userName={userName}
              toggleResetPasswordModal={toggleResetPasswordModal}
              toggleShortcutModalVisibility={toggleShortcutModalVisibility}
            />
          }
          orientation={ORIENTATION_TOP_RIGHT}
          enhanceWithOnContext={false}
        >
          {({ toggleMenu }) => (
            <IconButton tooltip={t('profile.tooltip')} tooltipPosition="top" onClick={toggleMenu}>
              <AccountCircle />
            </IconButton>
          )}
        </ContextMenu>
      )}
    </Modal>
  );
};

export default ProfileMenu;
