import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import loginApi from '../../api/LoginApi';
import { loginActions } from '../actions/creators';
import { fetchUser } from './user';

function* loginSaga() {
  yield takeLatest(getType(loginActions.initiateLogin), initiateLogin);
  yield takeLatest(getType(loginActions.initiateRedirect), initiateRedirectLogin);
  yield takeLatest(getType(loginActions.initiateOauthSignIn), initiateOauthSignIn);
}

function* initiateLogin(action: ReturnType<typeof loginActions.initiateLogin>): any {
  const { request, success, failure } = loginActions.loginRequest;
  yield put(request());
  try {
    yield call(
      loginApi.login,
      action.payload.email,
      action.payload.password,
      `${window.location.origin}${action.payload.returnUrl}`
    );
    yield put(success());
    yield call(fetchUser);
    yield put(push(action.payload.returnUrl));
  } catch (e) {
    yield put(failure(e.response));
  }
}

function* initiateRedirectLogin(action: ReturnType<typeof loginActions.initiateRedirect>): any {
  const { request, success, failure } = loginActions.loginRedirectRequest;
  yield put(request());
  try {
    const redirect = yield call(loginApi.redirect, action.payload.returnUrl);
    const decodedURI = new URL(decodeURIComponent(redirect));
    yield put(success({ isSameOrigin: decodedURI.origin === window.location.origin }));

    if (decodedURI.origin === window.location.origin) {
      yield put(push(decodedURI.pathname));
    } else {
      window.location.href = decodedURI.href;
    }
  } catch (e) {
    yield put(failure(e.response));
  }
}

function* initiateOauthSignIn(action: ReturnType<typeof loginActions.initiateOauthSignIn>): any {
  const { request, success, failure } = loginActions.oauthSignInRequest;
  yield put(request());
  try {
    const redirect = yield call(loginApi.oauthSignIn, action.payload.searchQuery);
    yield put(success());
    window.location.href = redirect;
  } catch (e) {
    yield put(failure(e.response));
  }
}

export default loginSaga;
