import { MetaDataReference, MetaDataValue } from '../types/metadata';

export const mapMetaData = (references: MetaDataReference[], metaData: MetaDataValue[]) =>
  references.map((metaDataReference: MetaDataReference) => {
    const data = metaData.find((metaDataItem: MetaDataValue) => metaDataItem.code === metaDataReference.code);

    return { ...metaDataReference, ...data };
  });

export const getMetaDataValue = (metaDataItem: MetaDataValue) => {
  if (metaDataItem.dataType !== 'MULTISELECT') {
    return metaDataItem.value || '-';
  }
  const foundKey = Object.keys(metaDataItem.availableValues || {}).find(
    (key: string) => key.toLowerCase() === metaDataItem.value?.toLowerCase()
  );

  return foundKey && metaDataItem.availableValues && metaDataItem.availableValues[foundKey];
};
