import { createAction, createAsyncAction } from 'typesafe-actions';
import { SortOrder } from '@unbrace/components';
import { ScreenConfiguration, CustomFilter } from 'modules/app/types/filters';
import { HttpErrorResponse } from 'types';
import { SaveCustomFilterData } from '../../../types/filters';
import { SuccessCallback } from '../../../../../types/index';

export const setSearchText = createAction('FILTERS_SEARCH_SET', (action) => (searchText: string, filterKey: string) =>
  action({ searchText, filterKey })
);
export const clearSearchText = createAction('FILTERS_SEARCH_CLEAR', (action) => (filterKey: string) =>
  action(filterKey)
);

export const setPageIndex = createAction('FILTERS_PAGE_INDEX_SET', (action) => (pageIndex: number, filterKey: string) =>
  action({ pageIndex, filterKey })
);

export const setPageSize = createAction('FILTERS_PAGE_SIZE_SET', (action) => (pageSize: number, filterKey: string) =>
  action({ pageSize, filterKey })
);

export const setAdditionalFilters = createAction(
  'FILTERS_ADITIONAL_FILTERS_SET',
  (action) => (filters: { [key: string]: any }, filterKey: string) => action({ filters, filterKey })
);

export const clearAdditionalFilters = createAction(
  'FILTERS_ADDITIONAL_FILTERS_CLEAR',
  (action) => (filterKey: string) => action({ filterKey })
);

export const toggleColumnSort = createAction(
  'FILTERS_SORTING_SET',
  (action) => (columnNames: string[], filterKey: string, sortOrder?: SortOrder) =>
    action({ filterKey, columnNames, sortOrder })
);

export const replaceFilters = createAction('FILTERS_REPLACE', (action) => (filters: CustomFilter, filterKey: string) =>
  action({ filterKey, filters })
);

export const getCustomFilters = createAction('FILTERS_CUSTOM_GET', (action) => (screen: string) => action(screen));

export const getCustomFiltersRequest = createAsyncAction(
  'FILTERS_CUSTOM_GET_REQUEST',
  'FILTERS_CUSTOM_GET_SUCCESS',
  'FILTERS_CUSTOM_GET_REJECTED'
)<undefined, { screenConfigs: ScreenConfiguration[]; moduleKey: string }, HttpErrorResponse>();

export const saveCustomFilter = createAction(
  'FILTERS_CUSTOM_SAVE',
  (action) => (params: SaveCustomFilterData, successCallback?: SuccessCallback) => action({ params, successCallback })
);

export const saveCustomFilterRequest = createAsyncAction(
  'FILTERS_CUSTOM_SAVE_REQUEST',
  'FILTERS_CUSTOM_SAVE_SUCCESS',
  'FILTERS_CUSTOM_SAVE_REJECTED'
)<undefined, ScreenConfiguration, HttpErrorResponse>();

export const deleteCustomFilter = createAction('FILTERS_CUSTOM_DELETE', (action) => (id: number, screen: string) =>
  action({ id, screen })
);

export const deleteCustomFilterRequest = createAsyncAction(
  'FILTERS_CUSTOM_DELETE_REQUEST',
  'FILTERS_CUSTOM_DELETE_SUCCESS',
  'FILTERS_CUSTOM_DELETE_REJECTED'
)<undefined, { id: number; screen: string }, HttpErrorResponse>();
