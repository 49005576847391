import { ServicefeeFilterState } from '../types/globalInfo';

export const SERVICEFEE_BASE_URL = '/servicefee';
export const SERVICEFEE_MODULE_KEY = 'servicefee';
export const SERVICEFEE_PAGE_SIZE = 20;

export const servicefeeStatuses: ServicefeeFilterState[] = [
  {
    servicefeeStatusDescription: 'claimed',
    servicefeeStatusId: 0,
  },
  {
    servicefeeStatusDescription: 'unclaimed',
    servicefeeStatusId: 1,
  },
  {
    servicefeeStatusDescription: 'paid',
    servicefeeStatusId: 2,
  },
  {
    servicefeeStatusDescription: 'expired',
    servicefeeStatusId: 3,
  },
];
