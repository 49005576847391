import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Table, TableBody } from '@unbrace/components';
import { MultiSelectActions } from 'hooks/useMultiSelect';
import styled from '../../../../config/theme';
import { ChangedPrices } from '../../containers/ProductDefaultView';
import { Pricing } from '../../types/pricing';
import ProductPriceGraph from './ProductPriceGraph';
import ProductPricingRow from './ProductPricingRow';
import TableWrapper from 'components/Table/TableWrapper';

type Props = {
  changedPrices: ChangedPrices;
  handleDelete: (price: Pricing) => void;
  handleFormChange: (values: Pricing) => void;
  isEntity?: boolean;
  pricing: Pricing[];
  selectedPricing: Pricing[];
  selectedPricingActions: MultiSelectActions;
};

const ProductPricingGroup: React.FC<Props> = ({
  changedPrices,
  handleDelete,
  handleFormChange,
  isEntity,
  pricing,
  selectedPricing,
  selectedPricingActions,
}) => {
  const { t } = useTranslation('products');
  const [first] = pricing;

  return (
    <React.Fragment>
      <PricingGroup>
        <h1>{isEntity ? `${t('pricing.pricingPerEntityTitle')} ${first.entityName}` : t('pricing.pricingTitle')}</h1>
        <TableWrapper>
          <Table>
            <TableBody>
              {pricing &&
                pricing.map((price: Pricing) => (
                  <ProductPricingRow
                    handleDelete={handleDelete}
                    handleFormChange={handleFormChange}
                    key={price.priceId}
                    price={(price.priceId && changedPrices[price.priceId]) || price}
                    selectedPricing={selectedPricing}
                    selectedPricingActions={selectedPricingActions}
                  />
                ))}
            </TableBody>
          </Table>
        </TableWrapper>
      </PricingGroup>
      <GraphGroup>
        <ProductPriceGraph pricing={pricing} />
      </GraphGroup>
    </React.Fragment>
  );
};

const GraphGroup = styled.article`
  background: ${(props) => props.theme.product.graph.color.background};
  box-shadow: ${(props) => props.theme.internalPaper.boxShadow.inset};
  padding: 30px 50px;
`;

const PricingGroup = styled.article`
  padding: 40px;
  > h1 {
    font-size: ${(props) => props.theme.product.pricing.fontSize.title};
    font-weight: ${(props) => props.theme.product.pricing.fontWeight.title};
    margin: 0 0 15px 0;
  }
`;

export default ProductPricingGroup;
