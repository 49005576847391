import { useQueryWithAlert } from 'adapters/queries';
import { productsKeys } from '../keyFactory';
import { api } from '..';
import { GetPriceTranslationsParams, GetProductRebateGroupsParams } from 'adapters/products/productsAPI';

export const useGetProductsMetaData = () =>
  useQueryWithAlert(productsKeys.metaData(), () => api.getProductMetadatas(), {
    staleTime: Infinity,
  });

export const useGetPriceMethods = () =>
  useQueryWithAlert(productsKeys.priceMethods(), () => api.getPriceMethods(), {
    staleTime: Infinity,
  });

export const useGetPriceTranslations = (params: GetPriceTranslationsParams) =>
  useQueryWithAlert(productsKeys.priceTranslations(params), () => api.getPriceTranslations(params), {
    staleTime: Infinity,
  });

export const useGetPriceTypes = () =>
  useQueryWithAlert(productsKeys.priceTypes(), () => api.getPriceTypes(), {
    staleTime: Infinity,
  });

export const useGetProductTypes = () =>
  useQueryWithAlert(productsKeys.productTypes(), () => api.getProductTypes(), {
    staleTime: Infinity,
  });

export const useGetProductRebategroups = (params: GetProductRebateGroupsParams) =>
  useQueryWithAlert(productsKeys.rebateGroups(params), () => api.getProductRebateGroups(params), {
    staleTime: Infinity,
  });

export const useGetProductStatuses = () =>
  useQueryWithAlert(productsKeys.statuses(), () => api.getProductStatuses(), {
    staleTime: Infinity,
  });
