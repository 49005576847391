import * as React from 'react';
import { AccessibleModulesContext } from '../context/AccessibleModules';
import { EditableModulesContext } from '../context/EditableModules';
import { Modules } from '../types/modules';
import useModulesStore from './useModulesStore';
import { AccessRightsContext } from '../context/AccessRights';

type Props = {
  children: (modules: Modules) => JSX.Element | null;
};

const AllowedModulesFetcher: React.FC<Props> = ({ children }: Props) => {
  const { hasEditFunctionality, accessibleModules, functionalityAccessRights } = useModulesStore();

  return accessibleModules && accessibleModules.length > 0 ? (
    <EditableModulesContext.Provider value={{ hasEditFunctionality }}>
      <AccessibleModulesContext.Provider value={accessibleModules.map((module) => module.key)}>
        <AccessRightsContext.Provider value={{ functionalityAccessRights }}>
          {children(accessibleModules)}
        </AccessRightsContext.Provider>
      </AccessibleModulesContext.Provider>
    </EditableModulesContext.Provider>
  ) : null;
};

export default AllowedModulesFetcher;
