import { TenantTheme } from '../tenants/unbrace';

const fullViewTheme = (theme: TenantTheme) => ({
  border: `1px solid ${theme.palette.neutral.shade3}`,
  color: {
    primary: theme.palette.neutral.shade9,
    secondary: theme.palette.neutral.shade5,
  },
  fontSize: {
    blockTitle: theme.typography.fontSize.medium_2,
    header: theme.typography.fontSize.medium_9,
    subTitle: theme.typography.fontSize.regular,
  },
  fontWeight: {
    blockTitle: 200,
    editLabel: 600,
    header: 500,
  },
});

export default fullViewTheme;
