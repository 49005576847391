import { getType } from 'typesafe-actions';
import { groupBy } from '../../../../helpers';
import { NestedGroup } from '../../../../helpers/groupBy';
import { AccessRight, Account, FunctionalityAccessRight } from '../../types/user';
import { userAccessRightsActions, userActions, UserActionsType } from '../actions/creators';

export type UserState = {
  isAuthenticated: boolean;
  account?: Account;
  accessRights?: NestedGroup<AccessRight>;
  functionalityAccessRights?: FunctionalityAccessRight[];
};

const userReducer = (state: UserState = { isAuthenticated: false }, action: UserActionsType) => {
  switch (action.type) {
    case getType(userActions.fetchUser.success):
      return {
        ...state,
        account: action.payload,
        isAuthenticated: true,
      };
    case getType(userActions.fetchUser.failure):
    case getType(userActions.clearUser):
      return {
        isAuthenticated: false,
      };
    case getType(userAccessRightsActions.fetchUserAccessRights.success):
      return {
        ...state,
        accessRights: groupBy<AccessRight>(action.payload, 'accessType', 'accessCode') as NestedGroup<AccessRight>,
        functionalityAccessRights: action.payload.map((item) => item.accessCode) as FunctionalityAccessRight[],
      };
    case getType(userAccessRightsActions.fetchUserAccessRights.failure):
      return {
        ...state,
        accessRights: undefined,
      };

    default:
      return state;
  }
};

export default userReducer;
