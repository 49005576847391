import * as React from 'react';
import { formatCurrency } from '../../helpers';
import styled, { css } from 'styled-components';

type Props = {
  price: number;
  precision?: number;
  accentuate?: boolean;
};

export const CurrencyNumberFormat: React.FC<Props> = ({ price, precision, accentuate }) => (
  <CurrencyNumber accentuate={accentuate}>{formatCurrency(price, precision)}</CurrencyNumber>
);

const CurrencyNumber = styled.span<{ accentuate?: boolean }>`
  ${(props) =>
    props.accentuate &&
    css`
      color: ${props.theme.palette.warning.shade8};
      font-weight: 500;
    `}
`;
