import AxiosConfig, { RequestConfig } from '../../../config/api/AxiosConfig';
import { FILTERS_API } from './endpoints';
import { screenConfigTypes, SaveCustomFilterData, ApplicationCode } from '../types/filters';

class FiltersApi {
  public async getCustomFilters(screen: string) {
    const requestConfig: RequestConfig = {
      params: {
        type: screenConfigTypes.FILTERS,
        applicationCode: ApplicationCode.CBO,
        screen,
      },
      url: FILTERS_API,
    };

    return AxiosConfig.fetchAxiosGetRequest(requestConfig);
  }

  public async saveCustomFilter(data: SaveCustomFilterData) {
    const requestConfig: RequestConfig = {
      data: {
        type: screenConfigTypes.FILTERS,
        applicationCode: ApplicationCode.CBO,
        ...data,
      },
      url: FILTERS_API,
    };

    return AxiosConfig.fetchAxiosPostRequest(requestConfig);
  }

  public async deleteCustomFilter(id: number) {
    const requestConfig: RequestConfig = {
      url: `${FILTERS_API}/${id}`,
    };

    return AxiosConfig.fetchAxiosDeleteRequest(requestConfig);
  }
}

export default new FiltersApi();
