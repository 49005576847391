import { api } from '../adapters';
import {
  useGetPricing,
  useMutateCreatePricing,
  useMutateDeletePricing,
  useMutateUpdatePricing,
} from '../adapters/queries/pricingQueries';
import { GetParamByIndex, SuccessCallback } from 'types';

const usePricingStore = (productguid?: string) => {
  const { data, isLoading, isFetching, isPreviousData } = useGetPricing(productguid);
  const { mutate: updateItem, isLoading: isUpdatePricingLoading } = useMutateUpdatePricing();
  const { mutate: createItem, isLoading: isCreatePricingLoading } = useMutateCreatePricing();
  const { mutate: deletePricing, isLoading: isDeletePricingLoading } = useMutateDeletePricing();

  const updatePricing = (payload: GetParamByIndex<typeof api.updatePrice, 0>, successCallback?: SuccessCallback) => {
    updateItem(payload, {
      onSuccess: successCallback,
    });
  };

  const createPricing = (payload: GetParamByIndex<typeof api.createPrice, 0>, successCallback?: SuccessCallback) => {
    createItem(payload, {
      onSuccess: successCallback,
    });
  };

  return {
    pricing: data,
    isPending: (isLoading || isFetching) && isPreviousData,
    updatePricing,
    isUpdatePricingLoading,
    createPricing,
    isCreatePricingLoading,
    deletePricing,
    isDeletePricingLoading,
  };
};

export default usePricingStore;
