import { IconButton, Modal } from '@unbrace/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ModuleEditAccessContext } from 'modules/security';
import { Confirmation } from '../../../../components';
import { Delete, Edit } from '../../../../components/icons';
import { Pricing } from '../../types/pricing';
import PricingModal from './NewPrice/PricingModal';
import usePricingStore from 'modules/products/services/usePricingStore';

type Props = {
  handleDelete: (price: Pricing) => void;
  price: Pricing;
};

const ProductPricingActions: React.FC<Props> = ({ handleDelete, price }) => {
  const { t } = useTranslation('products');
  const { isDeletePricingLoading } = usePricingStore();
  const deletePrice = () => {
    handleDelete(price);
  };
  const { canEdit } = React.useContext(ModuleEditAccessContext);

  return (
    <React.Fragment>
      <Modal content={PricingModal} contentProps={{ price, productGuid: price.productGuid, isEdit: true }}>
        {(toggleModal) => (
          <IconButton onClick={toggleModal} tooltip={t('pricing.editView.tooltip.edit')} isDisabled={!canEdit}>
            <Edit />
          </IconButton>
        )}
      </Modal>
      <Confirmation variant="delete" callback={deletePrice}>
        <IconButton
          isLoading={isDeletePricingLoading}
          tooltip={t('pricing.editView.tooltip.delete')}
          isDisabled={!canEdit}
        >
          <Delete />
        </IconButton>
      </Confirmation>
    </React.Fragment>
  );
};

export default ProductPricingActions;
