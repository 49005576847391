import styled from 'config/theme';

export const FilterListTitle = styled.h5`
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 600;
  color: ${(props) => props.theme.internalSidebar.color.filterTitle};
`;

export const FilterListWrapper = styled.div`
  :not(:last-child) {
    margin-bottom: 32px;
  }
`;
