import * as React from 'react';
import { loadScene } from 'components';
import ModuleRoutes from 'components/routing/ModuleRoutes';
import { Module } from '../../security/types/modules';
import { IP_MODULE_KEY } from '../constants';
import { IP_BASE_ROUTES } from '.';
import { Route } from 'react-router';

type Props = {
  allowedModules: Module[];
};

const ipOverviewScene = loadScene('ip', 'IpScene');

const IpRoutes: React.FC<Props> = ({ allowedModules }: Props) => (
  <ModuleRoutes allowedModules={allowedModules} moduleKey={IP_MODULE_KEY}>
    <Route path={IP_BASE_ROUTES} exact component={ipOverviewScene} />
  </ModuleRoutes>
);

export default IpRoutes;
