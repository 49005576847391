import { TenantTheme } from '../tenants/unbrace';

const avatarTheme = (theme: TenantTheme) => ({
  color: {
    primary: {
      bColor: theme.palette.primary.shade1,
      tColor: theme.palette.primary.shade4,
    },
    secondary: {
      bColor: theme.palette.success.shade1,
      tColor: theme.palette.success.shade4,
    },
    tertiary: {
      bColor: theme.palette.warning.shade1,
      tColor: theme.palette.warning.shade8,
    },
    quaternary: {
      bColor: theme.palette.danger.shade1,
      tColor: theme.palette.danger.shade4,
    },
    quinary: {
      bColor: '#e9fdff',
      tColor: '#136268',
    },
    senary: {
      bColor: '#cebfef',
      tColor: '#432385',
    },
    septenary: {
      bColor: '#eefdda',
      tColor: '#39580c',
    },
    octonary: {
      bColor: '#f5eec8',
      tColor: '#aa9200',
    },
    nonary: {
      bColor: '#f7e4cb',
      tColor: '#c77503',
    },
    denary: {
      bColor: '#fbd7e8',
      tColor: '#6a0d3a',
    },
  },
  width: {
    small: '40px',
    normal: '80px',
  },
  fontSize: {
    small: '15px',
    normal: '25px',
  },
  spanHeight: {
    small: '40px',
    normal: '60px',
  },
  lineHeight: {
    small: '25px',
    normal: '40px',
  },
  spanWidth: {
    small: '40px',
    normal: '60px',
  },
  padding: {
    small: '8px',
    normal: '10px',
  },
});

export default avatarTheme;
