import { TenantTheme } from '../tenants/unbrace';

const mailsTheme = (theme: TenantTheme) => ({
  listItem: {
    border: {
      bottom: `1px solid ${theme.palette.neutral.shade2}`,
    },
    color: {
      header: theme.palette.neutral.shade9,
      secondaryIcon: theme.palette.neutral.shade4,
      loader: theme.palette.neutral.shade4,
    },
    fontSize: {
      header: theme.typography.fontSize.regular,
      icon: theme.typography.fontSize.medium_5,
    },
    background: {
      hover: theme.palette.neutral.shade1,
      active: theme.palette.primary.shade1,
    },
  },
  loadingState: {
    color: theme.palette.neutral.shade6,
    background: theme.palette.neutral.shade0,
  },
  content: {
    main: {
      background: theme.palette.neutral.shade1,
    },
    attachment: {
      background: {
        basic: theme.palette.neutral.shade0,
        normal: theme.palette.neutral.shade2,
      },
      borderRadius: theme.globals.borderRadiusSmall,
      color: {
        hover: theme.palette.primary.shade5,
        icon: theme.palette.neutral.shade4,
        normal: theme.palette.neutral.shade9,
      },
      fontSize: {
        icon: theme.typography.fontSize.regular,
        item: theme.typography.fontSize.regular,
      },
    },
    detail: {
      fontSize: {
        content: theme.typography.fontSize.regular,
        header: theme.typography.fontSize.medium_6,
      },
      fontWeight: {
        header: 600,
      },
    },
    header: {
      color: {
        mailAddressHover: theme.palette.primary.shade5,
        main: theme.palette.neutral.shade4,
        timestamp: theme.palette.neutral.shade4,
      },
      fontWeight: 500,
    },
  },
});

export default mailsTheme;
