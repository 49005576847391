import { TYPE_CURRENCY, TYPE_NUMBER } from 'components/Form/Input';
import NumberInput from 'components/Form/NumberInput';
import { Field, FieldProps, FormikErrors, FormikTouched } from 'formik';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FormikDatePicker, FormikSelect } from '../../../../components/Form';
import styled from '../../../../config/theme';
import { PriceType } from '../../types/globalInfo';
import { Pricing } from '../../types/pricing';
import PricingAdvancedOptions from './PricingAdvancedOptions';
import useGlobalInfoStore from 'modules/products/services/useGlobalInfoStore';

type Props = {
  errors: FormikErrors<Pricing>;
  touched: FormikTouched<Pricing>;
  setFieldValue: (name: string, value: any) => void;
  isEdit?: boolean;
};

const PricingForm: React.FC<Props> = ({ touched, errors }) => {
  const { t } = useTranslation('products');
  const { priceTypes } = useGlobalInfoStore();

  return (
    <React.Fragment>
      <FormRow labelMargin>
        <Field name="quantityFrom">
          {({ field, form }: FieldProps) => (
            <NumberInput
              {...field}
              type={TYPE_NUMBER}
              isClearable
              value={parseInt(field.value, 10) || undefined}
              precision={0}
              error={(touched.quantityFrom && errors.quantityFrom) || ''}
              placeholder={t('pricing.from')}
              label={t('pricing.quantity')}
              onChange={({ numberValue }) => form.setFieldValue(field.name, numberValue)}
            />
          )}
        </Field>
        <Field name="quantityUntil">
          {({ field, form }: FieldProps) => (
            <NumberInput
              {...field}
              type={TYPE_NUMBER}
              noLabel
              isClearable
              precision={0}
              value={parseInt(field.value, 10) || undefined}
              error={(touched.quantityUntil && errors.quantityUntil) || ''}
              placeholder={t('pricing.to')}
              onChange={({ numberValue }) => form.setFieldValue(field.name, numberValue)}
            />
          )}
        </Field>
      </FormRow>
      <FormRow labelMargin>
        <Field name="dateFrom">
          {(fieldProps: FieldProps) => (
            <FormikDatePicker
              {...fieldProps}
              inputProps={{
                label: t('pricing.period'),
                placeholder: t('pricing.from'),
              }}
              dayPickerProps={{
                disabledDays: { after: fieldProps.form.values.dateUntil },
              }}
            />
          )}
        </Field>
        <Field name="dateUntil">
          {(fieldProps: FieldProps) => (
            <FormikDatePicker
              {...fieldProps}
              inputProps={{
                noLabel: true,
                placeholder: t('pricing.to'),
              }}
              dayPickerProps={{
                disabledDays: { before: fieldProps.form.values.dateFrom },
              }}
            />
          )}
        </Field>
      </FormRow>
      <FormRow>
        <Field name="amount">
          {({ field, form }: FieldProps) => (
            <NumberInput
              {...field}
              type={TYPE_CURRENCY}
              isClearable
              value={parseFloat(field.value) || 0}
              error={(touched.amount && errors.amount) || ''}
              label={t('pricing.price')}
              onChange={({ numberValue }) => form.setFieldValue(field.name, numberValue)}
            />
          )}
        </Field>
        <Field name="priceTypeId">
          {(fieldProps: FieldProps) => (
            <FormikSelect
              error={(touched.priceTypeId && errors.priceTypeId) || ''}
              {...fieldProps}
              label={t('pricing.type')}
              placeholder={t('pricing.editView.tooltip.selectPriceType')}
              type="text"
              options={priceTypes?.map((priceType: PriceType) => ({
                label: priceType.description,
                value: priceType.id,
              }))}
              menuPosition="fixed"
              errorAsBlock
            />
          )}
        </Field>
      </FormRow>
      <PricingAdvancedOptions />
    </React.Fragment>
  );
};

const FormRow = styled.div<{ labelMargin?: boolean }>`
  display: flex;
  position: relative;
  justify-content: space-between;

  > * {
    width: 48%;
    margin-bottom: 24px;

    div {
      margin-bottom: 0px;
    }
  }

  input {
    margin-bottom: 0px;
  }

  > div:nth-child(2) {
    margin-top: ${(props) => props.labelMargin && '24px'};
  }
`;

export default PricingForm;
