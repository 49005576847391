import { ArrayHelpers } from 'formik';
import * as React from 'react';
import { Paper } from '../../../components';
import { MetaData, UseMetaDataActions } from '../../../types/metadata';
import PageWrapper from '../../app/components/PageWrapper';
import RouteLeavingGuard from '../../app/components/RouteLeavingGuard';
import { ProductInfo } from '../components';
import { ProductBaseForm } from '../components/edit';
import useProductStore from '../services/useProductStore';
import { Product, ProductBaseFormValues } from '../types/product';

type Props = {
  baseHeader: JSX.Element;
  product: Product;
  setShowEditView: (val: boolean) => void;
  metaDataActions: UseMetaDataActions;
};

const ProductBaseEdit: React.FC<Props> = ({ baseHeader, product, metaDataActions }) => {
  const [productChanged, setProductChanged] = React.useState(false);
  const [metaDataArrayHelpers, setMetaDataArrayHelpers] = React.useState<ArrayHelpers | null>(null);
  const productEditFormRef: React.RefObject<any> = React.useRef(null);
  const handleFormChange = React.useCallback(() => setProductChanged(true), []);
  const { updateProduct, isUpdateProductLoading } = useProductStore();

  const handleCancel = () => {
    productEditFormRef.current.resetForm();
    setProductChanged(false);
    metaDataActions.resetMetaData();
  };
  const handleSave = () => {
    productEditFormRef.current.handleSubmit();
  };

  const onHandleSubmit = React.useCallback(
    (formValues: ProductBaseFormValues) => {
      setProductChanged(false);
      updateProduct({
        ...product,
        ...formValues,
        // these fallback values are just for TypeScript purposes (they are typed as number | null, but our schema forces it to be a number)
        priceMethodId: formValues.priceMethodId,
        productStatusId: formValues.productStatusId,
        productTypeId: formValues.productTypeId,
        partnerCenterProfile:
          Object.keys(formValues.partnerCenterProfile || {}).length === 0
            ? { offerId: '' }
            : formValues.partnerCenterProfile,
      });
    },
    [product, updateProduct]
  );

  const handleAddMetaData = React.useCallback(
    (newMetaData: MetaData) => () => {
      if (metaDataArrayHelpers) {
        metaDataArrayHelpers.push(newMetaData);
        metaDataActions.toggleMetaData(newMetaData.metadataId.toString());
      }
    },
    [metaDataArrayHelpers, metaDataActions]
  );

  return (
    <PageWrapper>
      <RouteLeavingGuard when={productChanged} />
      {React.cloneElement(baseHeader, {
        handleAddMetaData,
        handleCancel,
        handleSave,
        hasChanges: productChanged,
        isSaving: isUpdateProductLoading,
      })}
      <Paper>
        <ProductInfo
          code={product.code}
          priceMethodDescription={product.priceMethodDescription}
          productDescription={
            product.translations && product.translations.length > 0 ? product.translations[0].description : ''
          }
          productStatusDescription={product.productStatusDescription}
          productStatusId={product.productStatusId}
          productTypeDescription={product.productTypeDescription}
        />
        <ProductBaseForm
          handleFormChange={handleFormChange}
          product={{
            ...product,
            partnerCenterProfile: product.partnerCenterProfile ?? {},
            metadatas: product.metadatas ?? [],
            priceMethodId: product.priceMethodId,
            productStatusId: product.productStatusId,
            productTypeId: product.productTypeId,
          }}
          formRef={productEditFormRef}
          setMetaDataArrayHelpers={setMetaDataArrayHelpers}
          handleSubmit={onHandleSubmit}
          metaDataActions={metaDataActions}
        />
      </Paper>
    </PageWrapper>
  );
};

export default ProductBaseEdit;
