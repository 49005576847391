import * as React from 'react';
import { Route } from 'react-router-dom';
import { loadScene } from '../../../components';
import ModuleRoutes from '../../../components/routing/ModuleRoutes';
import { Module } from '../../security/types/modules';
import { CONTRACTS_MODULE_KEY } from '../constants';

type Props = {
  allowedModules: Module[];
};

const contractScene = loadScene('contract', 'ContractScene');

export const CONTRACTS_BASE_ROUTES = '/contract';
export const CONTRACT_DETAIL_URL = '/contract';

const ContractRoutes: React.FC<Props> = ({ allowedModules }: Props) => {
  return (
    <ModuleRoutes allowedModules={allowedModules} moduleKey={CONTRACTS_MODULE_KEY}>
      <React.Fragment>
        <Route path={CONTRACTS_BASE_ROUTES} component={contractScene} />
      </React.Fragment>
    </ModuleRoutes>
  );
};

export default ContractRoutes;
