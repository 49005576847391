import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

type Props = {};

const Claim: React.FC<Props> = (props: Props): JSX.Element => (
  <BaseSvgWrapper {...props}>
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.0621 5.0019C13.7167 5.03811 13.397 5.20143 13.1653 5.46013C12.9335 5.71883 12.8062 6.05445 12.808 6.40177V41.0318H10.4749C10.4263 41.0293 10.3777 41.0293 10.3291 41.0318C10.1452 41.0414 9.96507 41.087 9.79887 41.1662C9.63266 41.2454 9.48368 41.3565 9.36044 41.4933C9.23719 41.6301 9.14209 41.7898 9.08058 41.9633C9.01906 42.1368 8.99232 42.3208 9.0019 42.5046C9.01147 42.6885 9.05717 44.0352 9.13638 44.2014C9.2156 44.3675 9.32677 44.5165 9.46356 44.6397C9.60034 44.763 9.76006 44.858 9.93359 44.9195C10.1071 44.981 10.2911 45.0077 10.4749 44.9981H19.1074C19.2929 45.0007 19.4771 44.9664 19.6493 44.8973C19.8214 44.8281 19.9781 44.7254 20.1102 44.5952C20.2423 44.4649 20.3472 44.3097 20.4188 44.1385C20.4904 43.9674 20.5273 42.6172 20.5273 42.4317C20.5273 42.2462 20.4904 42.0625 20.4188 41.8914C20.3472 41.7202 20.2423 41.565 20.1102 41.4347C19.9781 41.3045 19.8214 41.2018 19.6493 41.1326C19.4771 41.0635 19.2929 41.0292 19.1074 41.0318H16.7743V24.6001H40.3243C40.5843 24.6021 40.8396 24.5317 41.0618 24.3967C41.284 24.2618 41.4642 24.0676 41.5823 23.836C41.7004 23.6044 41.7516 23.3445 41.7303 23.0854C41.709 22.8263 41.616 22.5783 41.4617 22.3691L36.008 14.801L41.4763 7.21837C41.6265 7.00916 41.716 6.76259 41.7351 6.50578C41.7542 6.24896 41.7021 5.99185 41.5846 5.76273C41.467 5.53361 41.2885 5.34136 41.0687 5.20713C40.8489 5.07289 40.5964 5.00188 40.3389 5.0019H14.2079C14.1593 4.99937 14.1107 4.99937 14.0621 5.0019ZM16.7743 8.96818H36.4309L31.9688 13.9698C31.7946 14.2092 31.7007 14.4977 31.7007 14.7937C31.7007 15.0898 31.7946 15.3782 31.9688 15.6176L36.4309 20.6338H16.7743V8.96818Z" />
    </svg>
  </BaseSvgWrapper>
);

export default Claim;
