const unbraceTypography = {
  fontFamily: '"Poppins", Arial, sans-serif',
  fontFamilyMono: 'Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
  letterSpacing: '1px',

  // 16px = 1 rem - as defined in injectGlobals
  fontSize: {
    huge_0: '3.00rem',
    huge_2: '3.12rem',
    huge_4: '3.25rem',
    huge_6: '3.50rem',
    huge_8: '3.75rem',
    huge_9: '4.00rem',
    large_0: '2.00rem',
    large_2: '2.12rem',
    large_4: '2.50rem',
    large_6: '2.62rem',
    large_8: '2.75rem',
    large_9: '2.89rem',
    medium_2: '1.12rem',
    medium_4: '1.25rem',
    medium_5: '1.37rem',
    medium_6: '1.50rem',
    medium_7: '1.62rem',
    medium_8: '1.75rem',
    medium_9: '1.89rem',
    regular: '1.00rem',
    small_4: '0.82rem',
    small_5: '0.89rem',
  },
};

export { unbraceTypography };
