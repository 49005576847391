import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const Tab: React.FC = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M35.3525 32.2651L29.7053 38L26.5756 34.8202L34.7536 26.5152L30.6717 22.3699C28.4267 20.0901 26.5899 18.211 26.5899 18.1942C26.5899 18.1774 27.291 17.4519 28.1478 16.5819L29.7057 15L35.3528 20.7347C38.4588 23.8889 41 26.4832 41 26.5C41 26.5168 38.4586 29.1109 35.3525 32.2651Z" />
      <path d="M35.2645 28.8926L9 28.8926L9 24.3388L35.2645 24.3388V28.8926Z" />
      <rect x="13" y="12" width="16" height="4" transform="rotate(90 13 12)" />
    </svg>
  </BaseSvgWrapper>
);

export default Tab;
