import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const Paste: React.FC = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11 34.3794H6V6H34V11H11V34.3794Z" />
      <rect x="15" y="3" width="10" height="4" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 43H43V15H15V43ZM21 20.5H37V22.5H21V20.5ZM37 25.5H21V27.5H37V25.5ZM21 30.5H37V32.5H21V30.5ZM37 35.5H21V37.5H37V35.5Z"
      />
    </svg>
  </BaseSvgWrapper>
);

export default Paste;
