import * as React from 'react';
import { ContentLoader, SkeletonGenerator } from '../../../../components';

const PricingLoadingState: React.FC = () => (
  <section style={{ maxWidth: 600 }}>
    <SkeletonGenerator count={1}>
      <ContentLoader height={30}>
        <rect x="3" y="10" rx="2" ry="2" width="80" height="10" />
      </ContentLoader>
    </SkeletonGenerator>
    <SkeletonGenerator count={6}>
      <ContentLoader height={35}>
        <rect x="14" y="17" rx="2" ry="2" width="14" height="14" />
        <rect x="48" y="15" rx="2" ry="2" width="100" height="7" />
        <rect x="48" y="29" rx="2" ry="2" width="70" height="5" />
      </ContentLoader>
    </SkeletonGenerator>
  </section>
);

export default PricingLoadingState;
