import * as React from 'react';
import { Route } from 'react-router-dom';
import { loadScene } from '../../../components';
import ModuleRoutes from '../../../components/routing/ModuleRoutes';
import { Module } from '../../security/types/modules';
import { TASKS_MODULE_KEY } from '../constants';

type Props = {
  allowedModules: Module[];
};

const scene = loadScene('tasks', 'TasksScene');

export const TASKS_BASE_ROUTES = '/tasks';

const TaskRoutes: React.FC<Props> = ({ allowedModules }: Props) => {
  return (
    <ModuleRoutes allowedModules={allowedModules} moduleKey={TASKS_MODULE_KEY}>
      <Route path={'/tasks'} exact component={scene} />
    </ModuleRoutes>
  );
};

export default TaskRoutes;
