import {
  MutationFunction,
  QueryFunction,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { logoutActions } from 'modules/security/redux/actions/creators';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { alertsActions } from '../modules/app/redux/actions/creators/index';
import { HttpErrorResponse } from '../types/index';
import { DownloadResponse } from './servicefee/servicefeeAPI';

type MutationOptions<TData, TError, TVariables, TContext> = Omit<
  UseMutationOptions<TData, TError, TVariables, TContext>,
  'mutationFn'
> & {
  suppressErrorAlert?: boolean;
};

const createErrorAlert = (error: HttpErrorResponse, dispatch: Dispatch<any>) => {
  if (error.data?.statusCode === 401) {
    logoutActions.initiateLogout();
  } else {
    dispatch(alertsActions.setAlert(error));
  }
};

export const useMutationWithAlert = <TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(
  mutationFn: MutationFunction<TData, TVariables>,
  options?: MutationOptions<TData, TError, TVariables, TContext>
) => {
  const dispatch = useDispatch();

  return useMutation(mutationFn, {
    ...options,
    onError: (error, variables, context) => {
      if (!options?.suppressErrorAlert) {
        createErrorAlert((error as any).response, dispatch);
      }
      options?.onError?.(error, variables, context);
    },
  });
};

export const useMutationWithSuccessAlert = <TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(
  mutationFn: MutationFunction<TData, TVariables>,
  options?: MutationOptions<TData, TError, TVariables, TContext>
) => {
  const dispatch = useDispatch();

  return useMutationWithAlert(mutationFn, {
    ...options,
    onSuccess: (data, variables, context) => {
      dispatch(alertsActions.createSuccessAlert());
      options?.onSuccess?.(data, variables, context);
    },
  });
};

export const useDownloadMutationWithSuccessAlert = <TError = unknown, TVariables = void, TContext = unknown>(
  mutationFn: MutationFunction<DownloadResponse, TVariables>,
  options?: Omit<UseMutationOptions<DownloadResponse, TError, TVariables, TContext>, 'mutationFn'>
) => {
  const dispatch = useDispatch();

  return useMutationWithAlert(mutationFn, {
    ...options,
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);

      const binaryString = window.atob(data.byteArray || '');
      const bytes = new Uint8Array(binaryString.length);
      const arrayBuffer = bytes.map((_, index) => binaryString.charCodeAt(index));

      const blob = new Blob([arrayBuffer]);
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, data.fileName);
      } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', data.fileName || '');
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
      dispatch(alertsActions.createSuccessAlert());
    },
  });
};

export const useQueryWithAlert = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn' | 'initialData'> & {
    initialData?: () => undefined;
  }
) => {
  const dispatch = useDispatch();

  return useQuery(queryKey, queryFn, {
    ...options,
    onError: (error) => {
      createErrorAlert((error as any).response, dispatch);
      options?.onError?.(error);
    },
  });
};

export const useInfiniteQueryWithAlert = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: Omit<UseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryFnData, TQueryKey>, 'queryKey' | 'queryFn'>
) => {
  const dispatch = useDispatch();

  return useInfiniteQuery(queryKey, queryFn, {
    ...options,
    onError: (error) => {
      createErrorAlert((error as any).response, dispatch);
      options?.onError?.(error);
    },
  });
};
