import { ArrayHelpers, FieldArray, Form, Formik } from 'formik';
import * as React from 'react';
import MetaDataInfo from '../../../../components/MetaData/MetaDataInfo';
import styled from '../../../../config/theme';
import { UseMetaDataActions } from '../../../../types/metadata';
import { fullProductSchema } from '../../schema/productBaseEditSchema';
import { ProductBaseFormValues } from '../../types/product';
import { DescriptionInfo, GeneralInfo } from './';
import PartnerCenterInfo from './PartnerCenterInfo';
import PricingProfileInfo from './PricingProfileInfo';

type Props = {
  formRef: React.RefObject<any>;
  handleFormChange: () => void;
  handleSubmit: (values: ProductBaseFormValues) => void;
  metaDataActions?: UseMetaDataActions;
  newProduct?: boolean;
  product: ProductBaseFormValues;
  setMetaDataArrayHelpers?: (arrayHelpers: ArrayHelpers) => void;
};

const ProductBaseForm: React.FC<Props> = ({
  formRef,
  handleFormChange,
  handleSubmit,
  metaDataActions,
  product,
  setMetaDataArrayHelpers,
}) => {
  return (
    <Formik
      initialValues={{
        ...product,
      }}
      onSubmit={(values: ProductBaseFormValues) => {
        handleSubmit(values);
      }}
      innerRef={formRef}
      validationSchema={fullProductSchema}
      validate={handleFormChange}
      validateOnChange={false}
      render={({ errors, touched, values }) => {
        return (
          <FormWrapper>
            <Form>
              <GeneralInfo touched={touched} errors={errors} />
              <DescriptionInfo translations={values.translations} touched={touched} errors={errors} />
              <PricingProfileInfo touched={touched} errors={errors} />
              <PartnerCenterInfo
                touched={touched}
                errors={errors}
                partnerCenterProfile={product.partnerCenterProfile}
              />
              {setMetaDataArrayHelpers && (
                <FieldArray
                  name="metadatas"
                  render={(arrayHelpers) => (
                    <MetaDataInfo
                      amountOfButtons={1}
                      errors={errors}
                      arrayHelpers={arrayHelpers}
                      metaDatas={values.metadatas.map((item) => {
                        return { ...item, metadataId: item.metadataId, code: '', accessibility: 'editable' };
                      })}
                      metaDataActions={metaDataActions}
                      setMetaDataArrayHelpers={setMetaDataArrayHelpers}
                    />
                  )}
                />
              )}
            </Form>
          </FormWrapper>
        );
      }}
    />
  );
};

const FormWrapper = styled.div`
  padding-top: 25px;
`;

export default ProductBaseForm;
