import { Group, NestedGroup } from '../../../helpers/groupBy';
import { Module } from '../types/modules';
import { AccessRight } from '../types/user';

const FUNCTIONALITY = 'Functionality';
const MODULE_READ = 'MODULE_READ';
const MODULE_EDIT = 'MODULE_EDIT';
const MULTISELECT = 'Multiselect';
const BOOLEAN = 'Boolean';

const canReadModule = (module: Module, accessRights?: NestedGroup<AccessRight>) =>
  hasAccessToFunctionality(MODULE_READ, accessRights, module.key);

const canEditModule = (module: Module, accessRights?: NestedGroup<AccessRight>) =>
  hasAccessToFunctionality(MODULE_EDIT, accessRights, module.key);

const hasAccessToFunctionality = (
  accessCode: string,
  accessRights?: NestedGroup<AccessRight>,
  valueToCheck?: string
): boolean => {
  const functionality = getFunctionality(accessRights);

  if (functionality && functionality[accessCode]) {
    return functionality[accessCode].filter((ar) => hasAccessRights(ar, valueToCheck)).length > 0;
  }

  return false;
};

const getFunctionality = (accessRights?: NestedGroup<AccessRight>): Group<AccessRight> | undefined => {
  if (accessRights && accessRights[FUNCTIONALITY]) {
    return accessRights[FUNCTIONALITY];
  }

  return undefined;
};

const hasAccessRights = (accessRight: AccessRight, value?: string): boolean => {
  switch (accessRight.valueType) {
    case MULTISELECT:
      return accessRight.valueCode === value;
    case BOOLEAN:
      return accessRight.valueCode === 'TRUE';
    default:
      return false;
  }
};

export { canEditModule, canReadModule, hasAccessToFunctionality };
