import { Button, ModalContent } from '@unbrace/components';
import { useTranslation } from 'react-i18next';

type Props = {
  closeModal: () => void;
  onSuccess: () => void;
  title?: string;
  description?: string;
  isPending?: boolean;
};

const ConfirmationModal = ({ closeModal, onSuccess, title, description, isPending }: Props) => {
  const { t } = useTranslation();

  const handleConfirmClick = () => {
    onSuccess();
    closeModal();
  };

  return (
    <ModalContent>
      <ModalContent.Header>
        <h1>{title || t('confirmationModal.title')}</h1>
      </ModalContent.Header>
      <ModalContent.Body>{description || t('confirmationModal.description')}</ModalContent.Body>
      <ModalContent.Footer>
        <Button color="tertiary" onClick={closeModal}>
          {t('general.cancel')}
        </Button>
        <Button onClick={handleConfirmClick} color="primary" type="submit" isLoading={isPending}>
          {t('general.confirm')}
        </Button>
      </ModalContent.Footer>
    </ModalContent>
  );
};

export default ConfirmationModal;
