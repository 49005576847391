import * as React from 'react';
import Orb from '../../../components/icons/Orb';
import { ACTIVE, DECOMMISIONED, TOCHECK } from '../constants';

type Props = {
  status: string;
};

const ProductsOrb: React.FC<Props> = ({ status, ...props }: Props) => {
  switch (status) {
    case ACTIVE:
      return <Orb status="success" {...props} />;
    case TOCHECK:
      return <Orb status="warning" {...props} />;
    case DECOMMISIONED:
      return <Orb status="danger" {...props} />;
    default:
      return <Orb {...props} />;
  }
};

export default ProductsOrb;
