import { format, parse } from 'date-fns';

const formatAsDate = (dateString?: Date | string): string => {
  if (!dateString) {
    return '';
  }

  return format(dateString, 'DD/MM/YYYY');
};

const formatAsDayDate = (dateString?: Date | null | string): string => {
  if (!dateString) {
    return '';
  }

  return format(dateString, 'ddd - DD/MM/YYYY');
};

const formatAsDayDateTime = (dateString?: Date): string => {
  if (!dateString) {
    return '';
  }

  return format(dateString, 'ddd - DD/MM/YYYY - HH:mm');
};

const formatAsDateTime = (dateString?: Date | string): string => {
  if (!dateString) {
    return '';
  }

  return format(dateString, 'DD/MM/YYYY - HH:mm:ss');
};

const formatAsFullDateTime = (dateString?: Date): string => {
  if (!dateString) {
    return '';
  }

  return format(dateString, 'ddd - DD/MM/YYYY - HH:mm:ss');
};

const getTime = (dateString?: Date | string): string => {
  if (!dateString) {
    return '';
  }

  return format(dateString, 'HH:mm:ss');
};

const getTimeNoSec = (dateString?: Date | string): string => {
  if (!dateString) {
    return '';
  }

  return format(dateString, 'HH:mm');
};

const getMonthAndDay = (dateString: Date | string): string => {
  if (!dateString) {
    return '';
  }

  return format(dateString, 'MMM D');
};

const getDay = (dateString: Date): string => {
  if (!dateString) {
    return '';
  }

  return format(dateString, 'ddd');
};

const formatToSafeISODate = (dateString?: Date): string => {
  if (!dateString) {
    return '';
  }

  return format(dateString, 'YYYY-MM-DDT00:00:00Z');
};

const formatToWithoutTime = (dateString?: Date): string => {
  if (!dateString) {
    return '';
  }

  return format(dateString, 'YYYY-MM-DD', { locale: '' });
};

// Checks if date is in DD/MM/YYYY or DD-MM-YYYY format, does not check for leap years
const BASIC_DATE_REGEX = new RegExp(/^(0[1-9]|[12][0-9]|3[01])[/-](0[1-9]|1[012])[/-](\d{4})$/);

const dateParser = (dateString: string): Date | undefined => {
  const match = dateString.match(BASIC_DATE_REGEX);
  if (match) {
    const [day, month, year] = match.slice(1);

    return parse(`${year}-${month}-${day}T00:00:00Z`);
  }

  return undefined;
};

export {
  dateParser,
  formatAsDate,
  formatAsDateTime,
  formatAsDayDate,
  formatAsDayDateTime,
  formatToWithoutTime,
  formatAsFullDateTime,
  formatToSafeISODate,
  getDay,
  getMonthAndDay,
  getTime,
  getTimeNoSec,
};
