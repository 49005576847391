import * as React from 'react';
import { AppInsightsContext, AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { AppInsightsConfig } from '../../../config/monitoring/ApplicationInsights';

const InsightsProvider = (props: React.PropsWithChildren<{}>) =>
  AppInsightsConfig.plugin ? (
    <AppInsightsContext.Provider value={AppInsightsConfig.plugin}>
      <AppInsightsErrorBoundary
        onError={() => (
          <div>
            <h3>{'Something went wrong'}</h3>
            <p>{'Please try again or contact an administrator'}</p>
          </div>
        )}
        appInsights={AppInsightsConfig.plugin}
      >
        <React.Fragment>{props.children}</React.Fragment>
      </AppInsightsErrorBoundary>
    </AppInsightsContext.Provider>
  ) : (
    <React.Fragment>{props.children}</React.Fragment>
  );

export default InsightsProvider;
