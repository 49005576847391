export const ACTIVE: 'Active' = 'Active';
export const DECOMMISIONED: 'Decommisioned' = 'Decommisioned';
export const PRODUCTS_MODULE_KEY = 'products';
export const TOCHECK: 'To Check' = 'To Check';

export const PRODUCTS_BASE_URL = '/products';
export const PRODUCT_NEW_URL = '/products/add';

export const PRICING_ACTION_PREFIX = 'PRICING';
export const PRODUCT_ACTION_PREFIX = 'PRODUCT';
export const PRODUCTS_ACTION_PREFIX = 'PRODUCTS';

export type ACTIVE_TYPE = typeof ACTIVE;
export type DECOMMISIONED_TYPE = typeof DECOMMISIONED;
export type TOCHECK_TYPE = typeof TOCHECK;
