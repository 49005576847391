import { combineReducers } from 'redux';
import loginReducer, { LoginState } from './login';
import userReducer, { UserState } from './user';

export interface SecurityState {
  login: LoginState;
  user: UserState;
}

const securityReducer = combineReducers<SecurityState, any>({
  login: loginReducer,
  user: userReducer,
});

export default securityReducer;
