import { TenantTheme } from '../tenants/unbrace';

const toggleTheme = (theme: TenantTheme) => ({
  activeColor: theme.palette.success.shade4,
  activeHoverColor: theme.palette.success.shade3,
  activeTrackColor: theme.palette.success.shade2,
  inActiveColor: theme.palette.danger.shade4,
  inActiveHoverColor: theme.palette.danger.shade3,
  inActiveTrackColor: theme.palette.danger.shade2,
});

export default toggleTheme;
