import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const ServiceFee: React.FC = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.458333 26.458334" x="0px" y="0px">
      <path
        d="m13.228899 272.12915c-2.982304 0-5.9642642 1.13504-8.2351301 3.4059-4.54170518 4.54163-4.54170518 11.92951 0 16.47119 4.5416788 4.54165 11.9291101 4.54165 16.4708151 0 4.541679-4.54168 4.541679-11.92954 0-16.47119-2.270866-2.27084-5.253328-3.4059-8.235685-3.4059zm.791554 1.61195c2.302748.18121 4.555781 1.15194 6.321213 2.91735 1.763977 1.76397 2.734495 4.01423 2.916873 6.31502l-4.799727-.0269c-.359013-2.2769-2.180141-4.08064-4.465267-4.41402zm-1.588215 0-.02688 4.7997c-2.5257128.39828-4.4698976 2.59524-4.4698976 5.2293 0 2.91436 2.3795836 5.29394 5.2939686 5.29394 1.182714 0 2.273485-.39674 3.15677-1.05722l3.3723 3.41106c-3.956473 3.38749-9.8954961 3.21123-13.6418109-.53509-3.9347776-3.93472-3.9347511-10.29012 0-14.22487 1.7639771-1.76395 4.0147609-2.73447 6.3155499-2.91682zm.79719 6.32322c2.056051 0 3.705807 1.64976 3.705807 3.70578 0 2.05608-1.649756 3.70576-3.705807 3.70576-2.056024 0-3.7057807-1.64968-3.7057807-3.70576 0-2.05602 1.6497567-3.70578 3.7057807-3.70578z"
        transform="translate(0 -270.54165)"
      />
    </svg>
  </BaseSvgWrapper>
);

export default ServiceFee;
