import { TenantTheme } from '../tenants/unbrace';

// Extend @unbrace/components table
const tableTheme = (theme: TenantTheme) => ({
  background: {
    secondary: theme.palette.neutral.shade1,
    secondaryhover: theme.palette.neutral.shade2,
  },
  color: {
    secondary: theme.palette.neutral.shade5,
  },
});

export default tableTheme;
