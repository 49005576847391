import { Button, Flexer, media } from '@unbrace/components';
import useModuleFiltersStore from 'modules/app/redux/services/useModuleFiltersStore';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import FilterTableService from '../../services/FilterTable';

type Props = {
  moduleKey: string;
  fixedUnderFilters?: boolean;
};

const ResetFilters: React.FC<Props> = ({ moduleKey, fixedUnderFilters }: Props) => {
  const { t } = useTranslation();
  const { resetFilters } = useModuleFiltersStore(moduleKey);

  return (
    <FilterTableService moduleKey={moduleKey}>
      {({ setSearchText }) => (
        <StickyFlexer justify="center" align="center" fixedUnderFilters={fixedUnderFilters}>
          <Button type="reset" color="tertiary" onClick={() => resetFilters(setSearchText)} data-cy="reset-filters">
            {t('filters.resetFilters')}
          </Button>
        </StickyFlexer>
      )}
    </FilterTableService>
  );
};

export default ResetFilters;

const StickyFlexer = styled(Flexer)<{ fixedUnderFilters?: boolean }>`
  position: ${(props) => !props.fixedUnderFilters && 'fixed'};
  z-index: 100;
  bottom: 0;
  width: ${(props) => (props.fixedUnderFilters ? '100%' : '250px')};
  min-width: ${(props) => (props.fixedUnderFilters ? '100%' : '250px')};
  height: ${(props) => (props.fixedUnderFilters ? '50px' : '60px')};
  background-color: ${(props) => props.theme.internalSidebar.background.main};

  ${(props) => media.desktop`
    width: ${props.fixedUnderFilters ? '100%' : '312px'};
    min-width: ${props.fixedUnderFilters ? '100%' : '312px'};
  `}
`;
