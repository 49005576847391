import shortcuts from 'components/KeyboardShortcuts/shortcuts';
import useKeyboardShortcut from 'use-keyboard-shortcut';

const useKeyShortcut = () => {
  const useGoToCompanies = (funcToRun: Function) => useKeyboardShortcut(shortcuts.navigation.goToCompanies, funcToRun);
  const useGoToProducts = (funcToRun: Function) => useKeyboardShortcut(shortcuts.navigation.goToProducts, funcToRun);
  const useGoToContracts = (funcToRun: Function) => useKeyboardShortcut(shortcuts.navigation.goToContracts, funcToRun);
  const useGoToInvoices = (funcToRun: Function) => useKeyboardShortcut(shortcuts.navigation.goToInvoices, funcToRun);
  const useGoToDrafts = (funcToRun: Function) => useKeyboardShortcut(shortcuts.navigation.goToDrafts, funcToRun);
  const useGoToTasks = (funcToRun: Function) => useKeyboardShortcut(shortcuts.navigation.goToTasks, funcToRun);

  const useShowAllShortcuts = (funcToRun: Function) =>
    useKeyboardShortcut(shortcuts.general.showAllShortcuts, funcToRun);
  const useToggleEdit = (funcToRun: Function) => useKeyboardShortcut(shortcuts.general.toggleEditScreen, funcToRun);
  const useToggleDetail = (funcToRun: Function) => useKeyboardShortcut(shortcuts.general.toggleDetailScreen, funcToRun);
  const useOpenAdd = (funcToRun: Function) => useKeyboardShortcut(shortcuts.general.openAddScreen, funcToRun);
  const useToggleFilters = (funcToRun: Function) =>
    useKeyboardShortcut(shortcuts.general.toggleFilterSidebar, funcToRun);
  const useRefreshTable = (funcToRun: Function) => useKeyboardShortcut(shortcuts.general.refreshTable, funcToRun);
  const useFocusSearch = (funcToRun: Function) => useKeyboardShortcut(shortcuts.general.focusOnSearchInput, funcToRun);

  return {
    useGoToCompanies,
    useGoToProducts,
    useGoToContracts,
    useGoToInvoices,
    useGoToDrafts,
    useGoToTasks,
    useShowAllShortcuts,
    useToggleEdit,
    useToggleDetail,
    useOpenAdd,
    useToggleFilters,
    useRefreshTable,
    useFocusSearch,
  };
};

export default useKeyShortcut;
