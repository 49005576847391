import styled, { css } from 'config/theme';
import { media } from 'config/theme/utils';

const mobileStyling = css`
  padding: 24px;
  min-width: 250px;

  > div {
    h2,
    h2 > span {
      font-size: 28px;
    }
  }
`;

export const TileContainer = styled.div`
  div.stats {
    min-width: 280px;
    display: flex;
    flex-direction: column;
    padding: 32px;
    height: 100%;

    button {
      margin-top: 24px;
    }

    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 12px;

      &:first-child {
        margin-bottom: auto;
      }

      h2,
      h2 > span {
        color: ${props => props.theme.palette.primary.shade5};
        font-size: 30px;
        line-height: 35px;
        word-break: break-all;
      }

      h3,
      h3 > span {
        color: ${props => props.theme.palette.primary.shade5};
        font-size: 18px;
        font-weight: 300;
      }

      p {
        color: ${props => props.theme.palette.neutral.shade4};
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 1.5px;
      }
    }

    ${() => media.laptopSM`
      ${mobileStyling}
    `}

    ${() => media.mobile`
      ${mobileStyling}
    `}

    ${() => media.tablet`
      ${mobileStyling}
    `}
  }
`;
