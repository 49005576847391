import { createSelector } from 'reselect';
import { SortOrder } from '@unbrace/components';
import { getDefaultPageSize } from 'modules/app/constants';
import { RootState } from '../../../../config/store/rootReducer';
import { ModuleFilters, SortingFilter, FormattedModuleFilters, ScreenConfiguration } from '../../types/filters';

const getSearchText = (state: RootState, moduleKey: string): string | undefined =>
  state.app.filters[moduleKey] && state.app.filters[moduleKey].searchText
    ? state.app.filters[moduleKey].searchText
    : undefined;

const getPageIndex = (state: RootState, moduleKey: string): number | undefined =>
  state.app.filters[moduleKey] && state.app.filters[moduleKey].pageIndex
    ? state.app.filters[moduleKey].pageIndex
    : undefined;

const getPageSize = (state: RootState, moduleKey: string): number =>
  state.app.filters[moduleKey] && state.app.filters[moduleKey].pageSize
    ? state.app.filters[moduleKey].pageSize
    : getDefaultPageSize();

export const getAdditionalFilters = (state: RootState, moduleKey: string): object | undefined =>
  state.app.filters[moduleKey] && state.app.filters[moduleKey].additionalFilters
    ? state.app.filters[moduleKey].additionalFilters
    : undefined;

const getAllFilters = (state: RootState, moduleKey: string): ModuleFilters | undefined =>
  state.app.filters[moduleKey] ? state.app.filters[moduleKey] : undefined;

const getCustomFilters = (state: RootState, moduleKey: string): ScreenConfiguration[] | undefined =>
  state.app.screenConfigs?.[moduleKey]?.filters;

const getSorting = (state: RootState, moduleKey: string): SortingFilter | undefined =>
  state.app.filters[moduleKey]?.sorting;

const transformSortingToOrderBys = (sortingFilter?: SortingFilter) =>
  sortingFilter
    ? sortingFilter.type === SortOrder.ASC
      ? sortingFilter.columnNames
      : sortingFilter.columnNames.map((name) => `${name}_${sortingFilter.type.toLowerCase()}`)
    : undefined;

export const makeGetSearchText = () => createSelector(getSearchText, (searchText: string | undefined) => searchText);

export const getSearchTextFilter = createSelector(getSearchText, (searchText: string | undefined) => searchText);

export const makeGetPageIndex = () => createSelector(getPageIndex, (pageIndex: number | undefined) => pageIndex);

export const makeGetPageSize = () => createSelector(getPageSize, (pageSize: number) => pageSize);

export const makeGetCustomFilters = () =>
  createSelector(getCustomFilters, (customFilters?: ScreenConfiguration[]) => customFilters);

export const makeGetAdditionalFilters = () =>
  createSelector(getAdditionalFilters, (additionalFilters: object | undefined) => additionalFilters);

export const makeGetAllFilters = createSelector(getAllFilters, (filters: ModuleFilters | undefined):
  | FormattedModuleFilters
  | undefined => {
  if (!filters) return undefined;

  const { sorting, ...rest } = filters;
  const formattedOrderBys = transformSortingToOrderBys(sorting);

  return { ...rest, orderBys: formattedOrderBys ? formattedOrderBys : undefined };
});

export const makeGetSorting = () => createSelector(getSorting, (sortingFilter?: SortingFilter) => sortingFilter);
export const makeGetFormattedSorting = () => createSelector(getSorting, transformSortingToOrderBys);
