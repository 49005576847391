import * as Yup from 'yup';
import i18n from '../../config/i18n';

const metaDataSchema = Yup.object().shape({
  category: Yup.string(),
  code: Yup.string(),
  isAdded: Yup.boolean(),
  metadataId: Yup.number(),
  value: Yup.mixed()
    .when('dataType', {
      is: 'BOOL',
      then: Yup.string(),
    })
    .when('dataType', {
      is: 'PERCENTAGE',
      then: Yup.number()
        .min(0, () => i18n.t('validation:number.greaterThan', { more: 0 }))
        .max(100, () => i18n.t('validation:number.max', { max: 100 }))
        .required(() => i18n.t('validation:required')),
      otherwise: Yup.string().nullable(),
    }),
  isNew: Yup.boolean(),
  dataType: Yup.mixed().oneOf(['STRING', 'GUID', 'DECIMAL', 'MULTISELECT', 'BOOL', 'INTEGER', 'PERCENTAGE', 'DATE']),
});

export default metaDataSchema;
