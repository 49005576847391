/* eslint-disable complexity */
// eslint-disable-next-line import/no-unresolved
import { RelativeContainer } from 'components';
import { Close } from 'components/icons';
import * as React from 'react';
import styled from '../../config/theme';
import Checkbox from './Checkbox';
import { ErrorText } from './ErrorText';
import { FieldContainer } from './FieldContainer';
import { Label } from './Label';
import RadioButton from './RadioButton';

export const TYPE_CHECKBOX = 'checkbox';
export const TYPE_EMAIL = 'email';
export const TYPE_NUMBER = 'number';
export const TYPE_PASSWORD = 'password';
export const TYPE_TEXT = 'text';
export const TYPE_RADIO = 'radio';
export const TYPE_FILE = 'file';
export const TYPE_PERCENTAGE = 'percentage';
export const TYPE_CURRENCY = 'currency';

type InputType =
  | typeof TYPE_CHECKBOX
  | typeof TYPE_EMAIL
  | typeof TYPE_NUMBER
  | typeof TYPE_PASSWORD
  | typeof TYPE_FILE
  | typeof TYPE_TEXT
  | typeof TYPE_RADIO
  | typeof TYPE_PERCENTAGE
  | typeof TYPE_CURRENCY;

export type InputProps = {
  accept?: string;
  autocomplete?: 'on' | 'off';
  autoFocus?: boolean;
  checked?: boolean;
  className?: string;
  customTabIndex?: number;
  defaultChecked?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  error?: string;
  errorAsInline?: boolean;
  id?: string;
  label?: string;
  name: string;
  noLabel?: boolean;
  onBlur?: (e?: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (args?: any) => void;
  onClear?: (() => void) | ((e: React.MouseEvent<Element>) => void);
  isClearable?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  textWrap?: boolean;
  type: InputType;
  value?: string | number;
  addonWidth?: number;
  addonRight?: React.ReactNode;
  symbol?: string;
  textAlign?: 'left' | 'right';
  isRequired?: boolean;
};

const Input: React.FC<InputProps> = React.forwardRef((props: InputProps, ref: React.Ref<any>) => {
  const {
    autocomplete,
    autoFocus,
    customTabIndex,
    error,
    errorAsInline,
    label,
    name,
    noLabel,
    readOnly,
    type,
    isClearable,
    textAlign,
    isRequired,
  } = props;

  const { onClear, ...rest } = props;

  const formattedLabel = `${label || name}${isRequired ? '*' : ''}`;

  if (type === TYPE_CHECKBOX) {
    return <Checkbox error={props.error} {...rest} />;
  }

  if (type === TYPE_RADIO) {
    return <RadioButton {...rest} value={props.value !== undefined ? props.value.toString() : undefined} />;
  }

  if (type === TYPE_PERCENTAGE) {
    return (
      <FieldContainer>
        {!noLabel && <Label htmlFor={name}>{formattedLabel}</Label>}
        <Label inlineLabel hasParentLabel={!noLabel} htmlFor={name}>
          {'%'}
        </Label>

        <RelativeContainer>
          <StyledInput
            {...rest}
            addonWidth={props.addonWidth}
            hasInlineLabel
            autoComplete={autocomplete}
            hasError={Boolean(error)}
            id={name}
            ref={ref}
            tabIndex={customTabIndex}
            type={TYPE_PERCENTAGE}
            autoFocus={autoFocus}
            readOnly={readOnly}
            min={0}
            max={100}
            textAlign={textAlign}
          />
          {isClearable && onClear && props.value && (
            <ClearButton
              disabled={props.disabled}
              className="input__close"
              moveToRightAmount={props.addonWidth}
              onClick={onClear}
            />
          )}
          {props.addonRight && props.addonRight}
        </RelativeContainer>
        {error && <ErrorText block={!errorAsInline}>{error}</ErrorText>}
      </FieldContainer>
    );
  }

  if (type === TYPE_NUMBER) {
    return (
      <FieldContainer>
        {!noLabel && <Label htmlFor={name}>{formattedLabel}</Label>}

        <RelativeContainer>
          <StyledInput
            {...rest}
            addonWidth={props.addonWidth}
            hasInlineLabel
            autoComplete={autocomplete}
            hasError={Boolean(error)}
            id={name}
            ref={ref}
            tabIndex={customTabIndex}
            type={TYPE_TEXT}
            autoFocus={autoFocus}
            readOnly={readOnly}
            min={0}
            max={100}
            textAlign={textAlign}
          />
          {isClearable && onClear && props.value && (
            <ClearButton
              disabled={props.disabled}
              className="input__close"
              moveToRightAmount={props.addonWidth}
              onClick={onClear}
            />
          )}
          {props.addonRight && props.addonRight}
        </RelativeContainer>
        {error && <ErrorText block={!errorAsInline}>{error}</ErrorText>}
      </FieldContainer>
    );
  }

  if (type === TYPE_CURRENCY) {
    return (
      <FieldContainer>
        {!noLabel && <Label htmlFor={name}>{formattedLabel}</Label>}
        <Label inlineLabel hasParentLabel={!noLabel} htmlFor={name}>
          {props.symbol || '€'}
        </Label>
        <RelativeContainer>
          <StyledInput
            {...rest}
            addonWidth={props.addonWidth}
            hasInlineLabel
            autoComplete={autocomplete}
            hasError={Boolean(error)}
            id={name}
            ref={ref}
            tabIndex={customTabIndex}
            type={TYPE_PERCENTAGE}
            autoFocus={autoFocus}
            readOnly={readOnly}
            textAlign={textAlign}
          />
          {isClearable && onClear && props.value && (
            <ClearButton
              disabled={props.disabled}
              className="input__close"
              moveToRightAmount={props.addonWidth}
              onClick={onClear}
            />
          )}
          {props.addonRight && props.addonRight}
        </RelativeContainer>
        {error && <ErrorText block={!errorAsInline}>{error}</ErrorText>}
      </FieldContainer>
    );
  }

  return (
    <FieldContainer>
      {!noLabel && <Label htmlFor={name}>{formattedLabel}</Label>}
      <Label inlineLabel hasParentLabel={!noLabel} htmlFor={name} />
      <RelativeContainer>
        <StyledInput
          {...rest}
          addonWidth={props.addonWidth}
          hasInlineLabel
          autoComplete={autocomplete}
          hasError={Boolean(error)}
          id={name}
          ref={ref}
          tabIndex={customTabIndex}
          type={type}
          autoFocus={autoFocus}
          readOnly={readOnly}
          textAlign={textAlign}
        />
        {isClearable && onClear && props.value && (
          <ClearButton
            disabled={props.disabled}
            className="input__close"
            moveToRightAmount={props.addonWidth}
            onClick={onClear}
          />
        )}
        {props.addonRight && props.addonRight}
      </RelativeContainer>
      {error && <ErrorText block={!errorAsInline}>{error}</ErrorText>}
    </FieldContainer>
  );
});

export const StyledInput = styled.input<{
  hasError?: boolean;
  hasInlineLabel?: boolean;
  addonWidth?: number;
  isClearable?: boolean;
  textAlign?: 'left' | 'right';
}>`
  background: ${(props) => props.theme.form.input.background};
  border-radius: ${(props) => props.theme.form.input.borderRadius};
  border: ${(props) => (!props.hasError ? props.theme.form.input.border : props.theme.form.input.borderError)};
  box-shadow: ${(props) => props.theme.form.input.boxShadow};
  color: ${(props) => props.theme.form.input.color};
  margin: ${(props) => props.theme.form.input.margin};
  padding: ${(props) => props.theme.form.input.padding};
  transition: box-shadow 0.15s ease-in, border 0.15s ease-in;
  width: 100%;
  height: ${(props) => props.theme.form.input.height};

  ${(props) => `text-align: ${props.textAlign || 'left'}`};
  ${(props) => props.isClearable && props.hasInlineLabel && 'padding-right: 32px'};
  ${(props) =>
    props.isClearable && props.hasInlineLabel && props.addonWidth && `padding-right: ${props.addonWidth + 32}px`};
  ${(props) =>
    !props.isClearable && props.hasInlineLabel && props.addonWidth && `padding-right: ${props.addonWidth + 15}px`};

  &::placeholder {
    color: ${(props) => props.theme.form.input.colorPlaceholder};
  }

  &:focus {
    border: ${(props) => (!props.hasError ? props.theme.form.input.borderFocus : props.theme.form.input.borderError)};
    box-shadow: ${(props) =>
      !props.hasError ? props.theme.form.input.boxShadowFocus : props.theme.form.input.boxShadowFocusError};
  }

  &[disabled] {
    background: ${(props) => props.theme.form.input.backgroundDisabled};
    cursor: not-allowed;
  }

  ${(props) =>
    props.type === 'checkbox' &&
    `
    box-shadow: none;
    width: auto;
    display: flex;
    align-self: flex-start;
  `};
`;

const ClearButton = styled(Close)<{ moveToRightAmount?: number }>`
  &.input__close {
    &&& {
      position: absolute;
      top: 20px;
      right: ${(props) => (props.moveToRightAmount ? `${props.moveToRightAmount + 10}px` : '10px')};
      cursor: pointer;
      > svg {
        max-width: 17px;
        max-height: 17px;
        fill: ${(props) => props.theme.palette.neutral.shade4};
      }
      &:hover {
        > svg {
          fill: ${(props) => props.theme.palette.neutral.shade5};
        }
      }
      &[disabled] {
        background: ${(props) => props.theme.form.input.backgroundDisabled};
        cursor: not-allowed;
      }
    }
  }
`;

export default Input;
