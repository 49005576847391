import { ActionType } from 'typesafe-actions';
import * as filtersAction from './filters';
import * as alertsAction from './alerts';
import { createAlert, removeAlert, setAlert, createSuccessAlert } from './alerts';

export const filterActions = { ...filtersAction };

export const alertsActions = {
  createAlert,
  removeAlert,
  setAlert,
  createSuccessAlert,
};

export type FilterActionsType = ActionType<typeof filtersAction>;
export type AlertsAction = ActionType<typeof alertsAction>;
