import * as React from 'react';
import { media } from 'config/theme/utils';
import styled from '../../../../config/theme';

type Props = {
  className?: string;
  children: JSX.Element | JSX.Element[] | null | React.ReactNode;
};

const DraftActionsWrapper: React.FC<Props> = ({ className, children }: Props) => (
  <Wrapper className={`draftActions ${className}`}>{children}</Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  > button {
    margin-left: 10px;
  }

  ${() => media.desktop`
      margin-right: 25px;
    `}
`;

export default DraftActionsWrapper;
