import { NavLink } from 'react-router-dom';
import { ItemWrapper } from './ListItemElements';

type Props = {
  to: string;
  height: number;
  children: JSX.Element[];
};

const ListItem = ({ to, height, children, ...other }: Props) => (
  <ItemWrapper height={height} {...other}>
    <NavLink to={to} activeClassName="active">
      {children}
    </NavLink>
  </ItemWrapper>
);

export default ListItem;
