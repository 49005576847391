import { Button, SubmitButton } from '@unbrace/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Header } from '../../../app/components';
import { DraftActionsWrapper } from '../../../app/components/Header';
import { PRODUCTS_BASE_URL } from '../../constants';

type Props = {
  handleSave: () => void;
  hasChanges: boolean;
  isSaving?: boolean;
  productId: number;
};

const PricingNewHeader: React.FC<Props> = ({ handleSave, hasChanges, isSaving, productId }: Props) => {
  const { t } = useTranslation('products');
  const history = useHistory();
  const handleSkip = React.useCallback(() => history.push(`${PRODUCTS_BASE_URL}/${productId}`), [history, productId]);

  return (
    <Header>
      <div />
      <DraftActionsWrapper>
        <Button isLoading={isSaving} color="tertiary" onClick={handleSkip}>
          {t('header.skipPricing')}
        </Button>
        <SubmitButton isLoading={isSaving} color="primary" onClick={handleSave} isDisabled={!hasChanges}>
          {t('header.addPricingSave')}
        </SubmitButton>
      </DraftActionsWrapper>
    </Header>
  );
};

export default PricingNewHeader;
