import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const SyncError: React.FC = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M38.8163 41.3463L33.1417 35.5L38.8163 29.6537C39.0578 29.4049 39.0578 29.011 38.8163 28.7829L37.1059 27.0207L31.0088 33.3024L24.8915 27L23.1811 28.7622C22.9396 29.011 22.9396 29.4049 23.1811 29.6329L28.8557 35.4793L23.2012 41.3463C22.9598 41.5951 22.9598 41.989 23.2012 42.2171L24.9116 43.9793L31.0088 37.6976L37.126 44L38.8364 42.2378C39.0578 41.989 39.0578 41.5951 38.8163 41.3463Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.002 29.7846L26.074 24.7169C25.689 24.321 25.0559 24.3121 24.66 24.6972L20.7169 28.5315C20.321 28.9165 20.3121 29.5497 20.6972 29.9456L22.6949 32H13.0765C3.16364 32 -0.966632 20.8327 7.78912 14.3181C7.45883 4.23512 17.7016 -1.03874 25.9626 6.71709C32.7366 5.47585 36.702 7.18329 39.3456 13.0768C52.2203 16.4663 49.4283 30.5024 39.4779 31.8895L41.3604 29.9916C41.7493 29.5995 41.7467 28.9663 41.3546 28.5774L37.4497 24.7042C37.0576 24.3153 36.4244 24.3178 36.0355 24.71L31.002 29.7846Z"
      />
    </svg>
  </BaseSvgWrapper>
);

export default SyncError;
