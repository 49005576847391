import AxiosConfig, { RequestConfig } from '../../../config/api/AxiosConfig';
import { EXTERNAL_LINKS_URL } from './endpoints';
import { ExternalLink } from '../types/config';

class ConfigApi {
  public async getExternalLinks() {
    const requestConfig: RequestConfig = {
      url: EXTERNAL_LINKS_URL,
    };

    return AxiosConfig.fetchAxiosGetRequest<ExternalLink[]>(requestConfig);
  }
}

export default new ConfigApi();
