import { QueryCache, QueryClient, QueryClientProvider as PrimitiveProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: process.env.NODE_ENV === 'production' ? 3 : 1,
      refetchOnWindowFocus: process.env.NODE_ENV === 'production',
      // cacheTime: By default, "inactive" queries are garbage collected after 5 minutes.
      // staleTime: By default, instantly. Still gets data from the cache, but a background refetch can happen.
    },
    mutations: {
      retry: 1,
    },
  },
  queryCache: new QueryCache({
    onError: (_error, query) => {
      if (query.state.data === undefined) {
        // HANDLE ALERT ON LOAD
      } else if (query.state.data !== undefined) {
        // HANDLE ALERT ON REFETCH
      }
    },
  }),
});

type Props = {} & React.ComponentPropsWithoutRef<'div'>;

const QueryClientProvider = ({ children }: Props) => (
  <PrimitiveProvider client={queryClient}>
    {children}
    <ReactQueryDevtools />
  </PrimitiveProvider>
);

export default QueryClientProvider;
