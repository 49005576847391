export type Group<T> = {
  [key: string]: T[];
};

export type NestedGroup<T> = {
  [key: string]: Group<T>;
};

function groupBy<T>(collection: T[], groupByKey: string, thenByKey?: string): Group<T> | NestedGroup<T> {
  const rs: Group<T> = collection.reduce((result: Group<T>, item: T) => {
    (result[item[groupByKey]] = result[item[groupByKey]] || []).push(item);

    return result;
  }, {});

  return thenByKey ? thenBy<T>(rs, thenByKey) : rs;
}

function thenBy<T>(groupedResult: Group<T>, key: string): NestedGroup<T> {
  return Object.keys(groupedResult).reduce((result, group) => {
    result[group] = groupBy(groupedResult[group], key);

    return result;
  }, {});
}

export default groupBy;
