import { Button, ModalContent, SubmitButton } from '@unbrace/components';
import { Input } from 'components';
import { Pricing } from 'modules/products/types/pricing';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import PricingFormik from './PricingFormik';
import usePricingStore from 'modules/products/services/usePricingStore';

type Props = {
  toggleModal: VoidFunction;
  closeModal: VoidFunction;
  productGuid: string;
  price?: Pricing;
  isEdit?: boolean;
  keepOpenCheckbox: React.ElementType<typeof Input>;
};

const PricingModal: React.FC<Props> = ({ toggleModal, closeModal, productGuid, keepOpenCheckbox, price, isEdit }) => {
  const { t } = useTranslation('products');
  const formRef: React.RefObject<any> = React.useRef<any>(null);
  const { isUpdatePricingLoading, isCreatePricingLoading, createPricing, updatePricing } = usePricingStore();
  const handleSubmit = () => {
    formRef.current.handleSubmit();
  };

  return (
    <ModalContent>
      <ModalContent.Header>
        <h1>{isEdit ? t('pricing.modal.editTitle') : t('pricing.modal.title')}</h1>
        <h5>{isEdit ? t('pricing.modal.editSubTitle') : t('pricing.modal.subTitle')}</h5>
      </ModalContent.Header>
      <ModalContent.Body>
        <PricingFormik
          toggleModal={toggleModal}
          productGuid={productGuid}
          formRef={formRef}
          price={price}
          isEdit={isEdit}
          createPricing={createPricing}
          updatePricing={updatePricing}
        />
      </ModalContent.Body>
      <ModalContent.Footer>
        {!isEdit && keepOpenCheckbox}
        <Button onClick={closeModal} color="tertiary">
          {t('pricing.modal.close')}
        </Button>
        <SubmitButton
          isLoading={isEdit ? isUpdatePricingLoading : isCreatePricingLoading}
          onClick={handleSubmit}
          color="primary"
          type="submit"
        >
          {isEdit ? t('pricing.modal.edit') : t('pricing.modal.add')}
        </SubmitButton>
      </ModalContent.Footer>
    </ModalContent>
  );
};

export default PricingModal;
