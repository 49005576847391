import { UnbraceThemeProvider } from '@unbrace/components';
import QueryClientProvider from 'config/adapters/QueryClientProvider';
import 'config/i18n';
import configureStore, { history } from 'config/store/configureStore';
import { rootReducer } from 'config/store/rootReducer';
import { GlobalStyle, theme } from 'config/theme';
import 'config/yup';
import { ConnectedRouter } from 'connected-react-router';
import * as React from 'react';
import { Provider } from 'react-redux';
import { SecureModules } from '../../security';
import { Sidebar } from '../components';
import AppLoadingState from '../components/AppLoadingState';
import { Routes } from '../routes/';
import AlertsService from '../services/AlertService';
import InsightsProvider from '../services/InsightsService';

const App: React.FC = () => {
  return (
    <React.Suspense fallback={<AppLoadingState />}>
      <InsightsProvider>
        <UnbraceThemeProvider customBaseTheme={theme.base} customTheme={theme.default}>
          <QueryClientProvider>
            <Provider store={configureStore(rootReducer(history))}>
              <GlobalStyle />
              <AlertsService />

              <ConnectedRouter history={history}>
                <SecureModules>
                  {({ modules, isAuthenticated, account }) => (
                    <React.Fragment>
                      {isAuthenticated && (
                        <Sidebar userName={account && account.name} availableModules={[...modules]} />
                      )}
                      <Routes allowedModules={modules} isAuthenticated={isAuthenticated} />
                    </React.Fragment>
                  )}
                </SecureModules>
              </ConnectedRouter>
            </Provider>
          </QueryClientProvider>
        </UnbraceThemeProvider>
      </InsightsProvider>
    </React.Suspense>
  );
};

export default App;
