import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const ApprovePeriod: React.FC = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6632 45H5V9.44444H9.44444V5H16.1111V9.44444H33.8889V5H40.5556V9.44444H45V27.0766H40.5556V22.7778H9.44444V40.5556H20.6632V45ZM40.5556 18.3333V13.8889H9.44444V18.3333H40.5556Z"
      />
      <path d="M31.752 44.5943L27.2379 39.7892L24.4915 42.5915L31.7521 50L50 33.3812L47.4486 30.3781L31.752 44.5943Z" />
    </svg>
  </BaseSvgWrapper>
);

export default ApprovePeriod;
