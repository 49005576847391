import useModulesStore from '../services/useModulesStore';

export const useCanIAccesModule = () => {
  const { accessibleModules } = useModulesStore();

  const canIAccess = (moduleKey: string) =>
    Boolean(accessibleModules?.find((module) => module.key.toLowerCase() === moduleKey.toLowerCase()));

  return {
    canIAccess,
  };
};
