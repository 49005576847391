import * as React from 'react';
import { Route } from 'react-router-dom';
import { loadScene } from '../../../components';

type Props = {};

const internalLoginScene = loadScene('security', 'LoginScene');

export const SECURITY_BASE_ROUTES = '/login';

const SecurityRoutes: React.FC<Props> = () => {
  return (
    <React.Fragment>
      <Route path={'/login'} exact component={internalLoginScene} />
    </React.Fragment>
  );
};

export default SecurityRoutes;
