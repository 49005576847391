import styled from '../../config/theme';
import { Flexer } from '../Positioning';

export const Wrapper = styled(Flexer)`
  padding: 16px;
`;

export const ConfirmationDescription = styled.p`
  color: ${(props) => props.theme.contextMenu.color.blockTitle};
  font-size: ${(props) => props.theme.contextMenu.fontSize.blockTitle};
  font-weight: ${(props) => props.theme.contextMenu.fontWeight.blockTitle};
  letter-spacing: 1.5px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  white-space: pre-line;
`;

export const ConfirmationButtonWrapper = styled(Flexer)`
  button {
    margin-left: 5px;
    :nth-child(1) {
      margin-left: 0px;
    }
  }
`;
