import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { history } from '../store/configureStore';

class Insights {
  private _appInsights: ApplicationInsights | undefined;
  private _connString = process.env.REACT_APP_APP_INSIGHTS_CONN_STRING;
  public plugin: ReactPlugin | undefined;

  private _getURIPaths(url: string) {
    try {
      return new URL(url).pathname.split('/');
    } catch (_) {
      return url.split('/');
    }
  }

  private _isEnabled() {
    return Boolean(this._connString);
  }

  /** Because we are working with a SPA that doesn't change the title on every page view.
   *  We are changing the name of the telemetry item to a join of the path name.
   *  But as we do not want to track different guids as page name, we are also replacing the guid with "[id]"
   *  This way we will get better tracking of the page views.
   */
  private _adjustPageViewTelemetry() {
    if (this._appInsights) {
      this._appInsights.addTelemetryInitializer((envelop) => {
        const telemetryItem = envelop.baseData;
        if (telemetryItem && envelop.baseType?.includes('Pageview')) {
          if (telemetryItem.uri && typeof telemetryItem.uri === 'string') {
            const [, ...path] = this._getURIPaths(telemetryItem.uri);
            const safeIds = path.map((pathItem) =>
              pathItem.length > 22 || /^-?\d+$/.test(pathItem) ? '[id]' : pathItem
            );
            telemetryItem.name = safeIds.join('-');
          }
        }
      });
    }
  }

  constructor() {
    if (this._isEnabled()) {
      this.plugin = new ReactPlugin();
      this._appInsights = new ApplicationInsights({
        config: {
          connectionString: this._connString,
          // Get more info about correlation on https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript#enable-correlation
          // enableCorsCorrelation: true,

          // If you want to track request and response headers, set these to true,
          // but be aware that it will track everything, even headers containing sensitive data
          enableRequestHeaderTracking: false,
          enableResponseHeaderTracking: false,
          autoTrackPageVisitTime: true,

          // The React plug-in automatically tracks route changes and collects other React-specific telemetry.
          enableAutoRouteTracking: false,
          extensions: [this.plugin],
          extensionConfig: {
            [this.plugin.identifier]: { history },
          },
        },
      });

      this._appInsights.loadAppInsights();
      this._adjustPageViewTelemetry();
    }
  }
}

const AppInsightsConfig = new Insights();

export { AppInsightsConfig };
