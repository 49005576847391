import * as React from 'react';
import styled from 'config/theme';
import { media } from '../../config/theme/utils';
import ToggleBadge from './ToggleBadge';

type Props = {
  badge1Name: string;
  badge2Name: string;
  // true -> first badge active, false 2nd active
  state: boolean;
  setState: (state: boolean) => void;
};

const ViewToggle: React.FC<Props> = ({ badge1Name, badge2Name, state, setState }) => {
  const setFirstActive = React.useCallback(() => setState(true), [setState]);
  const setSecondActive = React.useCallback(() => setState(false), [setState]);

  return (
    <SwitcherWrapper>
      <ToggleBadge active={state} onClick={setFirstActive} data-cy={`${badge1Name}-toggle`}>
        {badge1Name}
      </ToggleBadge>
      <ToggleBadge active={!state} onClick={setSecondActive} data-cy={`${badge2Name}-toggle`}>
        {badge2Name}
      </ToggleBadge>
    </SwitcherWrapper>
  );
};

const SwitcherWrapper = styled.div`
  display: flex;
  margin: 0 0 15px 0;
  ${() => media.desktop`
    max-width: 1400px;
  `};
  border-bottom: 2px solid ${(props) => props.theme.palette.neutral.shade1};
`;

export default ViewToggle;
