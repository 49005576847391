import * as React from 'react';
import { Route } from 'react-router-dom';
import { loadScene } from '../../../components';
import ModuleRoutes from '../../../components/routing/ModuleRoutes';
import { Module } from '../../security/types/modules';
import { INVOICES_MODULE_KEY } from '../constants';

type Props = {
  allowedModules: Module[];
};

const invoiceScene = loadScene('invoice', 'InvoiceScene');

export const DRAFTS_BASE_ROUTES = '/drafts';
export const INVOICES_BASE_ROUTES = '/invoices';
export const INVOICES_SUB_ROUTE = '/invoices';
export const REQUESTLOG_SUB_ROUTE = '/invoicerequests';

const InvoiceRoutes: React.FC<Props> = ({ allowedModules }: Props) => {
  return (
    <ModuleRoutes allowedModules={allowedModules} moduleKey={INVOICES_MODULE_KEY}>
      <Route path={INVOICES_BASE_ROUTES} component={invoiceScene} />
    </ModuleRoutes>
  );
};

export default InvoiceRoutes;
