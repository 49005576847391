import { useQueryClient } from '@tanstack/react-query';
import { useMutationWithSuccessAlert, useQueryWithAlert } from 'adapters/queries';
import { PRODUCTS_BASE_ROUTES } from 'modules/products/routes';
import { ProductAndPriceFormValues } from 'modules/products/types/product';
import { useHistory } from 'react-router';
import { GetParamByIndex } from 'types';
import { api } from '..';
import { productsKeys } from '../keyFactory';

export const useGetProductDetail = (id?: number | string) =>
  useQueryWithAlert(
    productsKeys.detail(id),
    () => (typeof id === 'string' ? api.getProductDetailByGuid(id || '') : api.getProductDetailById(id || 0)),
    {
      enabled: id !== undefined,
      keepPreviousData: true,
    }
  );

export const useMutateUpdateProduct = () => {
  const queryClient = useQueryClient();

  return useMutationWithSuccessAlert(
    (payload: GetParamByIndex<typeof api.updateProduct, 0>) => api.updateProduct(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(productsKeys.list());
        queryClient.invalidateQueries(productsKeys.detailRoot());
      },
    }
  );
};

export const useMutateCreateProduct = () => {
  const queryClient = useQueryClient();
  const history = useHistory();

  return useMutationWithSuccessAlert(
    (payload: ProductAndPriceFormValues) =>
      api.createProduct({
        ...payload,
        productPartnerCenterProfile: payload.partnerCenterProfile,
      }),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(productsKeys.list());
        queryClient.invalidateQueries(productsKeys.detailRoot());
        history.push(
          variables.pricingNext
            ? `${PRODUCTS_BASE_ROUTES}/add/${data.productId}/pricing`
            : `${PRODUCTS_BASE_ROUTES}/${data.productId}`
        );
      },
    }
  );
};
