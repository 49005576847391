import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const MoreHorizontal: React.FC = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37 20.1698C37 22.5755 35.1111 24.5 32.9074 24.5C30.5463 24.5 28.6574 22.5755 28.6574 20.1698C28.6574 17.9245 30.5463 16 32.9074 16C35.1111 16 37 17.9245 37 20.1698Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.0926 20.1698C24.0926 22.5755 22.3611 24.5 20 24.5C17.6389 24.5 15.75 22.5755 15.75 20.1698C15.75 17.9245 17.6389 16 20 16C22.3611 16 24.0926 17.9245 24.0926 20.1698Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3426 20.1698C11.3426 22.5755 9.4537 24.5 7.09259 24.5C4.88889 24.5 3 22.5755 3 20.1698C3 17.9245 4.88889 16 7.09259 16C9.4537 16 11.3426 17.9245 11.3426 20.1698Z"
      />
    </svg>
  </BaseSvgWrapper>
);

export default MoreHorizontal;
