import { useMultiSelect } from 'hooks';
import * as React from 'react';
import { MetaData } from 'types/metadata';
import { useMetaData } from '../../../components/MetaData';
import { ProductHeader, ProductInfoFullView } from '../components';
import { Pricing } from '../types/pricing';
import ProductDefaultView from './ProductDefaultView';
import ProductEdit from './ProductEdit';
import useGlobalInfoStore from '../services/useGlobalInfoStore';
import useProductStore from '../services/useProductStore';
import usePricingStore from '../services/usePricingStore';

type Props = {
  activeProductsId: string | undefined;
};

const ProductsDetail: React.FC<Props> = ({ activeProductsId }) => {
  const { productsMetaData } = useGlobalInfoStore();
  const { newProduct, isPending } = useProductStore(activeProductsId);
  const { pricing, isPending: isPricingPending } = usePricingStore(newProduct?.productGuid);
  const [showFullView, setShowFullView] = React.useState(false);
  const [showEditView, setShowEditView] = React.useState(false);
  const [metaData, metaDataActions] = useMetaData(
    productsMetaData as unknown as MetaData[],
    newProduct?.metadatas as unknown as MetaData[]
  );

  const items = React.useMemo(
    () => Object.values(pricing?.entity || {}).reduce((result, key) => [...result, ...key], pricing?.general || []),
    [pricing]
  );
  const [selectedPricing, selectedPricingActions] = useMultiSelect<Pricing>(items, 'priceId');

  React.useEffect(() => {
    return () => {
      setShowEditView(false);
    };
  }, [activeProductsId]);

  const baseHeader = (
    <ProductHeader
      setShowEdit={setShowEditView}
      setShowFullView={setShowFullView}
      showEditView={showEditView}
      showFullView={showFullView}
      metaData={metaData}
      isPending={!newProduct?.productId}
      selectedPricing={selectedPricing}
      selectedPricingActions={selectedPricingActions}
    />
  );

  return (
    <React.Fragment>
      {!showFullView && !showEditView && (
        <ProductDefaultView
          baseHeader={baseHeader}
          key={activeProductsId}
          pricing={pricing}
          pricingIsPending={isPricingPending}
          product={newProduct}
          productIsPending={isPending}
          selectedPricing={selectedPricing}
          selectedPricingActions={selectedPricingActions}
        />
      )}
      {showFullView && (
        <ProductInfoFullView product={newProduct} baseHeader={baseHeader} setShowFullView={setShowFullView} />
      )}
      {showEditView && newProduct && (
        <ProductEdit
          baseHeader={baseHeader}
          product={newProduct}
          setShowEditView={setShowEditView}
          metaDataActions={metaDataActions}
        />
      )}
    </React.Fragment>
  );
};

export default ProductsDetail;
