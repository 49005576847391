import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

type Props = {
  className?: string;
};

const Attachment: React.FunctionComponent<Props> = (props: Props) => (
  <BaseSvgWrapper {...props}>
    <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
      <path d="M38.1655 29.0966L27.7269 39.5352C23.8124 43.4497 17.4097 43.4497 13.4952 39.5352C9.58069 35.6207 9.58069 29.2179 13.4952 25.3034L29.4869 9.31172C31.8234 6.97517 35.5862 6.97517 37.9228 9.31172C40.2593 11.6483 40.2593 15.411 37.9228 17.7476L22.7807 32.8897C22.0524 33.6179 20.7476 33.6179 19.989 32.8897C19.6248 32.5255 19.4124 32.04 19.4124 31.4938C19.4124 30.9779 19.6248 30.4621 19.989 30.0979L29.1531 20.9338C30.0331 20.0538 30.0331 18.5972 29.1531 17.7172C28.2731 16.8372 26.8166 16.8372 25.9366 17.7172L16.7724 26.851C15.5586 28.0648 14.8607 29.7034 14.8607 31.4634C14.8607 33.1931 15.5283 34.8317 16.7724 36.0759C19.3214 38.6248 23.4483 38.6248 25.9669 36.0759L41.109 20.9338C43.0814 18.9614 44.1738 16.3214 44.1738 13.4993C44.1738 10.7076 43.0814 8.06759 41.109 6.06483C39.1366 4.09241 36.4966 3 33.6745 3C30.8828 3 28.2428 4.09241 26.24 6.06483L10.2786 22.0566C4.57379 27.7614 4.57379 37.0166 10.2786 42.7214C13.131 45.5738 16.8634 47 20.5959 47C24.3283 47 28.091 45.5738 30.9131 42.7214L41.3517 32.2828C42.2317 31.4028 42.2317 29.9462 41.3517 29.0662C40.4717 28.1862 39.0455 28.1862 38.1655 29.0966Z" />
    </svg>
  </BaseSvgWrapper>
);

export default Attachment;
