import * as React from 'react';
import { Route } from 'react-router-dom';
import { MAILS_BASE_ROUTES, MAIL_MODULE_KEY } from '../constants';
import { loadScene } from 'components';
import ModuleRoutes from 'components/routing/ModuleRoutes';
import { Module } from 'modules/security/types/modules';

type Props = {
  allowedModules: Module[];
};

const mailScene = loadScene('mails', 'MailScene');

const MailRoutes: React.FunctionComponent<Props> = ({ allowedModules }: Props) => (
  <ModuleRoutes allowedModules={allowedModules} moduleKey={MAIL_MODULE_KEY}>
    <Route path={[MAILS_BASE_ROUTES, '/mails/:id']} exact component={mailScene} />
  </ModuleRoutes>
);

export default MailRoutes;
