import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const Reminder: React.FC = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.6211 10H3V40.66H46.8V28.7636C45.4501 29.5507 43.9784 30.1518 42.42 30.5315V36.28H7.38V17.008L23.367 31.243C23.805 31.681 24.462 31.9 24.9 31.9C25.338 31.9 25.995 31.681 26.433 31.243L29.6175 28.4075C28.393 27.6238 27.2788 26.6828 26.3035 25.613L24.9 26.863L10.884 14.38H22.0004C22.0112 12.8625 22.2269 11.3938 22.6211 10Z" />
      <path d="M50 15.0499C50 21.893 44.5133 27.4404 37.7452 27.4404C30.9771 27.4404 25.4904 21.893 25.4904 15.0499C25.4904 8.20684 30.9771 2.65942 37.7452 2.65942C44.5133 2.65942 50 8.20684 50 15.0499ZM28.1978 15.0499C28.1978 20.3812 32.4723 24.7031 37.7452 24.7031C43.0181 24.7031 47.2927 20.3812 47.2927 15.0499C47.2927 9.71863 43.0181 5.39675 37.7452 5.39675C32.4723 5.39675 28.1978 9.71863 28.1978 15.0499Z" />
      <path d="M36.0968 7.64557H39.038V17.558H37.5674C37.5674 17.558 37.1117 17.5456 36.924 17.5076C36.3931 17.4003 36.2668 17.1882 36.1581 16.6519C36.1184 16.4564 36.0968 16.0556 36.0968 16.0556V7.64557Z" />
      <path d="M43.2143 17.8806L41.7438 20.4559L36.5755 17.3953C36.5755 17.3953 36.3036 17.1475 36.2002 16.6597C36.0906 16.1426 36.0968 16.0711 36.0968 16.0711L37.5674 14.5842L43.2143 17.8806Z" />
    </svg>
  </BaseSvgWrapper>
);

export default Reminder;
