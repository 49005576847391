import { TenantTheme } from '../tenants/unbrace';

const itemSidebar = (theme: TenantTheme) => ({
  animation: {
    main: {
      entered: 'opacity: 1; transition: opacity 200ms 50ms, transform 200ms 0ms ease-in; transform: none',
      entering: 'opacity: 0.01; transform: scale(0.99)',
    },
    filters: {
      entered: 'opacity: 1; transition: opacity 200ms 50ms',
      entering: 'opacity: 0.01;',
    },
  },
  background: {
    count: theme.palette.neutral.shade0,
    item: {
      active: theme.palette.primary.shade1,
      hover: theme.palette.neutral.shade1,
    },
    main: theme.palette.neutral.shade0,
    filter: theme.palette.neutral.shade0,
    filterButton: {
      main: theme.palette.neutral.shade2,
      active: theme.palette.primary.shade2,
      pill: theme.palette.primary.shade4,
    },
  },
  border: {
    itemBottom: `solid 1px ${theme.palette.neutral.shade2}`,
    radiusCount: theme.globals.borderRadiusSmall,
    right: `solid 2px ${theme.palette.neutral.shade2}`,
  },
  color: {
    count: theme.palette.neutral.shade4,
    item: {
      header: theme.palette.neutral.shade9,
      subText: theme.palette.neutral.shade4,
      text: theme.palette.neutral.shade5,
    },
    loading: theme.palette.neutral.shade4,
    secondary: theme.palette.neutral.shade4,
    filterButton: {
      pill: theme.palette.primary.shade1,
    },
  },
  fontSize: {
    count: theme.typography.fontSize.small_4,
    header: theme.typography.fontSize.regular,
    headerStrong: theme.typography.fontSize.regular,
    icon: theme.typography.fontSize.medium_5,
    subText: theme.typography.fontSize.small_4,
    text: theme.typography.fontSize.small_5,
  },
});

export default itemSidebar;
