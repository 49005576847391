import { TenantTheme } from '../tenants/unbrace';

const taskTheme = (theme: TenantTheme) => ({
  color: {
    contact: theme.palette.primary.shade4,
    error: theme.palette.danger.shade4,
    primary: theme.palette.neutral.shade9,
    secondary: theme.palette.neutral.shade5,
    pre: theme.palette.neutral.shade6,
  },
  background: {
    pre: theme.palette.neutral.shade1,
  },
  border: {
    pre: `1px solid ${theme.palette.neutral.shade3}`,
  },
  fontSizes: {
    normal: theme.typography.fontSize.small_5,
    small: theme.typography.fontSize.small_4,
    title: theme.typography.fontSize.medium_6,
  },
  inlineTable: {
    color: theme.palette.neutral.shade4,
    borderBottom: `1px solid ${theme.palette.neutral.shade2}`,
  },
});

export default taskTheme;
