import { Field, FieldProps, FormikErrors, FormikTouched } from 'formik';
import { useTranslation } from 'react-i18next';
import { Segment } from '../../../../adapters/products/productsAPI';
import { FormikSelect } from '../../../../components/Form';
import { EditableItem } from '../../../../components/FullView';
import { getNestedError } from '../../../../helpers';
import { ProductBaseFormValues } from '../../types/product';

const SegmentOptionsFlat: Segment[] = ['Commercial', 'Education', 'Charity'];
const segmentOptions = SegmentOptionsFlat.map((segment) => ({
  label: segment,
  value: segment.toLowerCase(),
}));

type Props = {
  touched: FormikTouched<ProductBaseFormValues>;
  errors: FormikErrors<ProductBaseFormValues>;
};

const SegmentField = ({ errors, touched }: Props) => {
  const { t } = useTranslation('products');

  return (
    <EditableItem
      label={t('fullView.labels.segment')}
      value={
        <Field
          name={t(`partnerCenterProfile.segment`)}
          render={(fieldProps: FieldProps) => (
            <FormikSelect
              {...fieldProps}
              options={segmentOptions}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              error={getNestedError('partnerCenterProfile.segment', errors, touched)}
            />
          )}
        />
      }
    />
  );
};

export default SegmentField;
