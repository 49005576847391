import {
  useGetProductsMetaData,
  useGetPriceMethods,
  useGetPriceTranslations,
  useGetPriceTypes,
  useGetProductRebategroups,
  useGetProductStatuses,
  useGetProductTypes,
} from '../adapters/queries/globalInfoQueries';

const useGlobalInfoStore = () => {
  const { data: productsMetaData, isLoading: isProductsMetaDataLoading } = useGetProductsMetaData();
  const { data: priceMethods, isLoading: isPriceMethodsLoading } = useGetPriceMethods();
  const { data: priceTranslations, isLoading: isPriceTranslationsLoading } = useGetPriceTranslations({});
  const { data: priceTypes, isLoading: isPriceTypesLoading } = useGetPriceTypes();
  const { data: productRebategroups, isLoading: isProductRebategroupsLoading } = useGetProductRebategroups({});
  const { data: productStatuses, isLoading: isProductStatusesLoading } = useGetProductStatuses();
  const { data: productTypes, isLoading: isProductTypesLoading } = useGetProductTypes();

  return {
    productsMetaData,
    isProductsMetaDataLoading,
    priceMethods,
    isPriceMethodsLoading,
    priceTranslations,
    isPriceTranslationsLoading,
    priceTypes,
    isPriceTypesLoading,
    productRebategroups,
    isProductRebategroupsLoading,
    productStatuses,
    isProductStatusesLoading,
    productTypes,
    isProductTypesLoading,
  };
};

export default useGlobalInfoStore;
