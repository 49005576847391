import { Button, SubmitButton } from '@unbrace/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { Header } from '../../../app/components';
import { DraftActionsWrapper } from '../../../app/components/Header';
import { PRODUCTS_BASE_URL } from '../../constants';

type Props = {
  handleCancel: () => void;
  handleSave: () => void;
  handleSaveWithPricing: () => void;
  hasChanges: boolean;
  isSaving?: boolean;
} & RouteComponentProps;

const CompanyNewHeader: React.FC<Props> = ({
  handleCancel,
  handleSave,
  handleSaveWithPricing,
  hasChanges,
  history,
  isSaving,
}: Props) => {
  const { t } = useTranslation('products');
  const handleClose = React.useCallback(() => history.push(PRODUCTS_BASE_URL), [history]);

  return (
    <Header>
      <div />
      <DraftActionsWrapper>
        <Button isDisabled={isSaving} color="tertiary" onClick={hasChanges ? handleCancel : handleClose}>
          {hasChanges ? t('header.cancelEdit') : t('header.closeEdit')}
        </Button>
        <SubmitButton isLoading={isSaving} color="tertiary" onClick={handleSave} isDisabled={!hasChanges}>
          {t('header.addProductSave')}
        </SubmitButton>
        <SubmitButton isLoading={isSaving} color="primary" onClick={handleSaveWithPricing} isDisabled={!hasChanges}>
          {t('header.addPricingNextSave')}
        </SubmitButton>
      </DraftActionsWrapper>
    </Header>
  );
};

export default withRouter(CompanyNewHeader);
