import { ArrayHelpers, FormikErrors } from 'formik';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { MetaDataValue, UseMetaDataActions } from '../../types/metadata';
import { EditableItem } from '../FullView';
import MetaDataField from './MetaDataField';

type Props = {
  metaData: MetaDataValue;
  index: number;
  arrayHelpers: ArrayHelpers;
  metaDataActions?: UseMetaDataActions;
  errors: FormikErrors<{
    [key: string]: any;
    metadatas: MetaDataValue[];
  }>;
};

const EditableMetaData: React.FC<Props> = ({ metaData, errors, index, ...props }: Props) => {
  const { t } = useTranslation('metaData');
  const error = errors.metadatas && (errors.metadatas as FormikErrors<MetaDataValue>)[index];

  return (
    <EditableItem
      label={t(`metaData:${metaData.code?.toUpperCase()}`) || ''}
      value={
        <MetaDataField
          metaData={metaData}
          inputProps={{ noLabel: true, error: error && error.value }}
          index={index}
          {...props}
        />
      }
    />
  );
};

export default EditableMetaData;
