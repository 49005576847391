import { takeEvery, put, call } from 'redux-saga/effects';
import { GenericAction } from '../../../../types';
import accountApi from '../../../security/api/AccountApi';
import { logoutActions } from '../../../security/redux/actions/creators';
import { Account } from '../../../security/types/user';
import { alertsActions } from '../actions/creators';

function* alertsSaga() {
  yield takeEvery((action: GenericAction) => /(.*)_REJECTED/.test(action.type), handleAlertsWithUnauthenticatedFlow);
}

function* handleAlertsWithUnauthenticatedFlow(action: GenericAction) {
  const { payload } = action;
  if (payload?.status === 401) {
    const accountPayload: Account = yield call(accountApi.getAccount);
    if (!accountPayload) {
      yield put(logoutActions.initiateLogout());
    } else {
      yield put(alertsActions.setAlert(action.payload));
    }
  } else {
    yield put(alertsActions.setAlert(action.payload));
  }
}
export default alertsSaga;
