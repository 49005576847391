import AxiosConfig, { RequestConfig } from '../../../config/api/AxiosConfig';
import { ACCOUNT_URL, OAUTH_URL } from './endpoints';

class LoginApi {
  public async login(email: string, password: string, returnUrl: string) {
    const requestConfig: RequestConfig = {
      data: {
        email,
        password,
        returnUrl,
      },
      url: `${ACCOUNT_URL}/login`,
    };

    return AxiosConfig.fetchAxiosPostRequest(requestConfig);
  }

  public async redirect(returnUrl: string) {
    const requestConfig: RequestConfig = {
      params: {
        returnUrl,
      },
      url: `${ACCOUNT_URL}/login/redirect`,
    };

    return AxiosConfig.fetchAxiosRequestTemp(requestConfig);
  }

  public async oauthSignIn(uriString: string) {
    const requestConfig: RequestConfig = {
      url: `${OAUTH_URL}/signin${uriString}`,
    };

    return AxiosConfig.fetchAxiosRequestTemp(requestConfig);
  }
}

export default new LoginApi();
