import AxiosConfig, { RequestConfig } from '../../../config/api/AxiosConfig';
import { ACCOUNT_URL } from './endpoints';

class LogoutApi {
  public async logout() {
    const requestConfig: RequestConfig = {
      url: `${ACCOUNT_URL}/logout/redirect`,
    };

    return AxiosConfig.fetchAxiosRequestTemp<string | undefined>(requestConfig);
  }
}

export default new LogoutApi();
