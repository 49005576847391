import * as React from 'react';
import { useTranslation } from 'react-i18next';
import FormikSelect, { Props as FormikSelectProps } from './FormikSelect';

type Props = FormikSelectProps<{ label: string; value: string }>;

const MonthPicker: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation('global');

  const monthOptions = [
    {
      label: `${t('date.month.january')}`,
      value: '0',
    },
    {
      label: `${t('date.month.february')}`,
      value: '1',
    },
    {
      label: `${t('date.month.march')}`,
      value: '2',
    },
    {
      label: `${t('date.month.april')}`,
      value: '3',
    },
    {
      label: `${t('date.month.may')}`,
      value: '4',
    },
    {
      label: `${t('date.month.june')}`,
      value: '5',
    },
    {
      label: `${t('date.month.july')}`,
      value: '6',
    },
    {
      label: `${t('date.month.august')}`,
      value: '7',
    },
    {
      label: `${t('date.month.september')}`,
      value: '8',
    },
    {
      label: `${t('date.month.october')}`,
      value: '9',
    },
    {
      label: `${t('date.month.november')}`,
      value: '10',
    },
    {
      label: `${t('date.month.december')}`,
      value: '11',
    },
  ];

  return <FormikSelect {...props} options={monthOptions} />;
};

export default MonthPicker;
