import styled from 'styled-components';

export const ModalMainInfo = styled.div<{ fullWidthColumn?: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  .column {
    width: ${(props) => (props.fullWidthColumn ? '100%' : '48%')};

    &.fullWidth {
      width: 100%;
    }

    > p {
      font-size: ${(props) => props.theme.company.contact.info.fontSize.main};
      color: ${(props) => props.theme.company.contact.info.color.modalMain};
      font-weight: ${(props) => props.theme.company.contact.info.fontWeight.modalMain};
      padding: 0 0 8px;
      margin: 0;

      &.subTitle {
        color: ${(props) => props.theme.company.contact.info.color.modalSubTitle};
        font-weight: ${(props) => props.theme.company.contact.info.fontWeight.modalSubTitle};
        padding: 15px 0 0;
      }
    }

    > p > a,
    > a {
      font-size: ${(props) => props.theme.company.contact.info.fontSize.main};
      color: ${(props) => props.theme.palette.primary.shade4};
      font-weight: ${(props) => props.theme.company.contact.info.fontWeight.modalMain};
      padding: 0 0 8px;
      margin: 0;
      text-decoration: none;
      transition: color ease-in-out 0.14s;
      :hover {
        color: ${(props) => props.theme.palette.primary.shade6};
        cursor: pointer;
      }
    }
  }
`;
