import { getNotificationsTheme } from '@unbrace/notifications-micro-package';
import {
  contextTheme,
  drawerTheme,
  formTheme,
  fullViewTheme,
  getModalTheme,
  headerTheme,
  itemSidebarTheme,
  paperTheme,
  profileTheme,
  scrollbarTheme,
  sidebarTheme,
  skeletonTheme,
  tableTheme,
  toggleTheme,
  pillTheme,
  filterTheme,
  sceneWrapperTheme,
  getBadgeTheme,
} from './components';
import showMoreTheme from './components/showMore';
import {
  cmsTheme,
  companyTheme,
  contractTheme,
  dashboardTheme,
  invoiceTheme,
  loginTheme,
  productTheme,
  taskTheme,
  mailsTheme,
} from './partials';
import { unbraceTheme } from './tenants';
import avatarTheme from './components/avatar';

const themes = {
  base: {
    palette: unbraceTheme.palette,
    typography: unbraceTheme.typography,
  },
  default: {
    avatar: avatarTheme(unbraceTheme),
    badge: getBadgeTheme(unbraceTheme),
    cms: cmsTheme(unbraceTheme),
    company: companyTheme(unbraceTheme),
    contextMenu: contextTheme(unbraceTheme),
    contract: contractTheme(unbraceTheme),
    dashboard: dashboardTheme(unbraceTheme),
    drawer: drawerTheme(),
    form: formTheme(unbraceTheme),
    fullView: fullViewTheme(unbraceTheme),
    header: headerTheme(unbraceTheme),
    invoice: invoiceTheme(unbraceTheme),
    itemSidebar: itemSidebarTheme(unbraceTheme),
    login: loginTheme(unbraceTheme),
    mails: mailsTheme(unbraceTheme),
    modal: getModalTheme(unbraceTheme),
    notifications: getNotificationsTheme(unbraceTheme),
    palette: unbraceTheme.palette,
    internalPaper: paperTheme(unbraceTheme),
    pill: pillTheme(unbraceTheme),
    product: productTheme(unbraceTheme),
    profile: profileTheme(unbraceTheme),
    scrollbar: scrollbarTheme(unbraceTheme),
    showMore: showMoreTheme(unbraceTheme),
    internalSidebar: sidebarTheme(unbraceTheme),
    skeleton: skeletonTheme(unbraceTheme),
    table: tableTheme(unbraceTheme),
    task: taskTheme(unbraceTheme),
    toggle: toggleTheme(unbraceTheme),
    filter: filterTheme(unbraceTheme),
    typography: unbraceTheme.typography,
    sceneWrapper: sceneWrapperTheme(),
  },
};

export default themes;
