import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SortOrder } from '@unbrace/components';
import { RootState } from '../../../../config/store/rootReducer';
import { FormattedModuleFilters, CustomFilter, ScreenConfiguration } from '../../types/filters';
import { filterActions } from '../actions/creators';
import { setAdditionalFilters as setAdditionalFiltersAction } from '../actions/creators/filters';
import {
  makeGetAdditionalFilters,
  makeGetAllFilters,
  makeGetSorting,
  makeGetCustomFilters,
} from '../selectors/filters';
import { getDefaultPageSize } from '../../constants';
import { SuccessCallback } from '../../../../types/index';
import { useActionIsPending } from 'hooks';
import { getType } from 'typesafe-actions';

const useModuleFiltersStore = <F extends {}>(moduleKey: string) => {
  const [isSaving, startSaving] = useActionIsPending(getType(filterActions.saveCustomFilter));

  const dispatch = useDispatch();
  const allFilters = useSelector(
    (state: RootState) =>
      makeGetAllFilters(state, moduleKey) as (FormattedModuleFilters & { additionalFilters: F }) | undefined
  );
  const customFilters = useSelector((state: RootState) => makeGetCustomFilters()(state, moduleKey));

  const sorting = useSelector((state: RootState) => makeGetSorting()(state, moduleKey));

  const additionalFilters = useSelector(
    (state: RootState) => makeGetAdditionalFilters()(state, moduleKey) as F | undefined
  );

  const setPageIndex = React.useCallback(
    (pageNumber: number) => dispatch(filterActions.setPageIndex(pageNumber, moduleKey)),
    [dispatch, moduleKey]
  );

  const setPageSize = React.useCallback(
    (size: number) => dispatch(filterActions.setPageSize(size, moduleKey)),
    [dispatch, moduleKey]
  );

  const setAdditionalFilters = React.useCallback(
    (filters) => {
      dispatch(setAdditionalFiltersAction({ ...additionalFilters, ...filters }, moduleKey));
    },
    [moduleKey, additionalFilters, dispatch]
  );

  const toggleSortingForColumn = React.useCallback(
    (columnNames: string[], sortOrder?: SortOrder) => {
      dispatch(filterActions.toggleColumnSort(columnNames, moduleKey, sortOrder));
    },
    [dispatch, moduleKey]
  );

  const getCustomFilters = React.useCallback(() => {
    dispatch(filterActions.getCustomFilters(moduleKey));
  }, [dispatch, moduleKey]);

  const replaceFilters = React.useCallback(
    (filter: ScreenConfiguration) => {
      dispatch(filterActions.replaceFilters(filter.values, filter.screen.toLowerCase()));
    },
    [dispatch]
  );

  const clearAdditionalFilters = React.useCallback(() => {
    dispatch(filterActions.clearAdditionalFilters(moduleKey));
  }, [dispatch, moduleKey]);

  const saveCustomFilter = React.useCallback(
    (name: string, values: CustomFilter, successCallback?: SuccessCallback) => {
      startSaving();
      dispatch(filterActions.saveCustomFilter({ screen: moduleKey, name, values }, successCallback));
    },
    [dispatch, moduleKey, startSaving]
  );

  const deleteCustomFilter = React.useCallback(
    (id: number, screen: string) => {
      dispatch(filterActions.deleteCustomFilter(id, screen));
    },
    [dispatch]
  );

  const resetFilters = React.useCallback(
    (setSearch: (text: string) => void) => {
      clearAdditionalFilters();
      setPageIndex(1);
      setPageSize(getDefaultPageSize());
      setSearch('');
    },
    [clearAdditionalFilters, setPageIndex, setPageSize]
  );

  return {
    setPageIndex,
    setPageSize,
    setAdditionalFilters,
    allFilters,
    additionalFilters,
    pageSize: allFilters?.pageSize || getDefaultPageSize(),
    pageIndex: allFilters?.pageIndex || 1,
    search: allFilters?.searchText || '',
    orderBys: allFilters?.orderBys,
    sorting,
    toggleSortingForColumn,
    customFilters,
    getCustomFilters,
    replaceFilters,
    clearAdditionalFilters,
    saveCustomFilter,
    deleteCustomFilter,
    isSaving,
    resetFilters,
  };
};

export default useModuleFiltersStore;
