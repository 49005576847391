import { IconButton, Pill } from '@unbrace/components';
import { useToggle } from '@unbrace/hooks';
import { Notifications } from '@unbrace/notifications-micro-package';
import { Logo } from 'components';
import {
  Bell,
  Cms,
  Company,
  Contract,
  DataQuality,
  FileImport,
  Home,
  Invoice,
  IpPlan,
  Mails,
  Products,
  Rebate,
  ServiceFee,
  Task,
} from 'components/icons';
import Access from 'components/icons/Access';
import useKeyShortcut from 'components/KeyboardShortcuts/hooks/useKeyShortcut';
import { ShortcutsOverviewModal } from 'components/KeyboardShortcuts/ShortcutsOverviewModal/';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ProfileMenu } from '../../../security';
import ExternalLinks from '../../../security/components/ExternalLinks';
import { Module, Modules } from '../../../security/types/modules';
import { Navigation, NavigationLink, SubNavigation } from './';
import { AsideWrapper } from './AsideWrapper';
import { Footer } from './Footer';
import { LogoWrapper } from './LogoWrapper';

type Props = {
  availableModules: Modules;
  userName?: string;
};

const ImageFactory: { [key: string]: JSX.Element } = {
  Access: <Access />,
  Cms: <Cms />,
  Company: <Company />,
  Contract: <Contract />,
  Dashboard: <Home />,
  Invoices: <Invoice />,
  IpPlan: <IpPlan />,
  Mails: <Mails />,
  Products: <Products />,
  ServiceFee: <ServiceFee />,
  RebateGroups: <Rebate />,
  FileImports: <FileImport />,
  Tasks: <Task />,
  DataQuality: <DataQuality />,
};

const Sidebar: React.FC<Props> = ({ availableModules, userName }: Props): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();

  const [shortcutModalVisible, toggleShortcutModalVisibility] = useToggle(false);

  const handleGoToModule = React.useCallback(
    (moduleKey: string) => {
      history.push(`/${moduleKey}`);
    },
    [history]
  );

  const { useGoToCompanies, useGoToProducts, useGoToInvoices, useGoToContracts, useGoToTasks, useGoToDrafts } =
    useKeyShortcut();

  useGoToCompanies(() => handleGoToModule('company'));
  useGoToProducts(() => handleGoToModule('products'));
  useGoToContracts(() => handleGoToModule('contract'));
  useGoToInvoices(() => handleGoToModule('invoices/invoices'));
  useGoToDrafts(() => handleGoToModule('invoices/drafts'));
  useGoToTasks(() => handleGoToModule('tasks'));

  return (
    <Notifications
      title={t('notifications.title')}
      closeTooltip={t('notifications.close')}
      position="left"
      emptyResources={{
        title: t('notifications.emptyTitle'),
        description: t('notifications.emptyDescription'),
      }}
      markAsReadResources={{ all: t('notifications.markAllAsRead'), single: t('notifications.markAsRead') }}
    >
      {({ toggleDrawer, count }) => (
        <React.Fragment>
          <ShortcutsOverviewModal
            shortcutModalVisible={shortcutModalVisible}
            toggleShortcutModalVisibility={toggleShortcutModalVisibility}
          />
          <AsideWrapper>
            <LogoWrapper>
              <Logo small />
            </LogoWrapper>
            <Navigation>
              <React.Fragment>
                {availableModules
                  .filter((module) => module.showInSideBar)
                  .map((module: Module) =>
                    module.subModules ? (
                      <SubNavigation key={module.key} module={module}>
                        {ImageFactory[module.key]}
                      </SubNavigation>
                    ) : (
                      <NavigationLink key={module.key} to={module.key}>
                        {ImageFactory[module.key]}
                      </NavigationLink>
                    )
                  )}
              </React.Fragment>
            </Navigation>
            <Footer>
              <ProfileMenu toggleShortcutModalVisibility={toggleShortcutModalVisibility} userName={userName} />
              <ExternalLinks />
              <IconButton onClick={toggleDrawer} tooltip={t('notifications.title')} tooltipPosition="top">
                <Bell />
                {count > 0 && (
                  <Pill color="danger" size="xs" style={{ position: 'absolute', top: -3, left: 26 }}>
                    {count}
                  </Pill>
                )}
              </IconButton>
            </Footer>
          </AsideWrapper>
        </React.Fragment>
      )}
    </Notifications>
  );
};

export default Sidebar;
