const shortcuts = {
  navigation: {
    goToCompanies: ['Shift', 'X', 'C'],
    goToProducts: ['Shift', 'X', 'P'],
    goToContracts: ['Shift', 'X', 'R'],
    goToInvoices: ['Shift', 'X', 'I'],
    goToDrafts: ['Shift', 'X', 'D'],
    goToTasks: ['Shift', 'X', 'T'],
  },
  general: {
    showAllShortcuts: ['Shift', '?'],
    toggleEditScreen: ['Shift', 'E'],
    toggleDetailScreen: ['Shift', 'D'],
    openAddScreen: ['Shift', 'A'],
    toggleFilterSidebar: ['Shift', 'F'],
    refreshTable: ['Shift', 'R'],
    focusOnSearchInput: ['Shift', 'S'],
    closeModal: ['Esc'],
  },
};

export default shortcuts;
