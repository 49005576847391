import { Field, FieldProps, FormikErrors, FormikTouched } from 'formik';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../../components/Form';
import { EditableItem, FullViewBlock } from '../../../../components/FullView';
import { getNestedError } from '../../../../helpers';
import { ProductBaseFormValues, ProductTranslation } from '../../types/product';

type Props = {
  errors: FormikErrors<ProductBaseFormValues>;
  translations?: ProductTranslation[];
  touched: FormikTouched<ProductBaseFormValues>;
};

const DescriptionInfo: React.FC<Props> = ({ errors, translations, touched }) => {
  const { t } = useTranslation('products');

  return (
    <FullViewBlock amountOfButtons={1}>
      <h2 className="block-title">{t('fullView.blockTitles.description')}</h2>
      <div className="block-data">
        {translations &&
          translations.map((item: ProductTranslation, index: number) => (
            <EditableItem
              key={item.language}
              label={t(`global:languages.full.${item.language}`)}
              value={
                <Field
                  name={`translations[${index}].description`}
                  render={({ field }: FieldProps) => (
                    <Input
                      {...field}
                      type="text"
                      noLabel
                      placeholder={item.description}
                      error={getNestedError(`translations[${index}].description`, errors, touched)}
                    />
                  )}
                />
              }
            />
          ))}
      </div>
    </FullViewBlock>
  );
};

export default DescriptionInfo;
