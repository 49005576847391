import { TenantTheme } from '../tenants/unbrace';

const loginTheme = (theme: TenantTheme) => ({
  animation: {
    entered: 'opacity: 1; transition: all 150ms ease; transform: none',
    entering: 'opacity: 0.01; transform: translateY(-15px)',
  },
  background: theme.palette.neutral.shade0,
  borderRadius: theme.globals.borderRadius,
  boxShadow: '2px 6px 34px rgba(0,0,0,0.08), 1px 3px 6px rgba(0,0,0,0.04)',
  padding: '30px',
  textColor: theme.palette.neutral.shade8,
  versionColor: theme.palette.neutral.shade4,
  versionSize: theme.typography.fontSize.small_5,
});

export default loginTheme;
