import React from 'react';
import { CSSTransition } from 'react-transition-group';
import styled, { css } from 'styled-components';
import { AnimationProps } from '../../config/theme/types';
import { media } from '../../config/theme/utils';
import { Image, Wrapper } from './components';

const EmptyStateImage = styled(Image)`
  height: 150px;

  ${media.mobile`
    height: 50px;
  `};
  ${media.tablet`
    height: 70px;
  `}
  ${media.laptopSM`
    height: 120px;
  `};
  ${media.laptopLG`
    height: 140px;
  `};
`;

type Props = {
  paper?: boolean;
  animate?: boolean;
  extraSpace?: boolean;
};

const EmptyStateWrapper = styled(Wrapper)<Props & AnimationProps>`
  margin: ${(props) => (props.extraSpace ? 'calc(4% + 24px)' : '4%')} auto;

  > p {
    margin: 10px auto 0;
  }

  ${(props) => media.desktop`
    ${props.paper && 'max-width: 1400px; margin: 16px; padding: 24px; width: auto;'};
  `};

  ${(props) => props.state === 'entering' && 'opacity: 0.01;'};
  ${(props) => props.state === 'entered' && 'opacity: 2; transition: opacity 200ms 100ms;'};
  ${(props) =>
    props.animate &&
    css`
      transition: opacity 0.15s ease-out, filter 0.1s ease-out;
      filter: grayscale(90%);
      opacity: 0.2;
    `}
`;

const TransitionedWrapper: React.FC<Props> = ({ children, ...rest }) => (
  <CSSTransition in timeout={300} mountOnEnter appear>
    {(state) => (
      <EmptyStateWrapper state={state} {...rest}>
        {children}
      </EmptyStateWrapper>
    )}
  </CSSTransition>
);

export { EmptyStateImage, TransitionedWrapper as EmptyStateWrapper };
