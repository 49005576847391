import { Field, FieldProps, FormikErrors, FormikTouched } from 'formik';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FormikSelect } from '../../../../components/Form';
import { EditableItem, FullViewBlock } from '../../../../components/FullView';
import { ProductBaseFormValues } from '../../types/product';
import useGlobalInfoStore from 'modules/products/services/useGlobalInfoStore';
import { getNestedError } from 'helpers';

type Props = {
  errors: FormikErrors<ProductBaseFormValues>;
  touched: FormikTouched<ProductBaseFormValues>;
};

const PricingProfileInfo: React.FC<Props> = ({ errors, touched }: Props) => {
  const { t } = useTranslation('products');
  const { productRebategroups } = useGlobalInfoStore();

  return productRebategroups && productRebategroups.length > 0 ? (
    <FullViewBlock amountOfButtons={1}>
      <h2 className="block-title">{t('fullView.blockTitles.pricingProfile')}</h2>
      <div className="block-data">
        <EditableItem
          label={t('fullView.labels.rebateGroup')}
          value={
            <Field
              name="partnerPricingProfile.rebateGroupId"
              render={(fieldProps: FieldProps) => (
                <FormikSelect
                  {...fieldProps}
                  error={getNestedError('partnerPricingProfile.rebateGroupId', errors, touched)}
                  type="text"
                  isClearable
                  options={productRebategroups.map((rebateGroup) => ({
                    label: rebateGroup.description,
                    value: rebateGroup.rebateGroupId,
                  }))}
                />
              )}
            />
          }
        />
      </div>
    </FullViewBlock>
  ) : null;
};

export default PricingProfileInfo;
