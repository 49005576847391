import { TableCell, TableRow } from '@unbrace/components';
import { useToggle } from '@unbrace/hooks';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDown, ChevronUp } from '../icons';
import { StyledShowMore } from './ShowMoreToggle';

type Props = {
  baseSize: number;
  children: RenderCallback | JSX.Element[] | undefined;
  className?: string;
  forTable?: boolean;
  tableSize?: number;
  loadInSteps?: boolean;
  onClick?: () => void;
  translations?: {
    showMore: string;
    showLess: string;
  };
};

type RenderCallback = (showMore: boolean) => JSX.Element[];

const isRenderCallback = (children: RenderCallback | JSX.Element[]): children is RenderCallback => {
  return typeof children === 'function';
};

const ShowMore: React.FC<Props> = ({
  children,
  baseSize,
  className,
  forTable,
  tableSize,
  onClick,
  translations,
  loadInSteps,
}) => {
  const { t } = useTranslation('global');
  const [showMore, setShowMore] = useToggle(false);
  const childElements = children ? (isRenderCallback(children) ? children(showMore) : children) : null;
  const [index, setIndex] = React.useState(baseSize);

  const toggleShowMore = (resetIndex: boolean) => {
    onClick?.();
    setShowMore(!resetIndex);
    if (resetIndex) {
      setIndex(baseSize);
    } else if (loadInSteps) {
      setIndex((previousIndex) => previousIndex + baseSize);
    }
  };

  const ShowLessDiv: JSX.Element = (
    <StyledShowMore key={children?.length} onClick={() => toggleShowMore(true)} className={className}>
      <ChevronUp />
      {translations?.showLess || t('showMore.showLess')}
    </StyledShowMore>
  );
  const ShowMoreDiv: JSX.Element = (
    <StyledShowMore key={children?.length} onClick={() => toggleShowMore(false)} className={className}>
      <ChevronDown />
      {translations?.showMore || t('showMore.showMore')}
    </StyledShowMore>
  );

  const renderNextItems = (renderInTable?: boolean, showMoreInSteps?: boolean) => {
    if (childElements) {
      if (!showMore) {
        return (
          <React.Fragment>
            {childElements.slice(0, baseSize)}
            {childElements.length >= baseSize &&
              (renderInTable ? (
                <TableRow isStatic data-cy="show-more">
                  <TableCell colSpan={tableSize}>{ShowMoreDiv}</TableCell>
                </TableRow>
              ) : (
                [ShowMoreDiv]
              ))}
          </React.Fragment>
        );
      } else if (showMore && !showMoreInSteps) {
        return (
          <React.Fragment>
            {childElements}
            {renderInTable ? (
              <TableRow isStatic data-cy="show-more">
                <TableCell colSpan={tableSize}>{ShowLessDiv}</TableCell>
              </TableRow>
            ) : (
              [ShowLessDiv]
            )}
          </React.Fragment>
        );
      }

      return (
        <React.Fragment>
          {childElements.slice(0, index)}
          {index < childElements.length ? (
            renderInTable ? (
              <TableRow isStatic data-cy="show-more">
                <TableCell colSpan={tableSize}>{ShowMoreDiv}</TableCell>
              </TableRow>
            ) : (
              [ShowMoreDiv]
            )
          ) : renderInTable ? (
            <TableRow isStatic data-cy="show-more">
              <TableCell colSpan={tableSize}>{ShowLessDiv}</TableCell>
            </TableRow>
          ) : (
            [ShowLessDiv]
          )}
        </React.Fragment>
      );
    }

    return null;
  };

  return renderNextItems(forTable, loadInSteps);
};

export default ShowMore;
