import * as React from 'react';
import { TableCell, TableRow, Text, TextWrap } from '@unbrace/components';
import { useTranslation } from 'react-i18next';
import { formatAsDate } from '../../../../helpers/dateFormatter';
import { CurrencyNumberFormat } from '../../../../components';
import { Pricing } from '../../types/pricing';

type Props = {
  price: Pricing;
};

const PricingRow: React.FC<Props> = ({ price }: Props) => {
  const { t } = useTranslation('products');

  return (
    <TableRow>
      <TableCell size={130}>
        <Text size="xs" colorLevel="light" asTag="p">
          {t('pricing.quantity')}
        </Text>
        <Text size="xs" colorLevel="light" asTag="span">
          {t('pricing.from')}
        </Text>{' '}
        <Text size="xs" color="primary" colorLevel="medium" asTag="span">
          {price.quantityFrom ? price.quantityFrom : '...'}
        </Text>{' '}
        <Text size="xs" colorLevel="light" asTag="span">
          {t('pricing.to')}
        </Text>{' '}
        <Text size="xs" color="primary" colorLevel="medium" asTag="span">
          {price.quantityUntil ? price.quantityUntil : ' ...'}
        </Text>
      </TableCell>
      <TableCell>
        {price.entityName && (
          <React.Fragment>
            <Text size="xs" colorLevel="light" asTag="p">
              {t('pricing.specific')}
            </Text>
            <Text asTag="p" size="xs">
              {price.entityName}
            </Text>
          </React.Fragment>
        )}
      </TableCell>
      <TableCell align="right">
        <p>
          {price.dateFrom && (
            <TextWrap>
              <Text size="xs" colorLevel="light" asTag="span">
                {t('pricing.from')}
              </Text>
              <Text size="xs" color="warning" asTag="span">
                {' '}
                {formatAsDate(price.dateFrom)}
              </Text>
            </TextWrap>
          )}
        </p>
        <p>
          {price.dateUntil && (
            <TextWrap>
              <Text size="xs" colorLevel="light" asTag="span">
                {t('pricing.to')}
              </Text>{' '}
              <Text size="xs" color="warning" asTag="span">
                {' '}
                {formatAsDate(price.dateUntil)}
              </Text>
            </TextWrap>
          )}
        </p>
      </TableCell>
      <TableCell align="right" size={130}>
        <CurrencyNumberFormat price={price.amount} />
      </TableCell>
      <TableCell align="right" size={200}>
        <Text size="xs">{price.priceTypeDescription}</Text>
      </TableCell>
    </TableRow>
  );
};

export default PricingRow;
