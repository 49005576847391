import deepmerge from 'deepmerge';

export const removeDuplicates = (array: any[], prop: string) =>
  array.filter((obj, pos, arr) => arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos);

const overwriteMerge = (destinationArray: any[], sourceArray: any[], options?: object) => sourceArray;

export function deepMergeWithArrayOverride<T>(x: Partial<T>, y: Partial<T>, options?: deepmerge.Options) {
  return deepmerge(x, y, { ...options, arrayMerge: overwriteMerge });
}

export function areArraysEqual<T>(array?: T[], array2?: T[]): boolean {
  return (array?.length === array2?.length && array?.every((value, index) => value === array2?.[index])) || false;
}

const removeDuplicate = { removeDuplicates };
export default removeDuplicate;
