import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Alerts } from '@unbrace/components';
import { makeGetAlerts } from '../redux/selectors/alerts';
import { alertsActions } from '../redux/actions/creators';

type Props = {};

const AlertsService: React.FC<Props> = () => {
  const alerts = useSelector(makeGetAlerts());
  const dispatch = useDispatch();
  const removeAlert = React.useCallback((id: string) => dispatch(alertsActions.removeAlert(id)), [dispatch]);

  return <Alerts alerts={alerts} removeAlert={removeAlert} />;
};

export default AlertsService;
