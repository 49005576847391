export type MetaData = {
  metadataId: number | string;
  dataType?: 'STRING' | 'MULTISELECT' | 'GUID' | 'BOOL' | 'INTEGER' | 'PERCENTAGE' | 'DATE' | 'DECIMAL';

  code: string;

  category?: string;

  accessibility: 'hidden' | 'editable' | 'readOnly';

  availableValues?: object;

  isAdded?: boolean;

  isNew?: boolean;

  showOnCreate?: boolean;
};

export type MetaDataValue = MetaData & {
  value?: string;
};

export type MetaDataReference = {
  code: string;

  value: string;
};

export type UseMetaDataActions = {
  toggleMetaData: (id: number | string) => void;
  resetMetaData: () => void;
};

export enum MetaDataAccess {
  Editable = 'editable',

  Hidden = 'hidden',

  ReadOnly = 'readonly',
}
