import * as React from 'react';
import { Route } from 'react-router-dom';
import { loadScene } from '../../../components';
import ModuleRoutes from '../../../components/routing/ModuleRoutes';
import { Module } from '../../security/types/modules';
import { PRODUCTS_MODULE_KEY } from '../constants';

type Props = {
  allowedModules: Module[];
};

const productsOverviewScene = loadScene('products', 'ProductsScene');

export const PRODUCTS_BASE_ROUTES = '/products';

const ProductRoutes: React.FC<Props> = ({ allowedModules }: Props) => (
  <ModuleRoutes allowedModules={allowedModules} moduleKey={PRODUCTS_MODULE_KEY}>
    <Route path={['/products', '/products/:id', '/products/add/:id/pricing']} exact component={productsOverviewScene} />
  </ModuleRoutes>
);

export default ProductRoutes;
