/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface GetServiceFeeDocumentResponse {
  serviceFeeDocumentId: number;
  serviceFeeDocumentGuid: string;
  companyGuid: string;
  companyNumber: string;
  companyName: string;
  serviceFees: GetServiceFeeResponse[];
  amountExclVat: number;
  amountVat: number;
  amountInclVat: number;
  docnumber?: string;
  dateDoc: string;
  dateCreated: string;
  docNumberCompany?: string;
  datePaid?: string;
  status: ServiceFeeStatus;
  structuredMessage?: string;
  currency?: string;
}

export interface GetServiceFeeDocumentOverviewResponse {
  items: GetServiceFeeDocumentResponse[];
  pageIndex: number;
  pageSize: number;
  totalPages: number;
  totalItems: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface GetServiceFeeResponse {
  serviceFeeId: number;
  serviceFeeGuid: string;
  serviceFeeDocumentId: number;
  invoiceLineId: number;
  endUserGuid: string;
  code: string;
  description: string;
  description2?: string;
  description3?: string;
  description4?: string;
  dateFrom: string;
  dateUntil?: string;
  amount: number;
  quantity: number;
  totalAmount: number;
  vatAmount: number;
  rebatePercentage: number;
  dateCreated: string;
}

/** ServiceFeeStatus */
export type ServiceFeeStatus = 'unclaimed' | 'claimed' | 'paid' | 'expired' | 'external';

/** DownloadResponse */
export interface DownloadResponse {
  byteArray?: string;
  contentType?: string;
  fileName?: string;
}

export interface GetServiceFeeDocumentsParams {
  pageIndex?: number;
  pageSize?: number;
  search?: string;
  /** @format date */
  dateFrom?: string;
  /** @format date */
  dateUntil?: string;
  orderBys?: any[];
  statuses?: ServiceFeeStatus[];
}

export interface LinkDocNumberToServiceFeeDocumentParams {
  docNumberCompany: string;
  id: number;
}

export interface SetDatePaidParams {
  datePaid: string;
  docNumberCompany?: string;
  id: number;
}

import axios, { AxiosInstance, AxiosRequestConfig, ResponseType } from 'axios';

type HeaderValue = string | string[] | number | boolean | null;

interface RawHeaders {
  [key: string]: HeaderValue;
}

interface HeadersDefaults {
  common: RawHeaders;
  delete: RawHeaders;
  get: RawHeaders;
  head: RawHeaders;
  post: RawHeaders;
  put: RawHeaders;
  patch: RawHeaders;
  options?: RawHeaders;
  purge?: RawHeaders;
  link?: RawHeaders;
  unlink?: RawHeaders;
}

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || 'http://localhost:3000' });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<T> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance
      .request({
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
        },
        params: query,
        responseType: responseFormat,
        data: body,
        url: path,
      })
      .then((response) => response.data?.response);
  };
}

/**
 * @title ServiceFeeModule
 * @version 1.0
 * @baseUrl http://localhost:3000
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * @description get service fees overview
     *
     * @name GetServiceFeeDocuments
     * @summary Your GET endpoint
     * @request GET:/api/servicefee/v2/documents
     */
    getServiceFeeDocuments: (query: GetServiceFeeDocumentsParams, params: RequestParams = {}) =>
      this.request<GetServiceFeeDocumentOverviewResponse, any>({
        path: `/api/servicefee/v2/documents`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description get service fees documents by company
     *
     * @name GetServiceFeeDocumentsForCompany
     * @summary Your GET endpoint
     * @request GET:/api/servicefee/v2/documents/{companyguid}
     */
    getServiceFeeDocumentsForCompany: (companyguid: string, params: RequestParams = {}) =>
      this.request<GetServiceFeeDocumentResponse[], any>({
        path: `/api/servicefee/v2/documents/${companyguid}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description link company number to service fee document
     *
     * @name LinkDocNumberToServiceFeeDocument
     * @request POST:/api/servicefee/v2/documents/{id}/link-doc-number-company
     */
    linkDocNumberToServiceFeeDocument: (
      { id, ...query }: LinkDocNumberToServiceFeeDocumentParams,
      params: RequestParams = {}
    ) =>
      this.request<GetServiceFeeDocumentResponse, any>({
        path: `/api/servicefee/v2/documents/${id}/link-doc-number-company`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description set date paid on service fee document
     *
     * @name SetDatePaid
     * @request POST:/api/servicefee/v2/documents/{id}/set-date-paid
     */
    setDatePaid: ({ id, ...query }: SetDatePaidParams, params: RequestParams = {}) =>
      this.request<GetServiceFeeDocumentResponse, any>({
        path: `/api/servicefee/v2/documents/${id}/set-date-paid`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description claim service fee document
     *
     * @name ClaimDocument
     * @request POST:/api/servicefee/v2/documents/{id}/claim
     */
    claimDocument: (id: number, params: RequestParams = {}) =>
      this.request<GetServiceFeeDocumentResponse, any>({
        path: `/api/servicefee/v2/documents/${id}/claim`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name DownloadInvoiceDocument
     * @summary Your GET endpoint
     * @request GET:/api/servicefee/v2/documents/{id}/download
     */
    downloadInvoiceDocument: (id: number, params: RequestParams = {}) =>
      this.request<DownloadResponse, any>({
        path: `/api/servicefee/v2/documents/${id}/download`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
}
