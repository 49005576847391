import AxiosConfig, { RequestConfig } from '../../../config/api/AxiosConfig';
import { AccessRights, Account } from '../types/user';
import { ACCOUNT_URL } from './endpoints';

class AccountApi {
  public async getAccount() {
    const requestConfig: RequestConfig = {
      url: `${ACCOUNT_URL}`,
    };

    return AxiosConfig.fetchAxiosGetRequest<Account>(requestConfig);
  }

  public async getAccessRights() {
    const requestConfig: RequestConfig = {
      url: `${ACCOUNT_URL}/accessrights`,
    };

    return AxiosConfig.fetchAxiosGetRequest<AccessRights>(requestConfig);
  }
}

export default new AccountApi();
