import { GetParamByIndex, SuccessCallback } from 'types';
import {
  useGetProductDetail,
  useMutateCreateProduct,
  useMutateUpdateProduct,
} from '../adapters/queries/productQueries';
import { api } from '../adapters';
import { ProductAndPriceFormValues } from '../types/product';

const useProductStore = (productId?: number | string) => {
  const { data, isLoading, isFetching, isPreviousData } = useGetProductDetail(productId);
  const { mutate: updateItem, isLoading: isUpdateProductLoading } = useMutateUpdateProduct();
  const { mutate: createItem, isLoading: isCreateProductLoading } = useMutateCreateProduct();

  const updateProduct = (payload: GetParamByIndex<typeof api.updateProduct, 0>, successCallback?: SuccessCallback) => {
    updateItem(payload, {
      onSuccess: successCallback,
    });
  };

  const createProduct = (payload: ProductAndPriceFormValues, successCallback?: SuccessCallback) => {
    createItem(payload, {
      onSuccess: successCallback,
    });
  };

  return {
    newProduct: data,
    isPending: (isLoading || isFetching) && isPreviousData,
    updateProduct,
    isUpdateProductLoading,
    createProduct,
    isCreateProductLoading,
  };
};

export default useProductStore;
