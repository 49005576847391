import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { RebateGroupsRoutes, REBATEGROUP_BASE_ROUTES } from 'modules/rebategroups/routes';
import { ACCESS_BASE_ROUTES, AccessRoutes } from 'modules/access/routes';
import { NotFound } from '../../../components/EmptyState';
import { CMS_BASE_ROUTES, CmsRoutes } from '../../cms/routes/';
import { COMPANY_BASE_ROUTES, CompanyRoutes } from '../../company/routes/';
import { ContractRoutes, CONTRACTS_BASE_ROUTES } from '../../contract/routes/';
import InvoiceRoutes, { INVOICES_BASE_ROUTES } from '../../invoice/routes/InvoiceRoutes';
import { MailRoutes } from '../../mails/routes';
import { ProductRoutes, PRODUCTS_BASE_ROUTES } from '../../products/routes/';
import { SECURITY_BASE_ROUTES, SecurityRoutes } from '../../security/routes/';
import { Module } from '../../security/types/modules';
import ServicefeeRoutes, { SERVICEFEE_BASE_ROUTES } from '../../servicefee/routes/ServicefeeRoutes';
import { TaskRoutes, TASKS_BASE_ROUTES } from '../../tasks/routes/';
import { SettingsRoutes, SETTINGS_BASE_ROUTES } from '../../settings/routes';
import FileImportRoutes from '../../fileimports/routes/FileImportRoutes';
import { FILEIMPORT_BASE_ROUTES } from '../../fileimports/routes';
import { IP_BASE_ROUTES } from 'modules/ip/routes';
import IpRoutes from '../../ip/routes/IpRoutes';
import { DataQualityRoutes, DATAQUALITY_BASE_ROUTES } from '../../dataquality/routes';
import { MAILS_BASE_ROUTES } from 'modules/mails/constants';

type Props = {
  allowedModules: Module[];
  isAuthenticated: boolean;
};

const Routes: React.FC<Props> = ({ allowedModules, isAuthenticated }: Props) => {
  return isAuthenticated ? (
    <React.Fragment>
      <Switch>
        <Redirect
          from="/"
          exact
          to={`/${allowedModules.filter((module) => module.showInSideBar)[0].key.toLowerCase()}`}
        />
        <Route path={CMS_BASE_ROUTES} render={() => <CmsRoutes allowedModules={allowedModules} />} />
        <Route path={COMPANY_BASE_ROUTES} render={() => <CompanyRoutes allowedModules={allowedModules} />} />
        <Route path={CONTRACTS_BASE_ROUTES} render={() => <ContractRoutes allowedModules={allowedModules} />} />
        <Route path={INVOICES_BASE_ROUTES} render={() => <InvoiceRoutes allowedModules={allowedModules} />} />
        <Route path={MAILS_BASE_ROUTES} render={() => <MailRoutes allowedModules={allowedModules} />} />
        <Route path={PRODUCTS_BASE_ROUTES} render={() => <ProductRoutes allowedModules={allowedModules} />} />
        <Route path={SERVICEFEE_BASE_ROUTES} render={() => <ServicefeeRoutes allowedModules={allowedModules} />} />
        <Route path={REBATEGROUP_BASE_ROUTES} render={() => <RebateGroupsRoutes allowedModules={allowedModules} />} />
        <Route path={FILEIMPORT_BASE_ROUTES} render={() => <FileImportRoutes allowedModules={allowedModules} />} />
        <Route path={IP_BASE_ROUTES} render={() => <IpRoutes allowedModules={allowedModules} />} />
        <Route path={TASKS_BASE_ROUTES} render={() => <TaskRoutes allowedModules={allowedModules} />} />
        <Route path={SETTINGS_BASE_ROUTES} render={() => <SettingsRoutes />} />
        <Route path={ACCESS_BASE_ROUTES} render={() => <AccessRoutes allowedModules={allowedModules} />} />
        <Route path={DATAQUALITY_BASE_ROUTES} render={() => <DataQualityRoutes allowedModules={allowedModules} />} />

        <Route render={() => <NotFound />} />
      </Switch>
    </React.Fragment>
  ) : (
    <Route path={SECURITY_BASE_ROUTES} render={() => <SecurityRoutes />} />
  );
};

export default Routes;
