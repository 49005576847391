import * as H from 'history';
import * as React from 'react';
import { useToggle } from '@unbrace/hooks';
import { Prompt, RouteComponentProps, withRouter } from 'react-router-dom';
import { Modal } from '@unbrace/components';
import { UnsavedChangesModal } from '../../../components';

type Props = {
  when: boolean;
  modalContent?: React.ComponentType<any>;
} & RouteComponentProps;

export type RouteLeavingGuardParams = {
  handleConfirmNavigationClick: () => void;
  closeModal: (e: React.MouseEvent) => void;
  modalVisible: boolean;
};

const RouteLeavingGuard = ({ when, history, modalContent }: Props) => {
  const [modalVisible, toggleModalVisibility] = useToggle(false);
  const [lastLocation, setLastLocation] = React.useState<H.Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = React.useState(false);

  React.useEffect(() => {
    if (lastLocation) {
      history.push(lastLocation);
    }
    // eslint-disable-next-line
  }, [confirmedNavigation]);

  const showModal = (location: H.Location) => {
    setLastLocation(location);
    toggleModalVisibility();
  };

  const closeModal = () => {
    toggleModalVisibility();
  };

  const handleBlockedNavigation = (nextLocation: H.Location) => {
    if (!confirmedNavigation) {
      showModal(nextLocation);

      return false;
    }

    return true;
  };

  const handleConfirmNavigationClick = () => {
    closeModal();
    if (lastLocation) {
      setConfirmedNavigation(true);
    }
  };

  return (
    <React.Fragment>
      <Prompt when={when} message={(location: H.Location) => handleBlockedNavigation(location)} />
      <Modal
        isVisible={modalVisible}
        content={modalContent ? modalContent : UnsavedChangesModal}
        contentProps={{ handleConfirmNavigationClick, closeModal }}
      />
    </React.Fragment>
  );
};

export default withRouter(RouteLeavingGuard);
