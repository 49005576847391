import { getYear } from 'date-fns';
import * as React from 'react';
import FormikSelect, { Props as FormikSelectProps } from './FormikSelect';

type Props = FormikSelectProps<{ label: string; value: string }>;

const YearPicker: React.FC<Props> = (props) => {
  const currentYear = getYear(new Date());

  const yearOptions = [
    {
      label: `${currentYear - 1}`,
      value: `${currentYear - 1}`,
    },
    {
      label: `${currentYear}`,
      value: `${currentYear}`,
    },
    {
      label: `${currentYear + 1}`,
      value: `${currentYear + 1}`,
    },
    {
      label: `${currentYear + 2}`,
      value: `${currentYear + 2}`,
    },
    {
      label: `${currentYear + 3}`,
      value: `${currentYear + 3}`,
    },
  ];

  return <FormikSelect {...props} options={yearOptions} />;
};

export default YearPicker;
