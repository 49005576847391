import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const PrintCheckmark: React.FC = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
      <path d="M30.1158 40.7656L25.6916 36.1129L23 38.8264L30.1158 46L48 29.908L45.4994 27L30.1158 40.7656Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.422 16.248H37.7244C40.7245 16.248 43.1463 18.7264 43.1463 21.7967V24.5822L32.3024 34.5965V27.3455H17.8439V36.5935H20.3171L20.3171 40.2927H14.2293V32.8943H7V21.7967C7 18.7264 9.42181 16.248 12.422 16.248ZM35.9171 21.7967C35.9171 22.814 36.7304 23.6463 37.7244 23.6463C38.7184 23.6463 39.5317 22.814 39.5317 21.7967C39.5317 20.7795 38.7184 19.9472 37.7244 19.9472C36.7304 19.9472 35.9171 20.7795 35.9171 21.7967ZM14.2293 7H35.9171V14.3984H14.2293V7Z"
      />
    </svg>
  </BaseSvgWrapper>
);

export default PrintCheckmark;
