import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ContextMenuBlock, ContextMenuContainer, ContextMenuItem } from '../../../components';
import { SETTINGS_BASE_ROUTES } from '../../settings/routes';
import useLogoutStore from '../services/useLogoutStore';

type Props = {
  closeMenu?: VoidFunction;
  toggleResetPasswordModal: VoidFunction;
  toggleShortcutModalVisibility: VoidFunction;
  userName?: string;
};

const ProfileMenuContent: React.FC<Props> = ({
  closeMenu,
  toggleResetPasswordModal,
  toggleShortcutModalVisibility,
  userName,
}: Props) => {
  const { t } = useTranslation('security');
  const { initiateLogout } = useLogoutStore();
  const history = useHistory();

  const goToAbout = React.useCallback(() => {
    history.push(`${SETTINGS_BASE_ROUTES}/about`);
  }, [history]);

  const goToSettings = React.useCallback(() => {
    history.push(`${SETTINGS_BASE_ROUTES}/settings`);
  }, [history]);

  const goToReleaseNotes = React.useCallback(() => {
    history.push(`${SETTINGS_BASE_ROUTES}/release-notes/1x`);
  }, [history]);

  return (
    <ContextMenuContainer closeMenu={closeMenu}>
      <ContextMenuBlock title={t('profile.help')}>
        <ContextMenuItem text={t('profile.shortcuts')} onClick={toggleShortcutModalVisibility} />
        <ContextMenuItem text={t('profile.settings')} onClick={goToSettings} />
        <ContextMenuItem text={t('profile.releaseNotes')} onClick={goToReleaseNotes} />
        <ContextMenuItem text={t('profile.about')} onClick={goToAbout} />
      </ContextMenuBlock>
      <ContextMenuBlock title={userName ? userName : t('profile.user')}>
        <ContextMenuItem text={t('profile.reset')} onClick={toggleResetPasswordModal} />
        <ContextMenuItem text={t('profile.logout')} onClick={() => initiateLogout()} />
      </ContextMenuBlock>
    </ContextMenuContainer>
  );
};

export default ProfileMenuContent;
