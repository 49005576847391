import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const Suspend: React.FC = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
      <path d="M25 4C13.38 4 4 13.38 4 25C4 36.62 13.38 46 25 46C36.62 46 46 36.62 46 25C46 13.38 36.62 4 25 4ZM25 40.7C16.6 40.7 9.3 33.925 9.3 25C9.3 16.075 17.125 9.3 25 9.3C35.08 9.3 40.7 16.075 40.7 25C40.7 33.4 33.925 40.7 25 40.7Z" />
      <path d="M30.25 18.7H19.75C19.12 18.7 18.7 19.12 18.7 19.75V30.25C18.7 30.88 19.12 31.3 19.75 31.3H30.25C30.88 31.3 31.3 30.88 31.3 30.25V19.75C31.3 19.12 30.88 18.7 30.25 18.7Z" />
    </svg>
  </BaseSvgWrapper>
);

export default Suspend;
