import * as React from 'react';
import { Route, Switch } from 'react-router';
import { loadScene } from '../../../components';

const SettingsScene = loadScene('settings', 'SettingsScene');
export const SETTINGS_BASE_ROUTES = '/settings';

const SettingsRoutes: React.FC = () => (
  <Switch>
    <Route path={SETTINGS_BASE_ROUTES} component={SettingsScene} />
  </Switch>
);

export default SettingsRoutes;
