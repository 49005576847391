import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { LinkProps, NavLink } from 'react-router-dom';
import styled from '../../../../config/theme';

const NavigationLink: React.FC<LinkProps> = ({ children: icon, to, ...other }: LinkProps) => {
  const { t } = useTranslation();

  return (
    <StyledNavigationLink>
      <NavLink activeClassName="active" to={`/${to}`.toLowerCase()} {...other}>
        {icon}
        <span>{t(`sidebar.${to}`, { defaultValue: to })}</span>
      </NavLink>
    </StyledNavigationLink>
  );
};

type NavLinkProps = {
  isOpen?: boolean;
};

export const StyledNavigationLink = styled('li')<NavLinkProps>`
  cursor: pointer;
  list-style: none;
  overflow: hidden;
  outline: 0;
  max-height: ${(props) => (props.isOpen ? '15rem' : '48px')};
  height: ${(props) => (props.isOpen ? 'auto' : '48px')};
  transition: max-height 0.15s ease-out;
  margin-bottom: 16px;

  a {
    padding: 10px 0px 10px 25px;
    color: ${(props) => props.theme.internalSidebar.color.text};
    display: flex;
    align-items: center;
    font-size: ${(props) => props.theme.typography.fontSize.regular};
    text-decoration: none;
    transition: background 0.25s ease-out;
    border-left: 4px solid transparent;
    background: ${(props) => (props.isOpen ? props.theme.internalSidebar.background.subMenuParent : 'transparent')};
    position: relative;
    font-weight: 200;

    &.active,
    &.active:hover,
    &.active:focus {
      background: ${(props) => props.theme.internalSidebar.background.active};
      border-left: 4px solid ${(props) => props.theme.internalSidebar.background.activeHighlight};
      color: ${(props) => props.theme.internalSidebar.color.activeText};
      font-family: ${(props) => props.theme.typography.fontFamily};
      font-weight: 500;

      i {
        color: ${(props) => props.theme.internalSidebar.color.activeText};
      }

      svg {
        fill: ${(props) => props.theme.internalSidebar.color.activeText};
      }
    }

    &:hover,
    &:focus {
      background: ${(props) => props.theme.internalSidebar.background.hover};
      color: ${(props) => props.theme.internalSidebar.color.text};
    }
  }

  > ul {
    ${(props) => props.isOpen && `background: ${props.theme.internalSidebar.background.submenu}`};
    margin: 0px 0 15px 0px !important;
    padding: 10px 0 !important;

    > li {
      margin-bottom: 0;

      > a {
        padding-left: 38px;
        background: transparent;
        padding-right: 0;
      }
    }
  }

  span.dropper svg {
    fill: ${(props) => props.theme.internalSidebar.color.icon};
    width: 20px;
    height: 20px;
    position: absolute;
    margin: 0;
    top: 14px;
    right: 10px;
  }

  svg {
    margin-right: 25px;
    fill: ${(props) => props.theme.internalSidebar.color.icon};
  }
`;

export default NavigationLink;
