import * as React from 'react';

const loadScene = (module: string, scene: string) => {
  const DynamicScene = React.lazy(() => {
    if (process.env.NODE_ENV === 'development') {
      return import(/* webpackMode: "eager" */ `../../modules/${module}/scenes/${scene}.tsx`);
    }

    return import(/* webpackMode: "lazy" */ `../../modules/${module}/scenes/${scene}.tsx`);
  });

  const Scene = React.memo(() => (
    <React.Suspense fallback={<div />}>
      <DynamicScene />
    </React.Suspense>
  ));

  return Scene;
};

export { loadScene };
