import BaseSvgWrapper from './BaseSvgWrapper';

const Transfer = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.4142 5.58584C44.9575 6.12912 45.1415 6.93558 44.8877 7.66076L32.2877 43.6608C32.0168 44.4348 31.3009 44.965 30.4815 44.9984C29.6622 45.0318 28.9054 44.5617 28.5724 43.8123L21.8172 28.6131C21.7363 28.5537 21.6589 28.4874 21.5858 28.4143C21.5126 28.3411 21.4464 28.2637 21.3869 28.1829L6.18773 21.4277C5.43836 21.0946 4.96824 20.3379 5.00166 19.5185C5.03509 18.6992 5.5653 17.9832 6.3393 17.7123L42.3393 5.11234C43.0645 4.85853 43.8709 5.04257 44.4142 5.58584ZM25.5855 27.243L30.1788 37.5779L38.2171 14.6114L25.5855 27.243ZM35.3886 11.783L22.7571 24.4146L12.4222 19.8213L35.3886 11.783Z"
      />
    </svg>
  </BaseSvgWrapper>
);

export default Transfer;
