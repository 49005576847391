import * as React from 'react';
import { NestedGroup } from '../../../helpers/groupBy';
import { AllowedModulesFetcher, UserManager } from '../services';
import { UserManagerRenderProps } from '../services/UserManager';
import { Modules } from '../types/modules';
import { AccessRight, Account } from '../types/user';
import UnauthenticatedFlow from './UnauthenticatedFlow';

type RenderCallBackProps = {
  accessRights?: NestedGroup<AccessRight>;
  account?: Account;
  modules: Modules;
  isAuthenticated: boolean;
};

type RenderCallback = ({ modules, isAuthenticated }: RenderCallBackProps) => JSX.Element;

type Props = {
  children: RenderCallback;
};

const SecureModules: React.FC<Props> = ({ children }: Props) => (
  <UserManager>
    {({ isAuthenticated, account }: UserManagerRenderProps) => (
      <React.Fragment>
        {isAuthenticated ? (
          <AllowedModulesFetcher>
            {(modules: Modules) =>
              children({
                account,
                isAuthenticated,
                modules,
              })
            }
          </AllowedModulesFetcher>
        ) : (
          <UnauthenticatedFlow>{children}</UnauthenticatedFlow>
        )}
      </React.Fragment>
    )}
  </UserManager>
);

export default SecureModules;
