import { useContext } from 'react';
import { AccessRightsContext } from '../context/AccessRights';
import { FunctionalityAccessRight } from '../types/user';

const hasAccess = (accessRight: FunctionalityAccessRight, userAccessRights?: FunctionalityAccessRight[]) => {
  if (!userAccessRights) {
    return false;
  }

  return userAccessRights.includes(accessRight);
};

type CanAccessProps = {
  accessRight: FunctionalityAccessRight;
  children: JSX.Element;
};

export function CanAccess(props: CanAccessProps) {
  const context = useContext(AccessRightsContext);

  if (!context.functionalityAccessRights) {
    return null;
  }

  if (!hasAccess(props.accessRight, context.functionalityAccessRights)) {
    return null;
  }

  return props.children;
}

export default CanAccess;
