import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FullViewBlock, FullViewItem } from '../../../../components/FullView';
import { PartnerCenterProfile } from '../../types/product';

type Props = {
  partnerCenterProfile?: PartnerCenterProfile;
};

const PartnerCenterInfo: React.FC<Props> = ({ partnerCenterProfile }: Props) => {
  const { t } = useTranslation('products');

  return partnerCenterProfile && (partnerCenterProfile.offerId || partnerCenterProfile.offerUri) ? (
    <FullViewBlock>
      <h2 className="block-title">{t('fullView.blockTitles.partnerCenterProfile')}</h2>
      <div className="block-data">
        <FullViewItem label={t('fullView.labels.offerId')} value={partnerCenterProfile.offerId} />
        <FullViewItem label={t('fullView.labels.offerUri')} value={partnerCenterProfile.offerUri} />
        <FullViewItem
          label={t('fullView.labels.availabilityProductId')}
          value={partnerCenterProfile.availabilityProductId}
        />
        <FullViewItem label={t('fullView.labels.availabilitySkuId')} value={partnerCenterProfile.availabilitySkuId} />
        <FullViewItem label={t('fullView.labels.availabilityId')} value={partnerCenterProfile.availabilityId} />
        <FullViewItem label={t('fullView.labels.segment')} value={partnerCenterProfile.segment} />
      </div>
    </FullViewBlock>
  ) : null;
};

export default PartnerCenterInfo;
